import React from "react";

export default function IconCross() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
      <path
        d="M5.20971 4.50253C5.40517 4.69808 5.40517 5.01458 5.20971 5.20959C5.11221 5.30709 4.98421 5.35607 4.85613 5.35607C4.72814 5.35607 4.60015 5.30709 4.50265 5.20959L3.00018 3.70702L1.4977 5.20959C1.4002 5.30709 1.27221 5.35607 1.14422 5.35607C1.01614 5.35607 0.888145 5.30709 0.790642 5.20959C0.595177 5.01458 0.595177 4.69808 0.790642 4.50253L2.29321 3.00005L0.790642 1.49758C0.595177 1.30202 0.595177 0.985527 0.790642 0.79052C0.986198 0.595055 1.30215 0.595055 1.4977 0.79052L3.00018 2.29308L4.50265 0.79052C4.6982 0.595055 5.01415 0.595055 5.20971 0.79052C5.40517 0.985527 5.40517 1.30202 5.20971 1.49758L3.70715 3.00005L5.20971 4.50253Z"
        fill="#757575"
      />
    </svg>
  );
}
