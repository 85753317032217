import { createSlice } from "@reduxjs/toolkit";
import Base64 from "../utils/base64";
import JanusState from "../controls/janus/site/JanusState";

// ENUM_STATUS_WINDOW - режим мода для отображения
export const ENUM_STATUS_WINDOW = {
  loading: "Loading",
  stream: "Stream",
  group: "Paid Chat",
  pause: "Pause",
  offline: "Offline",
  over: "Over",
  noConnect: "No connect",
  cameraOff: "Camera off",
  someonePrivate: "Private Chat",
  someoneExclusivePrivate: "Exclusive Private",
  endPrivate: "End private",
};

// ENUM_CHAT_MODE - режим мода для сервера
export const ENUM_CHAT_MODE = {
  paid: "paid-chat",
  view: "view-chat",
  free: "free-chat",
  private: "private-chat",
  pause: "pause-chat",
  tip: "tip-chat",
  exclusive: "exclusive-chat",
};

// ENUM_CHAT_TAB - варианты чата с сообщениями
export const ENUM_CHAT_TAB = {
  free: "free",
  tips: "tips",
  lovense: "lovense",
};

export const ENUM_TAB = {
  video: "video",
  profile: "profile",
  photo: "photo",
  snapshot: "snapshot",
};

export const ENUM_ACCESS = {
  guest: "guest",
  regular: "regular",
  premium: "premium",
};

export const modeByStatus = {
  [ENUM_CHAT_MODE.free]: ENUM_STATUS_WINDOW.stream,
  [ENUM_CHAT_MODE.paid]: ENUM_STATUS_WINDOW.group,
  [ENUM_CHAT_MODE.pause]: ENUM_STATUS_WINDOW.pause,
  [ENUM_CHAT_MODE.exclusive]: ENUM_STATUS_WINDOW.someoneExclusivePrivate,
  [ENUM_CHAT_MODE.view]: ENUM_STATUS_WINDOW.someonePrivate,
  [ENUM_CHAT_MODE.private]: ENUM_STATUS_WINDOW.someonePrivate,
  [ENUM_STATUS_WINDOW.offline]: ENUM_STATUS_WINDOW.offline,
};

export const textTypeByType = {
  chargeback: "chargeback",
  deposit: "Deposit",
  spend: "Spend",
  exclusive: "exclusive",
  tips: "tips",
  "package-buy": "Deposit",
  "correction-minus": "Correction minus",
  "correction-plus": "Correction-plus",
  "refund-minus": "Refund minus",
  "refund-plus": "Refund plus",
  "balance-correction": "Balance correction",
  "direct-transfer": "Tips",
  "tips-list": "Tips (List)",
  "tips-lovense": "Tips (Lovense)",
  "private-chat": "Private chat",
  "paid-chat": "Paid chat",
  "view-chat": "View chat",
  "pause-chat": "pause",
  "exclusive-chat": "Exclusive chat",
};

const DEFAULT_LOVENSE = {
  enable: false,
  tips: {},
  toys: {},
  show: false,
  queue: {
    running: {},
    queue: [],
    waiting: [],
  },
};

const DEFAULT_GALLERY = {
  photos: [],
  snapshots: [],
  videos: [],
};

const chatSlice = createSlice({
  name: "chat",
  initialState: {
    avatar: "",
    statusWindow: ENUM_STATUS_WINDOW.loading,
    chatActiveTab: ENUM_CHAT_TAB.free,
    chatActiveMode: ENUM_CHAT_MODE.pause,
    pageActiveTab: ENUM_TAB.profile,
    privateUser: "",
    tips: [],
    tipsMenu: [],
    messages: [],
    messagePin: {},
    broadcasterProfile: {},
    broadcaster: {},
    myStream: {},
    viewPrice: 0,
    viewTime: 0,
    settingInfo: {},
    loadingBroadcaster: false,
    send: {
      value: "",
      isAnonymity: false,
    },
    lovense: DEFAULT_LOVENSE,
    gallery: DEFAULT_GALLERY,
  },
  reducers: {
    setAvatar(state, newAction) {
      state.avatar = newAction.payload;
    },
    setStatusWindow(state, newAction) {
      state.statusWindow = newAction.payload;
      JanusState.statusWindow = newAction.payload;
    },
    setPrivateUser(state, newAction) {
      state.privateUser = newAction.payload;
    },
    setBroadcaster(state, newAction) {
      state.broadcaster = newAction.payload || {};
    },
    setBroadcasterProfile(state, newAction) {
      state.broadcasterProfile = newAction.payload || {};
    },
    setChatActiveTab(state, newAction) {
      state.chatActiveTab = newAction.payload;
    },
    setChatActiveMode(state, newAction) {
      state.chatActiveMode = newAction.payload;
      if (newAction.payload) window.chatActiveMode = newAction.payload;
      if (newAction.payload === ENUM_CHAT_MODE.free) state.viewPrice = 0;
    },
    setPageActiveTab(state, newAction) {
      state.pageActiveTab = newAction.payload;
    },
    setMessages(state, newAction) {
      state.messages = newAction.payload;
    },
    setMessagePin(state, newAction) {
      state.messagePin = newAction.payload;
    },
    setNewMessages(state, newAction) {
      const newMes = newAction.payload;
      if (newMes.text) newMes.text = Base64.decode(newMes.text);
      state.messages = [...(state.messages || []), newMes];
    },
    seRemMessages(state, newAction) {
      const oldMes = [...(state.messages || [])];
      const remMes = newAction.payload;

      console.log(oldMes, remMes);
      const newMes = oldMes?.filter((item) => !remMes.includes(item.id));
      state.messages = [...(newMes || [])];
    },
    setMyStream(state, newAction) {
      state.myStream = newAction.payload;
    },
    setViewPrice(state, newAction) {
      state.viewPrice = newAction.payload;
    },
    setViewTime(state, newAction) {
      state.viewTime = newAction.payload;
    },
    setSettingInfo(state, newAction) {
      state.settingInfo = newAction.payload;
    },
    setTips(state, newAction) {
      state.tips = newAction.payload;
    },
    setLoadingBroadcaster(state, newAction) {
      state.loadingBroadcaster = newAction.payload;
    },
    setTipsMenu(state, newAction) {
      state.tipsMenu = newAction.payload;
    },
    setIsAnonymity(state, newAction) {
      state.send.isAnonymity = newAction.payload;
    },
    setSendValue(state, newAction) {
      state.send.value = newAction.payload;
    },
    setLovenseTips(state, newAction) {
      const tips = newAction.payload;
      state.lovense.tips = tips;
    },
    setLovenseToys(state, newAction) {
      state.lovense.toys = newAction.payload;
    },
    setLovenseEnable(state, newAction) {
      state.lovense.enable = newAction.payload;
    },
    setLovenseShow(state, newAction) {
      state.lovense.show = newAction.payload;
    },
    setLovenseQueue(state, newAction) {
      state.lovense.queue.queue = newAction.payload;
    },
    setLovenseRunning(state, newAction) {
      state.lovense.queue.running = newAction.payload;
    },
    setLovenseWaiting(state, newAction) {
      state.lovense.queue.waiting = newAction.payload;
    },
    setPhotos(state, newAction) {
      state.gallery.photos = newAction.payload;
    },
    setSnapshots(state, newAction) {
      state.gallery.snapshots = newAction.payload;
    },
    setVideos(state, newAction) {
      state.gallery.videos = newAction.payload;
    },
    clearBroadcaster(state) {
      state.gallery = DEFAULT_GALLERY;
      state.lovense = DEFAULT_LOVENSE;
      state.broadcasterProfile = {};
      state.broadcaster = {};
    },
  },
});

export default chatSlice.reducer;

export const {
  setAvatar,
  setStatusWindow,
  setPrivateUser,
  setBroadcaster,
  setChatActiveTab,
  setPageActiveTab,
  setMessages,
  setNewMessages,
  seRemMessages,
  setChatActiveMode,
  setMyStream,
  setViewPrice,
  setViewTime,
  setSettingInfo,
  setTips,
  setLoadingBroadcaster,
  setTipsMenu,
  setBroadcasterProfile,
  setMessagePin,
  setLovenseTips,
  setLovenseToys,
  setLovenseEnable,
  setLovenseShow,
  setLovenseQueue,
  setLovenseRunning,
  setLovenseWaiting,
  setSendValue,
  setIsAnonymity,
  clearBroadcaster,
  setPhotos,
  setSnapshots,
  setVideos,
} = chatSlice.actions;
