import React from "react";

export default function IconStartChare() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_26_14348)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.08167 6.92099e-05C7.44698 -0.00640597 6.98895 0.442652 6.58411 0.852241C6.17926 1.26183 5.79455 1.66677 5.49982 1.78535C5.20509 1.90392 4.64705 1.87821 4.07135 1.8631C3.49565 1.848 2.85432 1.84126 2.40095 2.28547C1.94758 2.72968 1.94123 3.37101 1.94459 3.9469C1.94794 4.5228 1.96222 5.08122 1.83765 5.37348C1.71309 5.66573 1.3004 6.04214 0.882637 6.43854C0.464876 6.83494 0.00656592 7.28365 6.9209e-05 7.91833C-0.00640594 8.55302 0.442653 9.01099 0.852241 9.41583C1.26183 9.82068 1.66677 10.2054 1.78535 10.5002C1.90392 10.7949 1.87827 11.3529 1.86317 11.9286C1.84806 12.5043 1.84126 13.1457 2.28547 13.5991C2.72968 14.0525 3.37107 14.0588 3.94696 14.0554C4.52285 14.0521 5.08122 14.0378 5.37348 14.1623C5.66573 14.2869 6.04214 14.6997 6.43854 15.1174C6.83494 15.5352 7.28366 15.9934 7.91834 15.9999C8.55302 16.0064 9.01105 15.5573 9.41589 15.1478C9.82074 14.7382 10.2054 14.3332 10.5002 14.2147C10.7949 14.0961 11.3529 14.1218 11.9286 14.1369C12.5043 14.152 13.1457 14.1588 13.5991 13.7146C14.0524 13.2704 14.0588 12.629 14.0554 12.0531C14.0521 11.4772 14.0378 10.9187 14.1623 10.6265C14.2869 10.3342 14.6996 9.95786 15.1174 9.56146C15.5351 9.16506 15.9934 8.71635 15.9999 8.08167C15.9999 8.08164 15.9999 8.08162 15.9999 8.0816C16.0064 7.44691 15.5573 6.98895 15.1478 6.58411C14.7382 6.17926 14.3332 5.79449 14.2147 5.49976C14.0961 5.20503 14.1217 4.64706 14.1368 4.07135C14.1519 3.49565 14.1587 2.85426 13.7145 2.40088C13.2703 1.94752 12.6289 1.94123 12.053 1.94459C11.4771 1.94794 10.9188 1.96222 10.6265 1.83765C10.3343 1.71309 9.95786 1.3004 9.56146 0.882637C9.16506 0.464874 8.71634 0.00656594 8.08167 6.92099e-05ZM7.99969 3.06251C10.7224 3.06251 12.9371 5.27712 12.9371 7.99988C12.9371 10.7226 10.7224 12.9373 7.99969 12.9373C5.27695 12.9373 3.06232 10.7226 3.06232 7.99988C3.06232 5.27712 5.27695 3.06251 7.99969 3.06251Z"
          fill="url(#paint0_linear_26_14348)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.99971 4.59375C7.73987 4.59375 7.52155 4.74326 7.38607 4.9035C7.25058 5.06375 7.16261 5.24312 7.08077 5.41846C6.91708 5.76914 6.74743 6.10005 6.66359 6.16095C6.57976 6.22186 6.21262 6.28095 5.82851 6.32825C5.63646 6.3519 5.43874 6.38015 5.24447 6.45948C5.0502 6.5388 4.84052 6.7002 4.76022 6.94733C4.67991 7.19446 4.75466 7.44828 4.8652 7.62665C4.97573 7.80502 5.11911 7.94415 5.26058 8.07618C5.54352 8.34023 5.80577 8.60379 5.83779 8.70234C5.86981 8.80089 5.81256 9.16831 5.73885 9.54823C5.702 9.73819 5.66778 9.93502 5.68319 10.1443C5.6986 10.3536 5.7873 10.6029 5.99752 10.7556C6.20774 10.9084 6.47225 10.9157 6.67604 10.8657C6.87984 10.8157 7.05645 10.7223 7.22573 10.6286C7.56428 10.4411 7.89602 10.2731 7.99965 10.2731C8.10327 10.2731 8.43503 10.4411 8.77357 10.6286C8.94285 10.7224 9.1194 10.8157 9.3232 10.8657C9.52699 10.9157 9.7915 10.9084 10.0017 10.7557C10.2119 10.603 10.3007 10.3537 10.3161 10.1444C10.3315 9.93514 10.2973 9.73831 10.2604 9.54835C10.1868 9.16842 10.1295 8.80102 10.1615 8.70246C10.1935 8.60391 10.4558 8.34028 10.7388 8.07624C10.8803 7.94422 11.0237 7.80514 11.1342 7.62678C11.2448 7.44841 11.3195 7.19458 11.2392 6.94745C11.1589 6.70032 10.9492 6.53893 10.755 6.4596C10.5607 6.38027 10.363 6.35197 10.1709 6.32831C9.78681 6.281 9.41966 6.22192 9.33583 6.16101C9.252 6.1001 9.0824 5.76921 8.91872 5.41852C8.83688 5.24318 8.74889 5.06382 8.61342 4.90356C8.47794 4.74331 8.25962 4.59375 7.99977 4.59375H7.99971Z"
          fill="url(#paint1_linear_26_14348)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_26_14348"
          x1="8"
          y1="0"
          x2="8"
          y2="17.3333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_26_14348"
          x1="7.99971"
          y1="4.59375"
          x2="7.99971"
          y2="11.4184"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_26_14348">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
