import React from "react";
import { useEventListener } from "usehooks-ts";
import SmileToIndx from "../../mui/smile/SmileToIndx";
import ShowContainer from "../../mui/ShowContainer";
import { useDispatch, useSelector } from "react-redux";
import { setSendValue } from "../../../store/chatSlice";

export default function ChatSmile({ TextareaRef, open, setOpen, TextAreaStartRef }) {
  const dispatch = useDispatch();
  const sendValue = useSelector((state) => state.chat.send.value);

  const smiles = new Array(30);
  smiles.fill(1);

  useEventListener("click", () => {
    setOpen(false);
  });

  return (
    <ShowContainer condition={open}>
      <div
        className="chat__popup__smiles"
        onClick={(e) => {
          e.stopPropagation();
          TextareaRef?.current?.focus?.();
        }}
      >
        {smiles.map((_, indx) => (
          <div key={indx} className="chat__popup__smiles__smile">
            {
              <SmileToIndx
                indx={indx}
                onClick={(e, evt) => {
                  console.log(e, evt);
                  evt.preventDefault();
                  evt.stopPropagation();
                  TextareaRef?.current?.focus?.();
                  const startNumber = TextAreaStartRef.current;
                  const newValue =
                    sendValue.slice(0, startNumber) + e + sendValue.slice(startNumber);

                  dispatch(setSendValue(newValue));
                }}
              />
            }
          </div>
        ))}
      </div>
    </ShowContainer>
  );
}
