import Janus from "../../Janus";
import { ENUM_CHAT_MODE } from "../../store/chatSlice";
import KronaFeedBase from "./KronaFeedBase";

const selfBroadcaster = "broadcaster";
const selfClient = "client";

export default class KronaRemoteFeed extends KronaFeedBase {
  constructor(chat, participant) {
    super(chat);
    this._remoteFeed = null;
    this._participant = participant;
    this._participantSave = participant;

    console.log("window.chatActiveMode", window.chatActiveMode);
    if (window.chatActiveMode !== ENUM_CHAT_MODE.pause) {
      this.start();
    }
    // this.start();
  }

  _onMessage(msg, jsep) {
    if (msg.chatroom) {
      if (msg.chatroom === "attached") {
        // TODO: multistream support
        this._remoteFeed.rfid = msg.id;
      } else if (msg.chatroom === "event") {
        // Check if we got an event on a simulcast-related event from this publisher
        if (msg.substream || msg.temporal) {
          if (!this._remoteFeed.simulcastStarted) this._remoteFeed.simulcastStarted = true;
        }
      } else if (msg.chatroom === "slow_link") {
        console.log("Slow link detected!");
      } else if (msg.error) {
        this._chat.emit("error", Janus, msg.error);
      } else {
        // What has just happened?
      }
    }

    if (jsep) {
      // Answer and attach
      this._remoteFeed.createAnswer({
        jsep: jsep,
        tracks: [{ type: "data" }],
        success: (jsep) => {
          this._remoteFeed.send({
            message: {
              request: "start",
              room: this._chat.options.roomId,
            },
            jsep: jsep,
          });
        },
        error: (error) => {
          Janus.error("WebRTC error: {error}", { error });
          error = JSON.stringify(error);
          this._chat.emit("error", Janus, `WebRTC error: {error}", ${error}`);
        },
      });
    }
  }

  async startStopByMode(mode) {
    if (!mode) return;

    if (!this._participant) {
      this._participant = this._participantSave;
    }

    if (mode === ENUM_CHAT_MODE.pause) {
      if (!this._remoteFeed) return;
      await this.stop();
      return;
    }

    if (this._remoteFeed) return;
    await this.start();

    console.log("_remoteFeed", this._remoteFeed);
  }

  async start() {
    return new Promise((resolve) => {
      // A new feed has been published, create a new plugin handle and attach to it as a listener

      console.log("_remoteFeed", "pred start", this._remoteFeed);
      if (this._remoteFeed) return resolve(true);

      console.log("_remoteFeed", "start");

      this._chat._session.janusObject.attach({
        plugin: "krona.plugin.chatroom",
        opaqueId: window.roomOpaqueID,
        success: (pluginHandle) => {
          this._remoteFeed = pluginHandle;
          this._remoteFeed.simulcastStarted = false;

          let subscription = [];

          if (!this._participant?.stream) resolve(false);

          for (const stream of this._participant.streams) {
            // If the publisher is VP8/VP9 and this is an older Safari, let's avoid video
            if (
              stream.type === "video" &&
              Janus.webRTCAdapter.browserDetails.browser === "safari" &&
              ((stream.codec === "vp9" && !Janus.safariVp9) ||
                (stream.codec === "vp8" && !Janus.safariVp8))
            ) {
              console.warn(
                "Publisher is using " +
                  stream.codec.toUpperCase +
                  ", but Safari doesn't support it: disabling video stream #" +
                  stream.mindex
              );
              continue;
            }
            subscription.push({
              feed: this._participant.id, // This is mandatory
              mid: stream.mid, // This is optional (all streams, if missing)
            });
          }
          // FIXME Right now, this is always the same feed: in the future, it won't
          this._remoteFeed.rfid = this._participant.id;
          this._remoteFeed.rfdisplay = this._participant.display;

          // We wait for the plugin to send us an offer
          this._remoteFeed.send({
            message: {
              request: "join",
              room: this._chat.options.roomId,
              ptype: "subscriber",
              private_id: this._chat._localfeed._mypvtid,
              streams: subscription,
              sid: document.cookie.match(/(?:^|\s)krona.chat.sid\s*=\s*([^;]+)/)?.pop() || "",
            },
          });

          resolve(true);
        },
        error: (e) => {
          Janus.error(e);
          resolve(false);
          console.log("error", e);
        },
        onmessage: (msg, jsep) => {
          this._onMessage(msg, jsep);
        },
        ondataend: (e) => {
          console.log("end", e);
        },
        ondataopen: (data) => {
          this._chat.emit("ondataopen", data);
        },
        ondata: (data) => {
          console.log("data", data);
          this._onData(data);
        },
        onlocaltrack: (track, on) => {
          // The subscriber stream is recvonly, we don't expect anything here
          console.log("track, on", track, on);
        },
        onremotetrack: (track, mid, on) => {
          Janus.debug(`Remote feed #${this._remoteFeed.rfid}`);

          if (this._chat.options.chattype === selfBroadcaster) {
            this._chat.emit("remotetrack", Janus, track, mid, on, this._remoteFeed);
          } else if (this._chat.options.chattype === selfClient) {
            this._chat.emit("remotetrack", Janus, track, mid, on, this._remoteFeed);
          }
        },
        oncleanup: () => {
          if (this._remoteFeed) this._remoteFeed.simulcastStarted = false;
        },
      });
    });
  }

  async stop() {
    if (this._remoteFeed)
      await new Promise((resolve) => {
        this._remoteFeed.detach({
          success: () => {
            setTimeout(resolve, 500);
          },
          error: resolve,
        });
      });
    this._remoteFeed = null;
    this._participant = undefined;
  }

  set isAudioMuted(mute) {
    if (this._remoteFeed) mute ? this._remoteFeed.muteAudio() : this._remoteFeed.unmuteAudio();
  }

  get isAudioMuted() {
    if (this._remoteFeed) return this._remoteFeed.isAudioMuted();
    return undefined;
  }

  get janusObject() {
    return this._remoteFeed;
  }

  get id() {
    return this._participant.id;
  }

  get userId() {
    return this._participant?.user_id;
  }

  get isGuest() {
    return this._participant.guest;
  }

  get nickname() {
    return this._participant.display;
  }

  get streams() {
    return this._participant?.streams;
  }

  set streams(streams) {
    this._participant.streams = streams;
  }
}
