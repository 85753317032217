import React, { useRef } from "react";

import { useTranslation } from "react-i18next";
import IconArrow from "../../images/jsIcons/IconArrow";
import FlagImg from "./FlagImg";
import { useSelector } from "react-redux";
import useLanguage from "../../hooks/other/useLanguage";

export default function ChoseLang({ classNameUl }) {
  const chooseLangRef = useRef(null);

  const { t } = useTranslation();
  const languages = useSelector((state) => state.common.languages);
  const [selectLang, setSelectLang] = useLanguage();

  const getSelect = (lang) => (lang === selectLang ? "select" : "");

  return (
    <button
      className="chose-lang"
      onMouseOver={() => chooseLangRef.current.classList.remove("close")}
    >
      <span className="lang-item main select">
        <FlagImg flagCode={languages.find((el) => el.lang == selectLang)?.flag} />

        <span className="text">
          {languages.find((el) => el.lang == selectLang)?.title || ""}
          <IconArrow className="chose-lang__arrow" />
        </span>
      </span>
      <ul ref={chooseLangRef} className={classNameUl}>
        {languages.map((el, indx) => (
          <li
            key={indx}
            onClick={() => {
              setSelectLang(el.lang, { path: "/" });
              chooseLangRef.current.classList.add("close");
            }}
            className={`lang-item ${getSelect(el.lang)}`}
          >
            <button>
              <FlagImg flagCode={el.flag} />
              <span>{t(el.title)}</span>
            </button>
          </li>
        ))}
      </ul>
      <div className="chose-lang__plug" />
    </button>
  );
}
