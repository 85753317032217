import React, { useEffect } from "react";
import CusSelect from "../mui/CusSelect";
import IconSort from "../../images/jsIcons/IconSort";
import { useDispatch, useSelector } from "react-redux";
import { setSortModel } from "../../store/commonSlice";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function SortBySelect() {
  const sortingItems = useSelector((state) => state.siteInfo.commonSiteInfo.options?.sorting);
  const navigate = useNavigate();

  const value = useSelector((state) => state.common.typeSort);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const value = sortingItems.find((el) => el.selected)?.value;
    if (value) dispatch(setSortModel(value));
  }, [sortingItems]);

  const TitleComp = () => (
    <>
      <IconSort />
      <span className="cus-select__title__text">{t("Sort by")}:</span>
    </>
  );

  return (
    <CusSelect
      valueProps={value || sortingItems[0].value}
      title={<TitleComp />}
      onChange={(e) => {
        navigate("/");
        dispatch(setSortModel(e));
      }}
      options={sortingItems}
    />
  );
}
