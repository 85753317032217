import React from "react";

export default function Simple17() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_740_10647)">
        <path
          d="M12 23.5C18.3513 23.5 23.5 18.3513 23.5 12C23.5 5.64873 18.3513 0.5 12 0.5C5.64873 0.5 0.5 5.64873 0.5 12C0.5 18.3513 5.64873 23.5 12 23.5Z"
          fill="#FFCE52"
        />
        <path
          d="M19 13.5C19 15.16 15.87 16.5 12 16.5C8.13 16.5 5 15.16 5 13.5C6.75 13.93 10.355 14 12 14C14.3413 13.9745 16.6789 13.8075 19 13.5Z"
          fill="#F6FAFD"
        />
        <path
          d="M5 13.5C5 15.16 8.13 16.5 12 16.5C15.87 16.5 19 15.16 19 13.5C18.9983 14.3711 18.7881 15.2291 18.3871 16.0023C17.9861 16.7756 17.4059 17.4417 16.695 17.945C15.3379 18.9547 13.6915 19.4999 12 19.4999C10.3085 19.4999 8.66209 18.9547 7.305 17.945C6.59406 17.4417 6.01386 16.7756 5.61288 16.0023C5.21189 15.2291 5.00174 14.3711 5 13.5Z"
          fill="#AE2D4C"
        />
        <path
          d="M15.5 10.5H14.5C14.5 9.9175 15.1235 8 16.5 8C17.8765 8 18.5 9.9175 18.5 10.5H17.5C17.5 10.1965 17.058 9 16.5 9C15.942 9 15.5 10.1965 15.5 10.5Z"
          fill="#273941"
        />
        <path
          d="M6.5 10.5H5.5C5.5 9.9175 6.1235 8 7.5 8C8.8765 8 9.5 9.9175 9.5 10.5H8.5C8.5 10.1965 8.058 9 7.5 9C6.942 9 6.5 10.1965 6.5 10.5Z"
          fill="#273941"
        />
        <path
          d="M5.5 7.5V6.5C7.361 6.5 8.5 5.8525 8.5 5.5H9.5C9.5 6.813 7.488 7.5 5.5 7.5Z"
          fill="#273941"
        />
        <path
          d="M18.5 7.5C16.512 7.5 14.5 6.813 14.5 5.5H15.5C15.5 5.8525 16.639 6.5 18.5 6.5V7.5Z"
          fill="#273941"
        />
        <path
          d="M11.9992 1.99911C18.0742 1.99911 22.9992 6.25261 22.9992 11.4991H23.4867C23.3489 8.54514 22.0784 5.7578 19.9392 3.71607C17.7999 1.67434 14.9564 0.535156 11.9992 0.535156C9.04203 0.535156 6.1985 1.67434 4.05927 3.71607C1.92005 5.7578 0.649561 8.54514 0.511719 11.4991H0.999219C0.999219 6.25261 5.92422 1.99911 11.9992 1.99911Z"
          fill="#FFE369"
        />
        <path
          d="M23 11.5C23 16.7465 18.075 21 12 21C5.925 21 1 16.7465 1 11.5H0.5125C0.5055 11.666 0.5 11.8325 0.5 12C0.5 15.05 1.7116 17.9751 3.86827 20.1317C6.02494 22.2884 8.95001 23.5 12 23.5C15.05 23.5 17.9751 22.2884 20.1317 20.1317C22.2884 17.9751 23.5 15.05 23.5 12C23.5 11.8325 23.4945 11.666 23.4875 11.5H23Z"
          fill="#FFB32B"
        />
        <path
          d="M18.9064 4.90641C19.0674 4.74526 19.0162 4.43297 18.7919 4.20889C18.5677 3.98481 18.2553 3.93379 18.0943 4.09494C17.9333 4.25608 17.9845 4.56837 18.2088 4.79246C18.433 5.01654 18.7453 5.06756 18.9064 4.90641Z"
          fill="#F6FAFD"
        />
        <path
          d="M15.5757 2.45108C15.6563 2.3704 15.6307 2.21418 15.5186 2.10214C15.4065 1.9901 15.2503 1.96467 15.1696 2.04534C15.089 2.12601 15.1146 2.28223 15.2267 2.39427C15.3388 2.50632 15.4951 2.53175 15.5757 2.45108Z"
          fill="#F6FAFD"
        />
        <path
          d="M17.8115 4.31353C18.1335 3.99123 18.0309 3.36649 17.5822 2.91813C17.1336 2.46977 16.5087 2.36758 16.1867 2.68987C15.8646 3.01217 15.9672 3.63691 16.4159 4.08527C16.8646 4.53363 17.4894 4.63582 17.8115 4.31353Z"
          fill="#F6FAFD"
        />
        <path
          d="M18.5535 14.55C18.826 14.2666 18.9849 13.8929 19 13.5C16.6789 13.8075 14.3413 13.9745 12 14C10.355 14 6.75 13.93 5 13.5C5.01832 13.9159 5.19423 14.3091 5.492 14.6C7.64875 14.8911 9.82383 15.0248 12 15C14.1915 14.9803 16.3799 14.83 18.5535 14.55Z"
          fill="#EDEDED"
        />
        <path
          d="M18.8913 14.0119C18.3248 15.4269 15.4622 16.5004 11.9998 16.5004C8.54975 16.5004 5.69175 15.4334 5.11325 14.0254C5.08625 14.0189 5.05725 14.0134 5.03125 14.0074C5.04575 14.1664 5.06775 14.3219 5.09525 14.4744C5.62525 15.9079 8.50725 17.0004 11.9998 17.0004C15.4923 17.0004 18.3742 15.9079 18.9042 14.4744C18.9317 14.3209 18.9543 14.1644 18.9683 14.0039L18.8913 14.0119Z"
          fill="#8A293D"
        />
        <path
          d="M22.64 7.64C22.3405 7.84926 21.9893 7.97233 21.6247 7.99579C21.2601 8.01926 20.8961 7.94222 20.5722 7.77308C20.2484 7.60393 19.9771 7.34917 19.7881 7.03653C19.599 6.72389 19.4994 6.36536 19.5 6C19.5897 5.26553 19.8134 4.55375 20.16 3.9C20.625 2.905 21.22 2 21.5 2C22 2 23.5 4.895 23.5 6C23.5003 6.32259 23.4221 6.64041 23.2723 6.9261C23.1225 7.21179 22.9055 7.45679 22.64 7.64Z"
          fill="#3BC5F6"
        />
        <path
          d="M19.511 6.10938C19.505 6.18788 19.5 6.26388 19.5 6.33238C19.5 7.80538 20.395 8.99888 21.5 8.99888C21.8614 8.99123 22.21 8.86371 22.491 8.63637C22.3963 8.37838 22.2893 8.12521 22.17 7.87687C21.9555 7.95633 21.7287 7.99762 21.5 7.99888C20.9897 7.99818 20.499 7.80189 20.129 7.45041C19.7591 7.09893 19.5379 6.61899 19.511 6.10938Z"
          fill="#FFB32B"
        />
        <path
          d="M22.6409 8.51822C22.7257 8.438 22.8043 8.35141 22.8759 8.25922L22.8479 8.17672C22.7979 8.04222 22.7479 7.90922 22.6979 7.77672C22.6824 7.73722 22.6674 7.69772 22.6514 7.65822L22.6419 7.63672C22.4962 7.73608 22.3382 7.81608 22.1719 7.87472C22.2909 8.12272 22.3979 8.37588 22.4929 8.63422C22.5419 8.59822 22.5934 8.56172 22.6409 8.51822Z"
          fill="#FFCE52"
        />
        <path
          d="M21.5 2C21.22 2 20.625 2.905 20.16 3.9C19.8134 4.55375 19.5897 5.26553 19.5 6C19.5 6.53043 19.7107 7.03914 20.0858 7.41421C20.4609 7.78929 20.9696 8 21.5 8C22.0304 8 22.5391 7.78929 22.9142 7.41421C23.2893 7.03914 23.5 6.53043 23.5 6C23.5 4.895 22 2 21.5 2ZM22.355 6.26C22.0976 6.41728 21.8017 6.50033 21.5 6.5C21.1245 6.52093 20.756 6.39243 20.475 6.14258C20.194 5.89274 20.0232 5.54183 20 5.1665C20.0691 4.67128 20.2375 4.1951 20.495 3.7665C20.8435 3.1035 21.29 2.5 21.5 2.5C21.875 2.5 23 4.43 23 5.1665C22.9957 5.38887 22.9341 5.60637 22.8211 5.79794C22.7081 5.98951 22.5475 6.14866 22.355 6.26Z"
          fill="#00A3E1"
        />
        <path
          d="M22.9064 5.90641C23.0674 5.74526 23.0162 5.43297 22.7919 5.20889C22.5677 4.98481 22.2553 4.93379 22.0943 5.09494C21.9333 5.25608 21.9845 5.56837 22.2088 5.79246C22.433 6.01654 22.7453 6.06756 22.9064 5.90641Z"
          fill="#F6FAFD"
        />
        <path
          d="M22.5754 4.45108C22.656 4.3704 22.6305 4.21418 22.5184 4.10214C22.4063 3.9901 22.25 3.96467 22.1694 4.04534C22.0888 4.12601 22.1143 4.28223 22.2264 4.39427C22.3386 4.50632 22.4948 4.53175 22.5754 4.45108Z"
          fill="#F6FAFD"
        />
      </g>
      <defs>
        <clipPath id="clip0_740_10647">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
