import { getShortLang } from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { API_INFO } from "../../api/info";
import { setNewSiteInfo } from "../../store/siteInfoSlice";
import i18n from "../../i18n";
import { useCookies } from "react-cookie";

export const ENUM_LANG_LISTENER = {
  auto: "auto",
};

export default function useLanguage() {
  const shortLang = getShortLang();
  const dispatch = useDispatch();
  const languages = useSelector((state) => state.common.languages);
  const chosenLang = languages.find((el) => el.code === shortLang)?.lang;

  const [{ language: cookieLang }, setCookieLang] = useCookies(["language"]);

  useEffect(() => {
    // если в куке есть, то больше не заполняем
    if (cookieLang && cookieLang !== "undefined") return;

    setCookieLang("language", chosenLang, { path: "/" });
  }, [cookieLang, chosenLang]);

  useEffect(() => {
    API_INFO.listSiteCategory()
      .then((e) => {
        dispatch(setNewSiteInfo(e.data.categories));
      })
      .catch((e) => console.log(e))
      .finally(() => {
        const code = cookieLang?.split?.("_")?.[0];
        if (!code) return;
        i18n.changeLanguage(code);
      });
  }, [cookieLang]);

  return [cookieLang, (lang) => setCookieLang("language", lang, { path: "/" })];
}
