import React from "react";

export default function IconCrown() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="78" height="78" viewBox="0 0 78 78" fill="none">
      <path
        d="M55.25 71.5H22.75C21.4175 71.5 20.3125 70.395 20.3125 69.0625C20.3125 67.73 21.4175 66.625 22.75 66.625H55.25C56.5825 66.625 57.6875 67.73 57.6875 69.0625C57.6875 70.395 56.5825 71.5 55.25 71.5Z"
        fill="#FFEF5F"
      />
      <path
        d="M66.1367 17.9399L53.1367 27.2349C51.4142 28.4699 48.9442 27.7224 48.1967 25.7399L42.0542 9.35988C41.0142 6.53238 37.0167 6.53238 35.9767 9.35988L29.8017 25.7074C29.0542 27.7224 26.6167 28.4699 24.8942 27.2024L11.8942 17.9074C9.29425 16.0874 5.84925 18.6549 6.92175 21.6774L20.4417 59.5399C20.8967 60.8399 22.1317 61.6849 23.4967 61.6849H54.4692C55.8342 61.6849 57.0692 60.8074 57.5242 59.5399L71.0442 21.6774C72.1492 18.6549 68.7042 16.0874 66.1367 17.9399ZM47.1242 47.9374H30.8742C29.5417 47.9374 28.4367 46.8324 28.4367 45.4999C28.4367 44.1674 29.5417 43.0624 30.8742 43.0624H47.1242C48.4567 43.0624 49.5617 44.1674 49.5617 45.4999C49.5617 46.8324 48.4567 47.9374 47.1242 47.9374Z"
        fill="#FFEF5F"
      />
    </svg>
  );
}
