import React from "react";

export default function Smile1() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_740_10331)">
        <path
          d="M12 23.5C18.3513 23.5 23.5 18.3513 23.5 12C23.5 5.64873 18.3513 0.5 12 0.5C5.64873 0.5 0.5 5.64873 0.5 12C0.5 18.3513 5.64873 23.5 12 23.5Z"
          fill="#FFCE52"
        />
        <path
          d="M11.9992 1.99911C18.0742 1.99911 22.9992 6.25261 22.9992 11.4991H23.4867C23.3489 8.54514 22.0784 5.7578 19.9392 3.71607C17.7999 1.67434 14.9564 0.535156 11.9992 0.535156C9.04203 0.535156 6.1985 1.67434 4.05927 3.71607C1.92005 5.7578 0.649561 8.54514 0.511719 11.4991H0.999219C0.999219 6.25261 5.92422 1.99911 11.9992 1.99911Z"
          fill="#FFE369"
        />
        <path
          d="M23 11.5C23 16.7465 18.075 21 12 21C5.925 21 1 16.7465 1 11.5H0.5125C0.5055 11.666 0.5 11.8325 0.5 12C0.5 15.05 1.7116 17.9751 3.86827 20.1317C6.02494 22.2884 8.95001 23.5 12 23.5C15.05 23.5 17.9751 22.2884 20.1317 20.1317C22.2884 17.9751 23.5 15.05 23.5 12C23.5 11.8325 23.4945 11.666 23.4875 11.5H23Z"
          fill="#FFB32B"
        />
        <path
          d="M18.6564 4.65641C18.8174 4.49526 18.7662 4.18297 18.5419 3.95889C18.3177 3.73481 18.0053 3.68379 17.8443 3.84494C17.6833 4.00608 17.7345 4.31837 17.9588 4.54246C18.183 4.76654 18.4953 4.81756 18.6564 4.65641Z"
          fill="#F6FAFD"
        />
        <path
          d="M15.3254 2.20498C15.406 2.12431 15.3805 1.96809 15.2684 1.85604C15.1563 1.744 15 1.71857 14.9194 1.79924C14.8388 1.87992 14.8643 2.03614 14.9764 2.14818C15.0886 2.26022 15.2448 2.28565 15.3254 2.20498Z"
          fill="#F6FAFD"
        />
        <path
          d="M17.5634 4.06353C17.8855 3.74123 17.7829 3.11649 17.3342 2.66813C16.8855 2.21977 16.2607 2.11758 15.9386 2.43987C15.6166 2.76217 15.7192 3.38691 16.1679 3.83527C16.6165 4.28363 17.2413 4.38582 17.5634 4.06353Z"
          fill="#F6FAFD"
        />
        <path
          d="M17.5 17.5C19.433 17.5 21 15.933 21 14C21 12.067 19.433 10.5 17.5 10.5C15.567 10.5 14 12.067 14 14C14 15.933 15.567 17.5 17.5 17.5Z"
          fill="url(#paint0_radial_740_10331)"
        />
        <path
          d="M6.5 17.5C8.433 17.5 10 15.933 10 14C10 12.067 8.433 10.5 6.5 10.5C4.567 10.5 3 12.067 3 14C3 15.933 4.567 17.5 6.5 17.5Z"
          fill="url(#paint1_radial_740_10331)"
        />
        <path
          d="M12 19.5012C11.6022 19.5012 11.2206 19.3431 10.9393 19.0618C10.658 18.7805 10.5 18.399 10.5 18.0012H11.5C11.5 18.1 11.5293 18.1967 11.5843 18.2789C11.6392 18.3612 11.7173 18.4253 11.8087 18.4631C11.9 18.5009 12.0006 18.5108 12.0975 18.4915C12.1945 18.4723 12.2836 18.4246 12.3536 18.3547C12.4235 18.2848 12.4711 18.1957 12.4904 18.0987C12.5097 18.0017 12.4998 17.9012 12.4619 17.8098C12.4241 17.7185 12.36 17.6404 12.2778 17.5854C12.1956 17.5305 12.0989 17.5012 12 17.5012C11.8674 17.5012 11.7402 17.4485 11.6464 17.3547C11.5527 17.2609 11.5 17.1338 11.5 17.0012C11.5 16.8685 11.5527 16.7414 11.6464 16.6476C11.7402 16.5538 11.8674 16.5012 12 16.5012C12.0989 16.5012 12.1956 16.4718 12.2778 16.4169C12.36 16.3619 12.4241 16.2839 12.4619 16.1925C12.4998 16.1011 12.5097 16.0006 12.4904 15.9036C12.4711 15.8066 12.4235 15.7175 12.3536 15.6476C12.2836 15.5777 12.1945 15.5301 12.0975 15.5108C12.0006 15.4915 11.9 15.5014 11.8087 15.5392C11.7173 15.5771 11.6392 15.6411 11.5843 15.7234C11.5293 15.8056 11.5 15.9023 11.5 16.0012H10.5C10.4998 15.7579 10.5588 15.5182 10.6718 15.3029C10.7849 15.0875 10.9487 14.9028 11.149 14.7648C11.3493 14.6268 11.5802 14.5395 11.8217 14.5106C12.0632 14.4817 12.3082 14.512 12.5354 14.5988C12.7627 14.6856 12.9654 14.8264 13.1261 15.0091C13.2868 15.1917 13.4007 15.4106 13.4579 15.6471C13.5151 15.8835 13.514 16.1303 13.4546 16.3662C13.3952 16.6021 13.2794 16.82 13.117 17.0012C13.31 17.2169 13.4364 17.4839 13.481 17.7699C13.5257 18.0559 13.4866 18.3488 13.3685 18.6131C13.2504 18.8773 13.0584 19.1018 12.8155 19.2594C12.5727 19.417 12.2895 19.501 12 19.5012Z"
          fill="#273941"
        />
        <path
          d="M19 10.5H18C18 10.1022 17.842 9.72064 17.5607 9.43934C17.2794 9.15804 16.8978 9 16.5 9C16.1022 9 15.7206 9.15804 15.4393 9.43934C15.158 9.72064 15 10.1022 15 10.5H14C14 9.83696 14.2634 9.20107 14.7322 8.73223C15.2011 8.26339 15.837 8 16.5 8C17.163 8 17.7989 8.26339 18.2678 8.73223C18.7366 9.20107 19 9.83696 19 10.5Z"
          fill="#273941"
        />
        <path
          d="M10 10.5H9C9 10.1022 8.84196 9.72064 8.56066 9.43934C8.27936 9.15804 7.89782 9 7.5 9C7.10218 9 6.72064 9.15804 6.43934 9.43934C6.15804 9.72064 6 10.1022 6 10.5H5C5 9.83696 5.26339 9.20107 5.73223 8.73223C6.20107 8.26339 6.83696 8 7.5 8C8.16304 8 8.79893 8.26339 9.26777 8.73223C9.73661 9.20107 10 9.83696 10 10.5Z"
          fill="#273941"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_740_10331"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(17.506 14.0049) scale(3.501)"
        >
          <stop stopColor="#FF568D" />
          <stop offset="0.454" stopColor="#FF9678" />
          <stop offset="0.817" stopColor="#FFC26A" />
          <stop offset="1" stopColor="#FFD364" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_740_10331"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(6.502 14.0049) scale(0.29175)"
        >
          <stop stopColor="#FF568D" />
          <stop offset="0.454" stopColor="#FF9678" />
          <stop offset="0.817" stopColor="#FFC26A" />
          <stop offset="1" stopColor="#FFD364" />
        </radialGradient>
        <clipPath id="clip0_740_10331">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
