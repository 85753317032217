import KronaChatSettings from "./KronaChatSettings";
import KronaChatSession from "./KronaChatSession";
import KronaLocalFeed from "./KronaLocalFeed";
import Janus from "../../Janus";
import EventEmitter from "./EventEmitter";
import { ENUM_CHAT_MODE } from "../../store/chatSlice";
import { errorToast, infoToast } from "../../components/mui/Toaster";
import Base64 from "../../utils/base64";
import { debugLog } from "../../components/common/consts";
import { getRandomItemInList } from "../../utils/utils";
import JanusState from "./site/JanusState";

const serverDefault = "wss://demo.krona.chat/stream-ws";
const serverList = window.startConfig?.settings?.mediaserver?.list;
const server = getRandomItemInList(serverList, serverDefault);
console.log("server", server);
const chattype = ENUM_CHAT_MODE.pause;

export default class KronaChatBase extends EventEmitter {
  constructor(options) {
    super();

    const defoptions = {
      mode: "client",
      user: {},
    };

    this._$container = {};
    // this._$resizable = {};

    this.options = { ...defoptions, ...options };
    this._settings = new KronaChatSettings(this);
    this._session = new KronaChatSession(this, server);
    this._localfeed = new KronaLocalFeed(this);
    this._remotefeeds = {};
    this._chatId = 0;
    /**
     * @deprecated
     */
    this.chatMode = "";
  }

  async initialize(roomId, chatId) {
    this.roomId = roomId;
    this._chatId = chatId;

    const initialize = async () => {
      let result;

      result = await this._session.start();

      if (result === false) return false;
      if (typeof result === "string") {
        console.warn(result);
        return false;
      }

      result = await this._localfeed.start();

      if (result === false) return false;
      if (typeof result === "string") {
        console.warn(result);
        return false;
      }

      const url = this._chatId ? `/api/chat/${this._chatId}/join` : "/api/chat/join";

      const dataJoin = {
        mode: chattype,
        room: this.options.roomId,
        session: this._session.janusObject.getSessionId(),
        plugin: this._localfeed.janusObject.getId(),
        restore: JanusState.isRestore ?? true,
      };

      if (this.chatId) delete dataJoin.room;

      const jointRes = await (async () => {
        const response = await fetch(url, {
          headers: { "Content-Type": "application/json; charset=utf-8" },
          method: "POST",
          body: JSON.stringify(dataJoin),
        });

        if (!response.ok) {
          console.error(
            response.statusText,
            `Server error {status}, { status: ${response.status} }`
          );

          console.log(response);

          if (response?.message) errorToast(response?.message);

          return false;
        }

        const json = await response.json();
        JanusState.isRestore = true;

        if (json.ok === true) {
          this._chatId = json.result?.chat?.id;
          this.chatMode = json.result?.chat?.mode;
          this.participantMode = json.result?.participant?.mode;

          this.emit("joined.connect", json.result);

          if (json.message) console.info(json.message);
          infoToast(json.message);
        } else if (json.message) {
          console.error(json.message);
          if (debugLog) errorToast(json.message);
          return false;
        }

        return true;
      })();

      console.log("jointRes", jointRes);

      if (jointRes === false) return false;

      result = true;

      if (this.settings.ownVideoEnabled) {
        result = await this._localfeed.publish();
      }

      if (result === false) return false;
      if (result === false) return false;
      if (typeof result === "string") {
        console.warning(result);
        return false;
      }

      if (this._settings.ownAudioMuted) await this._localfeed.setAudioMuted(true);
      if (this._settings.ownVideoMuted) await this._localfeed.setVideoMuted(true);

      return true;
    };

    return new Promise((resolve) => {
      Janus.init({
        // debug: true,
        debug: false,
        callback: async () => {
          try {
            for (const feed of Object.values(this._remotefeeds)) await feed.stop();
            await this._localfeed.stop();
            const result = await initialize();
            resolve(result);
          } catch (e) {
            console.log(e);
            resolve(false);
          }
        },
      });
    });
  }

  async destroy() {
    JanusState.inDestroy = true;
    for (const feed of Object.values(this._remotefeeds)) await feed.stop();
    await this._localfeed.stop();
    await this._session.stop();
    this._remotefeeds = {};
    this._chatId = 0;
    JanusState.inDestroy = false;
  }

  setVideoResolution(resolution) {
    // const changeVideoMaxSize = () => {
    //   const ratio = parseInt(resolution.width) / parseInt(resolution.height);

    //   this._$resizable.height(this._$resizable.width() / ratio);
    //   this._$resizable.resizable({
    //     containment: ".chat-out",
    //     alsoResize: ".chat-left .slimScrollDiv,.cont_video_middle",
    //     aspectRatio: ratio,
    //   });
    // };

    // changeVideoMaxSize();
    this._settings.videoResolution = resolution;
  }

  async ban(info) {
    const response = await fetch("/api/chat/ban", {
      headers: { "Content-Type": "application/json; charset=utf-8" },
      method: "POST",
      body: JSON.stringify({ list: info }),
    });

    if (!response.ok) {
      console.error(response.statusText, ("Server error {status}", { status: response.status }));
      return false;
    }

    const json = await response.json();
    if (json.ok === true) {
      if (json.message) console.info(json.message);
      return true;
    } else {
      if (json.message) console.error(json.message);
      return false;
    }
  }

  async autoplay($video) {
    if (!$video.length) return false;

    try {
      const video = $video.get(0);
      const play = await video.play();
      return play !== false;
    } catch (e) {
      Janus.error(e);
      return false;
    }
  }

  async history(messageId = 0, count = 50) {
    if (!this._chatId) return false;

    try {
      const response = await fetch(`/api/chat/${this._chatId}/history`, {
        headers: { "Content-Type": "application/json; charset=utf-8" },
        method: "POST",
        body: JSON.stringify({
          message_id: messageId,
          count: count,
        }),
      });

      if (!response.ok) {
        console.error(response.statusText, ("Server error {status}", { status: response.status }));
        return false;
      }

      try {
        const json = await response.json();
        if (json.ok === true) {
          if (json.message) console.info(json.message);
          json.list.messages = json.list.messages.map((el) => ({
            ...el,
            text: Base64.decode(el.text),
          }));
          return json.list;
        }
        if (json.message) console.error(json.message);
      } catch (e) {
        console.error(
          response.statusText,
          ("Server response error: {error}", { error: e.message || e })
        );
      }
    } catch (e) {
      console.error(("Server request error: {error}", { error: e.message || e }));
    }

    return false;
  }

  async removeHistory(messageId) {
    if (!this._chatId) return false;

    try {
      const response = await fetch(
        `/api/chat/${this._chatId}/history/message/${messageId}/delete`,
        {
          headers: { "Content-Type": "application/json; charset=utf-8" },
          method: "POST",
          body: "{}",
        }
      );

      if (!response.ok) {
        console.error(response.statusText, ("Server error {status}", { status: response.status }));
        return false;
      }

      try {
        const json = await response.json();
        if (json.ok === true) {
          if (json.message) console.info(json.message);
          return json.result;
        }
        if (json.message) console.error(json.message);
      } catch (e) {
        console.error(
          response.statusText,
          ("Server response error: {error}", { error: e.message || e })
        );
      }
    } catch (e) {
      console.error(("Server request error: {error}", { error: e.message || e }));
    }

    return false;
  }

  set roomId(roomId) {
    this.options.roomId = roomId;
  }

  get roomId() {
    return this.options.roomId;
  }

  get chatId() {
    return this._chatId;
  }

  get localfeed() {
    return this._localfeed;
  }

  get remotefeeds() {
    return this._remotefeeds;
  }

  get broadcasterFeed() {
    const feeds = Object.values(this._remotefeeds).filter((v) => v.userId === this.roomId);
    return feeds.length ? feeds[0] : null;
  }

  get settings() {
    return this._settings;
  }

  get isVideoMuted() {
    return this._localfeed.isVideoMuted;
  }

  /** 
  @deprecated
  */
  get isOnline() {
    console.log(this._session.janusObject, this._localfeed.janusObject);
    return this._session.janusObject !== null && this._localfeed.janusObject !== null;
  }
}
