import React from "react";

export default function Simple24() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_740_10806)">
        <path
          d="M12 23.5C18.3513 23.5 23.5 18.3513 23.5 12C23.5 5.64873 18.3513 0.5 12 0.5C5.64873 0.5 0.5 5.64873 0.5 12C0.5 18.3513 5.64873 23.5 12 23.5Z"
          fill="#FFCE52"
        />
        <path
          d="M11.9992 1.99911C18.0742 1.99911 22.9992 6.25261 22.9992 11.4991H23.4867C23.3489 8.54514 22.0784 5.7578 19.9392 3.71607C17.7999 1.67434 14.9564 0.535156 11.9992 0.535156C9.04203 0.535156 6.1985 1.67434 4.05927 3.71607C1.92005 5.7578 0.649561 8.54514 0.511719 11.4991H0.999219C0.999219 6.25261 5.92422 1.99911 11.9992 1.99911Z"
          fill="#FFE369"
        />
        <path
          d="M23 11.5C23 16.7465 18.075 21 12 21C5.925 21 1 16.7465 1 11.5H0.5125C0.5055 11.666 0.5 11.8325 0.5 12C0.5 15.05 1.7116 17.9751 3.86827 20.1317C6.02494 22.2884 8.95001 23.5 12 23.5C15.05 23.5 17.9751 22.2884 20.1317 20.1317C22.2884 17.9751 23.5 15.05 23.5 12C23.5 11.8325 23.4945 11.666 23.4875 11.5H23Z"
          fill="#FFB32B"
        />
        <path
          d="M18.9064 4.40641C19.0674 4.24526 19.0162 3.93297 18.7919 3.70889C18.5677 3.48481 18.2553 3.43379 18.0943 3.59494C17.9333 3.75608 17.9845 4.06837 18.2088 4.29246C18.433 4.51654 18.7453 4.56756 18.9064 4.40641Z"
          fill="#F6FAFD"
        />
        <path
          d="M15.5754 1.95108C15.656 1.8704 15.6305 1.71418 15.5184 1.60214C15.4063 1.4901 15.25 1.46467 15.1694 1.54534C15.0888 1.62601 15.1143 1.78223 15.2264 1.89427C15.3386 2.00632 15.4948 2.03175 15.5754 1.95108Z"
          fill="#F6FAFD"
        />
        <path
          d="M17.8134 3.81353C18.1355 3.49123 18.0329 2.86649 17.5842 2.41813C17.1355 1.96977 16.5107 1.86758 16.1886 2.18987C15.8666 2.51217 15.9692 3.13691 16.4179 3.58527C16.8665 4.03363 17.4913 4.13582 17.8134 3.81353Z"
          fill="#F6FAFD"
        />
        <path
          d="M12 18C13.1046 18 14 16.8807 14 15.5C14 14.1193 13.1046 13 12 13C10.8954 13 10 14.1193 10 15.5C10 16.8807 10.8954 18 12 18Z"
          fill="#273941"
        />
        <path
          d="M12 13.5C13.036 13.5 13.878 14.4885 13.98 15.75C13.9865 15.6665 14 15.5855 14 15.5C14 14.1195 13.1045 13 12 13C10.8955 13 10 14.1195 10 15.5C10 15.5855 10.0135 15.6665 10.02 15.75C10.122 14.4885 10.964 13.5 12 13.5Z"
          fill="#141E21"
        />
        <path
          d="M12.0004 18C12.4055 17.9738 12.7932 17.8257 13.1126 17.5751C13.432 17.3246 13.6681 16.9832 13.7899 16.596C13.2735 16.2091 12.6457 16 12.0004 16C11.3552 16 10.7273 16.2091 10.2109 16.596C10.3328 16.9832 10.5689 17.3246 10.8883 17.5751C11.2076 17.8257 11.5953 17.9738 12.0004 18Z"
          fill="#AE2D4C"
        />
        <path
          d="M11.9999 17.5001C11.6668 17.4854 11.3434 17.383 11.0624 17.2033C10.7815 17.0236 10.5529 16.7729 10.3999 16.4766C10.3374 16.5166 10.2709 16.5516 10.2109 16.5961C10.3327 16.9832 10.5688 17.3245 10.888 17.5751C11.2073 17.8256 11.5949 17.9738 11.9999 18.0001C12.405 17.9739 12.7927 17.8258 13.1121 17.5752C13.4315 17.3246 13.6676 16.9833 13.7894 16.5961C13.7294 16.5516 13.6629 16.5166 13.6004 16.4766C13.4474 16.773 13.2188 17.0237 12.9378 17.2034C12.6567 17.3831 12.3332 17.4855 11.9999 17.5001Z"
          fill="#8A293D"
        />
        <path
          d="M7 11.5C6.3372 11.4992 5.70178 11.2356 5.23311 10.7669C4.76444 10.2982 4.50079 9.6628 4.5 9H5.5C5.5 9.39782 5.65804 9.77936 5.93934 10.0607C6.22064 10.342 6.60218 10.5 7 10.5C7.39782 10.5 7.77936 10.342 8.06066 10.0607C8.34196 9.77936 8.5 9.39782 8.5 9H9.5C9.49921 9.6628 9.23556 10.2982 8.76689 10.7669C8.29822 11.2356 7.6628 11.4992 7 11.5Z"
          fill="#273941"
        />
        <path
          d="M17 11.5C16.3372 11.4992 15.7018 11.2356 15.2331 10.7669C14.7644 10.2982 14.5008 9.6628 14.5 9H15.5C15.5 9.39782 15.658 9.77936 15.9393 10.0607C16.2206 10.342 16.6022 10.5 17 10.5C17.3978 10.5 17.7794 10.342 18.0607 10.0607C18.342 9.77936 18.5 9.39782 18.5 9H19.5C19.4992 9.6628 19.2356 10.2982 18.7669 10.7669C18.2982 11.2356 17.6628 11.4992 17 11.5Z"
          fill="#273941"
        />
        <path
          d="M23.9999 4H20.4999C20.401 3.99998 20.3044 3.97064 20.2222 3.9157C20.14 3.86076 20.0759 3.78268 20.0381 3.69133C20.0002 3.59998 19.9903 3.49946 20.0096 3.40248C20.0289 3.30551 20.0765 3.21643 20.1464 3.1465L22.2929 1H20.4999V0H23.4999C23.5988 2.11122e-05 23.6954 0.029357 23.7776 0.0842992C23.8598 0.139241 23.9239 0.217323 23.9617 0.308673C23.9996 0.400023 24.0095 0.500539 23.9902 0.597516C23.9709 0.694492 23.9233 0.783574 23.8534 0.8535L21.7069 3H23.9999V4Z"
          fill="#048EC6"
        />
        <path
          d="M17.9999 7.5H14.9999C14.901 7.49998 14.8044 7.47064 14.7222 7.4157C14.64 7.36076 14.5759 7.28268 14.5381 7.19133C14.5002 7.09998 14.4903 6.99946 14.5096 6.90248C14.5289 6.80551 14.5765 6.71643 14.6464 6.6465L16.7929 4.5H14.9999V3.5H17.9999C18.0988 3.50002 18.1954 3.52936 18.2776 3.5843C18.3598 3.63924 18.4239 3.71732 18.4617 3.80867C18.4996 3.90002 18.5095 4.00054 18.4902 4.09752C18.4709 4.19449 18.4233 4.28357 18.3534 4.3535L16.2069 6.5H17.9999V7.5Z"
          fill="#048EC6"
        />
        <path
          d="M12.9999 5.5H10.9999C10.901 5.49998 10.8044 5.47064 10.7222 5.4157C10.64 5.36076 10.5759 5.28268 10.5381 5.19133C10.5002 5.09998 10.4903 4.99946 10.5096 4.90248C10.5289 4.80551 10.5765 4.71643 10.6464 4.6465L11.7929 3.5H10.9999V2.5H12.9999C13.0988 2.50002 13.1954 2.52936 13.2776 2.5843C13.3598 2.63924 13.4239 2.71732 13.4617 2.80867C13.4996 2.90002 13.5095 3.00054 13.4902 3.09752C13.4709 3.19449 13.4233 3.28357 13.3534 3.3535L12.2069 4.5H12.9999V5.5Z"
          fill="#048EC6"
        />
      </g>
      <defs>
        <clipPath id="clip0_740_10806">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
