import React from "react";

export default function Smile4() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_740_10520)">
        <path
          d="M12 23.5C18.3513 23.5 23.5 18.3513 23.5 12C23.5 5.64873 18.3513 0.5 12 0.5C5.64873 0.5 0.5 5.64873 0.5 12C0.5 18.3513 5.64873 23.5 12 23.5Z"
          fill="#FFCE52"
        />
        <path
          d="M18.495 13.6C18.81 13.57 19.145 13.535 19.5 13.5C19.4889 14.3876 19.2574 15.2585 18.8263 16.0344C18.3951 16.8103 17.7779 17.4668 17.03 17.945C15.5482 18.958 13.795 19.5 12 19.5C10.205 19.5 8.45184 18.958 6.97 17.945C6.22214 17.4668 5.60488 16.8103 5.17372 16.0344C4.74257 15.2585 4.51105 14.3876 4.5 13.5C4.795 13.565 5.135 13.625 5.51 13.675C5.675 15.53 8.515 17 12 17C15.535 17 18.4 15.49 18.495 13.6Z"
          fill="#AE2D4C"
        />
        <path
          d="M18.4948 13.6016C18.3998 15.4916 15.5348 17.0016 11.9998 17.0016C8.51477 17.0016 5.67477 15.5316 5.50977 13.6766C7.66508 13.9092 9.83199 14.0177 11.9998 14.0016C14.1704 13.9863 16.3386 13.8527 18.4948 13.6016Z"
          fill="#F6FAFD"
        />
        <path
          d="M18.5 10.5H17.5C17.5 9.7155 16.929 8 16.5 8C16.071 8 15.5 9.7155 15.5 10.5H14.5C14.5 10.084 14.9225 7 16.5 7C18.0775 7 18.5 10.084 18.5 10.5Z"
          fill="#273941"
        />
        <path
          d="M9.5 10.5H8.5C8.5 9.7155 7.929 8 7.5 8C7.071 8 6.5 9.7155 6.5 10.5H5.5C5.5 10.084 5.9225 7 7.5 7C9.0775 7 9.5 10.084 9.5 10.5Z"
          fill="#273941"
        />
        <path
          d="M11.9992 1.99911C18.0742 1.99911 22.9992 6.25261 22.9992 11.4991H23.4867C23.3489 8.54514 22.0784 5.7578 19.9392 3.71607C17.7999 1.67434 14.9564 0.535156 11.9992 0.535156C9.04203 0.535156 6.1985 1.67434 4.05927 3.71607C1.92005 5.7578 0.649561 8.54514 0.511719 11.4991H0.999219C0.999219 6.25261 5.92422 1.99911 11.9992 1.99911Z"
          fill="#FFE369"
        />
        <path
          d="M23 11.5C23 16.7465 18.075 21 12 21C5.925 21 1 16.7465 1 11.5H0.5125C0.5055 11.666 0.5 11.8325 0.5 12C0.5 15.05 1.7116 17.9751 3.86827 20.1317C6.02494 22.2884 8.95001 23.5 12 23.5C15.05 23.5 17.9751 22.2884 20.1317 20.1317C22.2884 17.9751 23.5 15.05 23.5 12C23.5 11.8325 23.4945 11.666 23.4875 11.5H23Z"
          fill="#FFB32B"
        />
        <path
          d="M18.9064 4.90641C19.0674 4.74526 19.0162 4.43297 18.7919 4.20889C18.5677 3.98481 18.2553 3.93379 18.0943 4.09494C17.9333 4.25608 17.9845 4.56837 18.2088 4.79246C18.433 5.01654 18.7453 5.06756 18.9064 4.90641Z"
          fill="#F6FAFD"
        />
        <path
          d="M15.5754 2.45498C15.656 2.37431 15.6305 2.21809 15.5184 2.10604C15.4063 1.994 15.25 1.96857 15.1694 2.04924C15.0888 2.12992 15.1143 2.28614 15.2264 2.39818C15.3386 2.51022 15.4948 2.53565 15.5754 2.45498Z"
          fill="#F6FAFD"
        />
        <path
          d="M17.8134 4.31353C18.1355 3.99123 18.0329 3.36649 17.5842 2.91813C17.1355 2.46977 16.5107 2.36758 16.1886 2.68987C15.8666 3.01217 15.9692 3.63691 16.4179 4.08527C16.8665 4.53363 17.4913 4.63582 17.8134 4.31353Z"
          fill="#F6FAFD"
        />
        <path
          d="M18.1498 14.6346C18.3526 14.3261 18.4715 13.97 18.4948 13.6016C16.3386 13.8527 14.1704 13.9863 11.9998 14.0016C9.83199 14.0177 7.66508 13.9092 5.50977 13.6766C5.55034 14.0557 5.68926 14.4176 5.91277 14.7266C7.93587 14.9202 9.96744 15.012 11.9998 15.0016C14.0548 14.989 16.1078 14.8665 18.1498 14.6346Z"
          fill="#EDEDED"
        />
        <path
          d="M12 18.0008C15.785 18.0008 18.8545 16.0288 18.9965 13.5508L18.4965 13.6008C18.4 15.4908 15.535 17.0008 12 17.0008C8.5155 17.0008 5.676 15.5313 5.51 13.6768C5.3355 13.6528 5.164 13.6268 5 13.5998C5.178 16.0553 8.24 18.0008 12 18.0008Z"
          fill="#8A293D"
        />
      </g>
      <defs>
        <clipPath id="clip0_740_10520">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
