import { API_CHAT } from "../../../api/chat";
import { TYPE_ENUM } from "../../../components/chat/chat/ChatFree";
import { errorToast, infoToast } from "../../../components/mui/Toaster";
import {
  ENUM_CHAT_MODE,
  ENUM_STATUS_WINDOW,
  modeByStatus,
  seRemMessages,
  setChatActiveMode,
  setLoadingBroadcaster,
  setLovenseEnable,
  setLovenseQueue,
  setLovenseRunning,
  setLovenseTips,
  setLovenseToys,
  setLovenseWaiting,
  setMessagePin,
  setNewMessages,
  setSettingInfo,
  setStatusWindow,
} from "../../../store/chatSlice";
import { ENUM_MODALS_VALUE, setModals } from "../../../store/commonSlice";
import { setBalance } from "../../../store/userSlice";
import { getBroadcasterByPathname } from "../../../utils/broadcasters";
import JanusDisconnect from "./JanusDisconnect";
import JanusState from "./JanusState";

export default class JanusInside {
  dispatch; // функция обновления стора из редакс
  navigate; // хук для навигации по сайту
  isActive; // активен ли поток с януса
  #userId; // id пользователя
  isMeAccepted; // - флаг что только что модель подтвердила приват пользователя

  constructor({ dispatch, navigate, destroyCurrent, initJanus, broadcasterId, chatId }) {
    this.dispatch = dispatch;
    this.navigate = navigate;
    this.isActive = true;
    this.destroyCurrent = destroyCurrent;
    this.initJanus = initJanus;
    this.roomId = broadcasterId;
    if (!window.badAttempts) window.badAttempts = 0;
    this.#userId = 0;
    this.chatId = chatId;
    this.isMeAccepted = false;
    this.disconnect = new JanusDisconnect({ initJanus, chatId, dispatch, roomId: broadcasterId });
  }

  get userId() {
    return this.#userId || (JanusState.clientChat?.options?.user?.id ?? 0);
  }

  async newUserId(id) {
    this.#userId = id;
  }

  extraMess(changes) {
    const extra = changes?.extra;

    //reason
    const reason = extra?.reason;
    if (reason) {
      const mes = reason?.message;
      if (mes) {
        if (reason?.type === "not-enough-money") {
          errorToast(mes);
          this.dispatch(setModals({ name: ENUM_MODALS_VALUE.deposit, value: true }));
          return;
        }
        infoToast(mes);
      }
    }

    // warning
    const warningMes = extra?.warning?.message;
    if (warningMes) errorToast(warningMes);
  }

  async #getCurrentStatus() {
    // CRUTCH
    return getBroadcasterByPathname().then((e) => {
      const chatMode = e?.chat?.mode;
      if (!chatMode) {
        console.log("status offline");
        this.dispatch(setStatusWindow(ENUM_STATUS_WINDOW.offline));
        return;
      }
      const mode = modeByStatus[chatMode];
      this.dispatch(setStatusWindow(mode));
      this.dispatch(setChatActiveMode(chatMode));
    });
  }

  // setOfflineModel - устанавливает модель в офлайн
  setOfflineModel() {
    this.isActive = false;
    console.log("status offline");
    this.dispatch(setStatusWindow(ENUM_STATUS_WINDOW.loading));
    this.dispatch(setChatActiveMode(ENUM_CHAT_MODE.pause));
    setTimeout(() => {
      if (JanusState.statusWindow === ENUM_STATUS_WINDOW.loading && !this.isActive) {
        console.log("status offline");
        this.dispatch(setStatusWindow(ENUM_STATUS_WINDOW.offline));
      }
    }, 10 * 1000);
  }

  // эксклюзив или приват или пауза по моей инициативе
  meStatusChange(chat_mode) {
    // window.itSelfMode = chat_mode;

    // для восстановления #проблемы с остановкой привата
    // restoreStatus()

    // если нажал на стоп

    if (chat_mode === ENUM_CHAT_MODE.pause) {
      if (window.chatActiveMode) {
        console.log("window.chatActiveMode", window.chatActiveMode);
        if ([ENUM_CHAT_MODE.private, ENUM_CHAT_MODE.exclusive].includes(window.chatActiveMode)) {
          console.log("status", ENUM_STATUS_WINDOW.pause);
          this.dispatch(setStatusWindow(ENUM_STATUS_WINDOW.pause));
          return;
        }
        console.log("status", modeByStatus[window.chatActiveMode]);
        this.dispatch(setStatusWindow(modeByStatus[window.chatActiveMode]));
      }
      return;
    }

    console.log("is me ", chat_mode);

    setTimeout(() => {
      this.dispatch(setChatActiveMode(chat_mode));
      console.log("status stream");
      this.dispatch(setStatusWindow(ENUM_STATUS_WINDOW.stream));
      // }, 1000);
    }, 0);
  }

  // смена статуса на сервере
  serverChangeStatus(participant) {
    if (!participant.chat_mode) return;
    this.isActive = true;

    console.log(this.userId, participant.user_id, participant.chat_mode);

    if (!this.userId || !participant.user_id) return;
    if (participant.user_id === this.userId) {
      // т.е я  совершил событие
      this.meStatusChange(participant.chat_mode);
    }
  }

  // onRemoteTrack - получение стрима с сервера
  async onRemoteTrack(janus, track, on) {
    console.log("onRemoteTrack", track, on);
    // продолжаем если трэк включен
    if (!(on && track)) return;
    console.log("get stream");
    JanusState.readyState = track.readyState;

    // // если трэк замьючен то показываем лодер
    // if (track.muted && JanusState.statusWindow === ENUM_STATUS_WINDOW.stream) {
    //   console.log("setLoadingBroadcaster true");
    //   this.dispatch(setLoadingBroadcaster(true));
    // }

    // если трэк закончен, то показываем лодер и дальше не идём
    if (track.readyState === "ended") {
      if (JanusState.statusWindow === ENUM_STATUS_WINDOW.stream) {
        console.log("setLoadingBroadcaster true");
        this.dispatch(setLoadingBroadcaster(true));
      }
      return;
    }

    if (track.kind === "audio") {
      const stream = new MediaStream([track]);
      await janus.attachMediaStream(JanusState.audioRef.current, stream); // attach audio
    } else {
      const stream = new MediaStream([track]);
      await janus.attachMediaStream(JanusState.videoRef.current, stream); // attach video
    }
  }

  // listener - прослушивать всех событий
  listener(clientChat, statuses) {
    clientChat
      .on("message", (janus, msg) => {
        console.log("msg", msg);

        if ((msg.krona == "message" || msg.type === "message") && (msg.id || +msg.id === 0)) {
          this.dispatch(setNewMessages(msg));
          console.log("message-", msg);
        }
      })
      .on("message.remove", (remMes) => {
        console.log(remMes);
        this.dispatch(seRemMessages(remMes));
      })
      .on("remotetrack", this.onRemoteTrack.bind(this))
      // всем
      .on("room.change", async (participant) => {
        console.log("change", participant);

        const title = participant?.title;
        if (title) {
          const msg = { id: 0, type: "pin", title: title };
          this.dispatch(setMessagePin(msg));
        }

        if (!participant?.chat_mode) return;

        const message = {
          mode: participant.chat_mode,
          type: TYPE_ENUM.switched,
        };
        this.dispatch(setNewMessages(message));

        statuses.getSetting();

        // если только что модель подтвердила придёт в participant.change
        if (!this.isMeAccepted) {
          statuses.frontLogic(participant.chat_mode);

          this.serverChangeStatus(participant);
        }
      }) // всем
      .on("room.switch", (request, participant) => {
        console.log("switch", participant);
        this.serverChangeStatus(participant);

        if (request.accepted) {
          this.isMeAccepted = true;
          setTimeout(() => {
            this.isMeAccepted = false;
          }, 3 * 1000);
        }

        if (request.rejected || request.accepted) {
          const detail = { detail: { request: request } };
          dispatchEvent(new CustomEvent("response.switch", detail));
        } else {
          JanusState.requestId = request.id;
        }
      })
      .on("room", (request, participant) => {
        console.log("room", request, participant);
      })
      .on("room.title", (title) => {
        console.log("title", title);
        // const msg = { id: 0, type: "pin", title: title };
        // this.dispatch(setMessagePin(msg));
      })
      .on("connection.changed", (link) => {
        console.log("changed", link);
      })
      .on("change", (e) => {
        console.log("change", e);
      })
      .on("update", (e) => {
        console.log("update");
        console.log(e);
      })

      .on("unpublished", (_, msg) => {
        console.log("unpublished", msg);
        if (msg.error) errorToast(msg.error);
      })
      .on("kicked", async (data) => {
        console.log("kicked", data);
        if (data.leaving !== "ok") return;
        JanusState.isLeave = true;
        JanusState.isRedirect = true;

        await this.navigate("/");
        setTimeout(() => {
          errorToast(data.message || "you have been excluded from the chat");
        }, 50);

        setTimeout(() => {
          JanusState.isRedirect = false;
          JanusState.isAwait = false;
        }, 3 * 1000);

        //
        this.destroyCurrent();
      })
      .on("leaved", (msg) => {
        console.log("leaved", msg);
        if (msg.user_id !== msg.room) return;

        const clientChat = JanusState.clientChat;
        if (clientChat?.settings?.ownVideoEnabled || clientChat.settings?.ownAudioEnabled) {
          console.log("unpublish");
          clientChat.localfeed.unpublish();
        }

        console.log("status offline");
        this.dispatch(setChatActiveMode(ENUM_CHAT_MODE.pause));
        this.dispatch(setStatusWindow(ENUM_STATUS_WINDOW.offline));
      })
      .on("event", (data) => {
        console.log(data);
      })
      .on("participant.stats", (message) => {
        console.log("participant.stats", message);
      })
      // только для этого пользователя
      .on("participant.change", (participant, changes) => {
        console.log("chatmode", participant, changes);

        if (participant.user_id !== this.#userId) this.newUserId(participant.user_id);

        if (changes.balance) {
          console.log("balance", changes);
          if (participant.user_id === this.#userId) this.dispatch(setBalance(changes.balance));
        }

        this.serverChangeStatus(participant);

        //startStopByMode
        for (const feed of Object.values(JanusState.clientChat._remotefeeds)) {
          feed.startStopByMode(participant.chat_mode);
        }

        // mes

        this.extraMess(changes);
      })
      .on("error", (_, msg) => {
        console.log("error", msg);
        if (typeof msg == "string") errorToast(msg);
        if (typeof msg.error == "string") errorToast(msg.error);

        // JanusState.clientChat?.destroy?.();
        // if (!JanusState?.isRedirect) this.navigate("");
      })
      .on("disconnect", () => {
        // window.itSelfPrivate = false;
        // window.itSelfExclusive = false;
        if (JanusState.isLeave) return;
        JanusState.disconnect();

        console.log("disconnect", this.roomId, this.chatId);

        if (this.roomId && this.chatId) {
          this.disconnect.doReconnect(this.roomId, this.chatId);
        }
      })
      .on("joined", (participant) => {
        console.log("joined", participant);
        statuses.joined = participant;
        const title = participant.description;
        if (title) {
          const msg = { id: 0, type: "pin", title: title };
          this.dispatch(setMessagePin(msg));
        }

        if (participant.participans_by_chat_mode["free-chat"]) {
          this.dispatch(setChatActiveMode(ENUM_CHAT_MODE.free));
          this.dispatch(setStatusWindow(ENUM_STATUS_WINDOW.stream));
        }

        this.isActive = true;
      })
      .on("joining", async (participant) => {
        console.log("joining", participant);

        if (participant.user_id !== this.roomId) return;

        const resSetting = await API_CHAT.setting(this.chatId);
        const settings = resSetting.data.settings;
        this.dispatch(setSettingInfo(settings));

        const mode = participant.chat_mode;
        JanusState.modelMode = mode;

        const status = modeByStatus[mode];
        console.log("status", status);
        this.dispatch(setChatActiveMode(mode));
        this.dispatch(setStatusWindow(status));
      })
      .on("joined.connect", (result) => {
        console.log("joined.connect", result);

        const chatMode = result?.chat?.mode;
        const participantMode = result?.participant?.mode;

        JanusDisconnect.clearBadAttempts();

        if (!participantMode) return;
        if (participantMode === ENUM_CHAT_MODE.free) {
          // FIXME - должен приходить адекватный статус
          this.#getCurrentStatus();

          return;
        }

        console.log(participantMode, "1");
        if (participantMode === ENUM_CHAT_MODE.pause) {
          // FIXME - должен приходить адекватный статус
          this.#getCurrentStatus();

          // const mode = modeByStatus[chatMode];
          // console.log("chatMode", chatMode);
          // if (mode) {
          //   console.log("status", mode);
          //   this.dispatch(setStatusWindow(mode));
          // }
          // this.dispatch(setChatActiveMode(chatMode));
          return;
        }

        console.log("status stream");
        this.dispatch(setStatusWindow(ENUM_STATUS_WINDOW.stream));
        this.dispatch(setChatActiveMode(chatMode));

        // window.itSelfMode = participantMode;
      })
      .on("mute", (e) => {
        console.log("mute", e);
      })
      .on("unmute", (e) => {
        console.log("unmute", e);
      })
      .on("ondataopen", () => {
        console.log("ondataopen");
        this.isActive = true;
        window.badAttempts = 0;
        statuses.getSetting();
        // dispatch(setStatusWindow(ENUM_STATUS_WINDOW.stream));
      })
      .on("participant.login", (current, previous) => {
        console.log(current, previous);
        if (current.user_id !== previous.user_id) {
          this.#getCurrentStatus();
        }
      })
      .on("lovense.queue", (queue, running, waiting) => {
        console.log(queue, running, waiting);

        this.dispatch(setLovenseQueue(queue));
        this.dispatch(setLovenseRunning(running));
        this.dispatch(setLovenseWaiting(waiting));
      })
      .on("lovense.enabled", (enabled) => {
        this.dispatch(setLovenseEnable(enabled));
      })
      .on("lovense.settings", (levels, special) => {
        this.dispatch(setLovenseTips({ levels, special }));
      })
      .on("lovense.toys,", (toys) => {
        this.dispatch(setLovenseToys(toys));
      });
  }
}
