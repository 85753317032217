import React from "react";

export default function IconSetting() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <g clipPath="url(#clip0_26_13176)">
        <g filter="url(#filter0_di_26_13176)">
          <g filter="url(#filter1_di_26_13176)">
            <path
              d="M17.4606 10.9725L15.8061 9.67876C15.8271 9.47925 15.8451 9.24602 15.8451 8.99926C15.8451 8.7525 15.8278 8.51927 15.8061 8.31976L17.4621 7.02527C17.7696 6.78227 17.8536 6.35101 17.6579 5.99179L15.9373 3.015C15.7536 2.67901 15.3508 2.5065 14.9421 2.65725L12.9899 3.44099C12.6156 3.17099 12.2233 2.943 11.8176 2.76075L11.5206 0.690012C11.4718 0.297 11.1298 0 10.7256 0H7.27562C6.87136 0 6.53014 0.297 6.48211 0.684L6.18437 2.76226C5.79136 2.93927 5.40514 3.16427 5.01437 3.4425L3.05687 2.65651C2.69009 2.51476 2.25134 2.67374 2.06909 3.00825L0.346362 5.98876C0.143123 6.33227 0.227112 6.77851 0.541373 7.02752L2.19586 8.32127C2.1696 8.57401 2.15687 8.79377 2.15687 9.00004C2.15687 9.2063 2.16964 9.42602 2.19586 9.67954L0.539862 10.974C0.23235 11.2178 0.1491 11.649 0.34485 12.0075L2.06536 14.9843C2.24912 15.3195 2.64811 15.4935 3.0606 15.342L5.01286 14.5583C5.38636 14.8275 5.7786 15.0555 6.18437 15.2385L6.48137 17.3085C6.5301 17.703 6.87136 18 7.27636 18H10.7263C11.1306 18 11.4726 17.703 11.5206 17.316L11.8183 15.2385C12.2114 15.0608 12.5968 14.8365 12.9883 14.5575L14.9458 15.3435C15.0403 15.3802 15.1386 15.399 15.2399 15.399C15.5308 15.399 15.7986 15.24 15.9336 14.9925L17.6616 12C17.8536 11.649 17.7696 11.2178 17.4606 10.9725ZM9.0006 12C7.34611 12 6.00061 10.6545 6.00061 9C6.00061 7.34551 7.34611 6.00001 9.0006 6.00001C10.6551 6.00001 12.0006 7.34551 12.0006 9C12.0006 10.6545 10.6551 12 9.0006 12Z"
              fill="url(#paint0_linear_26_13176)"
              shapeRendering="crispEdges"
            />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_di_26_13176"
          x="-57.7642"
          y="-29"
          width="133.528"
          height="134"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="29" />
          <feGaussianBlur stdDeviation="29" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_13176" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_26_13176"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="6.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.169764 0 0 0 0 0.624988 0 0 0 0 0.768743 0 0 0 0.03 0"
          />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_26_13176" />
        </filter>
        <filter
          id="filter1_di_26_13176"
          x="-57.7642"
          y="-29"
          width="133.528"
          height="134"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="29" />
          <feGaussianBlur stdDeviation="29" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_13176" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_26_13176"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="6.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.169764 0 0 0 0 0.624988 0 0 0 0 0.768743 0 0 0 0.03 0"
          />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_26_13176" />
        </filter>
        <linearGradient
          id="paint0_linear_26_13176"
          x1="8.99993"
          y1="0"
          x2="8.99993"
          y2="19.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_26_13176">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
