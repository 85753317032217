import React from "react";

export default function Smile6() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_740_10434)">
        <path
          d="M12 23.5C18.3513 23.5 23.5 18.3513 23.5 12C23.5 5.64873 18.3513 0.5 12 0.5C5.64873 0.5 0.5 5.64873 0.5 12C0.5 18.3513 5.64873 23.5 12 23.5Z"
          fill="#FFCE52"
        />
        <path
          d="M11.9992 1.99911C18.0742 1.99911 22.9992 6.25261 22.9992 11.4991H23.4867C23.3489 8.54514 22.0784 5.7578 19.9392 3.71607C17.7999 1.67434 14.9564 0.535156 11.9992 0.535156C9.04203 0.535156 6.1985 1.67434 4.05927 3.71607C1.92005 5.7578 0.649561 8.54514 0.511719 11.4991H0.999219C0.999219 6.25261 5.92422 1.99911 11.9992 1.99911Z"
          fill="#FFE369"
        />
        <path
          d="M23 11.5C23 16.7465 18.075 21 12 21C5.925 21 1 16.7465 1 11.5H0.5125C0.5055 11.666 0.5 11.8325 0.5 12C0.5 15.05 1.7116 17.9751 3.86827 20.1317C6.02494 22.2884 8.95001 23.5 12 23.5C15.05 23.5 17.9751 22.2884 20.1317 20.1317C22.2884 17.9751 23.5 15.05 23.5 12C23.5 11.8325 23.4945 11.666 23.4875 11.5H23Z"
          fill="#FFB32B"
        />
        <path
          d="M18.9064 4.90641C19.0674 4.74526 19.0162 4.43297 18.7919 4.20889C18.5677 3.98481 18.2553 3.93379 18.0943 4.09494C17.9333 4.25608 17.9845 4.56837 18.2088 4.79246C18.433 5.01654 18.7453 5.06756 18.9064 4.90641Z"
          fill="#F6FAFD"
        />
        <path
          d="M15.5754 2.45498C15.656 2.37431 15.6305 2.21809 15.5184 2.10604C15.4063 1.994 15.25 1.96857 15.1694 2.04924C15.0888 2.12992 15.1143 2.28614 15.2264 2.39818C15.3386 2.51022 15.4948 2.53565 15.5754 2.45498Z"
          fill="#F6FAFD"
        />
        <path
          d="M17.8134 4.31353C18.1355 3.99123 18.0329 3.36649 17.5842 2.91813C17.1355 2.46977 16.5107 2.36758 16.1886 2.68987C15.8666 3.01217 15.9692 3.63691 16.4179 4.08527C16.8665 4.53363 17.4913 4.63582 17.8134 4.31353Z"
          fill="#F6FAFD"
        />
        <path
          d="M14 15H10C9.73503 15.0008 9.48113 15.1064 9.29377 15.2938C9.1064 15.4811 9.00079 15.735 9 16V19C9.00089 19.7911 9.31382 20.5498 9.87084 21.1115C10.4279 21.6732 11.184 21.9925 11.975 22H12C12.7956 22 13.5587 21.6839 14.1213 21.1213C14.6839 20.5587 15 19.7956 15 19V16C14.9992 15.735 14.8936 15.4811 14.7062 15.2938C14.5189 15.1064 14.265 15.0008 14 15Z"
          fill="#78B300"
        />
        <path
          d="M20 12.5C20.0108 14.008 19.4804 15.4699 18.505 16.62C17.5671 17.7087 16.3578 18.5298 15 19V16C14.9992 15.735 14.8936 15.4811 14.7062 15.2938C14.5189 15.1064 14.265 15.0008 14 15H10C9.73503 15.0008 9.48113 15.1064 9.29377 15.2938C9.1064 15.4811 9.00079 15.735 9 16V19C6.33 18.04 4 15.785 4 12.5C4.235 12.5 5.13 12.625 6.52 12.75C7.91 12.875 9.79 13 12 13C14.6745 12.9993 17.3463 12.8323 20 12.5Z"
          fill="#273941"
        />
        <path
          d="M16.5 7.5C16.4011 7.5 16.3044 7.47068 16.2222 7.41573C16.14 7.36079 16.0759 7.2827 16.0381 7.19134C16.0002 7.09998 15.9903 6.99945 16.0096 6.90245C16.0289 6.80546 16.0765 6.71637 16.1464 6.64645C16.2164 6.57652 16.3055 6.5289 16.4025 6.50961C16.4994 6.49031 16.6 6.50022 16.6913 6.53806C16.7827 6.5759 16.8608 6.63999 16.9157 6.72221C16.9707 6.80444 17 6.90111 17 7H18C17.9987 6.69087 17.902 6.38969 17.723 6.13765C17.544 5.88562 17.2915 5.69504 17 5.592V5H16V5.592C15.6667 5.70941 15.3856 5.94101 15.2067 6.24577C15.0277 6.55053 14.9624 6.90879 15.0223 7.25709C15.0822 7.60539 15.2634 7.92126 15.5339 8.14875C15.8043 8.37624 16.1466 8.50067 16.5 8.5C16.5989 8.5 16.6956 8.52932 16.7778 8.58426C16.86 8.63921 16.9241 8.7173 16.9619 8.80866C16.9998 8.90002 17.0097 9.00056 16.9904 9.09755C16.9711 9.19454 16.9235 9.28363 16.8536 9.35355C16.7836 9.42348 16.6945 9.4711 16.5975 9.49039C16.5006 9.50969 16.4 9.49978 16.3087 9.46194C16.2173 9.4241 16.1392 9.36001 16.0843 9.27779C16.0293 9.19556 16 9.09889 16 9H15C15.0013 9.30913 15.098 9.61031 15.277 9.86235C15.456 10.1144 15.7085 10.305 16 10.408V11H17V10.408C17.3333 10.2906 17.6144 10.059 17.7933 9.75423C17.9723 9.44947 18.0376 9.09121 17.9777 8.74291C17.9178 8.39461 17.7366 8.07874 17.4661 7.85125C17.1957 7.62376 16.8534 7.49933 16.5 7.5Z"
          fill="#4F813B"
        />
        <path
          d="M7.5 7.5C7.40111 7.5 7.30444 7.47068 7.22221 7.41573C7.13999 7.36079 7.0759 7.2827 7.03806 7.19134C7.00022 7.09998 6.99031 6.99945 7.00961 6.90245C7.0289 6.80546 7.07652 6.71637 7.14645 6.64645C7.21637 6.57652 7.30546 6.5289 7.40245 6.50961C7.49945 6.49031 7.59998 6.50022 7.69134 6.53806C7.7827 6.5759 7.86079 6.63999 7.91573 6.72221C7.97068 6.80444 8 6.90111 8 7H9C8.99872 6.69087 8.90196 6.38969 8.72296 6.13765C8.54395 5.88562 8.29145 5.69504 8 5.592V5H7V5.592C6.66666 5.70941 6.38563 5.94101 6.20668 6.24577C6.02773 6.55053 5.96242 6.90879 6.02231 7.25709C6.08219 7.60539 6.26342 7.92126 6.53388 8.14875C6.80434 8.37624 7.14659 8.50067 7.5 8.5C7.59889 8.5 7.69556 8.52932 7.77779 8.58426C7.86001 8.63921 7.9241 8.7173 7.96194 8.80866C7.99978 8.90002 8.00969 9.00056 7.99039 9.09755C7.9711 9.19454 7.92348 9.28363 7.85355 9.35355C7.78363 9.42348 7.69454 9.4711 7.59755 9.49039C7.50055 9.50969 7.40002 9.49978 7.30866 9.46194C7.2173 9.4241 7.13921 9.36001 7.08427 9.27779C7.02932 9.19556 7 9.09889 7 9H6C6.00128 9.30913 6.09804 9.61031 6.27704 9.86235C6.45605 10.1144 6.70855 10.305 7 10.408V11H8V10.408C8.33334 10.2906 8.61437 10.059 8.79332 9.75423C8.97227 9.44947 9.03758 9.09121 8.97769 8.74291C8.91781 8.39461 8.73658 8.07874 8.46612 7.85125C8.19566 7.62376 7.85341 7.49933 7.5 7.5Z"
          fill="#4F813B"
        />
        <path
          d="M16.5 7.5V8.5C16.6326 8.5 16.7598 8.55268 16.8536 8.64645C16.9473 8.74022 17 8.86739 17 9C17 9.13261 16.9473 9.25979 16.8536 9.35356C16.7598 9.44732 16.6326 9.5 16.5 9.5V11H17V10.408C17.3333 10.2906 17.6144 10.059 17.7933 9.75424C17.9723 9.44948 18.0376 9.09122 17.9777 8.74291C17.9178 8.39461 17.7366 8.07874 17.4661 7.85125C17.1957 7.62376 16.8534 7.49933 16.5 7.5Z"
          fill="#78B300"
        />
        <path
          d="M17 7H18C17.9987 6.69087 17.902 6.38969 17.723 6.13765C17.544 5.88562 17.2915 5.69504 17 5.592V5H16.5V6.5C16.6326 6.5 16.7598 6.55268 16.8536 6.64645C16.9473 6.74021 17 6.86739 17 7Z"
          fill="#78B300"
        />
        <path
          d="M8 9C8 9.13261 7.94732 9.25979 7.85355 9.35356C7.75979 9.44732 7.63261 9.5 7.5 9.5V11H8V10.408C8.33334 10.2906 8.61437 10.059 8.79332 9.75424C8.97227 9.44948 9.03758 9.09122 8.97769 8.74291C8.91781 8.39461 8.73658 8.07874 8.46612 7.85125C8.19566 7.62376 7.85341 7.49933 7.5 7.5V8.5C7.63261 8.5 7.75979 8.55268 7.85355 8.64645C7.94732 8.74022 8 8.86739 8 9Z"
          fill="#78B300"
        />
        <path
          d="M8 7H9C8.99872 6.69087 8.90196 6.38969 8.72296 6.13765C8.54395 5.88562 8.29145 5.69504 8 5.592V5H7.5V6.5C7.63261 6.5 7.75979 6.55268 7.85355 6.64645C7.94732 6.74021 8 6.86739 8 7Z"
          fill="#78B300"
        />
        <path
          d="M14 15H10C9.73503 15.0008 9.48113 15.1064 9.29377 15.2938C9.1064 15.4811 9.00079 15.735 9 16V19C9.00089 19.7911 9.31382 20.5498 9.87084 21.1115C10.4279 21.6732 11.184 21.9925 11.975 22H12C12.7956 22 13.5587 21.6839 14.1213 21.1213C14.6839 20.5587 15 19.7956 15 19V16C14.9992 15.735 14.8936 15.4811 14.7062 15.2938C14.5189 15.1064 14.265 15.0008 14 15Z"
          fill="#78B300"
        />
        <path
          d="M12 21H11.975C11.184 20.9925 10.4279 20.6732 9.87084 20.1115C9.31382 19.5498 9.00089 18.7911 9 18V19C9.00089 19.7911 9.31382 20.5498 9.87084 21.1115C10.4279 21.6732 11.184 21.9925 11.975 22H12C12.7956 22 13.5587 21.6839 14.1213 21.1213C14.6839 20.5587 15 19.7956 15 19V18C15 18.7956 14.6839 19.5587 14.1213 20.1213C13.5587 20.6839 12.7956 21 12 21Z"
          fill="#426704"
        />
        <path
          d="M14 15H10C9.73503 15.0008 9.48113 15.1064 9.29377 15.2938C9.1064 15.4811 9.00079 15.735 9 16V17C9.00079 16.735 9.1064 16.4811 9.29377 16.2938C9.48113 16.1064 9.73503 16.0008 10 16H14C14.265 16.0008 14.5189 16.1064 14.7062 16.2938C14.8936 16.4811 14.9992 16.735 15 17V16C14.9992 15.735 14.8936 15.4811 14.7062 15.2938C14.5189 15.1064 14.265 15.0008 14 15Z"
          fill="#4F813B"
        />
        <path
          d="M12 18C11.9011 18 11.8044 17.9707 11.7222 17.9157C11.64 17.8608 11.5759 17.7827 11.5381 17.6913C11.5002 17.6 11.4903 17.4994 11.5096 17.4025C11.5289 17.3055 11.5765 17.2164 11.6464 17.1464C11.7164 17.0765 11.8055 17.0289 11.9025 17.0096C11.9994 16.9903 12.1 17.0002 12.1913 17.0381C12.2827 17.0759 12.3608 17.14 12.4157 17.2222C12.4707 17.3044 12.5 17.4011 12.5 17.5H13.5C13.4987 17.1909 13.402 16.8897 13.223 16.6376C13.044 16.3856 12.7915 16.195 12.5 16.092V15.5H11.5V16.092C11.1667 16.2094 10.8856 16.441 10.7067 16.7458C10.5277 17.0505 10.4624 17.4088 10.5223 17.7571C10.5822 18.1054 10.7634 18.4213 11.0339 18.6488C11.3043 18.8762 11.6466 19.0007 12 19C12.0989 19 12.1956 19.0293 12.2778 19.0843C12.36 19.1392 12.4241 19.2173 12.4619 19.3087C12.4998 19.4 12.5097 19.5006 12.4904 19.5975C12.4711 19.6945 12.4235 19.7836 12.3536 19.8536C12.2836 19.9235 12.1945 19.9711 12.0975 19.9904C12.0006 20.0097 11.9 19.9998 11.8087 19.9619C11.7173 19.9241 11.6392 19.86 11.5843 19.7778C11.5293 19.6956 11.5 19.5989 11.5 19.5H10.5C10.5013 19.8091 10.598 20.1103 10.777 20.3624C10.956 20.6144 11.2085 20.805 11.5 20.908V21.5H12.5V20.908C12.8333 20.7906 13.1144 20.559 13.2933 20.2542C13.4723 19.9495 13.5376 19.5912 13.4777 19.2429C13.4178 18.8946 13.2366 18.5787 12.9661 18.3512C12.6957 18.1238 12.3534 17.9993 12 18Z"
          fill="#EDEDED"
        />
        <path
          d="M12 14C14.6468 13.9981 17.2911 13.8348 19.918 13.511C19.9713 13.1766 19.9987 12.8386 20 12.5C17.3463 12.8323 14.6745 12.9993 12 13C9.79 13 7.91 12.875 6.52 12.75C5.13 12.625 4.235 12.5 4 12.5C4.00144 12.8362 4.02852 13.1719 4.081 13.504C4.398 13.5225 5.2485 13.6355 6.52 13.75C7.91 13.875 9.79 14 12 14Z"
          fill="#141E21"
        />
        <path
          d="M15.0008 15.5H14.8613C14.9516 15.6512 14.9997 15.8239 15.0008 16V19C15.3426 18.8775 15.6765 18.7342 16.0008 18.571V16.5C16 16.235 15.8944 15.9811 15.7071 15.7938C15.5197 15.6064 15.2658 15.5008 15.0008 15.5Z"
          fill="#141E21"
        />
        <path
          d="M9 16C9.00108 15.8239 9.04924 15.6512 9.1395 15.5H9C8.73503 15.5008 8.48113 15.6064 8.29377 15.7938C8.1064 15.9811 8.00079 16.235 8 16.5V18.57C8.32438 18.7333 8.65832 18.8769 9 19V16Z"
          fill="#141E21"
        />
      </g>
      <defs>
        <clipPath id="clip0_740_10434">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
