import React from "react";
import useSmile from "../../../hooks/useSmile";

export default function SmileToIndx({ indx, onClick }) {
  const { SMILE_TO_IMGSMILE } = useSmile();
  const smilesTable = Object.keys(SMILE_TO_IMGSMILE);
  // const smiles = Object.values(SMILE_TO_IMGSMILE);

  // return <span onClick={(e) => onClick(smilesTable[indx], e)}>{smiles[indx]}</span>;
  return <span onClick={(e) => onClick(smilesTable[indx], e)}>{smilesTable[indx]}</span>;
}
