import Janus from "../../Janus";
import { API_URL_CHAT } from "../../api/main";
import { errorToast, infoToast } from "../../components/mui/Toaster";
import { ENUM_STATUS_WINDOW } from "../../store/chatSlice";
import Base64 from "../../utils/base64";
import { getShortLang } from "../../utils/utils";
import KronaFeedBase from "./KronaFeedBase";
import KronaRemoteFeed from "./KronaRemoteFeed";

const ViewChat = "view-chat";
const PauseChat = "pause-chat";

export default class KronaLocalFeed extends KronaFeedBase {
  constructor(chat) {
    super(chat);

    this._id = 0;
    this._guest = false;
    this._mypvtid = undefined;
    this._user_id = 0;
    this._nickname = undefined;
    this._sfutest = null;
    this._bandwidth = 2000 * 1000;
    this._description = undefined;
    this._lostConnectionTimerHandle = null;
    this._lostConnectionTimerInterval = 5000;
    this._lostConnectionPacketsCounter = 0;
    this._lostConnectionPacketsBound = 2;
    this._isLostConnection = false;
    this._streams = [];
  }

  async start() {
    if (this._sfutest) return true;

    return new Promise((resolve) => {
      // Attach to chat room test plugin
      this._chat._session.janusObject.attach({
        plugin: "krona.plugin.chatroom",
        opaqueId: window.roomOpaqueID,
        success: (pluginHandle) => {
          this._sfutest = pluginHandle;
          window.sfutest = this._sfutest;

          if (this._lostConnectionTimerHandle) clearTimeout(this._lostConnectionTimerHandle);
          this._lostConnectionTimerHandle = setInterval(() => {
            this.lostConnectionWorker();
          }, this._lostConnectionTimerInterval);

          resolve(true);
        },
        error: (e) => {
          console.error(e);
          if (!this._sfutest) resolve(false);
        },
        reconnect: () => {
          console.log("reconnect");
        },
        ondata: (data) => {
          this._onData(data);
        },
        onmessage: (msg, jsep) => {
          if (msg.reason === "kicked") {
            this._chat.emit("kicked", msg);
          }
          this._onMessage(msg, jsep);
        },
        onlocaltrack: (track, on) => {
          this._chat.emit("localtrack", Janus, track, on, this._sfutest);
        },
        onremotetrack: (track, mid, on) => {
          console.log("onremotetrack", track, mid, on);
          // The publisher stream is sendonly, we don't expect anything here
        },
        oncleanup: () => {
          // TODO: clear tracks event
          this._localstream = null;
        },
      });
    });
  }

  async stop() {
    if (this._sfutest) {
      if (this._lostConnectionTimerHandle) {
        clearTimeout(this._lostConnectionTimerHandle);
        this._lostConnectionTimerHandle = null;
      }

      console.log("stop");

      if (this._chat.settings.ownVideoEnabled || this._chat.settings.ownAudioEnabled) {
        await this.unpublish();
      }
      await this.leave();
      await new Promise((resolve) => {
        this._sfutest.detach({
          success: () => {
            setTimeout(resolve, 500);
          },
          error: resolve,
        });
      });
    }

    this._mypvtid = undefined;
    this._sfutest = null;
  }

  async leave() {
    return new Promise((resolve) => {
      if (!this._sfutest) {
        resolve(false);
        return;
      }
      this._sfutest.send({
        message: {
          request: "leave",
        },
        success: () => {
          resolve(true);
        },
        error: (e) => {
          Janus.error(e);
          resolve(false);
        },
      });
    });
  }

  async unpublish() {
    return new Promise((resolve) => {
      if (!this._sfutest) {
        resolve(false);
        return;
      }

      this._sfutest.send({
        message: {
          request: "unpublish",
        },
        success: () => {
          this._chat.settings.ownVideoEnabled = false;
          this._chat.settings.ownAudioEnabled = false;
          this._chat.once("unpublished", () => {
            setTimeout(() => {
              resolve(true);
            }, 1000);
          });
        },
        error: (e) => {
          Janus.error(e);
          resolve(false);
        },
      });
    });
  }

  async publish() {
    let tracks = [{ type: "data" }];

    if (this._chat.settings.ownAudioEnabled) {
      tracks.push({
        type: "audio",
        capture: {
          deviceId: { exact: this._chat.settings.audioDevice },
        },
        recv: false,
      });
    }
    if (this._chat.settings.ownVideoEnabled || this._chat.settings.ownAudioEnabled) {
      // We only support SVC for VP9 and (still WIP) AV1
      tracks.push({
        type: "video",
        capture: {
          deviceId: { exact: this._chat.settings.videoDevice },
          width: this._chat.settings.videoResolution.width,
          height: this._chat.settings.videoResolution.height,
        },
        recv: false,
        //svc: ((vcodec === 'vp9' || vcodec === 'av1') && doSvc) ? doSvc : null
      });
    }

    const createOffer = async () => {
      return new Promise((resolve) => {
        this._sfutest.createOffer({
          tracks: tracks,
          success: resolve,
          error: (e) => {
            Janus.error(e);
            errorToast("an error occurred, the page may not have access to your devices");
            resolve(false);
          },
        });
      });
    };

    const publish = (jsep) => {
      this.publishPromise = new Promise((resolve) => {
        this._sfutest.send({
          jsep: jsep,
          message: {
            request: "configure",
            audio: true,
            video: true,
            bitrate: this._bandwidth,
            data: true, // FIXME: comment and test
          },
          success: () => {
            resolve(true);
          },
          error: (e) => {
            Janus.error(e);
            resolve(false);
          },
        });
      });

      return this.publishPromise;
    };

    let result = false;
    const jsep = await createOffer();
    if (jsep) {
      result = await publish(jsep);
    } else {
      console.log("publish", jsep);
      await this.unpublish();
    }

    this.publishPromise = undefined;
    return result;
  }

  /**
   * @param {Object} params
   * @param {boolean} params[].ownAudioEnabled
   * @param {boolean} params[].ownVideoEnabled
   * @param {string | true} params[].videoDevice
   * @param {string | true} params[].audioDevice
   * @param {Object} params[].videoResolution
   * @param {string} params[].videoResolution[].width
   * @param {string} params[].videoResolution[].height
   */
  async publishWitchParams(params) {
    let tracks = [{ type: "data" }];

    if (params.ownAudioEnabled) {
      tracks.push({
        type: "audio",
        capture: { deviceId: { exact: params.audioDevice } },
        recv: false,
      });
    }
    if (params.ownVideoEnabled) {
      tracks.push({
        type: "video",
        capture: {
          deviceId: { exact: params.videoDevice },
          width: params.videoResolution?.width,
          height: params.videoResolution?.height,
        },
        recv: false,
      });
    }

    const createOffer = async () => {
      return new Promise((resolve) => {
        this._sfutest.createOffer({
          tracks: tracks,
          success: (e) => {
            resolve(e);
          },
          error: (e) => {
            errorToast("an error occurred, the page may not have access to your devices");
            Janus.error(e);
            resolve(false);
          },
        });
      });
    };

    const publish = (jsep) => {
      this.publishPromise = new Promise((resolve) => {
        this._sfutest.send({
          jsep: jsep,
          message: {
            request: "configure",
            audio: true,
            video: true,
            bitrate: this._bandwidth,
            data: true, // FIXME: comment and test
          },
          success: () => {
            resolve(true);
          },
          error: (e) => {
            Janus.error(e);
            resolve(false);
          },
        });
      });

      return this.publishPromise;
    };

    let result = false;
    const jsep = await createOffer();
    if (jsep) {
      result = await publish(jsep);
    } else {
      console.log("publishWitchParams", jsep);
      if (this._chat.settings.ownVideoEnabled || this._chat.settings.ownAudioEnabled) {
        await this.unpublish();
      }
    }

    this.publishPromise = undefined;
    return result;
  }

  async send(text, participants = []) {
    console.log("isSend");
    if (this.chatMode === PauseChat || this.chatMode === ViewChat) {
      const mesEn = "Text chat is not available in this chat room mode";
      const mesRu = "Текстовый чат недоступен в этом режиме чата.";
      const mes = getShortLang() === "ru" ? mesRu : mesEn;
      errorToast(mes);
      throw (mes, { mode: this.chatMode });
    }

    return new Promise((resolve) => {
      if (!text || !text.length || !this._sfutest) {
        resolve(false);
        return;
      }

      console.log("isSend");
      ///

      const fn = async () => {
        try {
          // TODO - проверить на привате
          console.log(this._chat, participants);

          const response = await fetch(`${API_URL_CHAT}/${this._chat._chatId}/message/send`, {
            headers: { "Content-Type": "application/json; charset=utf-8" },
            method: "POST",
            body: JSON.stringify({
              text: Base64.encode(text),
              recipients: participants,
            }),
          });

          if (!response.ok) {
            errorToast(`Server error status ${response.status}`);
            return false;
          }

          const json = await response.json();
          if (json.ok === true) {
            if (json.message) infoToast(json.message);
          } else if (json.message) errorToast(json.message);
          resolve(true);
        } catch (e) {
          console.log(e);
          resolve(false);
        }
      };

      fn();

      // this._sfutest.data({
      //   text: JSON.stringify({
      //     type: "message",
      //     participants: participants,
      //     text: Base64.encode(text),
      //   }),
      //   error: (reason) => {
      //     Janus.error(reason);
      //     resolve(false);
      //   },
      //   success: () => {
      //     resolve(true);
      //   },
      // });
    });
  }

  /**
   * Get the own audio stream is enabled
   * @returns {boolean}
   */
  get isAudioEnabled() {
    return Boolean(
      this._sfutest &&
        this._sfutest.webrtcStuff &&
        this._sfutest.webrtcStuff.myStream &&
        this._sfutest.webrtcStuff.myStream.getAudioTracks().length > 0
    );
  }

  /**
   * Control send own audio stream
   * @param en - True to enable stream
   * @returns {Promise<boolean>}
   */
  async setAudioEnabled(en) {
    return new Promise((resolve) => {
      if (!this._sfutest) {
        resolve(false);
        return;
      }
      if (this.isAudioEnabled === en) {
        resolve(true);
        return;
      }

      const params = {
        success: (jsep) => {
          this._sfutest.send({
            message: {
              audio: en,
              video: this._chat.settings.ownVideoEnabled,
            },
            jsep: jsep,
            success: () => {
              resolve(true);
            },
            error: (e) => {
              Janus.error(e);
              resolve(false);
            },
          });
        },
        error: (e) => {
          Janus.error(e);
          errorToast("an error occurred, the page may not have access to your devices");

          resolve(false);
        },
      };

      this._sfutest.createOffer({
        ...params,
        ...(en ? { media: { addAudio: true } } : { media: { removeAudio: true } }),
      });
    });
  }

  /**
   * Set own audio stream enabled
   * @param mute - True for mute audio, False for unmute audio
   */
  async setAudioMuted(mute) {
    if (this._chat.settings.ownAudioMuted !== mute) this._chat.settings.ownAudioMuted = mute;

    if (!this._sfutest) return;

    if (mute) {
      this._sfutest.muteAudio();
    } else {
      if (!this.isAudioEnabled) {
        (async () => {
          await this.setAudioEnabled(true);
          // FIXME: setAudioEnabled isn't working properly, temporary using republish crutch
          console.log("setAudioMuted");
          await this.unpublish();
          await this.publish();
        })();
      } else {
        this._sfutest.unmuteAudio();
      }
    }
  }

  /**
   * Get is own audio stream enabled
   * @returns {boolean|undefined}
   */
  get isAudioMuted() {
    if (this._sfutest) return Boolean(this._sfutest.isAudioMuted());
    return undefined;
  }

  /**
   * Get the own video stream is enabled
   * @returns {boolean}
   */
  get isVideoEnabled() {
    return Boolean(
      this._sfutest.webrtcStuff &&
        this._sfutest.webrtcStuff.myStream &&
        this._sfutest.webrtcStuff.myStream.getVideoTracks().length > 0
    );
  }

  /**
   * Control send own video stream
   * @param en
   * @returns {Promise<boolean>}
   */
  async setVideoEnabled(en) {
    return new Promise((resolve) => {
      if (!this._sfutest) {
        resolve(false);
        return;
      }

      if (this.isVideoEnabled === en) {
        resolve(true);
        return;
      }

      const params = {
        success: (jsep) => {
          this._sfutest.send({
            message: {
              audio: this._chat.settings.ownAudioEnabled,
              video: en,
            },
            jsep: jsep,
            success: () => {
              resolve(true);
            },
            error: (e) => {
              Janus.error(e);
              resolve(false);
            },
          });
        },
        error: (e) => {
          Janus.error(e);
          resolve(false);
          errorToast("an error occurred, the page may not have access to your devices");
        },
      };

      this._sfutest.createOffer({
        ...params,
        ...(en ? { media: { addVideo: true } } : { media: { removeVideo: true } }),
      });
    });
  }

  /**
   * Set own video stream mute
   * @param mute - True for mute video, False for unmute video
   */
  async setVideoMuted(mute) {
    if (!this._sfutest) return;

    if (mute) {
      this._sfutest.muteVideo();
    } else {
      if (!this.isVideoEnabled) {
        (async () => {
          await this.setVideoEnabled(true);
          // FIXME: setVideoEnabled isn't working properly, temporary using republish crutch
          console.log("setVideoMuted");
          await this.unpublish();
          await this.publish();
        })();
      } else {
        this._sfutest.unmuteVideo();
      }
    }
  }

  /**
   * Get the own video stream mute
   * @returns {boolean|undefined}
   */
  get isVideoMuted() {
    if (this._sfutest) return this._sfutest.isVideoMuted();
    return false;
  }

  get audioLevel() {
    if (this._sfutest) return this._sfutest.getRemoteVolume();
    return undefined;
  }

  get microphoneLevel() {
    if (this._sfutest) return this._sfutest.getLocalVolume();
    return undefined;
  }

  _onData(data) {
    super._onData(data);
  }

  _onMessage(msg, jsep) {
    if (msg.chatroom === "joined") {
      // Publisher/manager created, negotiate WebRTC and attach to existing feeds, if any
      if (this._chat.options.user.id === 0 && this._chat.options.user.id !== msg.id) {
        // guest users has real random id, save them for identification own messages
        this._chat.options.user.id = msg.id;
      }
      this._chat.emit("joined", msg);

      this._id = msg.id;
      this._description = msg.description;
      this._guest = msg.guest;
      this._mypvtid = msg.private_id;
      this._user_id = msg.user_id;
      this._nickname = msg.display;

      // Any new feed to attach to?
      if (msg.publishers) {
        this._chat.emit("subscribers", Janus, msg.publishers);
        for (const participant of msg.publishers)
          if (!(participant.id in this._chat._remotefeeds))
            this._chat._remotefeeds[participant.id] = new KronaRemoteFeed(this._chat, participant);
      }
    } else if (msg.chatroom === "destroyed") {
      console.warning("The room has been destroyed");
    } else if (msg.chatroom === "event") {
      // Any info on our streams or a new feed to attach to?

      if (msg.joining) {
        const participant = msg.joining;
        this._chat.emit("joining", participant);
      } else if (msg.streams) {
        this._streams = msg.streams;
      } else if (msg.publishers) {
        this._chat.emit("subscribers", Janus, msg.publishers);
        this._chat.emit("subscriber", Janus, msg);
        for (const participant of msg.publishers) {
          if (participant.user_id === this._chat.roomId) {
            window.chatActiveMode = ENUM_STATUS_WINDOW.stream;
            this._chat._remotefeeds[participant.id] = new KronaRemoteFeed(this._chat, participant);
          }

          if (participant.dummy) continue;
          if (!(participant.id in this._chat._remotefeeds))
            this._chat._remotefeeds[participant.id] = new KronaRemoteFeed(this._chat, participant);
        }
      } else if (msg.configured) {
        // publish ok, this._asyncPromise.publish
      } else if (msg.leaving) {
        this._chat.emit("leaved", msg);
        const remoteFeed = this._chat._remotefeeds[msg.leaving];
        if (remoteFeed) {
          delete this._chat._remotefeeds[msg.leaving];
          remoteFeed.stop();
        }
      } else if (msg.unpublished) {
        // One of the publishers has unpublished?
        console.log(("Publisher left: {publisher}", { publisher: msg.unpublished }));
        if (msg.unpublished === "ok") {
          //mutedVideo = true;
          //appSignal.emit('videoStateChanged', mutedVideo);
          //mutedAudio = true;
          //appSignal.emit('audioStateChanged', mutedAudio);

          this._chat.emit("unpublished", Janus, msg, this.janusObject);
        } else {
          // remote video feed
          const remoteFeed = this._chat._remotefeeds[msg.unpublished];
          if (remoteFeed) {
            this._chat.emit("unpublished", Janus, msg, remoteFeed.janusObject);
            delete this._chat._remotefeeds[msg.unpublished];
            remoteFeed.stop();
          }
        }
      } else if (msg.kicked) {
        this._chat.emit("kicked", msg);
        const remoteFeed = this._chat._remotefeeds[msg.kicked];
        if (remoteFeed) {
          delete this._chat._remotefeeds[remoteFeed.rfid];
          remoteFeed.stop();
        }
      } else if (msg.error) {
        if (msg.error_code === 435) this._chat.emit("unpublished", Janus, msg);
        else this._chat.emit("error", Janus, msg);
      }
    } else if (msg.krona) {
      const processKronaMessage = (json) => {
        const type = json.krona;
        if (type === "participant.login") {
          console.log("participant.login");
          const user = json.current;
          this._guest = user.guest;
          this._chat.emit(type, json.current, json.previous);
        } else if (type === "room.title") {
          this._description = json.title;
          this._chat.emit(type, json.title);
        } else if (type === "self.status") {
          this._lostConnectionPacketsCounter++;
          this._chat.emit(type, json.status);
        } else if (type === "participant.balance" || type === "broadcaster.balance") {
          this._chat.emit(type, json.data);
        } else if (type === "participant.change") {
          if (json.user) this._chat.emit(type, json.user, json.changes);
          if (json.participans_by_chat_mode)
            this._onParticipantStats(json.participans_by_chat_mode);
        } else if (type === "room.change") {
          if (json.changes.chat_mode) this._chat_mode = json.changes.chat_mode;
          this._chat.emit(type, json.changes);
          if (json.participans_by_chat_mode)
            this._onParticipantStats(json.participans_by_chat_mode);
        } else if (type === "room.switch") {
          console.log("roomSwitch", json);
          this._chat.emit(type, json.request, json.user);
          if (json.participans_by_chat_mode)
            this._onParticipantStats(json.participans_by_chat_mode);
        } else if (type === "message.remove") {
          this._chat.emit(type, json.messages);
        } else if (type === "lovense.queue") {
          console.log(type, json);
          this._chat.emit(type, json.queue, json.running, json.waiting);
        } else if (type === "lovense.enabled") {
          console.log(type, json);
          this._chat.emit(type, json.enabled);
        } else if (type === "lovense.settings") {
          console.log(type, json);
          this._chat.emit(type, json.levels, json.special);
        } else if (type === "lovense.toys") {
          console.error("toys", json);

          // this._chat.emit(type, json);
        } else if (type === "message") {
          this._chat.emit(type, Janus, {
            ...json,
            isSelf: json.from.id === this._chat.localfeed.id,
          });
        } else {
          console.error(" error type", type, msg);
        }
      };

      processKronaMessage(msg);
    }

    if (msg.participans_by_chat_mode) this._onParticipantStats(msg.participans_by_chat_mode);

    if (jsep) this._sfutest.handleRemoteJsep({ jsep: jsep });
  }

  /**
   * Feed handle
   * @returns {null|object}
   */
  get janusObject() {
    return this._sfutest;
  }

  /**
   * Return True if participant is guest or False for registered user
   * @returns {boolean}
   */
  get isGuest() {
    return this._guest;
  }

  /**
   * Participant id
   * @returns {number}
   */
  get id() {
    return this._id;
  }

  /**
   * Participant id in database
   * @returns {number}
   */
  get userId() {
    return this._user_id;
  }

  /**
   * Participant nick name
   * @returns {string|undefined}
   */
  get nickname() {
    return this._nickname;
  }

  /**
   * Room description (used as chat room title)
   * @returns {undefined|string}
   */
  get description() {
    return this._description;
  }

  get stats() {
    return this._participantStats;
  }

  /**
   * Worker for lost connection machine
   */
  lostConnectionWorker() {
    if (this._lostConnectionPacketsCounter < this._lostConnectionPacketsBound) {
      if (!this._isLostConnection) this._chat.emit("connection.changed", false);
      this._isLostConnection = true;
    } else {
      if (this._isLostConnection) this._chat.emit("connection.changed", true);
      this._isLostConnection = false;
    }
    this._lostConnectionPacketsCounter = 0;
  }

  get streams() {
    return this._streams;
  }

  set streams(streams) {
    this._streams = streams;
  }
}
