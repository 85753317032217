import React from "react";

export default function Simple13() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_740_10565)">
        <path
          d="M12 23.5C18.3513 23.5 23.5 18.3513 23.5 12C23.5 5.64873 18.3513 0.5 12 0.5C5.64873 0.5 0.5 5.64873 0.5 12C0.5 18.3513 5.64873 23.5 12 23.5Z"
          fill="#CF4054"
        />
        <path
          d="M11.9992 1.99911C18.0742 1.99911 22.9992 6.25261 22.9992 11.4991H23.4867C23.3489 8.54514 22.0784 5.7578 19.9392 3.71607C17.7999 1.67434 14.9564 0.535156 11.9992 0.535156C9.04203 0.535156 6.1985 1.67434 4.05927 3.71607C1.92005 5.7578 0.649561 8.54514 0.511719 11.4991H0.999219C0.999219 6.25261 5.92422 1.99911 11.9992 1.99911Z"
          fill="#F45269"
        />
        <path
          d="M23 11.5C23 16.7465 18.075 21 12 21C5.925 21 1 16.7465 1 11.5H0.5125C0.5055 11.666 0.5 11.8325 0.5 12C0.5 15.05 1.7116 17.9751 3.86827 20.1317C6.02494 22.2884 8.95001 23.5 12 23.5C15.05 23.5 17.9751 22.2884 20.1317 20.1317C22.2884 17.9751 23.5 15.05 23.5 12C23.5 11.8325 23.4945 11.666 23.4875 11.5H23Z"
          fill="#AE2D4C"
        />
        <path
          d="M18.6564 4.65641C18.8174 4.49526 18.7662 4.18297 18.5419 3.95889C18.3177 3.73481 18.0053 3.68379 17.8443 3.84494C17.6833 4.00608 17.7345 4.31837 17.9588 4.54246C18.183 4.76654 18.4953 4.81756 18.6564 4.65641Z"
          fill="#F6FAFD"
        />
        <path
          d="M15.3254 2.20108C15.406 2.1204 15.3805 1.96418 15.2684 1.85214C15.1563 1.7401 15 1.71467 14.9194 1.79534C14.8388 1.87601 14.8643 2.03223 14.9764 2.14427C15.0886 2.25632 15.2448 2.28175 15.3254 2.20108Z"
          fill="#F6FAFD"
        />
        <path
          d="M17.5634 4.06353C17.8855 3.74123 17.7829 3.11649 17.3342 2.66813C16.8855 2.21977 16.2607 2.11758 15.9386 2.43987C15.6166 2.76217 15.7192 3.38691 16.1679 3.83527C16.6165 4.28363 17.2413 4.38582 17.5634 4.06353Z"
          fill="#F6FAFD"
        />
        <path
          d="M12 13.5C14.7614 13.5 17 13.0523 17 12.5C17 11.9477 14.7614 11.5 12 11.5C9.23858 11.5 7 11.9477 7 12.5C7 13.0523 9.23858 13.5 12 13.5Z"
          fill="#AE2D4C"
        />
        <path
          d="M19.7235 8.44969L19.2765 7.55469L16.8095 8.78819L15.346 9.52019L15.278 9.55419L15.2855 9.56919C15.1882 9.63411 15.1173 9.73163 15.0855 9.84419C15.0299 10.0583 15.0012 10.2785 15 10.4997C15 11.6212 15.6585 12.4997 16.5 12.4997C17.3415 12.4997 18 11.6212 18 10.4997C17.9995 10.1296 17.917 9.76414 17.7585 9.42969L19.7235 8.44969Z"
          fill="#141E21"
        />
        <path
          d="M8.71684 9.56578L8.72434 9.55078L8.65634 9.51678L7.19284 8.78478L4.72434 7.55078L4.27734 8.44828L6.24234 9.43078C6.08416 9.76447 6.00169 10.129 6.00084 10.4983C6.00084 11.6198 6.65934 12.4983 7.50084 12.4983C8.34234 12.4983 9.00084 11.6198 9.00084 10.4983C9.00029 10.2765 8.97207 10.0556 8.91684 9.84078C8.88506 9.72822 8.81414 9.6307 8.71684 9.56578Z"
          fill="#141E21"
        />
        <path
          d="M8.42933 9.96438C8.47631 10.1388 8.49985 10.3187 8.49933 10.4994C8.49933 11.3294 8.04933 11.9994 7.49933 11.9994C6.94933 11.9994 6.49933 11.3294 6.49933 10.4994C6.49049 10.268 6.52746 10.0371 6.60811 9.81998C6.68877 9.60289 6.81153 9.40388 6.96933 9.23438L8.42933 9.96438Z"
          fill="#273941"
        />
        <path
          d="M17.5 10.4994C17.5 11.3294 17.05 11.9994 16.5 11.9994C15.95 11.9994 15.5 11.3294 15.5 10.4994C15.4995 10.3187 15.523 10.1388 15.57 9.96438L17.03 9.23438C17.1878 9.40388 17.3106 9.60289 17.3912 9.81998C17.4719 10.0371 17.5089 10.268 17.5 10.4994Z"
          fill="#273941"
        />
        <path
          d="M15.5 18H14.5C14.5 17.094 13.385 16 12 16C10.615 16 9.5 17.094 9.5 18H8.5C8.5 16.584 9.9965 15 12 15C14.0035 15 15.5 16.584 15.5 18Z"
          fill="#141E21"
        />
      </g>
      <defs>
        <clipPath id="clip0_740_10565">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
