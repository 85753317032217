export const ENUM_LOCALSTORAGE = {
  autostart: "autostart",
  cameraId: "cameraId",
  devices: "devices",
  localCamera: "localCamera",
  localMicro: "localMicro",
  localResolution: "localResolution",
  lastAmount: "lastAmount",
  confirmAge: "confirmAge",
  haveAccount: "haveAccount",
};
