import { API_URL_SITE } from "./main";
import { cusAxios } from "./main";

const listLanguage = async () => {
  let response = await cusAxios({
    method: "GET",
    url: `${API_URL_SITE}/languages`,
  });
  return response;
};
const listLanguagesAll = async () => {
  let response = await cusAxios({
    method: "GET",
    url: `${API_URL_SITE}/languages/all`,
  });
  return response;
};

const listCounties = async () => {
  let response = await cusAxios({
    method: "GET",
    url: `${API_URL_SITE}/geo/countries`,
  });
  return response;
};
const listAreas = async () => {
  let response = await cusAxios({
    method: "GET",
    url: `${API_URL_SITE}/geo/areas`,
  });
  return response;
};

const listSiteCategory = async () => {
  let response = await cusAxios({
    method: "GET",
    url: `${API_URL_SITE}/categories/tree`,
  });
  return response;
};

const setIssueClaim = async (data) => {
  let response = await cusAxios({
    method: "POST",
    url: `${API_URL_SITE}/issue/claim`,
    data,
  });
  return response;
};

export const API_INFO = {
  listLanguage: listLanguage,
  listCounties: listCounties,
  listAreas: listAreas,
  listSiteCategory: listSiteCategory,
  setIssueClaim,
  listLanguagesAll,
};
