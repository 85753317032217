import React from "react";

export default function Icon18Plus() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <g clipPath="url(#clip0_26_13257)">
        <path
          d="M9 8.47266C9.29124 8.47266 9.52734 8.23656 9.52734 7.94531C9.52734 7.65407 9.29124 7.41797 9 7.41797C8.70876 7.41797 8.47266 7.65407 8.47266 7.94531C8.47266 8.23656 8.70876 8.47266 9 8.47266Z"
          fill="url(#paint0_linear_26_13257)"
        />
        <path
          d="M9 10.582C9.29124 10.582 9.52734 10.3459 9.52734 10.0547C9.52734 9.76344 9.29124 9.52734 9 9.52734C8.70876 9.52734 8.47266 9.76344 8.47266 10.0547C8.47266 10.3459 8.70876 10.582 9 10.582Z"
          fill="url(#paint1_linear_26_13257)"
        />
        <g filter="url(#filter0_di_26_13257)">
          <path
            d="M9 0C4.05668 0 0 4.05668 0 9C0 13.9433 4.05668 18 9 18C13.9433 18 18 13.9433 18 9C18 4.05668 13.9433 0 9 0ZM6.36328 6.89062V11.6367H5.30859V8.37552C5.14283 8.43462 4.96702 8.47266 4.78125 8.47266H4.25391V7.41797H4.78125C5.0722 7.41797 5.30859 7.18158 5.30859 6.89062V6.36328H6.36328V6.89062ZM10.582 10.0547C10.582 10.9271 9.87237 11.6367 9 11.6367C8.12763 11.6367 7.41797 10.9271 7.41797 10.0547C7.41797 9.64804 7.57645 9.28055 7.82944 9C7.57645 8.71945 7.41797 8.35196 7.41797 7.94531C7.41797 7.07295 8.12763 6.36328 9 6.36328C9.87237 6.36328 10.582 7.07295 10.582 7.94531C10.582 8.35196 10.4235 8.71945 10.1706 9C10.4235 9.28055 10.582 9.64804 10.582 10.0547ZM14.2734 9.52734H13.2188V10.582H12.1641V9.52734H11.1094V8.47266H12.1641V7.41797H13.2188V8.47266H14.2734V9.52734Z"
            fill="url(#paint2_linear_26_13257)"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_di_26_13257"
          x="-58"
          y="-29"
          width="134"
          height="134"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="29" />
          <feGaussianBlur stdDeviation="29" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_13257" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_26_13257"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="6.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.169764 0 0 0 0 0.624988 0 0 0 0 0.768743 0 0 0 0.03 0"
          />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_26_13257" />
        </filter>
        <linearGradient
          id="paint0_linear_26_13257"
          x1="9"
          y1="7.41797"
          x2="9"
          y2="8.56055"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_26_13257"
          x1="9"
          y1="9.52734"
          x2="9"
          y2="10.6699"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_26_13257"
          x1="9"
          y1="0"
          x2="9"
          y2="19.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
        </linearGradient>
        <clipPath id="clip0_26_13257">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
