import React from "react";

export default function Smile2() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M12 23C18.3513 23 23.5 17.8513 23.5 11.5C23.5 5.14873 18.3513 0 12 0C5.64873 0 0.5 5.14873 0.5 11.5C0.5 17.8513 5.64873 23 12 23Z"
        fill="#FFCE52"
      />
      <path
        d="M11.9737 1.49758C18.0507 1.48108 22.9852 5.72108 23.0007 10.9676H23.4882C23.3413 8.01481 22.0628 5.23205 19.9182 3.19705C17.7737 1.16204 14.9278 0.0311261 11.9713 0.0391044C9.01494 0.0470827 6.17517 1.19334 4.04162 3.23989C1.90807 5.28644 0.64465 8.07606 0.513672 11.0296H1.00067C0.985172 5.78058 5.90067 1.51408 11.9737 1.49758Z"
        fill="#FFE369"
      />
      <path
        d="M23.0005 10.9707C23.0135 16.2178 18.1005 20.4842 12.025 20.5007C5.94949 20.5173 1.01349 16.2772 1.00049 11.0307H0.513488C0.506488 11.1967 0.501488 11.3632 0.501988 11.5307C0.510209 14.5807 1.7297 17.5025 3.89218 19.6534C6.05466 21.8043 8.983 23.008 12.033 22.9998C15.083 22.9915 18.0048 21.772 20.1556 19.6096C22.3065 17.4471 23.5102 14.5187 23.502 11.4688C23.502 11.3008 23.4955 11.1347 23.488 10.9688L23.0005 10.9707Z"
        fill="#FFB32B"
      />
      <path
        d="M18.6363 4.1378C18.7969 3.97622 18.7448 3.66406 18.5199 3.44059C18.2951 3.21711 17.9826 3.16694 17.822 3.32852C17.6614 3.4901 17.7135 3.80226 17.9384 4.02573C18.1632 4.24921 18.4757 4.29938 18.6363 4.1378Z"
        fill="#F6FAFD"
      />
      <path
        d="M15.2988 1.69177C15.3792 1.61088 15.3532 1.45472 15.2408 1.34299C15.1284 1.23125 14.9721 1.20624 14.8917 1.28713C14.8113 1.36802 14.8372 1.52418 14.9497 1.63591C15.0621 1.74765 15.2184 1.77266 15.2988 1.69177Z"
        fill="#F6FAFD"
      />
      <path
        d="M17.5408 3.54974C17.862 3.22657 17.7577 2.60211 17.3078 2.15497C16.8579 1.70782 16.2329 1.60732 15.9117 1.93048C15.5905 2.25365 15.6948 2.87811 16.1447 3.32525C16.5946 3.7724 17.2196 3.8729 17.5408 3.54974Z"
        fill="#F6FAFD"
      />
      <path
        d="M17.5078 17.9844C19.4408 17.9844 21.0078 16.4174 21.0078 14.4844C21.0078 12.5514 19.4408 10.9844 17.5078 10.9844C15.5748 10.9844 14.0078 12.5514 14.0078 14.4844C14.0078 16.4174 15.5748 17.9844 17.5078 17.9844Z"
        fill="url(#paint0_radial_740_10344)"
      />
      <path
        d="M6.50781 18.0156C8.44081 18.0156 10.0078 16.4486 10.0078 14.5156C10.0078 12.5826 8.44081 11.0156 6.50781 11.0156C4.57482 11.0156 3.00781 12.5826 3.00781 14.5156C3.00781 16.4486 4.57482 18.0156 6.50781 18.0156Z"
        fill="url(#paint1_radial_740_10344)"
      />
      <path
        d="M12.0226 19.5008C11.8256 19.5013 11.6305 19.463 11.4483 19.3881C11.2661 19.3132 11.1005 19.2032 10.9608 19.0643C10.8211 18.9254 10.7102 18.7603 10.6343 18.5785C10.5585 18.3967 10.5192 18.2018 10.5186 18.0048L11.5186 18.0023C11.5186 18.1012 11.548 18.1978 11.6029 18.2801C11.6578 18.3623 11.7359 18.4264 11.8273 18.4642C11.9187 18.5021 12.0192 18.512 12.1162 18.4927C12.2132 18.4734 12.3023 18.4258 12.3722 18.3558C12.4421 18.2859 12.4897 18.1968 12.509 18.0998C12.5283 18.0028 12.5184 17.9023 12.4806 17.8109C12.4427 17.7196 12.3786 17.6415 12.2964 17.5866C12.2142 17.5316 12.1175 17.5023 12.0186 17.5023C11.886 17.5027 11.7587 17.4504 11.6646 17.3569C11.5706 17.2634 11.5175 17.1364 11.5171 17.0038C11.5167 16.8712 11.569 16.7438 11.6625 16.6498C11.756 16.5557 11.883 16.5027 12.0156 16.5023C12.1145 16.5023 12.2112 16.473 12.2934 16.418C12.3756 16.3631 12.4397 16.285 12.4776 16.1936C12.5154 16.1023 12.5253 16.0017 12.506 15.9047C12.4867 15.8078 12.4391 15.7187 12.3692 15.6487C12.2993 15.5788 12.2102 15.5312 12.1132 15.5119C12.0162 15.4926 11.9157 15.5025 11.8243 15.5403C11.7329 15.5782 11.6548 15.6423 11.5999 15.7245C11.545 15.8067 11.5156 15.9034 11.5156 16.0023L10.5156 16.0048C10.5149 15.7616 10.5732 15.5218 10.6857 15.3062C10.7982 15.0905 10.9615 14.9055 11.1614 14.767C11.3614 14.6285 11.592 14.5407 11.8334 14.5111C12.0748 14.4816 12.3198 14.5112 12.5472 14.5974C12.7747 14.6836 12.9777 14.8238 13.1389 15.0059C13.3001 15.1881 13.4146 15.4067 13.4725 15.6429C13.5304 15.8791 13.53 16.1259 13.4713 16.3619C13.4126 16.598 13.2974 16.8162 13.1356 16.9978C13.3294 17.2132 13.4565 17.4801 13.5018 17.7663C13.5471 18.0524 13.5086 18.3455 13.3909 18.6103C13.2732 18.875 13.0813 19.0999 12.8385 19.258C12.5957 19.416 12.3123 19.5003 12.0226 19.5008Z"
        fill="#273941"
      />
      <path
        d="M7.50419 11.5111C8.33261 11.5086 9.0015 10.6112 8.99819 9.50662C8.99487 8.40206 8.32061 7.50864 7.49219 7.51112C6.66376 7.51361 5.99487 8.41106 5.99819 9.51562C6.0015 10.6202 6.67576 11.5136 7.50419 11.5111Z"
        fill="#273941"
      />
      <path
        d="M7.4984 11.0129C8.05068 11.0114 8.49657 10.3386 8.49431 9.51017C8.49206 8.68175 8.04251 8.0114 7.49023 8.0129C6.93795 8.01441 6.49206 8.6872 6.49432 9.51562C6.49657 10.344 6.94612 11.0144 7.4984 11.0129Z"
        fill="#141E21"
      />
      <path
        d="M7.99219 9.51172C8.26833 9.51172 8.49219 9.28786 8.49219 9.01172C8.49219 8.73558 8.26833 8.51172 7.99219 8.51172C7.71605 8.51172 7.49219 8.73558 7.49219 9.01172C7.49219 9.28786 7.71605 9.51172 7.99219 9.51172Z"
        fill="#F6FAFD"
      />
      <path
        d="M19.9981 10.4801H18.9981C18.9975 10.2832 18.9582 10.0882 18.8823 9.90641C18.8065 9.72463 18.6955 9.55956 18.5559 9.42065C18.4162 9.28173 18.2506 9.17168 18.0684 9.09678C17.8862 9.02189 17.691 8.98361 17.4941 8.98413C17.2971 8.98466 17.1021 9.02398 16.9203 9.09985C16.7385 9.17571 16.5735 9.28664 16.4346 9.4263C16.2957 9.56596 16.1856 9.73162 16.1107 9.9138C16.0358 10.096 15.9975 10.2912 15.9981 10.4881L14.9981 10.4911C14.9963 9.82809 15.2579 9.1915 15.7255 8.72139C16.1931 8.25128 16.8283 7.98617 17.4913 7.98438C18.1543 7.98259 18.7909 8.24427 19.261 8.71184C19.7312 9.17942 19.9963 9.81459 19.9981 10.4776V10.4801Z"
        fill="#273941"
      />
      <path
        d="M17.1216 18.8187C16.9016 18.6022 15.6071 15.0997 17.6326 13.0837C17.8003 12.8977 18.0045 12.7481 18.2325 12.6443C18.4605 12.5406 18.7074 12.4849 18.9579 12.4807C19.2083 12.4765 19.457 12.524 19.6883 12.6201C19.9197 12.7162 20.1288 12.8588 20.3026 13.0392C20.9626 13.6892 20.9526 14.5702 20.4971 15.4587C21.3921 15.0032 22.2821 14.9887 22.9421 15.6382C23.1249 15.8074 23.2702 16.0131 23.3687 16.2419C23.4672 16.4707 23.5165 16.7177 23.5137 16.9668C23.5108 17.2159 23.4557 17.4616 23.352 17.6881C23.2483 17.9146 23.0983 18.1168 22.9116 18.2817C20.8861 20.2992 17.5616 19.2517 17.1216 18.8187Z"
        fill="#CF4054"
      />
      <path
        d="M22.9421 15.6382C22.2821 14.9882 21.3921 15.0032 20.4971 15.4587C20.9521 14.5702 20.9626 13.6887 20.3026 13.0392C20.1288 12.8588 19.9197 12.7162 19.6883 12.6201C19.457 12.524 19.2083 12.4765 18.9579 12.4807C18.7074 12.4849 18.4605 12.5406 18.2325 12.6443C18.0045 12.7481 17.8003 12.8977 17.6326 13.0837C15.6071 15.0997 16.9016 18.6022 17.1216 18.8187C17.5616 19.2517 20.8861 20.2977 22.9116 18.2817C23.0983 18.1168 23.2483 17.9146 23.352 17.6881C23.4557 17.4616 23.5108 17.2159 23.5137 16.9668C23.5165 16.7177 23.4672 16.4707 23.3687 16.2419C23.2702 16.0131 23.1249 15.8074 22.9421 15.6382ZM22.0831 17.6232C20.6361 19.0632 18.2611 18.3162 17.9471 18.0067C17.7901 17.8522 16.8651 15.3502 18.3121 13.9102C18.4319 13.7774 18.5777 13.6705 18.7406 13.5965C18.9034 13.5224 19.0798 13.4827 19.2587 13.4797C19.4376 13.4767 19.6152 13.5107 19.7804 13.5793C19.9456 13.648 20.0949 13.7499 20.2191 13.8787C20.4347 14.1083 20.566 14.4044 20.5912 14.7184C20.6165 15.0324 20.5343 15.3456 20.3581 15.6067C20.622 15.431 20.9371 15.3482 21.2533 15.3715C21.5696 15.3947 21.8691 15.5227 22.1046 15.7352C22.2351 15.8561 22.3389 16.0029 22.4092 16.1664C22.4795 16.3298 22.5148 16.5061 22.5128 16.684C22.5108 16.8619 22.4715 17.0374 22.3975 17.1992C22.3234 17.3609 22.2163 17.5054 22.0831 17.6232Z"
        fill="#AE2D4C"
      />
      <path
        d="M22.4214 17.3761C22.582 17.2145 22.5299 16.9023 22.3051 16.6789C22.0803 16.4554 21.7678 16.4052 21.6072 16.5668C21.4466 16.7284 21.4987 17.0405 21.7235 17.264C21.9484 17.4875 22.2608 17.5377 22.4214 17.3761Z"
        fill="#F6FAFD"
      />
      <path
        d="M22.5898 16.4261C22.6702 16.3453 22.6442 16.1891 22.5318 16.0774C22.4194 15.9656 22.2631 15.9406 22.1827 16.0215C22.1023 16.1024 22.1282 16.2585 22.2407 16.3703C22.3531 16.482 22.5094 16.507 22.5898 16.4261Z"
        fill="#F6FAFD"
      />
      <path
        d="M17.7005 19.1237C17.4921 19.0586 17.2969 18.957 17.124 18.8237C16.7782 18.1436 16.5785 17.3986 16.538 16.6367C16.475 17.6038 16.6171 18.5733 16.955 19.4817C16.9949 19.6036 17.0527 19.7189 17.1265 19.8237C17.7586 20.2073 18.4781 20.4235 19.217 20.4517C19.5998 20.1418 19.9626 19.8078 20.303 19.4517C19.422 19.5321 18.534 19.4202 17.7005 19.1237Z"
        fill="#FFB32B"
      />
      <defs>
        <radialGradient
          id="paint0_radial_740_10344"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(17.5121 14.4886) rotate(-0.156) scale(3.501)"
        >
          <stop stopColor="#FF568D" />
          <stop offset="0.454" stopColor="#FF9678" />
          <stop offset="0.817" stopColor="#FFC26A" />
          <stop offset="1" stopColor="#FFD364" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_740_10344"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(6.50867 14.5203) rotate(-0.156) scale(3.501)"
        >
          <stop stopColor="#FF568D" />
          <stop offset="0.454" stopColor="#FF9678" />
          <stop offset="0.817" stopColor="#FFC26A" />
          <stop offset="1" stopColor="#FFD364" />
        </radialGradient>
      </defs>
    </svg>
  );
}
