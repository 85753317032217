import Janus from "../../Janus";

export default class KronaChatSession {
  constructor(chat, server) {
    const initRoomId = (reload = false) => {
      let opaqueId;

      if (window.performance) {
        if (performance.navigation.type === 1) {
          console.log("@@@ Page was reloaded ------------------");
          reload = true;
        }
      }
      if (reload) {
        // Refresh/reload this page
        opaqueId = window.roomOpaqueID;
        if (!opaqueId) {
          opaqueId = `chatroom-${Janus.randomString(12)}`;
          window.roomOpaqueID = opaqueId;
        }
      } else {
        // Simple load this page
        opaqueId = `chatroom-${Janus.randomString(12)}`;
        window.roomOpaqueID = opaqueId;
      }
    };

    initRoomId();
    this._server = server;
    this._chat = chat;
    this._janusSession = null;
  }

  async start() {
    if (this._janusSession) return true;
    if (!Janus.isWebrtcSupported()) return false;

    return await new Promise((resolve) => {
      // Create session
      this._janusSession = new Janus({
        server: this._server,
        //iceServers: [
        //  {
        //    urls: [
        //      'stun:stun.voip.eutelia.it:3478',
        //      'stun:stun.l.google.com:19302',
        //      'stun:stun1.l.google.com:19302',
        //      'stun:stun2.l.google.com:19302',
        //      'stun:stun3.l.google.com:19302',
        //      'stun:stun4.l.google.com:19302',
        //      'stun:stun01.sipphone.com',
        //      'stun:stun.ekiga.net',
        //      'stun:stun.fwdnet.net',
        //      'stun:stun.ideasip.com',
        //      'stun:stun.iptel.org',
        //      'stun:stun.rixtelecom.se',
        //      'stun:stun.schlund.de',
        //      'stun:stunserver.org',
        //      'stun:stun.softjoys.com',
        //      'stun:stun.voiparound.com',
        //      'stun:stun.voipbuster.com',
        //      'stun:stun.voipstunt.com',
        //      'stun:stun.voxgratia.org',
        //      'stun:stun.xten.com',
        //    ],
        //  },
        //],
        success: () => {
          resolve(true);
        },
        error: (e) => {
          Janus.error(e);
          if (!this._janusSession) {
            resolve(false);
          } else {
            (async () => {
              await this.stop();
              this._chat.emit("disconnect");
            })();
          }
        },
        destroyed: () => {
          this._chat.emit("disconnect");
        },
      });
    });
  }

  async stop() {
    if (this._janusSession) {
      await new Promise((resolve) => {
        this._janusSession.destroy({
          success: () => {
            setTimeout(resolve, 500);
          },
          error: resolve,
        });
      });
      this._janusSession = null;
    }
  }

  get janusObject() {
    return this._janusSession;
  }
}
