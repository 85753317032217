import React, { lazy, Suspense, useMemo } from "react";

// import ImgLangFlag from "../mui/ImgLangFlag";

import { Link } from "react-router-dom";
import ShowContainer from "../../mui/ShowContainer";
import { DELAY_GET_BROADCAST } from "../HomeContent";

import ImageUpdate from "../../mui/ImageUpdate";
import IconMobile from "../../../images/jsIcons/IconMobile";
// const ImageUpdate = lazy(() => import("../../mui/ImageUpdate"));
const BtnDick = lazy(() => import("./BtnDick"));
const FlagImg = lazy(() => import("../../mui/FlagImg"));
const CardOnline = lazy(() => import("./CardOnline"));
const CardPlug = lazy(() => import("./CardPlug"));
const BtnLike = lazy(() => import("./BtnLike"));

// ModelCard - карточка модели
export default function ModelCard({ card, isUpdate }) {
  //card
  // {
  //   id: Ид карточки,
  //   imgSource: Картинка,
  //   categories: Массив категорий,
  //   isNew: Флаг отображать ли new  в углу ,
  // device:{
  //   type тип устройства
  // }
  //   name: Имя модели,
  //   lang: Язык модели,
  //   view: Количество просмотров,
  // },

  const { preview, isNew, mode, name, lang, view, chat, lovense, device } = card;
  const imgSource = useMemo(() => chat?.preview || preview, [chat, preview]);

  const isTwoClass = device?.type === "mobile" && lovense?.enable ? "two" : "";

  return (
    <div className={"home__models__card"}>
      <Link to={`/chat/${name}`}>
        <div className="home__models__card__img">
          {/* <Suspense fallback={<img src={noImg} width={150} height={150} alt="plug" />}> */}
          <ImageUpdate
            isBlur
            width={150}
            height={150}
            isUpdate={isUpdate}
            path={chat?.preview}
            imgSource={imgSource}
            time={DELAY_GET_BROADCAST * 1000}
          />
          {/* </Suspense> */}

          <Suspense>
            <ShowContainer condition={device?.type === "mobile"}>
              <button className={`home__models__card__mobile ${isTwoClass}`}>
                <IconMobile />
              </button>
            </ShowContainer>
            <BtnDick lovense={lovense} />
            <CardPlug isNew={isNew} mode={mode} name={name} />
          </Suspense>
        </div>
      </Link>
      <div className="home__models__card__description">
        <div className="home__models__card__description__left">
          <Suspense>{lang && <FlagImg flagCode={lang} />}</Suspense>
          <span className="home__models__card__name">{name}</span>
        </div>
        <div className="home__models__card__description__right">
          <ShowContainer condition={!!card.online}>
            <Suspense>
              <CardOnline view={view} />
            </Suspense>
          </ShowContainer>
          <Suspense>
            <BtnLike cardId={card.id} />
          </Suspense>
        </div>
      </div>
    </div>
  );
}
