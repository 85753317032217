import Janus from "../../Janus";
import { errorToast } from "../../components/mui/Toaster";

const DEFAULT_MODE = "free-chat";

export default class KronaFeedBase {
  constructor(chat) {
    this._chat = chat;
    this._chat_mode = DEFAULT_MODE;
    this._participantStats = {};
  }

  _onData(data) {
    const json = JSON.parse(data);
    const type = json.krona ?? json.type;

    if (!type) {
      // TODO: handle error data type
      console.error("### No data type specified");
      return;
    }

    this._processData(type, json);
  }

  _processData(type, json) {
    if (type === "message") {
      // Text message
      // TODO: Change format
      console.log("message");
      this._chat.emit("message", Janus, {
        ...json,
        isSelf: json.from.id === this._chat.localfeed.id,
      });
    } else {
      // TODO: unknown message type
      console.error("### Unknown data type specified", type, json);
    }
  }

  _onParticipantStats(stats) {
    const isEqual = () => {
      for (const [k] of Object.entries(stats))
        if (this._participantStats[k] !== stats[k]) return false;
      return true;
    };

    if (isEqual()) return;

    this._chat.emit("participant.stats", stats, this._participantStats);
    this._participantStats = stats;
  }

  logError(e) {
    errorToast(e);
  }

  get chatMode() {
    return this._chat_mode;
  }
}
