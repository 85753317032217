import React from "react";

export default function Smile9() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_740_10390)">
        <path
          d="M12 23.5C18.3513 23.5 23.5 18.3513 23.5 12C23.5 5.64873 18.3513 0.5 12 0.5C5.64873 0.5 0.5 5.64873 0.5 12C0.5 18.3513 5.64873 23.5 12 23.5Z"
          fill="#FFCE52"
        />
        <path
          d="M11.9992 1.99911C18.0742 1.99911 22.9992 6.25261 22.9992 11.4991H23.4867C23.3489 8.54514 22.0784 5.7578 19.9392 3.71607C17.7999 1.67434 14.9564 0.535156 11.9992 0.535156C9.04203 0.535156 6.1985 1.67434 4.05927 3.71607C1.92005 5.7578 0.649561 8.54514 0.511719 11.4991H0.999219C0.999219 6.25261 5.92422 1.99911 11.9992 1.99911Z"
          fill="#FFE369"
        />
        <path
          d="M23 11.5C23 16.7465 18.075 21 12 21C5.925 21 1 16.7465 1 11.5H0.5125C0.5055 11.666 0.5 11.8325 0.5 12C0.5 15.05 1.7116 17.9751 3.86827 20.1317C6.02494 22.2884 8.95001 23.5 12 23.5C15.05 23.5 17.9751 22.2884 20.1317 20.1317C22.2884 17.9751 23.5 15.05 23.5 12C23.5 11.8325 23.4945 11.666 23.4875 11.5H23Z"
          fill="#FFB32B"
        />
        <path
          d="M18.9064 4.90641C19.0674 4.74526 19.0162 4.43297 18.7919 4.20889C18.5677 3.98481 18.2553 3.93379 18.0943 4.09494C17.9333 4.25608 17.9845 4.56837 18.2088 4.79246C18.433 5.01654 18.7453 5.06756 18.9064 4.90641Z"
          fill="#F6FAFD"
        />
        <path
          d="M15.5754 2.45498C15.656 2.37431 15.6305 2.21809 15.5184 2.10604C15.4063 1.994 15.25 1.96857 15.1694 2.04924C15.0888 2.12992 15.1143 2.28614 15.2264 2.39818C15.3386 2.51022 15.4948 2.53565 15.5754 2.45498Z"
          fill="#F6FAFD"
        />
        <path
          d="M17.8134 4.31353C18.1355 3.99123 18.0329 3.36649 17.5842 2.91813C17.1355 2.46977 16.5107 2.36758 16.1886 2.68987C15.8666 3.01217 15.9692 3.63691 16.4179 4.08527C16.8665 4.53363 17.4913 4.63582 17.8134 4.31353Z"
          fill="#F6FAFD"
        />
        <path
          d="M16.9999 19.5C16.9999 18.12 14.7649 17 11.9999 17C9.23491 17 6.99991 18.12 6.99991 19.5L6.94991 19.565C6.19996 19.0798 5.58305 18.415 5.1552 17.6309C4.72736 16.8468 4.50211 15.9682 4.49991 15.075C4.4827 14.8088 4.57126 14.5465 4.74634 14.3452C4.92142 14.1439 5.16887 14.0199 5.43491 14C6.37491 14.54 8.62991 14.5 11.9999 14.5C15.3699 14.5 17.6249 14.54 18.5599 14C18.8268 14.0187 19.0754 14.1423 19.2515 14.3437C19.4277 14.5451 19.5169 14.808 19.4999 15.075C19.4977 15.9682 19.2725 16.8468 18.8446 17.6309C18.4168 18.415 17.7999 19.0798 17.0499 19.565L16.9999 19.5Z"
          fill="#273941"
        />
        <path
          d="M16.9992 19.5L17.0492 19.565C15.5474 20.5379 13.7882 21.0378 11.9992 21C10.2102 21.0378 8.45103 20.5379 6.94922 19.565L6.99922 19.5C6.99922 18.12 9.23422 17 11.9992 17C14.7642 17 16.9992 18.12 16.9992 19.5Z"
          fill="#AE2D4C"
        />
        <path
          d="M8.25037 4.50013C8.55965 4.49683 8.86625 4.55784 9.15072 4.67928C9.43518 4.80072 9.69131 4.97995 9.90286 5.2056C10.1144 5.43125 10.2768 5.6984 10.3796 5.9901C10.4825 6.2818 10.5236 6.59169 10.5004 6.90013C10.5004 10.5001 6.75037 12.5001 6.00037 12.5001C5.64037 12.5001 1.81037 10.6501 1.51537 7.30513C1.50537 7.17013 1.50037 7.03513 1.50037 6.90013C1.45969 6.35728 1.61938 5.81836 1.94924 5.38531C2.27909 4.95226 2.75622 4.65513 3.29037 4.55013C3.44129 4.51595 3.59563 4.49918 3.75037 4.50013C4.87537 4.50013 5.62537 5.30013 6.00037 6.50013C6.37537 5.30013 7.12537 4.50013 8.25037 4.50013Z"
          fill="#CF4054"
        />
        <path
          d="M22.5004 6.90013C22.5004 7.03346 22.4954 7.16846 22.4854 7.30513C22.2204 10.6501 18.7204 12.5001 18.0004 12.5001C17.6254 12.5001 13.5004 10.5001 13.5004 6.90013C13.4772 6.59169 13.5183 6.2818 13.6212 5.9901C13.7241 5.6984 13.8864 5.43125 14.0979 5.2056C14.3095 4.97995 14.5656 4.80072 14.8501 4.67928C15.1346 4.55784 15.4412 4.49683 15.7504 4.50013C16.8754 4.50013 17.6254 5.30013 18.0004 6.50013C18.3754 5.30013 19.1254 4.50013 20.2504 4.50013C20.4052 4.49918 20.5595 4.51595 20.7104 4.55013C21.2446 4.65513 21.7217 4.95226 22.0516 5.38531C22.3814 5.81836 22.5411 6.35728 22.5004 6.90013Z"
          fill="#CF4054"
        />
        <path
          d="M5.43491 15C6.37491 15.54 8.62991 15.5 11.9999 15.5C15.3699 15.5 17.6249 15.54 18.5599 15C18.7734 15.0104 18.9773 15.0915 19.1396 15.2305C19.3019 15.3696 19.4134 15.5587 19.4564 15.768C19.4854 15.5381 19.5 15.3067 19.4999 15.075C19.5169 14.808 19.4277 14.5451 19.2515 14.3437C19.0754 14.1423 18.8268 14.0187 18.5599 14C17.6249 14.54 15.3699 14.5 11.9999 14.5C8.62991 14.5 6.37491 14.54 5.43491 14C5.16887 14.0199 4.92142 14.1439 4.74634 14.3452C4.57126 14.5465 4.4827 14.8088 4.49991 15.075C4.49984 15.3065 4.51437 15.5378 4.54341 15.7675C4.58584 15.5588 4.69644 15.3701 4.85784 15.2312C5.01923 15.0923 5.22223 15.0109 5.43491 15Z"
          fill="#141E21"
        />
        <path
          d="M16.7647 18.7422C15.3224 19.5923 13.6732 20.0273 11.9992 19.9992C10.3252 20.0273 8.67606 19.5923 7.23372 18.7422C7.08451 18.9668 7.0031 19.2296 6.99922 19.4992L6.94922 19.5642C8.45103 20.5371 10.2102 21.037 11.9992 20.9992C13.7882 21.037 15.5474 20.5371 17.0492 19.5642L16.9992 19.4992C16.9953 19.2296 16.9139 18.9668 16.7647 18.7422Z"
          fill="#8A293D"
        />
        <path
          d="M18 12.4988C17.645 12.4988 13.9345 10.7038 13.5395 7.45831C13.5155 7.60397 13.5023 7.75121 13.5 7.89881C13.5 11.4988 17.625 13.4988 18 13.4988C18.72 13.4988 22.22 11.6488 22.485 8.30381C22.495 8.16715 22.5 8.03215 22.5 7.89881C22.4997 7.74698 22.488 7.59539 22.465 7.44531C22.1125 10.6988 18.7095 12.4988 18 12.4988Z"
          fill="#FBB40A"
        />
        <path
          d="M6 12.4988C5.645 12.4988 1.9345 10.7038 1.5395 7.45831C1.51551 7.60397 1.50231 7.75121 1.5 7.89881C1.5 11.4988 5.625 13.4988 6 13.4988C6.72 13.4988 10.22 11.6488 10.485 8.30381C10.495 8.16715 10.5 8.03215 10.5 7.89881C10.4997 7.74698 10.488 7.59539 10.465 7.44531C10.1125 10.6988 6.7095 12.4988 6 12.4988Z"
          fill="#FBB40A"
        />
        <path
          d="M20.2504 4.50013C19.1254 4.50013 18.3754 5.30013 18.0004 6.50013C18.2919 5.60013 18.8754 5.00013 19.7504 5.00013C19.9863 4.99338 20.221 5.03571 20.4398 5.12444C20.6585 5.21318 20.8563 5.34638 21.0209 5.51561C21.1854 5.68483 21.313 5.88639 21.3955 6.10751C21.4781 6.32863 21.5138 6.56449 21.5004 6.80013C21.5004 9.50013 18.5839 11.0001 18.0004 11.0001C17.7204 11.0001 14.7414 9.61263 14.5119 7.10363C14.5044 7.00363 14.5004 6.90113 14.5004 6.80013C14.4747 6.3865 14.6025 5.97795 14.8594 5.65273C15.1162 5.3275 15.484 5.10849 15.8924 5.03763C16.0099 5.01188 16.13 4.9993 16.2504 5.00013C17.1254 5.00013 17.7089 5.60013 18.0004 6.50013C17.6254 5.30013 16.8754 4.50013 15.7504 4.50013C15.5956 4.49918 15.4413 4.51595 15.2904 4.55013C14.7562 4.65513 14.2791 4.95226 13.9492 5.38531C13.6194 5.81836 13.4597 6.35728 13.5004 6.90013C13.5004 7.03346 13.5054 7.16846 13.5154 7.30513C13.8104 10.6501 17.6404 12.5001 18.0004 12.5001C18.7504 12.5001 22.5004 10.5001 22.5004 6.90013C22.5236 6.59169 22.4825 6.2818 22.3796 5.9901C22.2768 5.6984 22.1144 5.43125 21.9029 5.2056C21.6913 4.97995 21.4352 4.80072 21.1507 4.67928C20.8663 4.55784 20.5597 4.49683 20.2504 4.50013Z"
          fill="#AE2D4C"
        />
        <path
          d="M8.25037 4.50013C7.12537 4.50013 6.37537 5.30013 6.00037 6.50013C6.29187 5.60013 6.87537 5.00013 7.75037 5.00013C7.98629 4.99338 8.22105 5.03571 8.43975 5.12444C8.65846 5.21318 8.85635 5.34638 9.02087 5.51561C9.1854 5.68483 9.31297 5.88639 9.39551 6.10751C9.47805 6.32863 9.51376 6.56449 9.50037 6.80013C9.50037 9.50013 6.58387 11.0001 6.00037 11.0001C5.72037 11.0001 2.74137 9.61263 2.51187 7.10363C2.50437 7.00263 2.50037 6.90013 2.50037 6.80013C2.47469 6.3865 2.60252 5.97795 2.85938 5.65273C3.11623 5.3275 3.48405 5.10849 3.89237 5.03763C4.00994 5.01188 4.13001 4.9993 4.25037 5.00013C5.12537 5.00013 5.70887 5.60013 6.00037 6.50013C5.62537 5.30013 4.87537 4.50013 3.75037 4.50013C3.59563 4.49918 3.44129 4.51595 3.29037 4.55013C2.75622 4.65513 2.27909 4.95226 1.94924 5.38531C1.61938 5.81836 1.45969 6.35728 1.50037 6.90013C1.50037 7.03346 1.50537 7.16846 1.51537 7.30513C1.81037 10.6501 5.64037 12.5001 6.00037 12.5001C6.75037 12.5001 10.5004 10.5001 10.5004 6.90013C10.5236 6.59169 10.4825 6.2818 10.3796 5.9901C10.2768 5.6984 10.1144 5.43125 9.90286 5.2056C9.69131 4.97995 9.43518 4.80072 9.15072 4.67928C8.86625 4.55784 8.55965 4.49683 8.25037 4.50013Z"
          fill="#AE2D4C"
        />
        <path
          d="M21.4064 6.9025C21.5674 6.74136 21.5162 6.42907 21.2919 6.20498C21.0677 5.9809 20.7553 5.92988 20.5943 6.09103C20.4333 6.25218 20.4845 6.56447 20.7088 6.78855C20.933 7.01263 21.2453 7.06365 21.4064 6.9025Z"
          fill="#F6FAFD"
        />
        <path
          d="M20.5754 5.95108C20.656 5.8704 20.6305 5.71418 20.5184 5.60214C20.4063 5.4901 20.25 5.46467 20.1694 5.54534C20.0888 5.62601 20.1143 5.78223 20.2264 5.89428C20.3386 6.00632 20.4948 6.03175 20.5754 5.95108Z"
          fill="#F6FAFD"
        />
        <path
          d="M9.40636 6.90641C9.56739 6.74526 9.51615 6.43297 9.29192 6.20889C9.06768 5.98481 8.75535 5.93379 8.59431 6.09494C8.43328 6.25608 8.48452 6.56837 8.70876 6.79246C8.93299 7.01654 9.24532 7.06756 9.40636 6.90641Z"
          fill="#F6FAFD"
        />
        <path
          d="M8.57543 5.95498C8.65604 5.87431 8.6305 5.71809 8.51838 5.60604C8.40626 5.494 8.25002 5.46857 8.1694 5.54924C8.08879 5.62992 8.11433 5.78614 8.22645 5.89818C8.33857 6.01022 8.49481 6.03565 8.57543 5.95498Z"
          fill="#F6FAFD"
        />
      </g>
      <defs>
        <clipPath id="clip0_740_10390">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
