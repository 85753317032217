import React from "react";

export default function IconLocation() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <g clipPath="url(#clip0_26_13145)">
        <g filter="url(#filter0_di_26_13145)">
          <path
            d="M16.4647 12.6201C16.2094 12.4799 15.8888 12.5731 15.7486 12.8284C15.6084 13.0836 15.7016 13.4043 15.9569 13.5445C16.585 13.8896 16.9453 14.2721 16.9453 14.5941C16.9453 14.9879 16.3874 15.5982 14.8214 16.1302C13.2744 16.6559 11.2069 16.9453 9 16.9453C6.79307 16.9453 4.72563 16.6559 3.17858 16.1302C1.61262 15.5982 1.05469 14.9879 1.05469 14.5941C1.05469 14.2721 1.41497 13.8896 2.04314 13.5444C2.29841 13.4042 2.39164 13.0836 2.25141 12.8283C2.11117 12.5731 1.79065 12.4798 1.53531 12.6201C0.834926 13.0048 0 13.6597 0 14.5941C0 15.3104 0.492609 16.3316 2.83929 17.1289C4.49276 17.6906 6.68067 18 9 18C11.3193 18 13.5072 17.6906 15.1607 17.1289C17.5074 16.3316 18 15.3104 18 14.5941C18 13.6597 17.1651 13.0048 16.4647 12.6201Z"
            fill="url(#paint0_linear_26_13145)"
            shapeRendering="crispEdges"
          />
        </g>
        <g filter="url(#filter1_di_26_13145)">
          <path
            d="M5.01866 15.3678C6.08917 15.6703 7.50312 15.8368 9.00004 15.8368C10.497 15.8368 11.9109 15.6703 12.9814 15.3678C14.2911 14.9979 14.9552 14.4623 14.9552 13.7761C14.9552 13.0899 14.2911 12.5544 12.9814 12.1845C12.6907 12.1024 12.3745 12.0303 12.0383 11.9689C11.8557 12.2843 11.6645 12.609 11.4645 12.943C11.8366 12.9988 12.1859 13.0673 12.5031 13.1481C13.467 13.3936 13.8191 13.6699 13.8915 13.7762C13.8191 13.8825 13.467 14.1588 12.5032 14.4043C11.5909 14.6366 10.4172 14.7692 9.18099 14.781C9.12112 14.7854 9.06079 14.7879 9.00004 14.7879C8.93925 14.7879 8.87896 14.7854 8.81905 14.781C7.58286 14.7692 6.40913 14.6367 5.49686 14.4043C4.53302 14.1588 4.18093 13.8824 4.10854 13.7762C4.18093 13.6699 4.53305 13.3936 5.49689 13.1481C5.81414 13.0673 6.16349 12.9988 6.53562 12.943C6.33562 12.609 6.14433 12.2843 5.96177 11.9689C5.6256 12.0304 5.30937 12.1024 5.01866 12.1845C3.70899 12.5544 3.04492 13.09 3.04492 13.7761C3.04492 14.4623 3.70899 14.9979 5.01866 15.3678Z"
            fill="url(#paint1_linear_26_13145)"
            shapeRendering="crispEdges"
          />
        </g>
        <g filter="url(#filter2_di_26_13145)">
          <path
            d="M8.99984 13.7332C9.46929 13.7332 9.8957 13.494 10.1406 13.0933C11.8566 10.2859 13.902 6.58582 13.902 4.90215C13.902 2.19909 11.7029 0 8.99984 0C6.29675 0 4.09766 2.19909 4.09766 4.90215C4.09766 6.58582 6.14315 10.2859 7.85909 13.0933C8.10399 13.494 8.53044 13.7332 8.99984 13.7332ZM7.02944 4.56557C7.02944 3.47913 7.91338 2.59523 8.99984 2.59523C10.0863 2.59523 10.9702 3.47913 10.9702 4.56557C10.9702 5.65204 10.0863 6.53593 8.99984 6.53593C7.91338 6.53593 7.02944 5.65207 7.02944 4.56557Z"
            fill="url(#paint2_linear_26_13145)"
            shapeRendering="crispEdges"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_di_26_13145"
          x="-58"
          y="-16.4452"
          width="134"
          height="121.445"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="29" />
          <feGaussianBlur stdDeviation="29" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_13145" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_26_13145"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="6.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.169764 0 0 0 0 0.624988 0 0 0 0 0.768743 0 0 0 0.03 0"
          />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_26_13145" />
        </filter>
        <filter
          id="filter1_di_26_13145"
          x="-54.9551"
          y="-17.0311"
          width="127.91"
          height="119.868"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="29" />
          <feGaussianBlur stdDeviation="29" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_13145" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_26_13145"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="6.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.169764 0 0 0 0 0.624988 0 0 0 0 0.768743 0 0 0 0.03 0"
          />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_26_13145" />
        </filter>
        <filter
          id="filter2_di_26_13145"
          x="-53.9023"
          y="-29"
          width="125.804"
          height="129.733"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="29" />
          <feGaussianBlur stdDeviation="29" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_13145" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_26_13145"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="6.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.169764 0 0 0 0 0.624988 0 0 0 0 0.768743 0 0 0 0.03 0"
          />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_26_13145" />
        </filter>
        <linearGradient
          id="paint0_linear_26_13145"
          x1="9"
          y1="12.5548"
          x2="9"
          y2="18.4538"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_26_13145"
          x1="9.00004"
          y1="11.9689"
          x2="9.00004"
          y2="16.1592"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_26_13145"
          x1="8.99984"
          y1="0"
          x2="8.99984"
          y2="14.8777"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_26_13145">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
