import React from "react";

export default function IconNewCircle() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_26_14356)">
        <path
          d="M7 0.0180664C3.14018 0.0180664 0 3.15825 0 7.01807C0.384508 16.3045 13.6169 16.3018 14 7.01801C14 3.15825 10.8598 0.0180664 7 0.0180664ZM4.76864 5.7675L4.32931 8.40344C4.30011 8.57868 4.16139 8.71519 3.98573 8.74163C3.81011 8.76799 3.63735 8.67835 3.55789 8.51946L2.80399 7.01167L2.57204 8.40347C2.553 8.51774 2.4185 8.79369 2.10003 8.74061C1.87657 8.70337 1.72564 8.49206 1.76288 8.26861L2.20221 5.63267C2.23141 5.45742 2.37013 5.32092 2.54578 5.29448C2.72158 5.26812 2.89417 5.35775 2.97363 5.51665L3.72753 7.02444L3.95948 5.63264C3.99673 5.40919 4.20801 5.2583 4.43149 5.29549C4.65495 5.33276 4.80588 5.54407 4.76864 5.7675ZM7.4393 6.11025H6.24914L6.16621 6.60791H7C7.5442 6.62959 7.54379 7.40676 7 7.42822H6.02949L5.94655 7.92588H7C7.5442 7.94756 7.54379 8.72473 7 8.74619H5.46238C5.20929 8.74619 5.01613 8.51864 5.0578 8.26861L5.49713 5.63267C5.53011 5.43489 5.7012 5.28994 5.90171 5.28994H7.43933C7.9835 5.3116 7.98309 6.08876 7.4393 6.11025ZM10.8814 8.51946C10.8031 8.67619 10.6338 8.76596 10.4601 8.74256C10.2864 8.71929 10.1467 8.58828 10.1124 8.41648L9.88113 7.26017L9.109 8.54705C8.8926 8.90112 8.35029 8.75018 8.34714 8.33604V5.7001C8.36883 5.1559 9.14599 5.15631 9.16746 5.7001V6.85526L9.72357 5.92842C9.81075 5.78309 9.97842 5.70647 10.1453 5.73532C10.3122 5.76425 10.4442 5.89284 10.4775 6.05901L10.6823 7.08342L11.4657 5.5167C11.7284 5.03964 12.4234 5.38759 12.1994 5.88355L10.8814 8.51946Z"
          fill="url(#paint0_linear_26_14356)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_26_14356"
          x1="7"
          y1="0.0180664"
          x2="7"
          y2="15.1455"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_26_14356">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
