import React from "react";
import Spinner from "./Spinner";

export default function LoaderContainer({ loader, children, className = "", center }) {
  const loaderClass = loader ? "loader" : "";

  return center ? (
    <div className={`loader-container-center ${loaderClass}`} style={{ margin: "auto" }}>
      {loader ? <Spinner className={className} /> : children}
    </div>
  ) : (
    <>{loader ? <Spinner className={className} /> : children}</>
  );
}
