import React from "react";

export default function Simple18() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_740_10669)">
        <path
          d="M12 23.5C18.3513 23.5 23.5 18.3513 23.5 12C23.5 5.64873 18.3513 0.5 12 0.5C5.64873 0.5 0.5 5.64873 0.5 12C0.5 18.3513 5.64873 23.5 12 23.5Z"
          fill="#FFB32B"
        />
        <path
          d="M14 14.5C14.8284 14.5 15.5 13.6046 15.5 12.5C15.5 11.3954 14.8284 10.5 14 10.5C13.1716 10.5 12.5 11.3954 12.5 12.5C12.5 13.6046 13.1716 14.5 14 14.5Z"
          fill="#273941"
        />
        <path
          d="M5 14.5C5.82843 14.5 6.5 13.6046 6.5 12.5C6.5 11.3954 5.82843 10.5 5 10.5C4.17157 10.5 3.5 11.3954 3.5 12.5C3.5 13.6046 4.17157 14.5 5 14.5Z"
          fill="#273941"
        />
        <path
          d="M14 14C14.5523 14 15 13.3284 15 12.5C15 11.6716 14.5523 11 14 11C13.4477 11 13 11.6716 13 12.5C13 13.3284 13.4477 14 14 14Z"
          fill="#141E21"
        />
        <path
          d="M5 14C5.55228 14 6 13.3284 6 12.5C6 11.6716 5.55228 11 5 11C4.44772 11 4 11.6716 4 12.5C4 13.3284 4.44772 14 5 14Z"
          fill="#141E21"
        />
        <path
          d="M14.5 12.5C14.7761 12.5 15 12.2761 15 12C15 11.7239 14.7761 11.5 14.5 11.5C14.2239 11.5 14 11.7239 14 12C14 12.2761 14.2239 12.5 14.5 12.5Z"
          fill="#F6FAFD"
        />
        <path
          d="M5.5 12.5C5.77614 12.5 6 12.2761 6 12C6 11.7239 5.77614 11.5 5.5 11.5C5.22386 11.5 5 11.7239 5 12C5 12.2761 5.22386 12.5 5.5 12.5Z"
          fill="#F6FAFD"
        />
        <path
          d="M11.5 19H10.5C10.5 18.796 10.1105 18.5 9.5 18.5C8.8895 18.5 8.5 18.796 8.5 19H7.5C7.5 18.1585 8.3785 17.5 9.5 17.5C10.6215 17.5 11.5 18.1585 11.5 19Z"
          fill="#273941"
        />
        <path
          d="M12 0.5C9.72552 0.5 7.50211 1.17446 5.61095 2.4381C3.71978 3.70174 2.2458 5.49779 1.37539 7.59914C0.504983 9.70049 0.277244 12.0128 0.720974 14.2435C1.1647 16.4743 2.25997 18.5234 3.86828 20.1317C5.47658 21.74 7.52568 22.8353 9.75647 23.279C11.9872 23.7228 14.2995 23.495 16.4009 22.6246C18.5022 21.7542 20.2983 20.2802 21.5619 18.3891C22.8255 16.4979 23.5 14.2745 23.5 12C23.5 8.95001 22.2884 6.02494 20.1317 3.86827C17.9751 1.7116 15.05 0.5 12 0.5ZM11 23C9.12108 23 7.28435 22.4428 5.72209 21.399C4.15982 20.3551 2.94218 18.8714 2.22315 17.1355C1.50412 15.3996 1.31599 13.4895 1.68254 11.6466C2.0491 9.80382 2.95389 8.11108 4.28249 6.78249C5.61109 5.45389 7.30383 4.5491 9.14665 4.18254C10.9895 3.81598 12.8996 4.00411 14.6355 4.72314C16.3714 5.44218 17.8551 6.65982 18.899 8.22208C19.9428 9.78435 20.5 11.6211 20.5 13.5C20.5 16.0196 19.4991 18.4359 17.7175 20.2175C15.9359 21.9991 13.5196 23 11 23Z"
          fill="#FFCE52"
        />
        <path
          d="M23.5008 11.9988C23.5005 9.63391 22.7712 7.32664 21.4122 5.39129C20.0532 3.45594 18.1306 1.98661 15.9063 1.18349C13.682 0.380364 11.2642 0.282496 8.98228 0.903219C6.70036 1.52394 4.66528 2.83307 3.1543 4.65225C5.35965 2.82361 8.16815 1.88398 11.0299 2.01731C13.8917 2.15065 16.6007 3.34735 18.6264 5.37312C20.6522 7.3989 21.8489 10.1079 21.9822 12.9696C22.1156 15.8314 21.1759 18.6399 19.3473 20.8453C20.6481 19.7674 21.6949 18.4155 22.4129 16.8863C23.1309 15.357 23.5024 13.6881 23.5008 11.9988Z"
          fill="#FFE369"
        />
        <path
          d="M21.9064 13.4064C22.0674 13.2453 22.0162 12.933 21.7919 12.7089C21.5677 12.4848 21.2553 12.4338 21.0943 12.5949C20.9333 12.7561 20.9845 13.0684 21.2088 13.2925C21.433 13.5165 21.7453 13.5676 21.9064 13.4064Z"
          fill="#F6FAFD"
        />
        <path
          d="M22.0754 9.95108C22.156 9.8704 22.1305 9.71418 22.0184 9.60214C21.9063 9.4901 21.75 9.46467 21.6694 9.54534C21.5888 9.62601 21.6143 9.78223 21.7264 9.89427C21.8386 10.0063 21.9948 10.0317 22.0754 9.95108Z"
          fill="#F6FAFD"
        />
        <path
          d="M22.3115 11.8096C22.6335 11.4873 22.5309 10.8626 22.0822 10.4142C21.6336 9.96587 21.0087 9.86367 20.6867 10.186C20.3646 10.5083 20.4672 11.133 20.9159 11.5814C21.3646 12.0297 21.9894 12.1319 22.3115 11.8096Z"
          fill="#F6FAFD"
        />
        <path
          d="M23.5 4C23.5 2.067 21.4855 0.5 19 0.5C16.5145 0.5 14.5 2.067 14.5 4C14.5 5.8175 16.221 7.328 18.5 7.5V9.5L21.7115 6.7885C22.2283 6.52183 22.6658 6.12368 22.9798 5.63414C23.2937 5.1446 23.4732 4.58096 23.5 4Z"
          fill="#5987DD"
        />
        <path
          d="M23.5004 3.99969C23.5004 2.06669 21.4859 0.499688 19.0004 0.499688C18.2313 0.469832 17.4664 0.626331 16.7709 0.955852C16.0754 1.28537 15.4698 1.77815 15.0059 2.39219C15.8906 1.79832 16.9348 1.48709 18.0004 1.49969C20.4859 1.49969 22.5004 3.06669 22.5004 4.99969C22.4959 5.56986 22.3219 6.1258 22.0004 6.59669C22.443 6.3187 22.811 5.9368 23.0724 5.4842C23.3338 5.0316 23.4807 4.52199 23.5004 3.99969Z"
          fill="#95B4FF"
        />
        <path
          d="M18.5 9.49813V7.49813C16.221 7.32613 14.5 5.81563 14.5 3.99813C14.5046 3.42391 14.6807 2.86416 15.0055 2.39062C14.5609 2.66945 14.1912 3.05284 13.9288 3.50734C13.6664 3.96184 13.5192 4.47366 13.5 4.99813C13.5 6.81563 15.221 8.32613 17.5 8.49813V10.4981L20.7115 7.78662C21.2253 7.49165 21.6658 7.08433 22 6.59513C21.9055 6.66113 21.811 6.72712 21.71 6.78662L18.5 9.49813Z"
          fill="#F29410"
        />
        <path d="M17.5 3.5H16.5V4.5H17.5V3.5Z" fill="#EDEDED" />
        <path d="M19.5 3.5H18.5V4.5H19.5V3.5Z" fill="#EDEDED" />
      </g>
      <defs>
        <clipPath id="clip0_740_10669">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
