import { useEffect, useRef } from "react";
import JanusState from "../controls/janus/site/JanusState";
import { sleep } from "../utils/utils";
import { useDebounceValue, useEventListener, useInterval } from "usehooks-ts";
import { useDispatch, useSelector } from "react-redux";
import {
  setBroadCasters,
  setBroadcastersOnline,
  setBroadcastersPayload,
  setMaxPage,
} from "../store/commonSlice";
import { API_BROADCASTER } from "../api/broadcasters";
import { setMainUser } from "../store/userSlice";
import { errorDevToast } from "../components/mui/Toaster";
import { TEST_MODE } from "../components/common/consts";
import { defaultModel } from "../testConst";
import { setTotal } from "../store/homeSlice";

const STATUS = {
  janusInProcess: "janusInProcess", // янус ещё не завершил процесс
  alreadyProgress: "janusInProcess", // основная функция уже запущенна
};

export default function useGetBroadcasterList({
  COUNT_PAGE_HOME,
  DELAY_GET_BROADCAST,
  setLoadingModels,
  handleModelUpdate,
  setIsUpdate,
}) {
  const dispatch = useDispatch();
  const currentSex = useSelector((state) => state.common.typeModel);
  const sortingModel = useSelector((state) => state.common.typeSort);
  const shown = useSelector((state) => state.common.shown);
  const filtersCategory = useSelector((state) => state.common.filter);
  const nicknameSelector = useSelector((state) => state.common.nickname);
  const currentPage = useSelector((state) => state.home.currentPage);

  // dispatch broadcaster для сохранения

  const [nickname, setNickname] = useDebounceValue(nicknameSelector, 500);
  const alreadyRef = useRef(false);

  const getBroadcastList = async () => {
    if (JanusState.inProcess) {
      setLoadingModels(true);

      await sleep(200);
      console.log("await inInitJanus");
      // handleModelUpdate([]);
      return STATUS.janusInProcess;
    }

    const filter = {
      categories: [currentSex, ...shown, ...filtersCategory],
      nickname: nickname,
    };

    const paging = { length: COUNT_PAGE_HOME, page: currentPage };
    if (sortingModel) paging.order = sortingModel;
    dispatch(setBroadcastersPayload({ filter, paging }));
    await API_BROADCASTER.list({ filter, paging })
      .then((e) => {
        const broadcastersInfo = e.data.broadcasters;
        const broadcasters = [...broadcastersInfo.data, ...broadcastersInfo.more];
        const models = API_BROADCASTER.utils.getModals(e);
        dispatch(setTotal(broadcastersInfo.total));
        handleModelUpdate(models);
        setIsUpdate(Math.random());
        dispatch(setBroadcastersOnline(broadcasters.filter((el) => el.online)));
        dispatch(setMainUser(e.data.user));
        dispatch(setMaxPage(broadcastersInfo.total / COUNT_PAGE_HOME));
      })
      .catch(errorDevToast);

    if (TEST_MODE) {
      handleModelUpdate(defaultModel);
      dispatch(setBroadCasters(defaultModel));
    }
  };

  const callbackBroadcaster = async () => {
    if (alreadyRef.current) return STATUS.alreadyProgress;
    alreadyRef.current = true;
    const res = await getBroadcastList();
    alreadyRef.current = false;
    return res;
  };

  useEffect(() => {
    setNickname(nicknameSelector);
  }, [nicknameSelector]);

  useEffect(() => {
    setLoadingModels(true);

    callbackBroadcaster()
      .then((status) => {
        if (status === STATUS.janusInProcess) {
          setLoadingModels(true);
          return;
        }
        setLoadingModels(false);
      })
      .catch(() => setLoadingModels(false));
  }, [currentSex, currentPage, shown, filtersCategory, nickname, sortingModel]);

  useEffect(() => {
    setIsUpdate(Math.random());
  }, [currentPage]);

  useInterval(() => {
    callbackBroadcaster();
  }, DELAY_GET_BROADCAST);

  useEventListener("destroy.model", () => {
    callbackBroadcaster();
  });
}
