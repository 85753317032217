import React from "react";

export default function IconSort() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        d="M9.00001 9.84375C9.22595 9.84375 9.42996 9.97893 9.51803 10.187C9.60609 10.3951 9.56112 10.6356 9.40383 10.7978L6.40383 13.8916C6.29789 14.0008 6.1522 14.0625 6.00001 14.0625C5.84783 14.0625 5.70214 14.0008 5.5962 13.8916L2.5962 10.7978C2.43891 10.6356 2.39394 10.3951 2.482 10.187C2.57007 9.97893 2.77408 9.84375 3.00001 9.84375H5.43751V4.5C5.43751 4.18934 5.68935 3.9375 6.00001 3.9375C6.31067 3.9375 6.56251 4.18934 6.56251 4.5V9.84375H9.00001Z"
        fill="white"
        fillOpacity="0.6"
      />
      <path
        d="M15 8.15625C15.2259 8.15625 15.43 8.02107 15.518 7.813C15.6061 7.60493 15.5611 7.36437 15.4038 7.20217L12.4038 4.10842C12.2979 3.99916 12.1522 3.9375 12 3.9375C11.8478 3.9375 11.7021 3.99916 11.5962 4.10842L8.5962 7.20217C8.43891 7.36437 8.39394 7.60493 8.482 7.813C8.57007 8.02107 8.77408 8.15625 9.00001 8.15625H11.4375V13.5C11.4375 13.8107 11.6894 14.0625 12 14.0625C12.3107 14.0625 12.5625 13.8107 12.5625 13.5L12.5625 8.15625H15Z"
        fill="white"
        fillOpacity="0.6"
      />
    </svg>
  );
}
