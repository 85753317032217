import React from "react";
import { useTranslation } from "react-i18next";
import IconFind from "../../images/jsIcons/IconFind";

export default function CusFind({ placeholder, grayWarning, ...dopOptions }) {
  const { t } = useTranslation();

  return (
    <div className="find">
      <button className="find__glass">
        <IconFind />
      </button>
      <input placeholder={t(placeholder)} className="find__input" {...dopOptions} />
      {grayWarning && <span className="find__gray-warning">{t(grayWarning)}</span>}
    </div>
  );
}
