import React from "react";

export default function IconGays({ className }) {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0696 0.5H11.7342C11.1929 0.5 10.7676 0.925349 10.7676 1.4667C10.7676 2.00806 11.1929 2.4334 11.7342 2.4334H14.7112L11.1542 5.99087C8.40917 3.94146 4.50422 4.1348 2.0298 6.60956C-0.6766 9.31633 -0.6766 13.7632 2.0298 16.4699C4.7362 19.1767 9.18243 19.1767 11.8888 16.4699C14.3633 13.9952 14.5952 10.051 12.5074 7.34425L16.0644 3.78679V6.8029C16.0644 7.34425 16.4897 7.7696 17.031 7.7696C17.5723 7.7696 17.9976 7.34425 17.9976 6.8029V1.4667C18.0362 0.925349 17.6109 0.5 17.0696 0.5ZM10.5743 15.1165C8.60248 17.0886 5.39347 17.0886 3.42166 15.1165C1.44986 13.1445 1.44986 9.93502 3.42166 7.96294C5.39347 5.99087 8.60248 5.99087 10.5743 7.96294C12.5461 9.93502 12.5461 13.1445 10.5743 15.1165Z"
        fill="#4B4B4B"
      />
    </svg>
  );
}
