import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function CusCheckbox({ id, title, count, name, onChange, checked = null }) {
  const [isChecked, setChecked] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (checked !== null) setChecked(checked);
  }, [checked]);

  return (
    <label htmlFor={id} className={`cus-checkbox ${isChecked ? "checked" : ""}`}>
      <div className="cus-checkbox__div">
        <div className="cus-checkbox__checkbox__container">
          <input
            name={name}
            checked={isChecked}
            onChange={(e) => {
              setChecked(e.target.checked);
              onChange?.(e);
            }}
            id={id}
            type="checkbox"
            className="cus-checkbox__checkbox"
          />
        </div>
        <span className="cus-checkbox__title">{typeof title === "string" ? t(title) : title}</span>
        <span className="cus-checkbox__count">{count}</span>
      </div>
    </label>
  );
}
