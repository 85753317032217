import React from "react";

export default function Simple23() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_740_10793)">
        <path
          d="M12 23.5C18.3513 23.5 23.5 18.3513 23.5 12C23.5 5.64873 18.3513 0.5 12 0.5C5.64873 0.5 0.5 5.64873 0.5 12C0.5 18.3513 5.64873 23.5 12 23.5Z"
          fill="#FFCE52"
        />
        <path
          d="M11.9992 1.99911C18.0742 1.99911 22.9992 6.25261 22.9992 11.4991H23.4867C23.3489 8.54514 22.0784 5.7578 19.9392 3.71607C17.7999 1.67434 14.9564 0.535156 11.9992 0.535156C9.04203 0.535156 6.1985 1.67434 4.05927 3.71607C1.92005 5.7578 0.649561 8.54514 0.511719 11.4991H0.999219C0.999219 6.25261 5.92422 1.99911 11.9992 1.99911Z"
          fill="#FFE369"
        />
        <path
          d="M23 11.5C23 16.7465 18.075 21 12 21C5.925 21 1 16.7465 1 11.5H0.5125C0.5055 11.666 0.5 11.8325 0.5 12C0.5 15.05 1.7116 17.9751 3.86827 20.1317C6.02494 22.2884 8.95001 23.5 12 23.5C15.05 23.5 17.9751 22.2884 20.1317 20.1317C22.2884 17.9751 23.5 15.05 23.5 12C23.5 11.8325 23.4945 11.666 23.4875 11.5H23Z"
          fill="#FFB32B"
        />
        <path
          d="M18.6564 4.15641C18.8174 3.99526 18.7662 3.68297 18.5419 3.45889C18.3177 3.23481 18.0053 3.18379 17.8443 3.34494C17.6833 3.50608 17.7345 3.81837 17.9588 4.04246C18.183 4.26654 18.4953 4.31756 18.6564 4.15641Z"
          fill="#F6FAFD"
        />
        <path
          d="M15.3254 2.20108C15.406 2.1204 15.3805 1.96418 15.2684 1.85214C15.1563 1.7401 15 1.71467 14.9194 1.79534C14.8388 1.87601 14.8643 2.03223 14.9764 2.14427C15.0886 2.25632 15.2448 2.28175 15.3254 2.20108Z"
          fill="#F6FAFD"
        />
        <path
          d="M17.5634 3.55962C17.8855 3.23733 17.7829 2.61259 17.3342 2.16423C16.8855 1.71587 16.2607 1.61367 15.9386 1.93596C15.6166 2.25826 15.7192 2.883 16.1679 3.33136C16.6165 3.77972 17.2413 3.88192 17.5634 3.55962Z"
          fill="#F6FAFD"
        />
        <path
          d="M18 12C19.6569 12 21 10.6569 21 9C21 7.34315 19.6569 6 18 6C16.3431 6 15 7.34315 15 9C15 10.6569 16.3431 12 18 12Z"
          fill="url(#paint0_radial_740_10793)"
        />
        <path
          d="M6 12C7.65685 12 9 10.6569 9 9C9 7.34315 7.65685 6 6 6C4.34315 6 3 7.34315 3 9C3 10.6569 4.34315 12 6 12Z"
          fill="url(#paint1_radial_740_10793)"
        />
        <path
          d="M12 14C9.7945 14 8 12.43 8 10.5H9C9 11.8785 10.3455 13 12 13C13.6545 13 15 11.8785 15 10.5H16C16 12.43 14.2055 14 12 14Z"
          fill="#273941"
        />
        <path
          d="M19 6.5H18C18 6.10218 17.842 5.72064 17.5607 5.43934C17.2794 5.15804 16.8978 5 16.5 5C16.1022 5 15.7206 5.15804 15.4393 5.43934C15.158 5.72064 15 6.10218 15 6.5H14C14 5.83696 14.2634 5.20107 14.7322 4.73223C15.2011 4.26339 15.837 4 16.5 4C17.163 4 17.7989 4.26339 18.2678 4.73223C18.7366 5.20107 19 5.83696 19 6.5Z"
          fill="#273941"
        />
        <path
          d="M10 6.5H9C9 6.10218 8.84196 5.72064 8.56066 5.43934C8.27936 5.15804 7.89782 5 7.5 5C7.10218 5 6.72064 5.15804 6.43934 5.43934C6.15804 5.72064 6 6.10218 6 6.5H5C5 5.83696 5.26339 5.20107 5.73223 4.73223C6.20107 4.26339 6.83696 4 7.5 4C8.16304 4 8.79893 4.26339 9.26777 4.73223C9.73661 5.20107 10 5.83696 10 6.5Z"
          fill="#273941"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_740_10793"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(18 9) scale(3)"
        >
          <stop stopColor="#FF568D" />
          <stop offset="0.454" stopColor="#FF9678" />
          <stop offset="0.817" stopColor="#FFC26A" />
          <stop offset="1" stopColor="#FFD364" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_740_10793"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(6 0.25) scale(0.25)"
        >
          <stop stopColor="#FF568D" />
          <stop offset="0.454" stopColor="#FF9678" />
          <stop offset="0.817" stopColor="#FFC26A" />
          <stop offset="1" stopColor="#FFD364" />
        </radialGradient>
        <clipPath id="clip0_740_10793">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
