export default class KronaChatSettings {
  constructor(chat) {
    this._ownAudioEnabled = false;
    this._ownVideoEnabled = false;
    this._ownAudioMuted = false;
    this._ownVideoMuted = false;
    this._audioDevice = null;
    this._videoDevice = null;
    this._videoResolution = { width: 540, height: 480 };
    this._chat = chat;
  }

  get audioDevice() {
    return this._audioDevice;
  }

  set audioDevice(device) {
    this._audioDevice = device;
  }

  get videoDevice() {
    return this._videoDevice;
  }

  set videoDevice(device) {
    this._videoDevice = device;
  }

  get videoResolution() {
    return this._videoResolution;
  }

  set videoResolution(resolution) {
    this._videoResolution = resolution;
  }

  get ownVideoEnabled() {
    return this._ownVideoEnabled;
  }

  set ownVideoEnabled(en) {
    this._ownVideoEnabled = en;
  }

  async setOwnVideoEnabled(en) {
    this._ownVideoEnabled = en;
    if (!this._chat.isOnline) return true;
    return this._chat.localfeed.setVideoEnabled(en);
  }

  get ownVideoMuted() {
    return this._ownVideoMuted;
  }

  set ownVideoMuted(mute) {
    this._ownVideoMuted = mute;
    this._chat.localfeed.setVideoMuted(mute);
  }

  get ownAudioEnabled() {
    return this._ownAudioEnabled;
  }

  set ownAudioEnabled(en) {
    this._ownAudioEnabled = en;
  }

  async setOwnAudioEnabled(en) {
    this._ownAudioEnabled = en;
    if (!this._chat.isOnline) return true;
    return this._chat.localfeed.setAudioEnabled(en);
  }

  get ownAudioMuted() {
    return this._ownAudioMuted;
  }

  set ownAudioMuted(mute) {
    this._ownAudioMuted = mute;
    this._chat.localfeed.setAudioMuted(mute);
  }
}
