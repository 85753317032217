import React, { useEffect } from "react";
import Spinner from "./Spinner";

export default function LoaderOverlay() {
  useEffect(() => {
    document.querySelector("body").classList.add("body__loader-overlay");
    return () => document.querySelector("body").classList.remove("body__loader-overlay");
  }, []);

  return (
    <div className="loader-overlay">
      <Spinner />
    </div>
  );
}
