import { API_URL_CHAT } from "./main";
import { cusAxios } from "./main";
import axios from "axios";

const chatJoin = async (data) => {
  let response = await cusAxios({
    method: "POST",
    url: `${API_URL_CHAT}/join`,
    data: data,
  });
  return response;
};

const chatFree = async (chatId) => {
  let response = await cusAxios({
    method: "GET",
    url: `${API_URL_CHAT}/${chatId}/request/free-chat`,
  });
  return response;
};

const chatPrivate = async (chatId) => {
  let response = await cusAxios({
    method: "GET",
    url: `${API_URL_CHAT}/${chatId}/request/private-chat`,
  });
  return response;
};

const chatExclusive = async (chatId) => {
  let response = await cusAxios({
    method: "GET",
    url: `${API_URL_CHAT}/${chatId}/request/exclusive-chat`,
  });
  return response;
};

const chatPaid = async (roomId) => {
  let response = await cusAxios({
    method: "GET",
    url: `${API_URL_CHAT}/${roomId}/request/paid-chat`,
  });
  return response;
};

const chatPause = async (roomId) => {
  let response = await cusAxios({
    method: "GET",
    url: `${API_URL_CHAT}/${roomId}/request/pause-chat`,
  });
  return response;
};

const chatView = async (roomId, participantId) => {
  let response = await cusAxios({
    method: "GET",
    url: `${API_URL_CHAT}/${roomId}/participant/${participantId}/mode/view-chat`,
  });
  return response;
};

const getSetting = async (chatId) => {
  let response = await cusAxios({
    method: "GET",
    //  https://devstart.cam4fan.com/webcam/model/api/chat/666/settings
    url: `${API_URL_CHAT}/${chatId}/settings`,
  });

  return response;
};

const postTips = async (roomID, data) => {
  let response = await cusAxios({
    method: "POST",
    url: `${API_URL_CHAT}/${roomID}/tip`,
    data: data,
  });

  return response;
};

const setUserMode = async (chatId, mode, participantId) => {
  let response = await cusAxios({
    method: "GET",
    url: `${API_URL_CHAT}/${chatId}/participant/${participantId}/mode/${mode}`,
  });

  return response;
};

const cancelPrivate = async (chatId, requestId) => {
  let response = await cusAxios({
    method: "GET",
    url: `${API_URL_CHAT}/${chatId}/request/${requestId}/cancel`,
  });

  return response;
};

const getTipsList = async (chatId) => {
  let response = await cusAxios({
    method: "GET",
    url: `${API_URL_CHAT}/${chatId}/tip/list`,
  });

  return response;
};

const stopChat = async (chatId) => {
  let response = await cusAxios({
    method: "POST",
    url: `${API_URL_CHAT}/${chatId}/stop`,
  });

  return response;
};
const previewChat = async (chatId) => {
  let response = await axios({
    method: "GET",
    responseType: "blob",
    url: `${API_URL_CHAT}/${chatId}/preview`,
  });

  return response;
};

const getLovenseSettings = async (chatId) => {
  let response = await cusAxios({
    method: "GET",
    url: `${API_URL_CHAT}/${chatId}/lovense/settings`,
  });
  return response;
};

const getLovenseToys = async (chatId) => {
  let response = await cusAxios({
    method: "GET",
    url: `${API_URL_CHAT}/${chatId}/lovense/toys`,
  });
  return response;
};

const getLovenseEnable = async (chatId) => {
  let response = await cusAxios({
    method: "GET",
    url: `${API_URL_CHAT}/${chatId}/lovense/`,
  });
  return response;
};

export const API_CHAT = {
  join: chatJoin,
  setting: getSetting,
  postTips: postTips,
  setUserMode: setUserMode,
  cancelPrivate: cancelPrivate,
  getTips: getTipsList,
  stopChat: stopChat,
  preview: previewChat,
  setMode: {
    free: chatFree,
    private: chatPrivate,
    exclusive: chatExclusive,
    view: chatView,
    paid: chatPaid,
    pause: chatPause,
  },
  lovense: {
    getSetting: getLovenseSettings,
    getToys: getLovenseToys,
    getEnable: getLovenseEnable,
  },
};
