import React from "react";

export default function Smile8() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_740_10481)">
        <path
          d="M12 23.5C18.3513 23.5 23.5 18.3513 23.5 12C23.5 5.64873 18.3513 0.5 12 0.5C5.64873 0.5 0.5 5.64873 0.5 12C0.5 18.3513 5.64873 23.5 12 23.5Z"
          fill="#FFCE52"
        />
        <path
          d="M11.9992 1.99911C18.0742 1.99911 22.9992 6.25261 22.9992 11.4991H23.4867C23.3489 8.54514 22.0784 5.7578 19.9392 3.71607C17.7999 1.67434 14.9564 0.535156 11.9992 0.535156C9.04203 0.535156 6.1985 1.67434 4.05927 3.71607C1.92005 5.7578 0.649561 8.54514 0.511719 11.4991H0.999219C0.999219 6.25261 5.92422 1.99911 11.9992 1.99911Z"
          fill="#FFE369"
        />
        <path
          d="M23 11.5C23 16.7465 18.075 21 12 21C5.925 21 1 16.7465 1 11.5H0.5125C0.5055 11.666 0.5 11.8325 0.5 12C0.5 15.05 1.7116 17.9751 3.86827 20.1317C6.02494 22.2884 8.95001 23.5 12 23.5C15.05 23.5 17.9751 22.2884 20.1317 20.1317C22.2884 17.9751 23.5 15.05 23.5 12C23.5 11.8325 23.4945 11.666 23.4875 11.5H23Z"
          fill="#FFB32B"
        />
        <path
          d="M18.6564 4.65641C18.8174 4.49526 18.7662 4.18297 18.5419 3.95889C18.3177 3.73481 18.0053 3.68379 17.8443 3.84494C17.6833 4.00608 17.7345 4.31837 17.9588 4.54246C18.183 4.76654 18.4953 4.81756 18.6564 4.65641Z"
          fill="#F6FAFD"
        />
        <path
          d="M15.3254 2.20498C15.406 2.12431 15.3805 1.96809 15.2684 1.85604C15.1563 1.744 15 1.71857 14.9194 1.79924C14.8388 1.87992 14.8643 2.03614 14.9764 2.14818C15.0886 2.26022 15.2448 2.28565 15.3254 2.20498Z"
          fill="#F6FAFD"
        />
        <path
          d="M17.5634 4.06353C17.8855 3.74123 17.7829 3.11649 17.3342 2.66813C16.8855 2.21977 16.2607 2.11758 15.9386 2.43987C15.6166 2.76217 15.7192 3.38691 16.1679 3.83527C16.6165 4.28363 17.2413 4.38582 17.5634 4.06353Z"
          fill="#F6FAFD"
        />
        <path
          d="M17.5 17.5C19.1569 17.5 20.5 16.1569 20.5 14.5C20.5 12.8431 19.1569 11.5 17.5 11.5C15.8431 11.5 14.5 12.8431 14.5 14.5C14.5 16.1569 15.8431 17.5 17.5 17.5Z"
          fill="url(#paint0_radial_740_10481)"
        />
        <path
          d="M6.5 17.5C8.15685 17.5 9.5 16.1569 9.5 14.5C9.5 12.8431 8.15685 11.5 6.5 11.5C4.84315 11.5 3.5 12.8431 3.5 14.5C3.5 16.1569 4.84315 17.5 6.5 17.5Z"
          fill="url(#paint1_radial_740_10481)"
        />
        <path
          d="M7.50032 6.5C7.3965 6.50087 7.29289 6.5094 7.19032 6.5255C7.92782 6.65 8.50032 7.482 8.50032 8.5C8.50032 9.6045 7.82882 10.5 7.00032 10.5C6.17182 10.5 5.50032 9.6045 5.50032 8.5C5.49744 8.07704 5.60498 7.66066 5.81232 7.292C5.27823 7.90244 4.98898 8.68897 5.00032 9.5C5.00032 11.157 6.11982 12.5 7.50032 12.5C8.88082 12.5 10.0003 11.157 10.0003 9.5C10.0003 7.843 8.88082 6.5 7.50032 6.5Z"
          fill="#F6FAFD"
        />
        <path
          d="M7.00006 10.5018C7.82856 10.5018 8.50006 9.60634 8.50006 8.50184C8.50006 7.48384 7.92756 6.65184 7.19006 6.52734C6.65561 6.61497 6.16837 6.88599 5.81206 7.29384C5.60472 7.6625 5.49717 8.07889 5.50006 8.50184C5.50006 9.60634 6.17156 10.5018 7.00006 10.5018Z"
          fill="#273941"
        />
        <path
          d="M7 10C7.55228 10 8 9.32843 8 8.5C8 7.67157 7.55228 7 7 7C6.44772 7 6 7.67157 6 8.5C6 9.32843 6.44772 10 7 10Z"
          fill="#141E21"
        />
        <path
          d="M6.5 8.5C6.77614 8.5 7 8.27614 7 8C7 7.72386 6.77614 7.5 6.5 7.5C6.22386 7.5 6 7.72386 6 8C6 8.27614 6.22386 8.5 6.5 8.5Z"
          fill="#F6FAFD"
        />
        <path
          d="M16.5003 6.5C16.3965 6.50087 16.2929 6.5094 16.1903 6.5255C16.9278 6.65 17.5003 7.482 17.5003 8.5C17.5003 9.6045 16.8288 10.5 16.0003 10.5C15.1718 10.5 14.5003 9.6045 14.5003 8.5C14.4974 8.07704 14.605 7.66066 14.8123 7.292C14.2782 7.90244 13.989 8.68897 14.0003 9.5C14.0003 11.157 15.1198 12.5 16.5003 12.5C17.8808 12.5 19.0003 11.157 19.0003 9.5C19.0003 7.843 17.8808 6.5 16.5003 6.5Z"
          fill="#F6FAFD"
        />
        <path
          d="M16.0001 10.5018C16.8286 10.5018 17.5001 9.60634 17.5001 8.50184C17.5001 7.48384 16.9276 6.65184 16.1901 6.52734C15.6556 6.61497 15.1684 6.88599 14.8121 7.29384C14.6047 7.6625 14.4972 8.07889 14.5001 8.50184C14.5001 9.60634 15.1716 10.5018 16.0001 10.5018Z"
          fill="#273941"
        />
        <path
          d="M16 10C16.5523 10 17 9.32843 17 8.5C17 7.67157 16.5523 7 16 7C15.4477 7 15 7.67157 15 8.5C15 9.32843 15.4477 10 16 10Z"
          fill="#141E21"
        />
        <path
          d="M15.5 8.5C15.7761 8.5 16 8.27614 16 8C16 7.72386 15.7761 7.5 15.5 7.5C15.2239 7.5 15 7.72386 15 8C15 8.27614 15.2239 8.5 15.5 8.5Z"
          fill="#F6FAFD"
        />
        <path
          d="M12 19.5012C11.7105 19.501 11.4273 19.417 11.1845 19.2594C10.9416 19.1018 10.7496 18.8773 10.6315 18.6131C10.5134 18.3488 10.4743 18.0559 10.519 17.7699C10.5636 17.4839 10.69 17.2169 10.883 17.0012C10.7206 16.82 10.6048 16.6021 10.5454 16.3662C10.486 16.1303 10.4849 15.8835 10.5421 15.6471C10.5994 15.4106 10.7132 15.1917 10.8739 15.0091C11.0346 14.8264 11.2373 14.6856 11.4646 14.5988C11.6918 14.512 11.9368 14.4817 12.1783 14.5106C12.4198 14.5395 12.6507 14.6268 12.851 14.7648C13.0513 14.9028 13.2151 15.0875 13.3282 15.3029C13.4412 15.5182 13.5002 15.7579 13.5 16.0012H12.5C12.5 15.9023 12.4707 15.8056 12.4157 15.7234C12.3608 15.6411 12.2827 15.5771 12.1913 15.5392C12.1 15.5014 11.9994 15.4915 11.9025 15.5108C11.8055 15.5301 11.7164 15.5777 11.6464 15.6476C11.5765 15.7175 11.5289 15.8066 11.5096 15.9036C11.4903 16.0006 11.5002 16.1011 11.5381 16.1925C11.5759 16.2839 11.64 16.3619 11.7222 16.4169C11.8044 16.4718 11.9011 16.5012 12 16.5012C12.1326 16.5012 12.2598 16.5538 12.3536 16.6476C12.4473 16.7414 12.5 16.8685 12.5 17.0012C12.5 17.1338 12.4473 17.2609 12.3536 17.3547C12.2598 17.4485 12.1326 17.5012 12 17.5012C11.9011 17.5012 11.8044 17.5305 11.7222 17.5854C11.64 17.6404 11.5759 17.7185 11.5381 17.8098C11.5002 17.9012 11.4903 18.0017 11.5096 18.0987C11.5289 18.1957 11.5765 18.2848 11.6464 18.3547C11.7164 18.4246 11.8055 18.4723 11.9025 18.4915C11.9994 18.5108 12.1 18.5009 12.1913 18.4631C12.2827 18.4253 12.3608 18.3612 12.4157 18.2789C12.4707 18.1967 12.5 18.1 12.5 18.0012H13.5C13.5 18.399 13.342 18.7805 13.0607 19.0618C12.7794 19.3431 12.3978 19.5012 12 19.5012Z"
          fill="#273941"
        />
        <path
          d="M19.6095 6.8125C19.1262 6.15912 18.5103 5.61532 17.802 5.21678C17.0938 4.81824 16.3093 4.57397 15.5 4.5V3.5C16.4602 3.57107 17.3939 3.84785 18.2377 4.31155C19.0815 4.77526 19.8157 5.41505 20.3905 6.1875L19.6095 6.8125Z"
          fill="#273941"
        />
        <path
          d="M4.39062 6.8125L3.60962 6.1875C4.18439 5.41505 4.91859 4.77526 5.76241 4.31155C6.60623 3.84785 7.53991 3.57107 8.50012 3.5V4.5C7.69015 4.57131 6.90467 4.81445 6.19606 5.2132C5.48744 5.61196 4.87195 6.15717 4.39062 6.8125Z"
          fill="#273941"
        />
        <path
          d="M2.5 0C2.36739 0 2.24022 0.0526784 2.14645 0.146447C2.05268 0.240215 2 0.367392 2 0.5V3.092C1.83987 3.03278 1.67072 3.00166 1.5 3C1.20333 3 0.913319 3.08797 0.666645 3.2528C0.419972 3.41762 0.227713 3.65189 0.114181 3.92597C0.000649929 4.20006 -0.0290551 4.50166 0.0288228 4.79264C0.0867006 5.08361 0.229562 5.35088 0.439341 5.56066C0.649119 5.77044 0.916394 5.9133 1.20737 5.97118C1.49834 6.02906 1.79994 5.99935 2.07403 5.88582C2.34811 5.77229 2.58238 5.58003 2.74721 5.33336C2.91203 5.08668 3 4.79667 3 4.5V1.0245C3.6295 1.087 4.5 1.3 4.5 2H5.5C5.5 1.033 4.712 0 2.5 0Z"
          fill="#00A3E1"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_740_10481"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(17.5 14.5) scale(3)"
        >
          <stop stopColor="#FF568D" />
          <stop offset="0.454" stopColor="#FF9678" />
          <stop offset="0.817" stopColor="#FFC26A" />
          <stop offset="1" stopColor="#FFD364" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_740_10481"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(6.5 0.25) scale(0.25)"
        >
          <stop stopColor="#FF568D" />
          <stop offset="0.454" stopColor="#FF9678" />
          <stop offset="0.817" stopColor="#FFC26A" />
          <stop offset="1" stopColor="#FFD364" />
        </radialGradient>
        <clipPath id="clip0_740_10481">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
