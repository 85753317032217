import React from "react";

export default function Smile15() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_740_10598)">
        <path
          d="M12.0002 23.5C18.3515 23.5 23.5002 18.3513 23.5002 12C23.5002 5.64873 18.3515 0.5 12.0002 0.5C5.64897 0.5 0.500244 5.64873 0.500244 12C0.500244 18.3513 5.64897 23.5 12.0002 23.5Z"
          fill="#FFCE52"
        />
        <path
          d="M11.9992 1.99911C18.0742 1.99911 22.9992 6.25261 22.9992 11.4991H23.4867C23.3489 8.54514 22.0784 5.7578 19.9392 3.71607C17.7999 1.67434 14.9564 0.535156 11.9992 0.535156C9.04203 0.535156 6.1985 1.67434 4.05927 3.71607C1.92005 5.7578 0.649561 8.54514 0.511719 11.4991H0.999219C0.999219 6.25261 5.92422 1.99911 11.9992 1.99911Z"
          fill="#FFE369"
        />
        <path
          d="M23 11.5C23 16.7465 18.075 21 12 21C5.925 21 1 16.7465 1 11.5H0.5125C0.5055 11.666 0.5 11.8325 0.5 12C0.5 15.05 1.7116 17.9751 3.86827 20.1317C6.02494 22.2884 8.95001 23.5 12 23.5C15.05 23.5 17.9751 22.2884 20.1317 20.1317C22.2884 17.9751 23.5 15.05 23.5 12C23.5 11.8325 23.4945 11.666 23.4875 11.5H23Z"
          fill="#FFB32B"
        />
        <path
          d="M4.5 15.5C6.433 15.5 8 13.933 8 12C8 10.067 6.433 8.5 4.5 8.5C2.567 8.5 1 10.067 1 12C1 13.933 2.567 15.5 4.5 15.5Z"
          fill="url(#paint0_radial_740_10598)"
        />
        <path
          d="M19.5 15.5C21.433 15.5 23 13.933 23 12C23 10.067 21.433 8.5 19.5 8.5C17.567 8.5 16 10.067 16 12C16 13.933 17.567 15.5 19.5 15.5Z"
          fill="url(#paint1_radial_740_10598)"
        />
        <path
          d="M1.32857 4.50007L2.50007 3.32857L1.91407 2.74257C1.75871 2.58725 1.54801 2.5 1.32832 2.5C1.10864 2.5 0.897941 2.58725 0.742574 2.74257C0.587254 2.89794 0.5 3.10864 0.5 3.32832C0.5 3.54801 0.587254 3.75871 0.742574 3.91407L1.32857 4.50007Z"
          fill="#3BC5F6"
        />
        <path
          d="M3.32857 2.50007L4.50007 1.32857L3.91407 0.742574C3.75871 0.587254 3.54801 0.5 3.32832 0.5C3.10864 0.5 2.89794 0.587254 2.74257 0.742574C2.58725 0.897941 2.5 1.10864 2.5 1.32832C2.5 1.54801 2.58725 1.75871 2.74257 1.91407L3.32857 2.50007Z"
          fill="#3BC5F6"
        />
        <path
          d="M1.24261 3.24432C1.39797 3.089 1.60867 3.00174 1.82836 3.00174C2.04805 3.00174 2.25874 3.089 2.41411 3.24432L1.91411 2.74432C1.75785 2.59339 1.54857 2.50987 1.33133 2.51175C1.11409 2.51363 0.906287 2.60077 0.752672 2.75438C0.599056 2.908 0.511923 3.1158 0.510041 3.33304C0.508159 3.55028 0.591677 3.75956 0.742608 3.91582L1.24261 4.41582C1.08729 4.26045 1.00003 4.04976 1.00003 3.83007C1.00003 3.61038 1.08729 3.39968 1.24261 3.24432Z"
          fill="#00A3E1"
        />
        <path
          d="M3.24261 1.24432C3.39797 1.089 3.60867 1.00174 3.82836 1.00174C4.04805 1.00174 4.25874 1.089 4.41411 1.24432L3.91411 0.744317C3.75785 0.593386 3.54857 0.509868 3.33133 0.51175C3.11409 0.513632 2.90629 0.600765 2.75267 0.754381C2.59906 0.907996 2.51192 1.1158 2.51004 1.33304C2.50816 1.55028 2.59168 1.75956 2.74261 1.91582L3.24261 2.41582C3.08729 2.26045 3.00003 2.04976 3.00003 1.83007C3.00003 1.61038 3.08729 1.39968 3.24261 1.24432Z"
          fill="#00A3E1"
        />
        <path
          d="M16 12.56C15.98 15.855 14.485 19 12 19C9.515 19 8.02 15.855 8 12.56C8.61895 12.8728 9.30691 13.0242 10 13C10.3828 12.9733 10.7566 12.8712 11.0998 12.6996C11.443 12.528 11.749 12.2902 12 12C12.251 12.2902 12.557 12.528 12.9002 12.6996C13.2434 12.8712 13.6172 12.9733 14 13C14.6931 13.0242 15.381 12.8728 16 12.56Z"
          fill="#273941"
        />
        <path
          d="M12.0008 19.0005C13.7228 19.0005 14.9678 17.4895 15.5778 15.466C14.498 14.8189 13.2596 14.4846 12.0008 14.5005C10.7421 14.4846 9.50362 14.8189 8.42383 15.466C9.03383 17.4895 10.2788 19.0005 12.0008 19.0005Z"
          fill="#AE2D4C"
        />
        <path
          d="M12.0008 17.999C10.5238 17.999 9.40083 16.886 8.72383 15.293C8.62383 15.348 8.52033 15.404 8.42383 15.4645C9.03383 17.488 10.2788 18.999 12.0008 18.999C13.7228 18.999 14.9678 17.488 15.5778 15.4645C15.4813 15.404 15.3778 15.348 15.2778 15.293C14.6008 16.886 13.4778 17.999 12.0008 17.999Z"
          fill="#8A293D"
        />
        <path
          d="M20.2765 9.95C19.382 9.5 18.3585 9.5 16.5 9.5C16.3674 9.5 16.2402 9.44732 16.1464 9.35355C16.0527 9.25979 16 9.13261 16 9C16 7.8675 17.784 6.5 20 6.5V7.5C19.0022 7.47467 18.0326 7.83252 17.2905 8.5C18.8195 8.509 19.772 8.5755 20.7235 9.05L20.2765 9.95Z"
          fill="#273941"
        />
        <path
          d="M3.72434 9.95L3.27734 9.055C4.22734 8.5795 5.18134 8.513 6.71034 8.505C5.96901 7.8357 4.99932 7.47603 4.00084 7.5V6.5C6.21684 6.5 8.00084 7.8675 8.00084 9C8.00084 9.13261 7.94816 9.25979 7.8544 9.35355C7.76063 9.44732 7.63345 9.5 7.50084 9.5C5.64234 9.5 4.61884 9.5 3.72434 9.95Z"
          fill="#273941"
        />
        <path
          d="M14 13.5C13.2557 13.4785 12.5447 13.1866 12 12.679C11.4553 13.1866 10.7443 13.4785 10 13.5C7.582 13.5 6.5 12.2445 6.5 11H7.5C7.5 11.897 8.505 12.5 10 12.5C10.3174 12.4743 10.6265 12.3856 10.9092 12.2391C11.1919 12.0925 11.4426 11.8911 11.6465 11.6465C11.7403 11.5528 11.8674 11.5001 12 11.5001C12.1326 11.5001 12.2597 11.5528 12.3535 11.6465C12.5574 11.8911 12.8081 12.0925 13.0908 12.2391C13.3735 12.3856 13.6826 12.4743 14 12.5C15.495 12.5 16.5 11.897 16.5 11H17.5C17.5 12.2445 16.418 13.5 14 13.5Z"
          fill="#273941"
        />
        <path
          d="M22.6715 4.50007L21.5 3.32857L22.086 2.74257C22.2414 2.58725 22.4521 2.5 22.6717 2.5C22.8914 2.5 23.1021 2.58725 23.2575 2.74257C23.4128 2.89794 23.5001 3.10864 23.5001 3.32832C23.5001 3.54801 23.4128 3.75871 23.2575 3.91407L22.6715 4.50007Z"
          fill="#3BC5F6"
        />
        <path
          d="M20.6715 2.50007L19.5 1.32857L20.086 0.742574C20.2414 0.587254 20.4521 0.5 20.6717 0.5C20.8914 0.5 21.1021 0.587254 21.2575 0.742574C21.4128 0.897941 21.5001 1.10864 21.5001 1.32832C21.5001 1.54801 21.4128 1.75871 21.2575 1.91407L20.6715 2.50007Z"
          fill="#3BC5F6"
        />
        <path
          d="M23.2577 2.74257C23.1023 2.58725 22.8916 2.5 22.6719 2.5C22.4522 2.5 22.2415 2.58725 22.0862 2.74257L21.5862 3.24257C21.6626 3.16345 21.754 3.10034 21.8551 3.05692C21.9562 3.01351 22.0649 2.99066 22.1749 2.9897C22.2849 2.98875 22.394 3.00971 22.4958 3.05137C22.5976 3.09303 22.6901 3.15455 22.7679 3.23233C22.8457 3.31012 22.9072 3.40262 22.9489 3.50444C22.9905 3.60625 23.0115 3.71535 23.0106 3.82535C23.0096 3.93536 22.9867 4.04407 22.9433 4.14515C22.8999 4.24623 22.8368 4.33765 22.7577 4.41407L23.2577 3.91407C23.413 3.75871 23.5003 3.54801 23.5003 3.32832C23.5003 3.10864 23.413 2.89794 23.2577 2.74257Z"
          fill="#00A3E1"
        />
        <path
          d="M21.2577 0.742574C21.1023 0.587254 20.8916 0.5 20.6719 0.5C20.4522 0.5 20.2415 0.587254 20.0862 0.742574L19.5862 1.24257C19.7424 1.09164 19.9517 1.00812 20.169 1.01001C20.3862 1.01189 20.594 1.09902 20.7476 1.25264C20.9012 1.40625 20.9884 1.61406 20.9902 1.8313C20.9921 2.04853 20.9086 2.25782 20.7577 2.41407L21.2577 1.91407C21.413 1.75871 21.5003 1.54801 21.5003 1.32832C21.5003 1.10864 21.413 0.897941 21.2577 0.742574Z"
          fill="#00A3E1"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_740_10598"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(4.49888 12.0041) scale(3.501)"
        >
          <stop stopColor="#FF568D" />
          <stop offset="0.454" stopColor="#FF9678" />
          <stop offset="0.817" stopColor="#FFC26A" />
          <stop offset="1" stopColor="#FFD364" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_740_10598"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(19.503 12.0041) scale(0.29175)"
        >
          <stop stopColor="#FF568D" />
          <stop offset="0.454" stopColor="#FF9678" />
          <stop offset="0.817" stopColor="#FFC26A" />
          <stop offset="1" stopColor="#FFD364" />
        </radialGradient>
        <clipPath id="clip0_740_10598">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
