import React from "react";
import { useTranslation } from "react-i18next";
import "./error-boundary.scss";
import IconWarning from "../../../images/jsIcons/IconWarning.jsx";
import LoaderContainer from "../LoaderContainer";

const ErrorPlug = ({ reload }) => {
  const { t } = useTranslation();

  return (
    <div className="error-block-plug">
      <IconWarning />
      <span className="error-block-plug__span">
        {t("Sorry, failed to load data. Refresh the page or click ")}{" "}
        <span className="error-block-plug__refresh" onClick={reload}>
          “{t("Refresh")}”
        </span>
      </span>
    </div>
  );
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, loader: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  reload() {
    this.setState({ loader: true });
    setTimeout(() => {
      this.setState({ loader: false });
      this.setState({ hasError: false });
    }, 500);
  }

  render() {
    const { hasError, loader } = this.state;
    const { children, fallbackComponent: FallbackComponent = null } = this.props;

    // const { children, fallbackComponent: FallbackComponent } = this.props;

    if (hasError) {
      // return <FallbackComponent />;
      return (
        FallbackComponent || (
          <div className="error-boundary__children">
            <LoaderContainer loader={loader} center>
              <ErrorPlug reload={() => this.reload()} />
            </LoaderContainer>
          </div>
        )
      );
    }

    return children;
  }
}

export default ErrorBoundary;
