import React from "react";
import { useTranslation } from "react-i18next";

export default function TextareaWitchSmile({
  TextareaRef,
  onKeyDown,
  value,
  onChange,
  onSelectCapture,
  placeholder,
  onFocus,
  onBlur,
}) {
  // const { replaceEmojiWithSvg } = useSmile();
  const { t } = useTranslation();

  // const [isPlaceholder, setIsPlaceholder] = useState(true);
  // const [selectionStart, setSelectionStart] = useState("");

  // const onWriteClick = () => {
  //   TextareaRef.current?.focus();
  //   setIsPlaceholder(false);
  // };

  return (
    <>
      {/* <span className="chat__send__write-mes" onClick={onWriteClick}>
        {value ? (
          replaceEmojiWithSvg(value)
        ) : (
          <ShowContainer condition={isPlaceholder}>
            <span className="placeholder">{t(placeholder)}</span>
          </ShowContainer>
        )}
      </span> */}
      <textarea
        rows={1}
        // style={{ position: "absolute", opacity: 0 }}
        className="chat__send__write-mes"
        value={value}
        ref={TextareaRef}
        placeholder={t(placeholder)}
        onChange={onChange}
        onFocus={(e) => {
          // setIsPlaceholder(false);
          onFocus(e);
        }}
        onBlur={(e) => {
          // setIsPlaceholder(true);
          onBlur(e);
        }}
        onKeyDown={onKeyDown}
        onSelectCapture={(e) => {
          // setSelectionStart(e.target.selectionStart);
          onSelectCapture(e);
        }}
      />
    </>
  );
}
