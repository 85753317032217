import React from "react";

export default function Simple27() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_740_14854)">
        <path
          d="M10.0013 19.5846C15.294 19.5846 19.5846 15.294 19.5846 10.0013C19.5846 4.70857 15.294 0.417969 10.0013 0.417969C4.70857 0.417969 0.417969 4.70857 0.417969 10.0013C0.417969 15.294 4.70857 19.5846 10.0013 19.5846Z"
          fill="#FFCE52"
        />
        <path
          d="M16.6693 10.418C16.679 11.7474 16.1813 13.0304 15.2776 14.0055C14.4078 14.9272 13.305 15.5966 12.0859 15.943V14.168C12.0853 13.9472 11.9973 13.7356 11.8411 13.5794C11.685 13.4233 11.4734 13.3353 11.2526 13.3346H8.7526C8.53179 13.3353 8.32021 13.4233 8.16408 13.5794C8.00794 13.7356 7.91993 13.9472 7.91927 14.168V15.943C5.52344 15.2346 3.33594 13.3263 3.33594 10.418C3.53177 10.418 4.2776 10.6263 5.43594 10.8346C6.94274 11.1091 8.47102 11.2485 10.0026 11.2513C12.2505 11.2463 14.4893 10.9665 16.6693 10.418Z"
          fill="#273941"
        />
        <path
          d="M10.0013 13.3359H11.2513C11.4721 13.3366 11.6837 13.4246 11.8398 13.5807C11.996 13.7369 12.084 13.9485 12.0846 14.1693V16.2526C12.0846 16.8051 11.8651 17.335 11.4744 17.7257C11.0837 18.1164 10.5538 18.3359 10.0013 18.3359C9.44877 18.3359 8.91886 18.1164 8.52816 17.7257C8.13746 17.335 7.91797 16.8051 7.91797 16.2526V14.1693C7.91863 13.9485 8.00664 13.7369 8.16277 13.5807C8.31891 13.4246 8.53049 13.3366 8.7513 13.3359H10.0013Z"
          fill="#CF4054"
        />
        <path
          d="M10.0026 1.66918C15.0651 1.66918 19.1693 5.21377 19.1693 9.58585H19.5755C19.4607 7.12421 18.4019 4.80142 16.6192 3.09998C14.8365 1.39853 12.4669 0.449219 10.0026 0.449219C7.53828 0.449219 5.16867 1.39853 3.38598 3.09998C1.60329 4.80142 0.544556 7.12421 0.429688 9.58585H0.835938C0.835938 5.21377 4.9401 1.66918 10.0026 1.66918Z"
          fill="#FFE369"
        />
        <path
          d="M19.168 9.58594C19.168 13.958 15.0638 17.5026 10.0013 17.5026C4.9388 17.5026 0.834635 13.958 0.834635 9.58594H0.428385C0.422552 9.72427 0.417969 9.86302 0.417969 10.0026C0.417969 12.5443 1.42764 14.9818 3.22486 16.779C5.02209 18.5763 7.45964 19.5859 10.0013 19.5859C12.543 19.5859 14.9805 18.5763 16.7777 16.779C18.575 14.9818 19.5846 12.5443 19.5846 10.0026C19.5846 9.86302 19.5801 9.72427 19.5742 9.58594H19.168Z"
          fill="#FFB32B"
        />
        <path
          d="M15.7556 4.08933C15.8898 3.95504 15.8471 3.69479 15.6603 3.50806C15.4734 3.32132 15.2131 3.27881 15.0789 3.4131C14.9447 3.54739 14.9874 3.80763 15.1743 3.99436C15.3612 4.1811 15.6214 4.22362 15.7556 4.08933Z"
          fill="#F6FAFD"
        />
        <path
          d="M12.9795 2.04452C13.0467 1.97729 13.0254 1.8471 12.932 1.75373C12.8386 1.66037 12.7083 1.63918 12.6412 1.7064C12.574 1.77363 12.5953 1.90381 12.6887 1.99718C12.7821 2.09055 12.9123 2.11174 12.9795 2.04452Z"
          fill="#F6FAFD"
        />
        <path
          d="M14.8478 3.59721C15.1162 3.32863 15.0306 2.80801 14.6567 2.43438C14.2828 2.06075 13.7622 1.97558 13.4938 2.24416C13.2254 2.51274 13.3109 3.03336 13.6848 3.40699C14.0587 3.78063 14.5794 3.86579 14.8478 3.59721Z"
          fill="#F6FAFD"
        />
        <path
          d="M10.0013 17.5013C9.44877 17.5013 8.91886 17.2818 8.52816 16.8911C8.13746 16.5004 7.91797 15.9705 7.91797 15.418V16.2513C7.91797 16.8038 8.13746 17.3337 8.52816 17.7244C8.91886 18.1151 9.44877 18.3346 10.0013 18.3346C10.5538 18.3346 11.0837 18.1151 11.4744 17.7244C11.8651 17.3337 12.0846 16.8038 12.0846 16.2513V15.418C12.0846 15.9705 11.8651 16.5004 11.4744 16.8911C11.0837 17.2818 10.5538 17.5013 10.0013 17.5013Z"
          fill="#8A293D"
        />
        <path
          d="M10.0026 12.0846C12.2259 12.078 14.4403 11.8045 16.5984 11.2701C16.6442 10.9883 16.6679 10.7034 16.6693 10.418C14.4893 10.9665 12.2505 11.2463 10.0026 11.2513C8.47102 11.2485 6.94274 11.1091 5.43594 10.8346C4.2776 10.6263 3.53177 10.418 3.33594 10.418C3.33744 10.6993 3.36057 10.9802 3.4051 11.258C3.67094 11.2896 4.37885 11.478 5.43594 11.668C6.94274 11.9424 8.47102 12.0818 10.0026 12.0846Z"
          fill="#141E21"
        />
        <path
          d="M10.0013 18.3333C9.44877 18.3333 8.91886 18.1138 8.52816 17.7231C8.13746 17.3324 7.91797 16.8025 7.91797 16.25V17.0833C7.91797 17.6359 8.13746 18.1658 8.52816 18.5565C8.91886 18.9472 9.44877 19.1667 10.0013 19.1667C10.5538 19.1667 11.0837 18.9472 11.4744 18.5565C11.8651 18.1658 12.0846 17.6359 12.0846 17.0833V16.25C12.0846 16.8025 11.8651 17.3324 11.4744 17.7231C11.0837 18.1138 10.5538 18.3333 10.0013 18.3333Z"
          fill="#F29410"
        />
        <path
          d="M11.2513 13.3359H8.7513C8.53049 13.3366 8.31891 13.4246 8.16277 13.5807C8.00664 13.7369 7.91863 13.9485 7.91797 14.1693V15.0026C7.91863 14.7818 8.00664 14.5702 8.16277 14.4141C8.31891 14.2579 8.53049 14.1699 8.7513 14.1693H8.9313C9.13639 14.1692 9.33428 14.2448 9.48711 14.3816C9.63994 14.5184 9.73697 14.7067 9.75964 14.9105L10.0013 17.0859L10.243 14.9105C10.2656 14.7067 10.3627 14.5184 10.5155 14.3816C10.6683 14.2448 10.8662 14.1692 11.0713 14.1693H11.2513C11.4721 14.1699 11.6837 14.2579 11.8398 14.4141C11.996 14.5702 12.084 14.7818 12.0846 15.0026V14.1693C12.084 13.9485 11.996 13.7369 11.8398 13.5807C11.6837 13.4246 11.4721 13.3366 11.2513 13.3359Z"
          fill="#AE2D4C"
        />
        <path
          d="M7.91667 14.1694C7.91707 14.0515 7.94279 13.9352 7.99208 13.8281C7.84586 13.8938 7.72167 14.0002 7.63439 14.1346C7.54711 14.269 7.50045 14.4258 7.5 14.586V15.8073C7.63792 15.8569 7.77667 15.9031 7.91667 15.9444V14.1694Z"
          fill="#141E21"
        />
        <path
          d="M12.0078 13.8281C12.0571 13.9352 12.0828 14.0515 12.0832 14.1694V15.9444C12.2232 15.9027 12.362 15.8569 12.4999 15.8073V14.586C12.4994 14.4258 12.4528 14.269 12.3655 14.1346C12.2782 14.0002 12.154 13.8938 12.0078 13.8281Z"
          fill="#141E21"
        />
        <path
          d="M13.75 8.33333C14.4404 8.33333 15 7.58714 15 6.66667C15 5.74619 14.4404 5 13.75 5C13.0596 5 12.5 5.74619 12.5 6.66667C12.5 7.58714 13.0596 8.33333 13.75 8.33333Z"
          fill="#273941"
        />
        <path
          d="M6.25 8.33333C6.94036 8.33333 7.5 7.58714 7.5 6.66667C7.5 5.74619 6.94036 5 6.25 5C5.55964 5 5 5.74619 5 6.66667C5 7.58714 5.55964 8.33333 6.25 8.33333Z"
          fill="#273941"
        />
        <path
          d="M13.7513 7.91797C14.2115 7.91797 14.5846 7.35832 14.5846 6.66797C14.5846 5.97761 14.2115 5.41797 13.7513 5.41797C13.2911 5.41797 12.918 5.97761 12.918 6.66797C12.918 7.35832 13.2911 7.91797 13.7513 7.91797Z"
          fill="#141E21"
        />
        <path
          d="M6.2513 7.91797C6.71154 7.91797 7.08464 7.35832 7.08464 6.66797C7.08464 5.97761 6.71154 5.41797 6.2513 5.41797C5.79106 5.41797 5.41797 5.97761 5.41797 6.66797C5.41797 7.35832 5.79106 7.91797 6.2513 7.91797Z"
          fill="#141E21"
        />
        <path
          d="M14.1667 6.66927C14.3968 6.66927 14.5833 6.48272 14.5833 6.2526C14.5833 6.02249 14.3968 5.83594 14.1667 5.83594C13.9365 5.83594 13.75 6.02249 13.75 6.2526C13.75 6.48272 13.9365 6.66927 14.1667 6.66927Z"
          fill="#F6FAFD"
        />
        <path
          d="M6.66667 6.66927C6.89679 6.66927 7.08333 6.48272 7.08333 6.2526C7.08333 6.02249 6.89679 5.83594 6.66667 5.83594C6.43655 5.83594 6.25 6.02249 6.25 6.2526C6.25 6.48272 6.43655 6.66927 6.66667 6.66927Z"
          fill="#F6FAFD"
        />
      </g>
      <defs>
        <clipPath id="clip0_740_14854">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
