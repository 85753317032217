import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import EN from "./locales/en/en.json";
import RU from "./locales/ru/ru.json";
import ES from "./locales/es/es.json";
import IT from "./locales/it/it.json";
import DE from "./locales/de/de.json";
import PT from "./locales/pt/pt.json";
import FR from "./locales/fr/fr.json";
import { getShortLang } from "./utils/utils";

// the translations
const resources = {
  en: { translation: EN },
  ru: { translation: RU },
  es: { translation: ES },
  it: { translation: IT },
  de: { translation: DE },
  pt: { translation: PT },
  fr: { translation: FR },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: getShortLang(),
  // debug: false,
  // keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
