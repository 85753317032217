import React from "react";

export default function IconAllModels(iconProps) {
  return (
    <svg
      {...iconProps}
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 5.5C8.20888 5.5 7.43552 5.7346 6.77772 6.17412C6.11993 6.61365 5.60723 7.23836 5.30448 7.96927C5.00173 8.70017 4.92252 9.50444 5.07686 10.2804C5.2312 11.0563 5.61216 11.769 6.17158 12.3284C6.73099 12.8878 7.44372 13.2688 8.21964 13.4231C8.99556 13.5775 9.79983 13.4983 10.5307 13.1955C11.2616 12.8928 11.8864 12.3801 12.3259 11.7223C12.7654 11.0645 13 10.2911 13 9.5C12.9989 8.43946 12.5772 7.42266 11.8273 6.67274C11.0773 5.92283 10.0605 5.50106 9 5.5ZM9 1.5C7.41775 1.5 5.87103 1.96919 4.55544 2.84824C3.23985 3.72729 2.21447 4.97672 1.60897 6.43853C1.00347 7.90034 0.84504 9.50887 1.15372 11.0607C1.4624 12.6126 2.22433 14.038 3.34315 15.1569C4.46197 16.2757 5.88743 17.0376 7.43928 17.3463C8.99113 17.655 10.5997 17.4965 12.0615 16.891C13.5233 16.2855 14.7727 15.2602 15.6518 13.9446C16.5308 12.629 17 11.0822 17 9.5C16.9977 7.37897 16.1541 5.34547 14.6543 3.84568C13.1545 2.34589 11.121 1.50229 9 1.5ZM9 14.8333C7.94517 14.8333 6.91402 14.5205 6.03696 13.9345C5.1599 13.3485 4.47631 12.5155 4.07265 11.541C3.66898 10.5664 3.56336 9.49408 3.76915 8.45952C3.97494 7.42495 4.48289 6.47464 5.22877 5.72876C5.97465 4.98288 6.92496 4.47493 7.95952 4.26915C8.99409 4.06336 10.0664 4.16897 11.041 4.57264C12.0155 4.97631 12.8485 5.6599 13.4345 6.53696C14.0205 7.41402 14.3333 8.44517 14.3333 9.5C14.3317 10.914 13.7693 12.2696 12.7695 13.2695C11.7696 14.2693 10.414 14.8317 9 14.8333Z"
        fill="#4B4B4B"
      />
    </svg>
  );
}
