import React from "react";
import imgLogo from "../../images/common/logo.svg";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCurrentPage } from "../../store/homeSlice";

export default function Logo({ className }) {
  const dispatch = useDispatch();

  const onLogoClick = () => {
    dispatch(setCurrentPage(1));
  };

  return (
    <Link to={"/"} className={className} onClick={onLogoClick}>
      <img width={150} height={20} className="logo" alt="logo" src={imgLogo} />
    </Link>
  );
}
