import React from "react";

export default function Smile3() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_740_10364)">
        <path
          d="M12 23.5C18.3513 23.5 23.5 18.3513 23.5 12C23.5 5.64873 18.3513 0.5 12 0.5C5.64873 0.5 0.5 5.64873 0.5 12C0.5 18.3513 5.64873 23.5 12 23.5Z"
          fill="#FFCE52"
        />
        <path
          d="M18.7765 10.45C17.882 10 16.8585 10 15 10C14.8674 10 14.7402 9.94732 14.6464 9.85355C14.5527 9.75979 14.5 9.63261 14.5 9.5C14.5 8.3675 16.284 7 18.5 7V8C17.5022 7.97467 16.5326 8.33252 15.7905 9C17.3195 9.009 18.272 9.0755 19.2235 9.55L18.7765 10.45Z"
          fill="#273941"
        />
        <path
          d="M5.22434 10.45L4.77734 9.555C5.72734 9.0795 6.68134 9.013 8.21034 9.005C7.46901 8.3357 6.49932 7.97603 5.50084 8V7C7.71684 7 9.50084 8.3675 9.50084 9.5C9.50084 9.63261 9.44817 9.75979 9.3544 9.85355C9.26063 9.94732 9.13345 10 9.00084 10C7.14234 10 6.11884 10 5.22434 10.45Z"
          fill="#273941"
        />
        <path
          d="M17.5991 13H18.9991C18.9991 17.18 15.7341 19.5 11.9991 19.5C10.2179 19.541 8.48048 18.9453 7.09913 17.82C6.41413 17.2221 5.87043 16.4796 5.50727 15.6461C5.14411 14.8125 4.97055 13.9088 4.99913 13H6.39913C6.39913 14.935 8.90413 16.5 11.9991 16.5C15.0941 16.5 17.5991 14.935 17.5991 13Z"
          fill="#AE2D4C"
        />
        <path
          d="M6.40039 13H17.6004C17.6004 14.935 15.0954 16.5 12.0004 16.5C8.90539 16.5 6.40039 14.935 6.40039 13Z"
          fill="#F6FAFD"
        />
        <path
          d="M11.9992 1.99911C18.0742 1.99911 22.9992 6.25261 22.9992 11.4991H23.4867C23.3489 8.54514 22.0784 5.7578 19.9392 3.71607C17.7999 1.67434 14.9564 0.535156 11.9992 0.535156C9.04203 0.535156 6.1985 1.67434 4.05927 3.71607C1.92005 5.7578 0.649561 8.54514 0.511719 11.4991H0.999219C0.999219 6.25261 5.92422 1.99911 11.9992 1.99911Z"
          fill="#FFE369"
        />
        <path
          d="M23 11.5C23 16.7465 18.075 21 12 21C5.925 21 1 16.7465 1 11.5H0.5125C0.5055 11.666 0.5 11.8325 0.5 12C0.5 15.05 1.7116 17.9751 3.86827 20.1317C6.02494 22.2884 8.95001 23.5 12 23.5C15.05 23.5 17.9751 22.2884 20.1317 20.1317C22.2884 17.9751 23.5 15.05 23.5 12C23.5 11.8325 23.4945 11.666 23.4875 11.5H23Z"
          fill="#FFB32B"
        />
        <path
          d="M18.9064 4.90641C19.0674 4.74526 19.0162 4.43297 18.7919 4.20889C18.5677 3.98481 18.2553 3.93379 18.0943 4.09494C17.9333 4.25608 17.9845 4.56837 18.2088 4.79246C18.433 5.01654 18.7453 5.06756 18.9064 4.90641Z"
          fill="#F6FAFD"
        />
        <path
          d="M15.5754 2.45498C15.656 2.37431 15.6305 2.21809 15.5184 2.10604C15.4063 1.994 15.25 1.96857 15.1694 2.04924C15.0888 2.12992 15.1143 2.28614 15.2264 2.39818C15.3386 2.51022 15.4948 2.53565 15.5754 2.45498Z"
          fill="#F6FAFD"
        />
        <path
          d="M17.8134 4.31353C18.1355 3.99123 18.0329 3.36649 17.5842 2.91813C17.1355 2.46977 16.5107 2.36758 16.1886 2.68987C15.8666 3.01217 15.9692 3.63691 16.4179 4.08527C16.8665 4.53363 17.4913 4.63582 17.8134 4.31353Z"
          fill="#F6FAFD"
        />
        <path
          d="M17.6 13C17.6 14.935 15.095 16.5 12 16.5C8.905 16.5 6.4 14.935 6.4 13H6C6 15.488 8.684 17.5 12 17.5C15.316 17.5 18 15.488 18 13H17.6Z"
          fill="#8A293D"
        />
        <path
          d="M17.6004 13H6.40039C6.40255 13.3467 6.4827 13.6885 6.63489 14H17.3659C17.5181 13.6885 17.5982 13.3467 17.6004 13Z"
          fill="#EDEDED"
        />
        <path
          d="M23.0221 11.2144C23.3281 11.5204 23.5 11.9354 23.5 12.3681C23.5 12.8009 23.3281 13.2159 23.0221 13.5219C22.7161 13.8279 22.3011 13.9998 21.8684 13.9998C21.4356 13.9998 21.0206 13.8279 20.7146 13.5219C20.0771 12.8849 19.2721 10.3489 19.5606 10.0604C19.8491 9.77187 22.3851 10.5769 23.0221 11.2144Z"
          fill="#3BC5F6"
        />
        <path
          d="M0.977899 11.2147C0.671905 11.5207 0.5 11.9357 0.5 12.3684C0.5 12.8012 0.671905 13.2162 0.977899 13.5222C1.28389 13.8282 1.69891 14.0001 2.13165 14.0001C2.56439 14.0001 2.97941 13.8282 3.2854 13.5222C3.9229 12.8852 4.7279 10.3502 4.4394 10.0607C4.1509 9.77119 1.6149 10.5772 0.977899 11.2147Z"
          fill="#3BC5F6"
        />
        <path
          d="M23.0215 11.2135C22.4615 10.6535 20.435 9.9635 19.757 10.0015C20.6146 10.1027 21.4336 10.4148 22.141 10.91C22.3706 11.1395 22.4995 11.4509 22.4995 11.7755C22.4995 12.1001 22.3706 12.4115 22.141 12.641C21.9115 12.8705 21.6001 12.9995 21.2755 12.9995C20.9509 12.9995 20.6396 12.8705 20.41 12.641C19.9142 11.9338 19.6014 11.1147 19.4995 10.257C19.462 10.9345 20.1495 12.961 20.712 13.5215C20.8627 13.6767 21.0428 13.8003 21.2418 13.8853C21.4407 13.9702 21.6546 14.0148 21.8709 14.0163C22.0872 14.0179 22.3017 13.9764 22.5018 13.8943C22.702 13.8123 22.8838 13.6912 23.0368 13.5382C23.1897 13.3853 23.3107 13.2034 23.3927 13.0032C23.4748 12.8031 23.5162 12.5886 23.5146 12.3723C23.513 12.1559 23.4684 11.9421 23.3834 11.7432C23.2984 11.5442 23.1747 11.3642 23.0195 11.2135H23.0215Z"
          fill="#00A3E1"
        />
        <path
          d="M1.85751 12.643C1.74385 12.5293 1.65369 12.3944 1.59218 12.2459C1.53066 12.0974 1.499 11.9382 1.499 11.7775C1.499 11.6167 1.53066 11.4576 1.59218 11.3091C1.65369 11.1606 1.74385 11.0256 1.85751 10.912C2.56468 10.4161 3.38379 10.1032 4.24151 10.0015C3.56351 9.96397 1.53701 10.6515 0.977007 11.2135C0.678299 11.521 0.512581 11.9337 0.515667 12.3624C0.518754 12.791 0.690397 13.2013 0.993502 13.5045C1.29661 13.8076 1.70683 13.9794 2.13552 13.9826C2.56421 13.9857 2.97694 13.8201 3.28451 13.5215C3.84501 12.963 4.53451 10.9365 4.49901 10.259C4.39775 11.1165 4.08562 11.9356 3.59051 12.643C3.47681 12.7569 3.34175 12.8473 3.19307 12.909C3.04438 12.9707 2.88498 13.0025 2.72401 13.0025C2.56303 13.0025 2.40364 12.9707 2.25495 12.909C2.10626 12.8473 1.9712 12.7569 1.85751 12.643Z"
          fill="#00A3E1"
        />
        <path
          d="M22.9064 11.9064C23.0674 11.7453 23.0162 11.433 22.7919 11.2089C22.5677 10.9848 22.2553 10.9338 22.0943 11.0949C21.9333 11.2561 21.9845 11.5684 22.2088 11.7925C22.433 12.0165 22.7453 12.0676 22.9064 11.9064Z"
          fill="#F6FAFD"
        />
        <path
          d="M22.5754 12.455C22.656 12.3743 22.6305 12.2181 22.5184 12.106C22.4063 11.994 22.25 11.9686 22.1694 12.0492C22.0888 12.1299 22.1143 12.2861 22.2264 12.3982C22.3386 12.5102 22.4948 12.5357 22.5754 12.455Z"
          fill="#F6FAFD"
        />
        <path
          d="M1.79192 11.7911C2.01615 11.567 2.06739 11.2547 1.90636 11.0936C1.74532 10.9324 1.43299 10.9835 1.20876 11.2075C0.984516 11.4316 0.933279 11.7439 1.09431 11.9051C1.25535 12.0662 1.56768 12.0152 1.79192 11.7911Z"
          fill="#F6FAFD"
        />
        <path
          d="M1.77229 12.3979C1.88441 12.2858 1.90995 12.1296 1.82933 12.0489C1.74872 11.9683 1.59247 11.9937 1.48035 12.1057C1.36823 12.2178 1.3427 12.374 1.42331 12.4547C1.50393 12.5353 1.66017 12.5099 1.77229 12.3979Z"
          fill="#F6FAFD"
        />
        <path
          d="M20.7134 13.5198C20.1784 12.775 19.8029 11.9278 19.6104 11.0312C19.5923 11.0373 19.5753 11.0464 19.5604 11.0583C19.2719 11.3468 20.0769 13.8828 20.7144 14.5198C20.9119 14.7169 21.1568 14.8598 21.4256 14.9348C21.6943 15.0097 21.9779 15.0142 22.2489 14.9478C22.421 14.5678 22.5651 14.1758 22.6799 13.7748C22.3698 13.9548 22.0089 14.0274 21.6533 13.9813C21.2977 13.9352 20.9673 13.773 20.7134 13.5198Z"
          fill="#FFC943"
        />
        <path
          d="M23.4145 12.8672C23.299 13.255 23.035 13.5815 22.68 13.7757C22.5655 14.1768 22.4218 14.5691 22.25 14.9492C22.4702 14.8987 22.6772 14.8022 22.8574 14.6659C23.0376 14.5297 23.1869 14.3568 23.2955 14.1587C23.3665 13.7854 23.4192 13.4059 23.4535 13.0202C23.441 12.9687 23.4305 12.9172 23.4145 12.8672Z"
          fill="#FFB32B"
        />
        <path
          d="M3.28481 14.5198C3.92231 13.8828 4.72731 11.3478 4.43881 11.0583C4.42384 11.0464 4.40692 11.0373 4.38881 11.0312C4.19626 11.9278 3.82076 12.775 3.28581 13.5198C3.03192 13.7726 2.70165 13.9347 2.34627 13.9807C1.99088 14.0267 1.63024 13.9541 1.32031 13.7743C1.43444 14.1754 1.57783 14.5676 1.74931 14.9478C2.02046 15.0144 2.30421 15.01 2.57317 14.935C2.84213 14.8601 3.08723 14.717 3.28481 14.5198Z"
          fill="#FFC943"
        />
        <path
          d="M1.75038 14.9487C1.57823 14.5687 1.43418 14.1767 1.31937 13.7757C0.96433 13.5815 0.700343 13.255 0.584875 12.8672C0.568875 12.9172 0.558375 12.9672 0.546875 13.0172C0.580208 13.4029 0.632875 13.7824 0.704875 14.1557C0.813189 14.3543 0.962374 14.5276 1.14259 14.6643C1.32281 14.801 1.52996 14.8979 1.75038 14.9487Z"
          fill="#FFB32B"
        />
      </g>
      <defs>
        <clipPath id="clip0_740_10364">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
