import React from "react";

export default function IconStartMedal() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 6C13 5.0111 12.7068 4.0444 12.1574 3.22215C11.6079 2.39991 10.8271 1.75904 9.91342 1.3806C8.99979 1.00217 7.99446 0.90315 7.02455 1.09608C6.05465 1.289 5.16373 1.76521 4.46447 2.46447C3.76521 3.16373 3.289 4.05465 3.09608 5.02455C2.90315 5.99446 3.00217 6.99979 3.3806 7.91342C3.75904 8.82705 4.39991 9.60794 5.22215 10.1574C6.0444 10.7068 7.0111 11 8 11C9.32562 10.9985 10.5965 10.4712 11.5339 9.53386C12.4712 8.59651 12.9985 7.32562 13 6ZM10.6856 5.34702L9.58464 6.42059L9.845 7.9362C9.85554 7.99751 9.84871 8.06055 9.82527 8.11818C9.80184 8.17581 9.76274 8.22573 9.71239 8.26228C9.66205 8.29883 9.60249 8.32056 9.54043 8.32501C9.47838 8.32945 9.41632 8.31644 9.36129 8.28744L8 7.57194L6.63867 8.28744C6.58362 8.31632 6.5216 8.32925 6.4596 8.32475C6.3976 8.32025 6.33809 8.29851 6.28779 8.26198C6.23749 8.22546 6.1984 8.1756 6.17494 8.11803C6.15147 8.06046 6.14457 7.99748 6.155 7.9362L6.41542 6.42059L5.3145 5.34702C5.26994 5.30367 5.2384 5.24871 5.22345 5.18837C5.2085 5.12802 5.21074 5.0647 5.22991 5.00556C5.24908 4.94642 5.28442 4.89383 5.33194 4.85374C5.37945 4.81364 5.43723 4.78764 5.49875 4.77869L7.02024 4.55734L7.70117 3.17834C7.73178 3.12628 7.77545 3.08313 7.82786 3.05314C7.88028 3.02316 7.93962 3.00739 8 3.00739C8.06039 3.00739 8.11973 3.02316 8.17214 3.05314C8.22455 3.08313 8.26823 3.12628 8.29884 3.17834L8.97982 4.55724L10.5013 4.77859C10.5628 4.78754 10.6206 4.81354 10.6681 4.85364C10.7156 4.89373 10.751 4.94632 10.7701 5.00546C10.7893 5.0646 10.7916 5.12792 10.7766 5.18827C10.7617 5.24861 10.7301 5.30367 10.6856 5.34702Z"
        fill="url(#paint0_linear_26_14340)"
      />
      <path
        d="M14.9335 12.1725L12.6896 9.17664C12.2357 9.84529 11.6442 10.4092 10.9547 10.8306C10.2651 11.252 9.49346 11.5211 8.69141 11.62L11.1231 14.8664C11.1621 14.9185 11.2154 14.9579 11.2765 14.98C11.3377 15.0021 11.4039 15.0059 11.4672 14.9908C11.5304 14.9757 11.5878 14.9425 11.6324 14.8951C11.677 14.8478 11.7068 14.7885 11.7181 14.7245L12.151 12.2672L14.6087 12.7005C14.6745 12.7121 14.7423 12.7037 14.8033 12.6763C14.8642 12.6489 14.9156 12.6038 14.9506 12.5469C14.9856 12.49 15.0027 12.4238 14.9997 12.3571C14.9967 12.2903 14.9736 12.226 14.9335 12.1725Z"
        fill="url(#paint1_linear_26_14340)"
      />
      <path
        d="M3.3104 9.17664L1.06643 12.1725C1.02639 12.226 1.00336 12.2903 1.00034 12.3571C0.997321 12.4238 1.01445 12.4899 1.0495 12.5468C1.08414 12.6041 1.1354 12.6495 1.19645 12.6769C1.2575 12.7044 1.32546 12.7126 1.3913 12.7005L3.84898 12.2672L4.28191 14.7245C4.29322 14.7885 4.32299 14.8478 4.36757 14.8951C4.41215 14.9425 4.46959 14.9757 4.53282 14.9908C4.59606 15.0059 4.66232 15.0021 4.72345 14.98C4.78459 14.9579 4.83793 14.9185 4.87691 14.8664L7.30856 11.62C6.50651 11.5211 5.73485 11.252 5.04529 10.8306C4.35574 10.4092 3.76422 9.84529 3.3104 9.17664Z"
        fill="url(#paint2_linear_26_14340)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_26_14340"
          x1="8"
          y1="1"
          x2="8"
          y2="11.8333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_26_14340"
          x1="11.8457"
          y1="9.17664"
          x2="11.8457"
          y2="15.4851"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_26_14340"
          x1="4.15428"
          y1="9.17664"
          x2="4.15428"
          y2="15.4851"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
