import React from "react";

export default function IconHollowStar() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
      <g clipPath="url(#clip0_26_13248)">
        <g filter="url(#filter0_di_26_13248)">
          <path
            d="M19 9.5C19 14.7468 14.7468 19 9.5 19C4.25323 19 0 14.7468 0 9.5C0 4.25323 4.25323 0 9.5 0C14.7468 0 19 4.25323 19 9.5Z"
            fill="white"
          />
        </g>
        <path
          d="M14.2108 7.50711C14.1379 7.31664 13.9725 7.1769 13.7726 7.13689L11.0889 6.59982L10.0311 4.48458C9.93051 4.28338 9.72496 4.15625 9.49998 4.15625C9.275 4.15625 9.06945 4.28338 8.96885 4.48458L7.91109 6.59982L5.22733 7.13689C5.02743 7.1769 4.86189 7.31664 4.78912 7.50711C4.71635 7.69759 4.74636 7.91212 4.8687 8.07535L6.4905 10.238L5.94908 12.9464C5.9066 13.1598 5.98416 13.3792 6.15144 13.5185C6.31858 13.6577 6.54848 13.6944 6.75055 13.6141L9.49998 12.5147L12.2481 13.6132C12.4503 13.6935 12.6802 13.6568 12.8474 13.5177C13.0145 13.3784 13.0922 13.1589 13.0497 12.9455L12.5082 10.2371L14.1313 8.07535C14.2536 7.91212 14.2836 7.69759 14.2108 7.50711Z"
          fill="#490E0E"
        />
      </g>
      <defs>
        <filter
          id="filter0_di_26_13248"
          x="-58"
          y="-29"
          width="135"
          height="135"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="29" />
          <feGaussianBlur stdDeviation="29" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_13248" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_26_13248"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="6.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.169764 0 0 0 0 0.624988 0 0 0 0 0.768743 0 0 0 0.03 0"
          />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_26_13248" />
        </filter>
        <clipPath id="clip0_26_13248">
          <rect width="19" height="19" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
