import React from "react";

export default function Simple28() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_740_10878)">
        <path
          d="M12 23.5C18.3513 23.5 23.5 18.3513 23.5 12C23.5 5.64873 18.3513 0.5 12 0.5C5.64873 0.5 0.5 5.64873 0.5 12C0.5 18.3513 5.64873 23.5 12 23.5Z"
          fill="#FFCE52"
        />
        <path
          d="M18.7765 9.95C17.882 9.5 16.8585 9.5 15 9.5C14.8674 9.5 14.7402 9.44732 14.6464 9.35355C14.5527 9.25979 14.5 9.13261 14.5 9C14.5 7.8675 16.284 6.5 18.5 6.5V7.5C17.5022 7.47467 16.5326 7.83252 15.7905 8.5C17.3195 8.509 18.272 8.5755 19.2235 9.05L18.7765 9.95Z"
          fill="#273941"
        />
        <path
          d="M5.22434 9.95L4.77734 9.055C5.72734 8.5795 6.68134 8.513 8.21034 8.505C7.46901 7.8357 6.49932 7.47603 5.50084 7.5V6.5C7.71684 6.5 9.50084 7.8675 9.50084 9C9.50084 9.13261 9.44817 9.25979 9.3544 9.35355C9.26063 9.44732 9.13345 9.5 9.00084 9.5C7.14234 9.5 6.11884 9.5 5.22434 9.95Z"
          fill="#273941"
        />
        <path
          d="M20 12.5C20.0117 14.0953 19.4144 15.6349 18.33 16.805C17.2863 17.9111 15.9629 18.7144 14.5 19.13V17C14.4992 16.735 14.3936 16.4811 14.2062 16.2938C14.0189 16.1064 13.765 16.0008 13.5 16H10.5C10.235 16.0008 9.98113 16.1064 9.79377 16.2938C9.6064 16.4811 9.50079 16.735 9.5 17V19.13C6.625 18.28 4 15.99 4 12.5C4.235 12.5 5.13 12.75 6.52 13C8.32816 13.3293 10.1621 13.4966 12 13.5C14.6975 13.494 17.3841 13.1582 20 12.5Z"
          fill="#273941"
        />
        <path
          d="M12 16H13.5C13.765 16.0008 14.0189 16.1064 14.2062 16.2938C14.3936 16.4811 14.4992 16.735 14.5 17V19.5C14.5 20.163 14.2366 20.7989 13.7678 21.2678C13.2989 21.7366 12.663 22 12 22C11.337 22 10.7011 21.7366 10.2322 21.2678C9.76339 20.7989 9.5 20.163 9.5 19.5V17C9.50079 16.735 9.6064 16.4811 9.79377 16.2938C9.98113 16.1064 10.235 16.0008 10.5 16H12Z"
          fill="#CF4054"
        />
        <path
          d="M11.9992 1.99911C18.0742 1.99911 22.9992 6.25261 22.9992 11.4991H23.4867C23.3489 8.54514 22.0784 5.7578 19.9392 3.71607C17.7999 1.67434 14.9564 0.535156 11.9992 0.535156C9.04203 0.535156 6.1985 1.67434 4.05927 3.71607C1.92005 5.7578 0.649561 8.54514 0.511719 11.4991H0.999219C0.999219 6.25261 5.92422 1.99911 11.9992 1.99911Z"
          fill="#FFE369"
        />
        <path
          d="M23 11.5C23 16.7465 18.075 21 12 21C5.925 21 1 16.7465 1 11.5H0.5125C0.5055 11.666 0.5 11.8325 0.5 12C0.5 15.05 1.7116 17.9751 3.86827 20.1317C6.02494 22.2884 8.95001 23.5 12 23.5C15.05 23.5 17.9751 22.2884 20.1317 20.1317C22.2884 17.9751 23.5 15.05 23.5 12C23.5 11.8325 23.4945 11.666 23.4875 11.5H23Z"
          fill="#FFB32B"
        />
        <path
          d="M18.9064 4.90641C19.0674 4.74526 19.0162 4.43297 18.7919 4.20889C18.5677 3.98481 18.2553 3.93379 18.0943 4.09494C17.9333 4.25608 17.9845 4.56837 18.2088 4.79246C18.433 5.01654 18.7453 5.06756 18.9064 4.90641Z"
          fill="#F6FAFD"
        />
        <path
          d="M15.5754 2.45108C15.656 2.3704 15.6305 2.21418 15.5184 2.10214C15.4063 1.9901 15.25 1.96467 15.1694 2.04534C15.0888 2.12601 15.1143 2.28223 15.2264 2.39427C15.3386 2.50632 15.4948 2.53175 15.5754 2.45108Z"
          fill="#F6FAFD"
        />
        <path
          d="M17.8134 4.31353C18.1355 3.99123 18.0329 3.36649 17.5842 2.91813C17.1355 2.46977 16.5107 2.36758 16.1886 2.68987C15.8666 3.01217 15.9692 3.63691 16.4179 4.08527C16.8665 4.53363 17.4913 4.63582 17.8134 4.31353Z"
          fill="#F6FAFD"
        />
        <path
          d="M12 21C11.337 21 10.7011 20.7366 10.2322 20.2678C9.76339 19.7989 9.5 19.163 9.5 18.5V19.5C9.5 20.163 9.76339 20.7989 10.2322 21.2678C10.7011 21.7366 11.337 22 12 22C12.663 22 13.2989 21.7366 13.7678 21.2678C14.2366 20.7989 14.5 20.163 14.5 19.5V18.5C14.5 19.163 14.2366 19.7989 13.7678 20.2678C13.2989 20.7366 12.663 21 12 21Z"
          fill="#8A293D"
        />
        <path
          d="M12 14.5C14.6679 14.4921 17.3253 14.1639 19.915 13.5225C19.9699 13.1844 19.9983 12.8425 20 12.5C17.3841 13.1582 14.6975 13.494 12 13.5C10.1621 13.4966 8.32816 13.3293 6.52 13C5.13 12.75 4.235 12.5 4 12.5C4.00181 12.8376 4.02956 13.1746 4.083 13.508C4.402 13.546 5.2515 13.772 6.52 14C8.32816 14.3293 10.1621 14.4966 12 14.5Z"
          fill="#141E21"
        />
        <path
          d="M12 22C11.337 22 10.7011 21.7366 10.2322 21.2678C9.76339 20.7989 9.5 20.163 9.5 19.5V20.5C9.5 21.163 9.76339 21.7989 10.2322 22.2678C10.7011 22.7366 11.337 23 12 23C12.663 23 13.2989 22.7366 13.7678 22.2678C14.2366 21.7989 14.5 21.163 14.5 20.5V19.5C14.5 20.163 14.2366 20.7989 13.7678 21.2678C13.2989 21.7366 12.663 22 12 22Z"
          fill="#F29410"
        />
        <path
          d="M13.5 16H10.5C10.235 16.0008 9.98113 16.1064 9.79377 16.2938C9.6064 16.4811 9.50079 16.735 9.5 17V18C9.50079 17.735 9.6064 17.4811 9.79377 17.2938C9.98113 17.1064 10.235 17.0008 10.5 17H10.716C10.9621 17 11.1996 17.0907 11.383 17.2548C11.5664 17.4189 11.6828 17.6449 11.71 17.8895L12 20.5L12.29 17.8895C12.3172 17.6449 12.4336 17.4189 12.617 17.2548C12.8004 17.0907 13.0379 17 13.284 17H13.5C13.765 17.0008 14.0189 17.1064 14.2062 17.2938C14.3936 17.4811 14.4992 17.735 14.5 18V17C14.4992 16.735 14.3936 16.4811 14.2062 16.2938C14.0189 16.1064 13.765 16.0008 13.5 16Z"
          fill="#AE2D4C"
        />
        <path
          d="M9.5 16.9993C9.50048 16.8579 9.53135 16.7183 9.5905 16.5898C9.41503 16.6686 9.266 16.7963 9.16127 16.9576C9.05653 17.1189 9.00054 17.307 9 17.4993V18.9648C9.1655 19.0243 9.332 19.0798 9.5 19.1293V16.9993Z"
          fill="#141E21"
        />
        <path
          d="M14.4102 16.5898C14.4693 16.7183 14.5002 16.8579 14.5007 16.9993V19.1293C14.6687 19.0793 14.8352 19.0243 15.0007 18.9648V17.4993C15.0001 17.307 14.9441 17.1189 14.8394 16.9576C14.7347 16.7963 14.5856 16.6686 14.4102 16.5898Z"
          fill="#141E21"
        />
      </g>
      <defs>
        <clipPath id="clip0_740_10878">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
