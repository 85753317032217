import React from "react";
import IconStartChat from "../images/jsIcons/IconStartChat";
import IconStartMedal from "../images/jsIcons/IconStartMedal";
import IconStartChare from "../images/jsIcons/IconStartChare";
import IconNewCircle from "../images/jsIcons/IconNewCircle";
import IconLoveCircle from "../images/jsIcons/IconLoveCircle";
import IconHollowStar from "../images/jsIcons/IconHollowStar";
import Icon18Plus from "../images/jsIcons/Icon18Plus";
import IconLocation from "../images/jsIcons/IconLocation";
import IconLanguage from "../images/jsIcons/IconLanguage";
import IconSetting from "../images/jsIcons/IconSetting";
import IconVibrator from "../images/jsIcons/IconVibrator";
import IconFire from "../images/jsIcons/IconFire";
import IconDildo from "../images/jsIcons/IconDildo";
import IconCouples from "../images/jsIcons/IconCouples";
import IconPrivate from "../images/jsIcons/IconPrivate";
import IconGirls from "../images/jsIcons/IconGirls";
import IconAllModels from "../images/jsIcons/IconAllModels";
import IconGays from "../images/jsIcons/IconGays";
import IconTrans from "../images/jsIcons/IconTrans";
import { createSlice } from "@reduxjs/toolkit";
import IconGroup from "../images/jsIcons/IconGroup";
import IconCrown from "../images/jsIcons/IconCrown";
import { ENUM_CHAT_MODE } from "./chatSlice";
import { SITE } from "../testConst";

/// -- ID --

// id main category
const SEX_ID = 1;
const SHOW_ID = 4;
const FILTER_ID = 5;
const SORTING_ID = 21;

// id filter
const FILTER_POPULAR_ID = 9;
const FILTER_AGE_ID = 78;
const FILTER_REGION_ID = 10;
const FILTER_LANGUAGE_ID = 22;
const FILTER_APPEARANCE_ID = 23;

// id sorting
const SORTING_POPULAR_ID = 26;
const SORTING_HIGHEST_SCOPE_ID = 74;
const SORTING_NEW_MODEL_ID = 75;
const SORTING_NEW_ROOMS_ID = 76;
const SORTING_FAVORITES_ID = 77;

// id show
const SHOW_OUTDOORS = 8;
const SHOW_VIBRATOR = 17;
const SHOW_DILDO = 18;
const SHOW_COUPLES = 19;
const SHOW_SPY_PRIVATE = 20;

// id sex
export const SEX_ALL_MODELS = 15;
export const SEX_GIRLS = 11;
export const SEX_GAYS = 12;
export const SEX_TRANS = 16;

/// -- ICON --

// icon sorting
const sortingIconById = {};
sortingIconById[SORTING_POPULAR_ID] = <IconStartChat />;
sortingIconById[SORTING_HIGHEST_SCOPE_ID] = <IconStartMedal />;
sortingIconById[SORTING_NEW_MODEL_ID] = <IconStartChare />;
sortingIconById[SORTING_NEW_ROOMS_ID] = <IconNewCircle />;
sortingIconById[SORTING_FAVORITES_ID] = <IconLoveCircle />;

// icon filter
const filterIconById = {};
filterIconById[FILTER_POPULAR_ID] = <IconHollowStar />;
filterIconById[FILTER_AGE_ID] = <Icon18Plus />;
filterIconById[FILTER_REGION_ID] = <IconLocation />;
filterIconById[FILTER_LANGUAGE_ID] = <IconLanguage />;
filterIconById[FILTER_APPEARANCE_ID] = <IconSetting />;

// icon shown
const shownIconById = {};
shownIconById[SHOW_OUTDOORS] = <IconFire />;
shownIconById[SHOW_VIBRATOR] = <IconVibrator />;
shownIconById[SHOW_DILDO] = <IconDildo />;
shownIconById[SHOW_COUPLES] = <IconCouples />;
shownIconById[SHOW_SPY_PRIVATE] = <IconPrivate />;

// icon sex
const sexIconById = {};
sexIconById[SEX_ALL_MODELS] = <IconAllModels />;
sexIconById[SEX_GIRLS] = <IconGirls />;
sexIconById[SEX_GAYS] = <IconGays />;
sexIconById[SEX_TRANS] = <IconTrans />;

// other
const classById = {};
classById[SEX_ALL_MODELS] = "all-models";
classById[SEX_GIRLS] = "girls";
classById[SEX_GAYS] = "guys";
classById[SEX_TRANS] = "trans";

export const ICON_BY_MODE = {
  [ENUM_CHAT_MODE.free]: <IconGroup />,
  [ENUM_CHAT_MODE.paid]: <IconGroup />,
  [ENUM_CHAT_MODE.view]: <IconPrivate />,
  [ENUM_CHAT_MODE.private]: <IconPrivate />,
  [ENUM_CHAT_MODE.exclusive]: <IconCrown />,
  [ENUM_CHAT_MODE.pause]: "",
};

const getCommonSite = (siteInfo = SITE.categories || []) => {
  const urlParse = window.startConfig?.url || [
    { path: ["campaign_id"], value: 115 },
    { path: ["lang"], value: "en_US" },
    { path: ["gender"], value: "trans" },
    { path: ["sort-by"], value: "new-models" },
    { path: ["skip-intro"], value: true },
    { path: ["filters", "age"], value: ["18-22", "23-30"] },
  ];

  /// -- CURRENT CATEGORY --
  const sorting = siteInfo.find((el) => el.id === SORTING_ID);
  const filter = siteInfo.find((el) => el.id === FILTER_ID);
  const show = siteInfo.find((el) => el.id === SHOW_ID);
  const sex = siteInfo.find((el) => el.id === SEX_ID);

  /// -- OPTIONS --

  const optionsSorting = sorting?.subcategories.map((el) => ({
    label: el.name,
    value: el.url,
    icon: sortingIconById[el.id],
    selected: el.selected,
    url: el.url,
  }));

  const optionsFilter = filter?.subcategories.map((el) => ({
    id: el.id,
    title: el.name || el.title,
    icon: filterIconById[el.id],
    defaultOpen: el.selected,
    url: el.url,

    children: el.subcategories.map((el2) => ({
      title: el2.title,
      type: el2?.subcategories ? "container" : "checkbox",
      value: el2.id,
      selected: !!el2.selected,
      url: el2.url,
      children: el2?.subcategories?.map((el3) => ({
        title: el3.title,
        type: "checkbox",
        value: el3.id,
        selected: el3.selected,
        url: el3.url,
      })),
    })),
  }));

  const optionsShown = show?.subcategories.map((el) => ({
    title: el.name || el.title,
    id: el.id,
    selected: el.selected,
    icon: shownIconById[el.id],
    url: el.url,
  }));

  const optionsSex = sex?.subcategories.map((el) => ({
    id: el.id,
    icon: sexIconById[el.id],
    title: el.name || el.title,
    titleShort: el.name || el.title,
    value: el.id,
    selected: el.selected,
    url: el.url,
    className: classById[el.id],
  }));

  return {
    commonInfo: siteInfo || {},
    urlParse: urlParse,
    sex: sex,
    show: show,
    filter: filter,
    sorting: sorting,
    options: {
      sorting: optionsSorting,
      filter: optionsFilter,
      shown: optionsShown,
      sex: optionsSex,
    },
  };
};

const siteInfoSlice = createSlice({
  name: "siteInfo",
  initialState: {
    commonSiteInfo: getCommonSite() || {},
  },
  reducers: {
    setNewSiteInfo(state, newAction) {
      state.commonSiteInfo = getCommonSite(newAction.payload);
    },
  },
});

export default siteInfoSlice.reducer;

export const { setNewSiteInfo } = siteInfoSlice.actions;
