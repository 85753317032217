import React, { useEffect, useRef, useState } from "react";
import HomeFilterMenu from "./HomeFilterMenu";
import CusButton from "../../mui/CusButton";
import { isBonusForNewUsers } from "../../common/consts";
import MenuForFreeBanner from "../MenuForFreeBanner";
import { setFilter } from "../../../store/commonSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toggleElemInList } from "../../../utils/utils";

export default function HomeFilters() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const filtersCategory = useSelector((state) => state.common.filter);
  const [filterOptions, setFilterOptions] = useState(filtersCategory);

  const buttonRef = useRef();

  useEffect(() => {
    setFilterOptions(filtersCategory);
  }, [filtersCategory]);

  const onChangeItem = (value) => setFilterOptions(toggleElemInList(filterOptions, value));
  const onSearch = () => {
    dispatch(setFilter(filterOptions));
    navigate("/");
  };
  const onClear = () => {
    setFilterOptions([]);
    dispatch(setFilter([]));
  };

  return (
    <div className="home__filter__container">
      <HomeFilterMenu
        buttonRef={buttonRef}
        onChangeItem={onChangeItem}
        filtersCategory={filtersCategory}
        filterOptions={filterOptions}
      />

      <div ref={buttonRef} className="home__filter__menu__item__buttons">
        <CusButton onClick={onSearch} color={"red"} text={"Search"} />
        <CusButton onClick={onClear} text={"Reset"} />
      </div>

      {isBonusForNewUsers && <MenuForFreeBanner />}
    </div>
  );
}
