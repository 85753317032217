import React from "react";

export default function Simple16() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_740_10619)">
        <path
          d="M12 23.5C18.3513 23.5 23.5 18.3513 23.5 12C23.5 5.64873 18.3513 0.5 12 0.5C5.64873 0.5 0.5 5.64873 0.5 12C0.5 18.3513 5.64873 23.5 12 23.5Z"
          fill="#764097"
        />
        <path
          d="M12 17C16.9706 17 21 13.6421 21 9.5C21 5.35786 16.9706 2 12 2C7.02944 2 3 5.35786 3 9.5C3 13.6421 7.02944 17 12 17Z"
          fill="url(#paint0_radial_740_10619)"
        />
        <path
          d="M11.9992 1.99911C18.0742 1.99911 22.9992 6.25261 22.9992 11.4991H23.4867C23.3489 8.54514 22.0784 5.7578 19.9392 3.71607C17.7999 1.67434 14.9564 0.535156 11.9992 0.535156C9.04203 0.535156 6.1985 1.67434 4.05927 3.71607C1.92005 5.7578 0.649561 8.54514 0.511719 11.4991H0.999219C0.999219 6.25261 5.92422 1.99911 11.9992 1.99911Z"
          fill="#A467C8"
        />
        <path
          d="M23 11.5C23 16.7465 18.075 21 12 21C5.925 21 1 16.7465 1 11.5H0.5125C0.5055 11.666 0.5 11.8325 0.5 12C0.5 15.05 1.7116 17.9751 3.86827 20.1317C6.02494 22.2884 8.95001 23.5 12 23.5C15.05 23.5 17.9751 22.2884 20.1317 20.1317C22.2884 17.9751 23.5 15.05 23.5 12C23.5 11.8325 23.4945 11.666 23.4875 11.5H23Z"
          fill="#522574"
        />
        <path
          d="M18.1564 4.65641C18.3174 4.49526 18.2662 4.18297 18.0419 3.95889C17.8177 3.73481 17.5053 3.68379 17.3443 3.84494C17.1833 4.00608 17.2345 4.31837 17.4588 4.54246C17.683 4.76654 17.9953 4.81756 18.1564 4.65641Z"
          fill="#F6FAFD"
        />
        <path
          d="M14.8257 2.20108C14.9063 2.1204 14.8807 1.96418 14.7686 1.85214C14.6565 1.7401 14.5003 1.71467 14.4196 1.79534C14.339 1.87601 14.3646 2.03223 14.4767 2.14427C14.5888 2.25632 14.7451 2.28175 14.8257 2.20108Z"
          fill="#F6FAFD"
        />
        <path
          d="M17.0615 4.06353C17.3835 3.74123 17.2809 3.11649 16.8322 2.66813C16.3836 2.21977 15.7587 2.11758 15.4367 2.43987C15.1146 2.76217 15.2172 3.38691 15.6659 3.83527C16.1146 4.28363 16.7394 4.38582 17.0615 4.06353Z"
          fill="#F6FAFD"
        />
        <path
          d="M21.5 5.5C19.5 5.5 18.5 4.5 18.5 2.5C20.3279 2.34291 22.068 1.64685 23.5 0.5C23.5 3.682 22.5 5.5 21.5 5.5Z"
          fill="#273941"
        />
        <path
          d="M2.5 5.5C4.5 5.5 5.5 4.5 5.5 2.5C3.67212 2.34291 1.93198 1.64685 0.5 0.5C0.5 3.682 1.5 5.5 2.5 5.5Z"
          fill="#273941"
        />
        <path
          d="M12 19C9.3835 19 7 17.5705 7 16H8C8 16.805 9.6795 18 12 18C14.3205 18 16 16.805 16 16H17C17 17.5705 14.6165 19 12 19Z"
          fill="#141E21"
        />
        <path
          d="M23.5 0.5C22.068 1.64685 20.3279 2.34291 18.5 2.5C18.5001 2.83442 18.5384 3.16774 18.614 3.4935C20.3845 3.31113 22.0666 2.62892 23.464 1.5265C23.4855 1.2 23.5 0.861 23.5 0.5Z"
          fill="#415B62"
        />
        <path
          d="M0.5 0.5C0.5 0.861 0.5145 1.2 0.5385 1.5265C1.93587 2.62892 3.61799 3.31113 5.3885 3.4935C5.46327 3.16764 5.50067 2.83433 5.5 2.5C3.67212 2.34291 1.93198 1.64685 0.5 0.5Z"
          fill="#415B62"
        />
        <path
          d="M21.5 4.5C19.869 4.5 18.915 3.8235 18.614 2.4935C18.5775 2.4935 18.534 2.5 18.5 2.5C18.5 4.5 19.5 5.5 21.5 5.5C22.5 5.5 23.5 3.682 23.5 0.5C23.487 0.5095 23.474 0.517 23.4615 0.5265C23.2715 3.0685 22.3865 4.5 21.5 4.5Z"
          fill="#141E21"
        />
        <path
          d="M2.5 4.5C1.6135 4.5 0.7285 3.0685 0.5385 0.5265C0.526 0.517 0.513 0.5095 0.5 0.5C0.5 3.682 1.5 5.5 2.5 5.5C4.5 5.5 5.5 4.5 5.5 2.5C5.466 2.5 5.4225 2.4945 5.386 2.4935C5.085 3.8235 4.131 4.5 2.5 4.5Z"
          fill="#141E21"
        />
        <path
          d="M19.7645 9.95L19.5505 9.5L19.3175 9.608C19.5488 10.1967 19.5465 10.8515 19.311 11.4386C19.0755 12.0256 18.6246 12.5005 18.0505 12.766C17.6326 12.958 17.1702 13.0319 16.7133 12.9796C16.2565 12.9274 15.8226 12.7509 15.459 12.4695C15.8032 12.9386 16.2991 13.2743 16.8626 13.4195C17.4261 13.5646 18.0224 13.5104 18.5505 13.266C18.8489 13.1291 19.1173 12.9345 19.3401 12.6933C19.5629 12.4522 19.7357 12.1693 19.8485 11.861C19.9614 11.5527 20.0121 11.2251 19.9977 10.8971C19.9832 10.5691 19.904 10.2472 19.7645 9.95Z"
          fill="#632E86"
        />
        <path
          d="M5.9499 12.766C5.37537 12.5009 4.92389 12.0262 4.68789 11.4392C4.45189 10.8521 4.44921 10.197 4.6804 9.608L4.4499 9.5L4.2379 9.95C4.09833 10.2471 4.01897 10.5689 4.0044 10.8968C3.98984 11.2247 4.04035 11.5522 4.15303 11.8605C4.26571 12.1688 4.43832 12.4517 4.66091 12.693C4.8835 12.9342 5.15165 13.1289 5.4499 13.266C5.97796 13.5104 6.57431 13.5646 7.13779 13.4195C7.70127 13.2743 8.19718 12.9386 8.5414 12.4695C8.17773 12.7509 7.74392 12.9274 7.28705 12.9796C6.83019 13.0319 6.36773 12.958 5.9499 12.766Z"
          fill="#632E86"
        />
        <path
          d="M18.0495 12.766C17.4466 13.046 16.7575 13.0762 16.1324 12.8502C15.5073 12.6242 14.9969 12.1603 14.7125 11.5595L14.5 11.1095L19.0495 9L19.2615 9.45C19.4011 9.74708 19.4804 10.0689 19.495 10.3968C19.5096 10.7247 19.4591 11.0522 19.3464 11.3605C19.2337 11.6688 19.0611 11.9517 18.8385 12.193C18.6159 12.4342 18.3477 12.6289 18.0495 12.766Z"
          fill="#273941"
        />
        <path
          d="M5.9499 12.766C6.55277 13.046 7.24186 13.0762 7.86696 12.8502C8.49206 12.6242 9.00246 12.1603 9.2869 11.5595L9.4994 11.1095L4.9499 9L4.7379 9.45C4.59833 9.74708 4.51897 10.0689 4.5044 10.3968C4.48984 10.7247 4.54035 11.0522 4.65303 11.3605C4.76571 11.6688 4.93832 11.9517 5.16091 12.193C5.3835 12.4342 5.65165 12.6289 5.9499 12.766Z"
          fill="#273941"
        />
        <path
          d="M18.05 12.2677C17.4471 12.5477 16.7579 12.578 16.1327 12.352C15.5075 12.126 14.997 11.662 14.7125 11.0612L14.6935 11.0202L14.5 11.1092L14.712 11.5592C14.9962 12.1605 15.5066 12.625 16.1319 12.8514C16.7573 13.0778 17.4468 13.0477 18.05 12.7677C18.5192 12.5506 18.9089 12.1924 19.1648 11.7432C19.4207 11.2939 19.53 10.776 19.4775 10.2617C19.4344 10.6913 19.2801 11.1023 19.0298 11.454C18.7794 11.8058 18.4418 12.0862 18.05 12.2677Z"
          fill="#141E21"
        />
        <path
          d="M5.94963 12.2677C5.55797 12.0862 5.22038 11.8057 4.97015 11.4539C4.71991 11.1022 4.56567 10.6913 4.52263 10.2617C4.47009 10.776 4.57933 11.2938 4.83513 11.7431C5.09094 12.1923 5.48054 12.5505 5.94963 12.7677C6.55257 13.0477 7.24174 13.078 7.86692 12.852C8.49209 12.626 9.00259 12.162 9.28713 11.5612L9.49913 11.1112L9.30613 11.0222L9.28713 11.0632C9.00223 11.6637 8.49158 12.1272 7.86644 12.3528C7.2413 12.5784 6.55232 12.5478 5.94963 12.2677Z"
          fill="#141E21"
        />
        <path
          d="M19.264 9.45C19.3809 9.7051 19.4529 9.97843 19.477 10.258C19.5275 9.81071 19.4538 9.35815 19.264 8.95L19.05 8.5L14.5 10.6075L14.693 11.0185L19.05 9L19.264 9.45Z"
          fill="#522574"
        />
        <path
          d="M4.94999 8.5L4.73799 8.95C4.54818 9.35815 4.47449 9.81071 4.52499 10.258C4.54842 9.97855 4.6198 9.70522 4.73599 9.45L4.94999 9L9.30899 11.0185L9.49999 10.6075L4.94999 8.5Z"
          fill="#522574"
        />
        <path
          d="M17.2497 12.0015C17.5368 12.0011 17.815 11.9019 18.0376 11.7205C18.2602 11.5392 18.4135 11.2867 18.4719 11.0056C18.5303 10.7245 18.4901 10.4318 18.3581 10.1769C18.2262 9.9219 18.0104 9.72012 17.7472 9.60547L17.0512 9.92747L17.9157 11.224L17.0837 11.779L16.1337 10.353L16.0607 10.3865C15.9999 10.5729 15.9845 10.7711 16.0157 10.9647C16.0469 11.1583 16.1238 11.3417 16.2401 11.4996C16.3563 11.6575 16.5086 11.7854 16.6842 11.8727C16.8598 11.96 17.0536 12.0041 17.2497 12.0015Z"
          fill="#AE2D4C"
        />
        <path
          d="M5.4999 10.7515C5.49718 10.9328 5.53395 11.1126 5.60767 11.2783C5.6814 11.444 5.79029 11.5917 5.92681 11.7111C6.06333 11.8305 6.22419 11.9188 6.39824 11.9698C6.57229 12.0208 6.75535 12.0333 6.93472 12.0064C7.11409 11.9796 7.28548 11.9141 7.43698 11.8143C7.58847 11.7146 7.71646 11.5832 7.81205 11.429C7.90764 11.2749 7.96854 11.1018 7.99053 10.9218C8.01252 10.7417 7.99507 10.5591 7.9394 10.3865L7.8664 10.353L6.9164 11.779L6.0844 11.224L6.9499 9.92747L6.2539 9.60547C6.03018 9.70218 5.83961 9.86219 5.70565 10.0658C5.57168 10.2694 5.50017 10.5077 5.4999 10.7515Z"
          fill="#AE2D4C"
        />
        <path
          d="M17.9153 11.2223L17.0508 9.92578L16.1328 10.3513L17.0833 11.7773L17.9153 11.2223Z"
          fill="#141E21"
        />
        <path
          d="M6.91598 11.7773L7.86648 10.3513L6.94848 9.92578L6.08398 11.2223L6.91598 11.7773Z"
          fill="#141E21"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_740_10619"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(11.9995 9.49975) scale(9 7.5)"
        >
          <stop stopColor="#A467C8" />
          <stop offset="0.296" stopColor="#9B5FBE" />
          <stop offset="0.793" stopColor="#824AA4" />
          <stop offset="1" stopColor="#764097" />
        </radialGradient>
        <clipPath id="clip0_740_10619">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
