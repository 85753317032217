import React from "react";

export default function IconMobile() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_di_22_7310)">
        <path
          d="M12.0625 2.6875V1.99303C12.0625 1.33825 11.5298 0.8125 10.875 0.8125H5.125C4.47022 0.8125 3.9375 1.33825 3.9375 1.99303V2.6875H12.0625ZM7.32291 1.78125H8.67706C8.76334 1.78125 8.83331 1.85122 8.83331 1.9375C8.83331 2.02378 8.76334 2.09375 8.67706 2.09375H7.32291C7.23662 2.09375 7.16666 2.02378 7.16666 1.9375C7.16666 1.85122 7.23662 1.78125 7.32291 1.78125Z"
          fill="url(#paint0_linear_22_7310)"
          shapeRendering="crispEdges"
        />
      </g>
      <g filter="url(#filter1_ddii_22_7310)">
        <path
          d="M12.0625 3H3.9375V12.625H12.0625V3Z"
          fill="url(#paint1_linear_22_7310)"
          shapeRendering="crispEdges"
        />
      </g>
      <path
        d="M7.99993 13.618C7.85443 13.618 7.73608 13.7364 7.73608 13.8819C7.73608 14.0274 7.85446 14.1457 7.99993 14.1457C8.1454 14.1457 8.26377 14.0274 8.26377 13.8819C8.26377 13.7364 8.14543 13.618 7.99993 13.618Z"
        fill="url(#paint2_linear_22_7310)"
      />
      <g filter="url(#filter2_di_22_7310)">
        <path
          d="M3.9375 12.9375V13.993C3.9375 14.6478 4.47022 15.1875 5.125 15.1875H10.875C11.5298 15.1875 12.0625 14.6478 12.0625 13.993V12.9375H3.9375ZM8 14.4583C7.68219 14.4583 7.42366 14.1997 7.42366 13.8819C7.42366 13.5641 7.68219 13.3056 8 13.3056C8.31781 13.3056 8.57634 13.5641 8.57634 13.8819C8.57634 14.1997 8.31781 14.4583 8 14.4583Z"
          fill="url(#paint3_linear_22_7310)"
          shapeRendering="crispEdges"
        />
      </g>
      <defs>
        <filter
          id="filter0_di_22_7310"
          x="-54.0625"
          y="-28.1875"
          width="124.125"
          height="117.875"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="29" />
          <feGaussianBlur stdDeviation="29" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_22_7310" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_22_7310"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="6.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.169764 0 0 0 0 0.624988 0 0 0 0 0.768743 0 0 0 0.03 0"
          />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_22_7310" />
        </filter>
        <filter
          id="filter1_ddii_22_7310"
          x="-54.0625"
          y="-26"
          width="124.125"
          height="125.625"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="29" />
          <feGaussianBlur stdDeviation="29" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_22_7310" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="29" />
          <feGaussianBlur stdDeviation="29" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_22_7310"
            result="effect2_dropShadow_22_7310"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_22_7310"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="6.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.169764 0 0 0 0 0.624988 0 0 0 0 0.768743 0 0 0 0.03 0"
          />
          <feBlend mode="normal" in2="shape" result="effect3_innerShadow_22_7310" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="6.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.169764 0 0 0 0 0.624988 0 0 0 0 0.768743 0 0 0 0.03 0"
          />
          <feBlend
            mode="normal"
            in2="effect3_innerShadow_22_7310"
            result="effect4_innerShadow_22_7310"
          />
        </filter>
        <filter
          id="filter2_di_22_7310"
          x="-54.0625"
          y="-16.0625"
          width="124.125"
          height="118.25"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="29" />
          <feGaussianBlur stdDeviation="29" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_22_7310" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_22_7310"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="6.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.169764 0 0 0 0 0.624988 0 0 0 0 0.768743 0 0 0 0.03 0"
          />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_22_7310" />
        </filter>
        <linearGradient
          id="paint0_linear_22_7310"
          x1="8"
          y1="0.8125"
          x2="8"
          y2="2.84375"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.34" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_22_7310"
          x1="8"
          y1="3"
          x2="8"
          y2="13.4271"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.34" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_22_7310"
          x1="7.99993"
          y1="13.618"
          x2="7.99993"
          y2="14.1897"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.34" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_22_7310"
          x1="8"
          y1="12.9375"
          x2="8"
          y2="15.375"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.34" />
        </linearGradient>
      </defs>
    </svg>
  );
}
