import React from "react";
import CusButton from "../mui/CusButton";
import { useTranslation } from "react-i18next";
import { ENUM_MODALS_VALUE, setModals } from "../../store/commonSlice";
import { useDispatch } from "react-redux";

export default function MenuForFreeBanner() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <div className="free-banner">
      <div className="free-banner__content">
        <h4 className="free-banner__title">{t("For free")}</h4>
        <p className="free-banner__p">{t("Register and get 50 tokens")}</p>
        <CusButton
          onClick={() => dispatch(setModals({ name: ENUM_MODALS_VALUE.register, value: true }))}
          className="free-banner__button custom-button__red"
          text={"Join Now"}
        />
      </div>
    </div>
  );
}
