import React from "react";

export default function Smile7() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_740_10458)">
        <path
          d="M12 23.5C18.3513 23.5 23.5 18.3513 23.5 12C23.5 5.64873 18.3513 0.5 12 0.5C5.64873 0.5 0.5 5.64873 0.5 12C0.5 18.3513 5.64873 23.5 12 23.5Z"
          fill="#FFCE52"
        />
        <path
          d="M12 16C16.9706 16 21 12.6421 21 8.5C21 4.35786 16.9706 1 12 1C7.02944 1 3 4.35786 3 8.5C3 12.6421 7.02944 16 12 16Z"
          fill="url(#paint0_radial_740_10458)"
        />
        <path
          d="M23 11.5C23 16.7465 18.075 21 12 21C5.925 21 1 16.7465 1 11.5H0.5125C0.5055 11.666 0.5 11.8325 0.5 12C0.5 15.05 1.7116 17.9751 3.86827 20.1317C6.02494 22.2884 8.95001 23.5 12 23.5C15.05 23.5 17.9751 22.2884 20.1317 20.1317C22.2884 17.9751 23.5 15.05 23.5 12C23.5 11.8325 23.4945 11.666 23.4875 11.5H23Z"
          fill="#FFB32B"
        />
        <path
          d="M16.5 13.5C17.3284 13.5 18 12.6046 18 11.5C18 10.3954 17.3284 9.5 16.5 9.5C15.6716 9.5 15 10.3954 15 11.5C15 12.6046 15.6716 13.5 16.5 13.5Z"
          fill="#273941"
        />
        <path
          d="M16.5 13C17.0523 13 17.5 12.3284 17.5 11.5C17.5 10.6716 17.0523 10 16.5 10C15.9477 10 15.5 10.6716 15.5 11.5C15.5 12.3284 15.9477 13 16.5 13Z"
          fill="#141E21"
        />
        <path
          d="M17 11.5C17.2761 11.5 17.5 11.2761 17.5 11C17.5 10.7239 17.2761 10.5 17 10.5C16.7239 10.5 16.5 10.7239 16.5 11C16.5 11.2761 16.7239 11.5 17 11.5Z"
          fill="#F6FAFD"
        />
        <path
          d="M7.5 13.5C8.32843 13.5 9 12.6046 9 11.5C9 10.3954 8.32843 9.5 7.5 9.5C6.67157 9.5 6 10.3954 6 11.5C6 12.6046 6.67157 13.5 7.5 13.5Z"
          fill="#273941"
        />
        <path
          d="M7.5 13C8.05228 13 8.5 12.3284 8.5 11.5C8.5 10.6716 8.05228 10 7.5 10C6.94772 10 6.5 10.6716 6.5 11.5C6.5 12.3284 6.94772 13 7.5 13Z"
          fill="#141E21"
        />
        <path
          d="M8 11.5C8.27614 11.5 8.5 11.2761 8.5 11C8.5 10.7239 8.27614 10.5 8 10.5C7.72386 10.5 7.5 10.7239 7.5 11C7.5 11.2761 7.72386 11.5 8 11.5Z"
          fill="#F6FAFD"
        />
        <path d="M5 9.5V8.5C6.861 8.5 8 7.8525 8 7.5H9C9 8.813 6.988 9.5 5 9.5Z" fill="#141E21" />
        <path
          d="M19 9.5C17.012 9.5 15 8.813 15 7.5H16C16 7.8525 17.139 8.5 19 8.5V9.5Z"
          fill="#141E21"
        />
        <path
          d="M12 17.5028H9.25C9.28433 16.807 9.59304 16.1532 10.1085 15.6846C10.6239 15.216 11.3041 14.9708 12 15.0028C12.6959 14.9708 13.3761 15.216 13.8915 15.6846C14.407 16.1532 14.7157 16.807 14.75 17.5028H12Z"
          fill="#273941"
        />
        <path
          d="M12 15.5028C12.609 15.4832 13.2067 15.6701 13.696 16.0332C14.1853 16.3963 14.5373 16.9142 14.695 17.5028H14.75C14.7157 16.807 14.407 16.1532 13.8915 15.6846C13.3761 15.216 12.6959 14.9708 12 15.0028C11.3041 14.9708 10.6239 15.216 10.1085 15.6846C9.59304 16.1532 9.28433 16.807 9.25 17.5028H9.305C9.46265 16.9142 9.81473 16.3963 10.304 16.0332C10.7933 15.6701 11.391 15.4832 12 15.5028Z"
          fill="#141E21"
        />
        <path
          d="M23.5 6C23.5 6.53043 23.2893 7.03914 22.9142 7.41421C22.5391 7.78929 22.0304 8 21.5 8C20.9696 8 20.4609 7.78929 20.0858 7.41421C19.7107 7.03914 19.5 6.53043 19.5 6C19.5 4.8955 21 2 21.5 2C22 2 23.5 4.8955 23.5 6Z"
          fill="#3BC5F6"
        />
        <path
          d="M21.5 2C21 2 19.5 4.8955 19.5 6C19.5 6.53043 19.7107 7.03914 20.0858 7.41421C20.4609 7.78929 20.9696 8 21.5 8C22.0304 8 22.5391 7.78929 22.9142 7.41421C23.2893 7.03914 23.5 6.53043 23.5 6C23.5 4.8955 22 2 21.5 2ZM21.5 6.25C21.1246 6.27079 20.7562 6.14225 20.4751 5.89243C20.1941 5.64261 20.0233 5.29178 20 4.9165C20 4.1805 21.125 2.25 21.5 2.25C21.875 2.25 23 4.1805 23 4.9165C22.9767 5.29178 22.8059 5.64261 22.5249 5.89243C22.2438 6.14225 21.8754 6.27079 21.5 6.25Z"
          fill="#00A3E1"
        />
        <path
          d="M21.5 8.00009C21.0569 7.99931 20.6267 7.85108 20.2771 7.57876C19.9276 7.30645 19.6786 6.92554 19.5695 6.49609C19.528 6.66105 19.5047 6.83006 19.5 7.00009C19.5004 7.39656 19.6186 7.78397 19.8396 8.11312C20.0606 8.44227 20.3744 8.69831 20.7413 8.84871C21.1081 8.99912 21.5113 9.03711 21.8998 8.95785C22.2883 8.8786 22.6444 8.68568 22.923 8.40359C22.838 8.14459 22.7443 7.88976 22.642 7.63909C22.3074 7.87379 21.9087 7.99982 21.5 8.00009Z"
          fill="#FFB32B"
        />
        <path
          d="M22.4064 5.90641C22.5674 5.74526 22.5162 5.43297 22.2919 5.20889C22.0677 4.98481 21.7553 4.93379 21.5943 5.09494C21.4333 5.25608 21.4845 5.56837 21.7088 5.79246C21.933 6.01654 22.2453 6.06756 22.4064 5.90641Z"
          fill="#F6FAFD"
        />
        <path
          d="M22.5754 4.95108C22.656 4.8704 22.6305 4.71418 22.5184 4.60214C22.4063 4.4901 22.25 4.46467 22.1694 4.54534C22.0888 4.62601 22.1143 4.78223 22.2264 4.89428C22.3386 5.00632 22.4948 5.03175 22.5754 4.95108Z"
          fill="#F6FAFD"
        />
        <path
          d="M18.4064 4.90641C18.5674 4.74526 18.5162 4.43297 18.2919 4.20889C18.0677 3.98481 17.7553 3.93379 17.5943 4.09494C17.4333 4.25608 17.4845 4.56837 17.7088 4.79246C17.933 5.01654 18.2453 5.06756 18.4064 4.90641Z"
          fill="#F6FAFD"
        />
        <path
          d="M15.0754 2.45108C15.156 2.3704 15.1305 2.21418 15.0184 2.10214C14.9063 1.9901 14.75 1.96467 14.6694 2.04534C14.5888 2.12601 14.6143 2.28223 14.7264 2.39427C14.8386 2.50632 14.9948 2.53175 15.0754 2.45108Z"
          fill="#F6FAFD"
        />
        <path
          d="M17.3115 4.30962C17.6335 3.98733 17.5309 3.36259 17.0822 2.91423C16.6336 2.46587 16.0087 2.36367 15.6867 2.68596C15.3646 3.00826 15.4672 3.633 15.9159 4.08136C16.3646 4.52972 16.9894 4.63192 17.3115 4.30962Z"
          fill="#F6FAFD"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_740_10458"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12 8.49975) scale(9 7.5)"
        >
          <stop stopColor="#5987DD" />
          <stop offset="0.118" stopColor="#638BD6" />
          <stop offset="0.316" stopColor="#7D98C3" />
          <stop offset="0.572" stopColor="#A8ABA3" />
          <stop offset="0.871" stopColor="#E3C678" />
          <stop offset="1" stopColor="#FFD364" />
        </radialGradient>
        <clipPath id="clip0_740_10458">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
