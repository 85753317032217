import React from "react";

export default function Simple30() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_740_10909)">
        <path
          d="M12 23.5C18.3513 23.5 23.5 18.3513 23.5 12C23.5 5.64873 18.3513 0.5 12 0.5C5.64873 0.5 0.5 5.64873 0.5 12C0.5 18.3513 5.64873 23.5 12 23.5Z"
          fill="#FFCE52"
        />
        <path
          d="M16.5 11C17.3284 11 18 10.1046 18 9C18 7.89543 17.3284 7 16.5 7C15.6716 7 15 7.89543 15 9C15 10.1046 15.6716 11 16.5 11Z"
          fill="#273941"
        />
        <path
          d="M7.5 11C8.32843 11 9 10.1046 9 9C9 7.89543 8.32843 7 7.5 7C6.67157 7 6 7.89543 6 9C6 10.1046 6.67157 11 7.5 11Z"
          fill="#273941"
        />
        <path
          d="M16.5 10.5C17.0523 10.5 17.5 9.82843 17.5 9C17.5 8.17157 17.0523 7.5 16.5 7.5C15.9477 7.5 15.5 8.17157 15.5 9C15.5 9.82843 15.9477 10.5 16.5 10.5Z"
          fill="#141E21"
        />
        <path
          d="M7.5 10.5C8.05228 10.5 8.5 9.82843 8.5 9C8.5 8.17157 8.05228 7.5 7.5 7.5C6.94772 7.5 6.5 8.17157 6.5 9C6.5 9.82843 6.94772 10.5 7.5 10.5Z"
          fill="#141E21"
        />
        <path
          d="M17 9C17.2761 9 17.5 8.77614 17.5 8.5C17.5 8.22386 17.2761 8 17 8C16.7239 8 16.5 8.22386 16.5 8.5C16.5 8.77614 16.7239 9 17 9Z"
          fill="#F6FAFD"
        />
        <path
          d="M8 9C8.27614 9 8.5 8.77614 8.5 8.5C8.5 8.22386 8.27614 8 8 8C7.72386 8 7.5 8.22386 7.5 8.5C7.5 8.77614 7.72386 9 8 9Z"
          fill="#F6FAFD"
        />
        <path
          d="M18.5 5H17.5C17.5 4.73478 17.3946 4.48043 17.2071 4.29289C17.0196 4.10536 16.7652 4 16.5 4C16.2348 4 15.9804 4.10536 15.7929 4.29289C15.6054 4.48043 15.5 4.73478 15.5 5H14.5C14.5 4.46957 14.7107 3.96086 15.0858 3.58579C15.4609 3.21071 15.9696 3 16.5 3C17.0304 3 17.5391 3.21071 17.9142 3.58579C18.2893 3.96086 18.5 4.46957 18.5 5Z"
          fill="#273941"
        />
        <path
          d="M9.5 5H8.5C8.5 4.73478 8.39464 4.48043 8.20711 4.29289C8.01957 4.10536 7.76522 4 7.5 4C7.23478 4 6.98043 4.10536 6.79289 4.29289C6.60536 4.48043 6.5 4.73478 6.5 5H5.5C5.5 4.46957 5.71071 3.96086 6.08579 3.58579C6.46086 3.21071 6.96957 3 7.5 3C8.03043 3 8.53914 3.21071 8.91421 3.58579C9.28929 3.96086 9.5 4.46957 9.5 5Z"
          fill="#273941"
        />
        <path
          d="M11.9992 1.99911C18.0742 1.99911 22.9992 6.25261 22.9992 11.4991H23.4867C23.3489 8.54514 22.0784 5.7578 19.9392 3.71607C17.7999 1.67434 14.9564 0.535156 11.9992 0.535156C9.04203 0.535156 6.1985 1.67434 4.05927 3.71607C1.92005 5.7578 0.649561 8.54514 0.511719 11.4991H0.999219C0.999219 6.25261 5.92422 1.99911 11.9992 1.99911Z"
          fill="#FFE369"
        />
        <path
          d="M23 11.5C23 16.7465 18.075 21 12 21C5.925 21 1 16.7465 1 11.5H0.5125C0.5055 11.666 0.5 11.8325 0.5 12C0.5 15.05 1.7116 17.9751 3.86827 20.1317C6.02494 22.2884 8.95001 23.5 12 23.5C15.05 23.5 17.9751 22.2884 20.1317 20.1317C22.2884 17.9751 23.5 15.05 23.5 12C23.5 11.8325 23.4945 11.666 23.4875 11.5H23Z"
          fill="#FFB32B"
        />
        <path
          d="M17.4064 2.90641C17.5674 2.74526 17.5162 2.43297 17.2919 2.20889C17.0677 1.98481 16.7553 1.93379 16.5943 2.09494C16.4333 2.25608 16.4845 2.56837 16.7088 2.79246C16.933 3.01654 17.2453 3.06756 17.4064 2.90641Z"
          fill="#F6FAFD"
        />
        <path
          d="M13.5754 1.95108C13.656 1.8704 13.6305 1.71418 13.5184 1.60214C13.4063 1.4901 13.25 1.46467 13.1694 1.54534C13.0888 1.62601 13.1143 1.78223 13.2264 1.89427C13.3386 2.00632 13.4948 2.03175 13.5754 1.95108Z"
          fill="#F6FAFD"
        />
        <path
          d="M15.8134 2.81353C16.1355 2.49123 16.0329 1.86649 15.5842 1.41813C15.1355 0.969772 14.5107 0.867576 14.1886 1.18987C13.8666 1.51217 13.9692 2.13691 14.4179 2.58527C14.8665 3.03363 15.4913 3.13582 15.8134 2.81353Z"
          fill="#F6FAFD"
        />
        <path
          d="M12 21.5C13.6569 21.5 15 19.4853 15 17C15 14.5147 13.6569 12.5 12 12.5C10.3431 12.5 9 14.5147 9 17C9 19.4853 10.3431 21.5 12 21.5Z"
          fill="#273941"
        />
        <path
          d="M11.999 21.5001C13.27 21.5001 14.354 20.3126 14.791 18.6386C14.045 17.9087 13.0427 17.5 11.999 17.5C10.9553 17.5 9.9531 17.9087 9.20703 18.6386C9.64403 20.3126 10.728 21.5001 11.999 21.5001Z"
          fill="#AE2D4C"
        />
        <path
          d="M12 21.5C10.456 21.5 9.1855 19.75 9.0195 17.5C9.0075 17.6645 9 17.831 9 18C9 20.4855 10.343 22.5 12 22.5C13.657 22.5 15 20.4855 15 18C15 17.831 14.9925 17.6645 14.9805 17.5C14.8145 19.75 13.544 21.5 12 21.5Z"
          fill="#FFB32B"
        />
        <path
          d="M12 13.5C13.544 13.5 14.8145 15.25 14.9805 17.5C14.9925 17.3355 15 17.169 15 17C15 14.5145 13.657 12.5 12 12.5C10.343 12.5 9 14.5145 9 17C9 17.169 9.0075 17.3355 9.0195 17.5C9.1855 15.25 10.456 13.5 12 13.5Z"
          fill="#141E21"
        />
        <path
          d="M11.999 20.4984C10.933 20.4984 10.0045 19.6574 9.47303 18.3984C9.38103 18.4734 9.29203 18.5519 9.20703 18.6349C9.64403 20.3109 10.728 21.4984 11.999 21.4984C13.27 21.4984 14.354 20.3109 14.791 18.6369C14.706 18.5539 14.617 18.4754 14.525 18.4004C13.9935 19.6574 13.065 20.4984 11.999 20.4984Z"
          fill="#8A293D"
        />
      </g>
      <defs>
        <clipPath id="clip0_740_10909">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
