import React from "react";

export default function IconLanguage() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <g clipPath="url(#clip0_26_13155)">
        <g filter="url(#filter0_di_26_13155)">
          <path
            d="M4.87648 4.81641H4.65077L4.229 6.92578H5.29825L4.87648 4.81641Z"
            fill="url(#paint0_linear_26_13155)"
          />
        </g>
        <g filter="url(#filter1_di_26_13155)">
          <path
            d="M13.4077 9.03516C13.6354 9.64684 13.9383 10.142 14.2735 10.5633C14.6087 10.142 14.9467 9.6468 15.1744 9.03516H13.4077Z"
            fill="url(#paint1_linear_26_13155)"
          />
        </g>
        <g filter="url(#filter2_di_26_13155)">
          <path
            d="M16.4181 3.19922H9.86909L11.2346 14.1567C11.2588 14.6049 11.1363 15.0268 10.8438 15.3571L8.53125 18H16.4181C17.2905 18 18.0001 17.2903 18.0001 16.418V4.81641C18.0001 3.94404 17.2905 3.19922 16.4181 3.19922ZM16.4181 9.03516H16.2773C15.9772 9.99784 15.5014 10.7511 15.0069 11.345C15.3942 11.6992 15.8085 11.9897 16.2203 12.3151C16.4475 12.4969 16.4845 12.8285 16.3022 13.0562C16.1207 13.2834 15.7878 13.3203 15.5612 13.138C15.1137 12.7848 14.6936 12.4892 14.2736 12.1037C13.8535 12.4892 13.4686 12.7848 13.0211 13.138C12.7945 13.3203 12.4615 13.2834 12.2801 13.0562C12.0977 12.8285 12.1348 12.4969 12.3619 12.3151C12.7738 11.9897 13.1529 11.6992 13.5403 11.345C13.0458 10.7512 12.6051 9.99788 12.305 9.03516H12.1642C11.8727 9.03516 11.6368 8.79929 11.6368 8.50781C11.6368 8.21633 11.8727 7.98047 12.1642 7.98047H13.7462V7.45312C13.7462 7.16164 13.9821 6.92578 14.2736 6.92578C14.565 6.92578 14.8009 7.16164 14.8009 7.45312V7.98047H16.4181C16.7096 7.98047 16.9454 8.21633 16.9454 8.50781C16.9454 8.79929 16.7096 9.03516 16.4181 9.03516Z"
            fill="url(#paint2_linear_26_13155)"
          />
        </g>
        <g filter="url(#filter3_di_26_13155)">
          <path
            d="M8.58389 1.38582C8.48552 0.595828 7.81038 0 7.01423 0H1.58203C0.709664 0 0 0.709664 0 1.58203V13.2539C0 14.1263 0.709664 14.8359 1.58203 14.8359C4.73449 14.8359 7.00232 14.8359 9.89828 14.8359C10.0521 14.6601 10.1811 14.55 10.1865 14.3204C10.1879 14.2629 8.59103 1.44292 8.58389 1.38582ZM6.46678 10.0795C6.18715 10.1371 5.90389 9.95555 5.84624 9.666L5.5092 7.98047H4.01818L3.68114 9.666C3.6245 9.95129 3.34948 10.1387 3.0606 10.0795C2.7753 10.0224 2.58989 9.74482 2.64705 9.459L3.70171 4.18556C3.75114 3.9394 3.96745 3.76172 4.21875 3.76172H5.30859C5.55989 3.76172 5.77621 3.9394 5.82564 4.18556L6.88032 9.459C6.93749 9.74482 6.75211 10.0224 6.46678 10.0795Z"
            fill="url(#paint3_linear_26_13155)"
          />
        </g>
        <g filter="url(#filter4_di_26_13155)">
          <path
            d="M6.16162 15.8906L6.25211 16.6142C6.31244 17.0987 6.6344 17.5929 7.164 17.8396C8.16342 16.7394 7.55877 17.405 8.93443 15.8906H6.16162Z"
            fill="url(#paint4_linear_26_13155)"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_di_26_13155"
          x="-53.771"
          y="-24.1836"
          width="117.069"
          height="118.109"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="29" />
          <feGaussianBlur stdDeviation="29" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_13155" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_26_13155"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="6.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.169764 0 0 0 0 0.624988 0 0 0 0 0.768743 0 0 0 0.03 0"
          />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_26_13155" />
        </filter>
        <filter
          id="filter1_di_26_13155"
          x="-44.5923"
          y="-19.9648"
          width="117.767"
          height="117.528"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="29" />
          <feGaussianBlur stdDeviation="29" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_13155" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_26_13155"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="6.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.169764 0 0 0 0 0.624988 0 0 0 0 0.768743 0 0 0 0.03 0"
          />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_26_13155" />
        </filter>
        <filter
          id="filter2_di_26_13155"
          x="-49.4688"
          y="-25.8008"
          width="125.469"
          height="130.801"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="29" />
          <feGaussianBlur stdDeviation="29" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_13155" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_26_13155"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="6.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.169764 0 0 0 0 0.624988 0 0 0 0 0.768743 0 0 0 0.03 0"
          />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_26_13155" />
        </filter>
        <filter
          id="filter3_di_26_13155"
          x="-58"
          y="-29"
          width="126.187"
          height="130.836"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="29" />
          <feGaussianBlur stdDeviation="29" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_13155" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_26_13155"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="6.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.169764 0 0 0 0 0.624988 0 0 0 0 0.768743 0 0 0 0.03 0"
          />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_26_13155" />
        </filter>
        <filter
          id="filter4_di_26_13155"
          x="-51.8384"
          y="-13.1094"
          width="118.773"
          height="117.949"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="29" />
          <feGaussianBlur stdDeviation="29" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_13155" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_26_13155"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="6.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.169764 0 0 0 0 0.624988 0 0 0 0 0.768743 0 0 0 0.03 0"
          />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_26_13155" />
        </filter>
        <linearGradient
          id="paint0_linear_26_13155"
          x1="4.76362"
          y1="4.81641"
          x2="4.76362"
          y2="7.10156"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_26_13155"
          x1="14.2911"
          y1="9.03516"
          x2="14.2911"
          y2="10.6906"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_26_13155"
          x1="13.2657"
          y1="3.19922"
          x2="13.2657"
          y2="19.2334"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_26_13155"
          x1="5.09326"
          y1="0"
          x2="5.09326"
          y2="16.0723"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_26_13155"
          x1="7.54803"
          y1="15.8906"
          x2="7.54803"
          y2="18.002"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_26_13155">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
