import { API_URL_USER } from "./main";
import { API_URL_SITE } from "./main";
import { cusAxios } from "./main";

const getPreview = (el) => {
  if (el.avatar) return el.avatar;
  if (!el.id) return undefined;
  if (!el.gallery?.[0]) return undefined;
  return (
    `/api/user/${el.id}/gallery/snapshot/${el.gallery?.[0]}/preview/180` ||
    `/api/user/${el.id}/gallery/photo/${el.gallery?.[0]}/preview/180` ||
    ""
  );
};

const list = async ({ filter = undefined, paging = undefined }) => {
  const data = { filter, paging };
  let response = await cusAxios({
    method: "POST",
    url: `${API_URL_SITE}/broadcasters`,
    data: data,
  });
  return response;
};

const getProfile = async (id) => {
  let response = await cusAxios({
    method: "GET",
    url: `${API_URL_USER}/${id}/profile`,
  });
  return response;
};

// utils

function getModals(e) {
  const broadcasters = e.data.broadcasters;
  const allBroadcast = [...broadcasters.data, ...broadcasters.more];
  const models = allBroadcast.map((el) => ({
    id: el.id,
    imgSource: el.avatar || "",
    preview: getPreview(el),
    categories: [],
    lang: el.profile?.languages?.[0],
    name: el.nickname,
    ...el,
  }));
  return models;
}

export const API_BROADCASTER = {
  list: list,
  getProfile: getProfile,
  utils: { getModals },
};
