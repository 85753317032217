import React from "react";

export default function IconTrans({ className }) {
  return (
    <svg
      className={className}
      width="24"
      height="27"
      viewBox="0 0 24 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.2079 4.84863H17.6629C17.2018 4.84863 16.8395 5.21097 16.8395 5.67213C16.8395 6.13328 17.2018 6.49562 17.6629 6.49562H20.1989L17.1688 9.52608C14.8304 7.78027 11.504 7.94497 9.3961 10.0531C7.09062 12.3589 7.09062 16.147 9.3961 18.4528C11.7016 20.7585 15.4891 20.7585 17.7946 18.4528C19.9025 16.3446 20.1001 12.9848 18.3216 10.679L21.3516 7.64851V10.2178C21.3516 10.679 21.7139 11.0413 22.175 11.0413C22.6361 11.0413 22.9984 10.679 22.9984 10.2178V5.67213C23.0313 5.21097 22.669 4.84863 22.2079 4.84863ZM16.6748 17.2999C14.9951 18.9798 12.2615 18.9798 10.5818 17.2999C8.90207 15.6199 8.90207 12.8859 10.5818 11.206C12.2615 9.52608 14.9951 9.52608 16.6748 11.206C18.3545 12.8859 18.3545 15.6199 16.6748 17.2999Z"
        fill="#4B4B4B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5895 5.67213C16.5895 5.07293 17.0637 4.59863 17.6629 4.59863H22.2079C22.8004 4.59863 23.2867 5.06882 23.2484 5.68047V10.2178C23.2484 10.817 22.7742 11.2913 22.175 11.2913C21.5758 11.2913 21.1016 10.817 21.1016 10.2178V8.25213L18.6509 10.7032C20.3638 13.1009 20.1146 16.486 17.9714 18.6295C15.5683 21.033 11.6224 21.033 9.21931 18.6295C6.81622 16.2261 6.81622 12.2798 9.21931 9.87635C11.3656 7.72981 14.7192 7.51747 17.1423 9.199L19.5954 6.74562H17.6629C17.0637 6.74562 16.5895 6.27132 16.5895 5.67213ZM17.6629 5.09863C17.3399 5.09863 17.0895 5.34901 17.0895 5.67213C17.0895 5.99524 17.3399 6.24562 17.6629 6.24562H20.8024L17.1926 9.85585L17.0193 9.72641C14.7769 8.0523 11.5899 8.21262 9.57289 10.2299C7.36503 12.438 7.36503 16.0678 9.57289 18.276C11.7807 20.4841 15.41 20.4841 17.6178 18.276C19.6371 16.2565 19.8251 13.0376 18.1236 10.8317L17.9894 10.6576L21.6016 7.0449V10.2178C21.6016 10.5409 21.852 10.7913 22.175 10.7913C22.498 10.7913 22.7484 10.5409 22.7484 10.2178V5.66321L22.749 5.65432C22.7709 5.34826 22.5346 5.09863 22.2079 5.09863H17.6629ZM16.498 11.3828C14.9159 9.80049 12.3406 9.80049 10.7586 11.3828C9.17647 12.9651 9.17647 15.5408 10.7586 17.1231C12.3406 18.7054 14.9159 18.7054 16.498 17.1231C18.0801 15.5408 18.0801 12.9651 16.498 11.3828ZM10.405 11.0292C12.1823 9.25167 15.0743 9.25167 16.8516 11.0292C18.6289 12.8068 18.6289 15.6991 16.8516 17.4766C15.0743 19.2542 12.1823 19.2542 10.405 17.4766C8.62766 15.6991 8.62766 12.8068 10.405 11.0292Z"
        fill="#4B4B4B"
      />
      <path
        d="M20.1252 13.8085C20.1252 10.4537 17.3308 7.72437 13.896 7.72437C10.4612 7.72437 7.66675 10.4537 7.66675 13.8085C7.66675 16.7688 9.84273 19.2416 12.7143 19.7824V21.3654H10.8237C10.1712 21.3654 9.64209 21.8821 9.64209 22.5195C9.64209 23.1569 10.1712 23.6736 10.8237 23.6736H12.7143V25.7903C12.7143 26.4278 13.2434 26.9444 13.896 26.9444C14.5486 26.9444 15.0776 26.4277 15.0776 25.7903V23.6736H16.9682C17.6208 23.6736 18.1499 23.1569 18.1499 22.5195C18.1499 21.8821 17.6208 21.3654 16.9682 21.3654H15.0776V19.7824C17.9492 19.2416 20.1252 16.7688 20.1252 13.8085ZM10.03 13.8085C10.03 11.7265 11.7642 10.0326 13.896 10.0326C16.0277 10.0326 17.7619 11.7264 17.7619 13.8085C17.7619 15.8905 16.0277 17.5844 13.896 17.5844C11.7643 17.5844 10.03 15.8906 10.03 13.8085Z"
        fill="#4B4B4B"
      />
      <path
        d="M9.1331 18.1589C11.4656 20.5701 15.3718 20.5889 17.8405 18.2007C20.3091 15.8126 20.4199 11.9079 18.0874 9.49674C16.0292 7.36911 12.7459 7.10475 10.306 8.71265L9.20535 7.57488L10.5642 6.26036C11.0332 5.80664 11.0542 5.06738 10.611 4.60928C10.1679 4.15118 9.42834 4.14763 8.95931 4.60135L7.60046 5.91587L6.12876 4.39452C5.68555 3.93638 4.94606 3.93287 4.47703 4.3866C4.008 4.84032 3.98703 5.57958 4.43018 6.03768L5.90189 7.55902L4.54304 8.87354C4.07401 9.32726 4.05304 10.0665 4.4962 10.5246C4.93935 10.9827 5.6789 10.9863 6.14793 10.5325L7.50677 9.21803L8.60743 10.3558C6.91951 12.7411 7.07488 16.0313 9.1331 18.1589ZM16.3888 11.1399C17.8364 12.6363 17.7677 15.0595 16.2356 16.5417C14.7035 18.0238 12.2793 18.0122 10.8317 16.5157C9.38409 15.0193 9.45282 12.5961 10.985 11.114C12.517 9.63186 14.9412 9.64345 16.3888 11.1399Z"
        fill="#4B4B4B"
      />
    </svg>
  );
}
