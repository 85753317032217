import React from "react";

export default function IconFilters() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M12 8.66671H4.00004C3.60004 8.66671 3.33337 8.40004 3.33337 8.00004C3.33337 7.60004 3.60004 7.33337 4.00004 7.33337H12C12.4 7.33337 12.6667 7.60004 12.6667 8.00004C12.6667 8.40004 12.4 8.66671 12 8.66671Z"
        fill="white"
      />
      <path
        d="M10 12.6667H6.00004C5.60004 12.6667 5.33337 12.4 5.33337 12C5.33337 11.6 5.60004 11.3334 6.00004 11.3334H10C10.4 11.3334 10.6667 11.6 10.6667 12C10.6667 12.4 10.4 12.6667 10 12.6667Z"
        fill="white"
      />
      <path
        d="M14 4.66671H2.00004C1.60004 4.66671 1.33337 4.40004 1.33337 4.00004C1.33337 3.60004 1.60004 3.33337 2.00004 3.33337H14C14.4 3.33337 14.6667 3.60004 14.6667 4.00004C14.6667 4.40004 14.4 4.66671 14 4.66671Z"
        fill="white"
      />
    </svg>
  );
}
