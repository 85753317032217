import React from "react";

export default function Simple14() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_740_10579)">
        <path
          d="M12 23.5C18.3513 23.5 23.5 18.3513 23.5 12C23.5 5.64873 18.3513 0.5 12 0.5C5.64873 0.5 0.5 5.64873 0.5 12C0.5 18.3513 5.64873 23.5 12 23.5Z"
          fill="#FFCE52"
        />
        <path
          d="M11.9992 1.99911C18.0742 1.99911 22.9992 6.25261 22.9992 11.4991H23.4867C23.3489 8.54514 22.0784 5.7578 19.9392 3.71607C17.7999 1.67434 14.9564 0.535156 11.9992 0.535156C9.04203 0.535156 6.1985 1.67434 4.05927 3.71607C1.92005 5.7578 0.649561 8.54514 0.511719 11.4991H0.999219C0.999219 6.25261 5.92422 1.99911 11.9992 1.99911Z"
          fill="#FFE369"
        />
        <path
          d="M23 11.5C23 16.7465 18.075 21 12 21C5.925 21 1 16.7465 1 11.5H0.5125C0.5055 11.666 0.5 11.8325 0.5 12C0.5 15.05 1.7116 17.9751 3.86827 20.1317C6.02494 22.2884 8.95001 23.5 12 23.5C15.05 23.5 17.9751 22.2884 20.1317 20.1317C22.2884 17.9751 23.5 15.05 23.5 12C23.5 11.8325 23.4945 11.666 23.4875 11.5H23Z"
          fill="#FFB32B"
        />
        <path
          d="M17.4064 2.90641C17.5674 2.74526 17.5162 2.43297 17.2919 2.20889C17.0677 1.98481 16.7553 1.93379 16.5943 2.09494C16.4333 2.25608 16.4845 2.56837 16.7088 2.79246C16.933 3.01654 17.2453 3.06756 17.4064 2.90641Z"
          fill="#F6FAFD"
        />
        <path
          d="M13.5754 1.95108C13.656 1.8704 13.6305 1.71418 13.5184 1.60214C13.4063 1.4901 13.25 1.46467 13.1694 1.54534C13.0888 1.62601 13.1143 1.78223 13.2264 1.89427C13.3386 2.00632 13.4948 2.03175 13.5754 1.95108Z"
          fill="#F6FAFD"
        />
        <path
          d="M15.8134 2.81353C16.1355 2.49123 16.0329 1.86649 15.5842 1.41813C15.1355 0.969772 14.5107 0.867576 14.1886 1.18987C13.8666 1.51217 13.9692 2.13691 14.4179 2.58527C14.8665 3.03363 15.4913 3.13582 15.8134 2.81353Z"
          fill="#F6FAFD"
        />
        <path
          d="M14.5 12V12.5C14.5 13.055 13.38 13.5 12 13.5C10.62 13.5 9.5 13.055 9.5 12.5V12H14.5Z"
          fill="#F6FAFD"
        />
        <path
          d="M14.5 12.5V16.5C14.5 17.055 14.5 17.5 14 17.5C13.3499 17.273 12.6805 17.1056 12 17C11.3195 17.1056 10.6501 17.273 10 17.5C9.5 17.5 9.5 17.055 9.5 16.5V12.5C9.5 13.055 10.62 13.5 12 13.5C13.38 13.5 14.5 13.055 14.5 12.5Z"
          fill="#AE2D4C"
        />
        <path
          d="M7.50094 7.08984V22.5848C6.41004 22.1187 5.39802 21.4861 4.50094 20.7098V7.99984L4.46094 7.90984C5.42415 7.47639 6.45041 7.19957 7.50094 7.08984Z"
          fill="#3BC5F6"
        />
        <path
          d="M19.54 7.90984L19.5 7.99984V20.7098C18.6029 21.4861 17.5909 22.1187 16.5 22.5848V7.08984C17.5505 7.19957 18.5768 7.47639 19.54 7.90984Z"
          fill="#3BC5F6"
        />
        <path d="M14.5 12H9.5V12.5H14.5V12Z" fill="#EDEDED" />
        <path
          d="M12 13.5C10.62 13.5 9.5 13.055 9.5 12.5V13C9.5 13.555 10.62 14 12 14C13.38 14 14.5 13.555 14.5 13V12.5C14.5 13.055 13.38 13.5 12 13.5Z"
          fill="#8A293D"
        />
        <path
          d="M14 17C13.3499 16.773 12.6805 16.6056 12 16.5C11.3195 16.6056 10.6501 16.773 10 17C9.5 17 9.5 16.555 9.5 16V16.5C9.5 17.055 9.5 17.5 10 17.5C10.6501 17.273 11.3195 17.1056 12 17C12.6805 17.1056 13.3499 17.273 14 17.5C14.5 17.5 14.5 17.055 14.5 16.5V16C14.5 16.555 14.5 17 14 17Z"
          fill="#8A293D"
        />
        <path
          d="M4.5 18.4453V20.7088C4.96959 21.1137 5.47123 21.4799 6 21.8038V21.8103C6.2205 21.9453 6.447 22.0718 6.677 22.1918L6.7175 22.2128C6.973 22.3453 7.2335 22.4698 7.5 22.5838V20.1668C6.98364 19.9673 6.48234 19.7309 6 19.4593C5.4729 19.1632 4.97119 18.8241 4.5 18.4453Z"
          fill="#048EC6"
        />
        <path
          d="M18 19.4593C17.5177 19.7309 17.0164 19.9673 16.5 20.1668V22.5838C16.7665 22.4698 17.0275 22.3453 17.283 22.2123L17.323 22.1918C17.553 22.0718 17.7795 21.9453 18 21.8103V21.8038C18.5288 21.4799 19.0304 21.1137 19.5 20.7088V18.4453C19.0288 18.8241 18.5271 19.1632 18 19.4593Z"
          fill="#048EC6"
        />
        <path
          d="M21.5 9.5H20.5C20.5 8.6 17.962 7.5 15 7.5V6.5C18.126 6.5 21.5 7.647 21.5 9.5Z"
          fill="#273941"
        />
        <path
          d="M3.5 9.5H2.5C2.5 7.647 5.874 6.5 9 6.5V7.5C6.038 7.5 3.5 8.6 3.5 9.5Z"
          fill="#273941"
        />
      </g>
      <defs>
        <clipPath id="clip0_740_10579">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
