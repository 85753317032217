import React from "react";

export default function IconFire() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_16_1296)">
        <path
          d="M14.3554 11.1841C14.1391 9.34058 12.9929 8.01697 11.8845 6.73706L11.7623 6.59583C10.9624 5.67041 10.4456 4.8479 10.1363 4.00745C9.8641 3.26807 9.83126 2.51184 9.79635 1.71106C9.78268 1.39514 9.7684 1.06848 9.73885 0.737183L9.67318 0L9.03439 0.373779C7.83895 1.07324 6.88253 1.98547 6.19198 3.08533C5.64034 3.96375 5.25826 4.96021 5.05624 6.04724C4.86324 7.08545 4.87191 8.00244 4.91842 8.6156L2.22543 7.65381L2.0798 8.12012C1.20809 10.9095 1.95223 12.8857 2.72994 14.0524C3.5732 15.3173 4.62484 15.9153 4.66927 15.9402L4.77609 16H6.59042L6.4836 15.9402C6.42294 15.9061 5.87826 15.5891 5.43331 14.9218C5.01608 14.2959 4.61605 13.2385 5.07955 11.7555L5.22518 11.2892L6.47482 11.7354C6.47274 11.4537 6.49264 11.1139 6.561 10.7461C6.80209 9.4491 7.52865 8.41296 8.66232 7.74976L9.30111 7.37598L9.36678 8.11304C9.38204 8.28406 9.38924 8.4502 9.3962 8.61072C9.4128 8.99231 9.42855 9.35266 9.55196 9.68799C9.69662 10.0809 9.94137 10.4691 10.3222 10.9098L10.3834 10.9803C10.9338 11.6161 11.5578 12.3367 11.6744 13.3301C11.776 14.1954 11.3526 15.2922 10.6898 15.8815L10.5564 16H12.4072L12.5405 15.8815C13.1339 15.354 13.6456 14.5771 13.9812 13.694C14.3106 12.8269 14.4436 11.9355 14.3554 11.1841Z"
          fill="white"
          fillOpacity="0.6"
        />
        <path
          d="M6.85034 15.0614H10.1844C10.5531 14.6483 10.8016 13.9463 10.7422 13.4395C10.6592 12.7328 10.1805 12.1801 9.67383 11.5947L9.61218 11.5236C9.15503 10.9948 8.85595 10.5145 8.67114 10.0122C8.54944 9.68152 8.50317 9.36121 8.47998 9.04895C7.08203 10.3718 7.45776 12.3058 7.4624 12.3278L7.63037 13.1448L5.85888 12.5121C5.59326 14.0424 6.53845 14.843 6.85034 15.0614Z"
          fill="white"
          fillOpacity="0.6"
        />
      </g>
      <defs>
        <clipPath id="clip0_16_1296">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
