import React from "react";

export default function IconGroup() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="195"
      height="146"
      viewBox="0 0 195 146"
      fill="none"
    >
      <path
        d="M99.1935 22.6855C85.8487 22.6855 74.9312 33.618 74.9312 46.9701C74.9312 60.3222 85.8536 71.2547 99.1935 71.2547C112.533 71.2547 123.456 60.3173 123.456 46.9701C123.461 33.618 112.533 22.6855 99.1935 22.6855ZM42.6846 129.748C42.6748 132.482 44.8858 134.696 47.6176 134.686H150.769C153.501 134.696 155.712 132.478 155.702 129.748C155.702 102.823 136.855 80.2249 111.595 74.5644C109.742 74.1316 107.807 74.8103 106.622 76.3054L99.6946 85.1625L92.806 76.1578C91.9069 74.9579 90.5017 74.2398 88.4774 74.2546C62.1269 79.5413 42.6846 102.419 42.6846 129.748ZM148.814 25.7641C138.623 25.7641 130.246 34.1197 130.246 44.3144C130.246 54.5043 138.623 62.8992 148.814 62.8992C158.999 62.8992 167.342 54.5043 167.342 44.3144C167.342 34.1197 158.999 25.7641 148.814 25.7641ZM127.539 69.7842C144.175 77.6775 156.965 92.2934 162.498 110.067H185.192C187.895 110.052 190.076 107.873 190.086 105.168C190.086 85.5117 176.334 68.9728 157.879 64.8368C156.017 64.4139 154.081 65.1073 152.907 66.6073L149.172 71.3678L145.468 66.4991C144.559 65.2893 143.139 64.5614 141.1 64.5959C135.882 65.6089 131.494 67.3794 127.539 69.7842Z"
        fill="url(#paint0_linear_1051_18787)"
      />
      <path
        d="M48.0278 25.8184C58.2181 25.8184 66.5955 34.1738 66.5955 44.3685C66.5955 54.5583 58.2181 62.9531 48.0278 62.9531C37.8423 62.9531 29.4994 54.5583 29.4994 44.3685C29.4994 34.1738 37.8423 25.8184 48.0278 25.8184ZM69.3027 69.8381C52.666 77.7313 39.8765 92.3472 34.344 110.12H11.6491C8.9467 110.106 6.76516 107.927 6.75533 105.222C6.75533 85.5654 20.5079 69.0267 38.9626 64.8907C40.8248 64.4678 42.7606 65.1612 43.9349 66.6612L47.6691 71.4217L51.3738 66.553C52.2828 65.3432 53.7027 64.6153 55.7418 64.6498C60.9598 65.6628 65.3475 67.4333 69.3027 69.8381Z"
        fill="url(#paint1_linear_1051_18787)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1051_18787"
          x1="116.385"
          y1="22.6855"
          x2="114.695"
          y2="167.381"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#707070" />
          <stop offset="1" stopColor="#484848" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1051_18787"
          x1="38.029"
          y1="25.8184"
          x2="40.2858"
          y2="134.698"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#707070" />
          <stop offset="1" stopColor="#484848" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
