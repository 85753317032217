import { createSlice } from "@reduxjs/toolkit";

const homeSlice = createSlice({
  name: "home",
  initialState: {
    models: [],
    currentPage: 1,
    total: 0,
  },
  reducers: {
    setModels(state, newAction) {
      state.models = newAction.payload;
    },
    setCurrentPage(state, newAction) {
      state.currentPage = newAction.payload;
    },
    setTotal(state, newAction) {
      state.total = newAction.payload;
    },
  },
});

export default homeSlice.reducer;

export const { setModels, setCurrentPage, setTotal } = homeSlice.actions;
