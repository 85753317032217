import React from "react";

export default function IconDildo() {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_16_1317)">
        <path
          d="M12.75 14.9414H4.25C3.38815 14.9414 2.68945 15.6401 2.68945 16.502C2.68945 16.777 2.91245 17 3.1875 17H13.8125C14.0876 17 14.3105 16.777 14.3105 16.502C14.3105 15.6401 13.6119 14.9414 12.75 14.9414Z"
          fill="white"
          fillOpacity="0.6"
        />
        <path
          d="M8.64796 2.75148L10.8897 4.05915C11.3375 4.32039 11.8999 3.99736 11.8999 3.47889C11.8999 3.3746 11.8756 3.27174 11.829 3.17847L10.619 0.758393C10.3865 0.293615 9.91147 0 9.3918 0C8.57647 0 7.91553 0.660941 7.91553 1.47628C7.91553 2.00135 8.1944 2.48691 8.64796 2.75148Z"
          fill="white"
          fillOpacity="0.6"
        />
        <path
          d="M4.65322 13.3974C4.82116 13.7332 5.16442 13.9454 5.53991 13.9454H11.1563C11.7365 13.9454 12.1939 13.4514 12.1494 12.8729L12.0125 11.0922C11.9392 10.1394 11.7658 9.19696 11.4952 8.28048C11.1905 7.24823 10.9837 6.19018 10.9228 5.11878C10.7357 5.08362 10.5544 5.01682 10.3877 4.9196L8.14602 3.61196C8.00368 3.52892 7.87153 3.43253 7.75071 3.32495C7.34616 5.48 7.56686 7.70637 8.28445 9.80049C8.45471 10.2974 8.57248 10.8103 8.64208 11.3308L7.03684 10.2607C6.74379 10.0653 6.39944 9.96103 6.04722 9.96103C5.06192 9.96103 4.26318 10.7598 4.26318 11.7451C4.26318 12.3186 4.39673 12.8843 4.65322 13.3974Z"
          fill="white"
          fillOpacity="0.6"
        />
      </g>
      <defs>
        <clipPath id="clip0_16_1317">
          <rect width="17" height="17" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
