import React from "react";

export default function Smile9() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_740_10503)">
        <path
          d="M12 23.5C18.3513 23.5 23.5 18.3513 23.5 12C23.5 5.64873 18.3513 0.5 12 0.5C5.64873 0.5 0.5 5.64873 0.5 12C0.5 18.3513 5.64873 23.5 12 23.5Z"
          fill="#FFCE52"
        />
        <path
          d="M3.68555 14.5H20.3155C19.9486 15.8806 19.1323 17.0998 17.9955 17.965C17.9355 16.885 15.0055 16.5 12.0005 16.5C8.65555 16.5 6.05555 16.885 6.00555 17.965C4.8688 17.0998 4.05253 15.8806 3.68555 14.5Z"
          fill="#273941"
        />
        <path
          d="M17.9959 17.965C17.9009 18.04 17.8009 18.115 17.7009 18.19C16.0335 19.3677 14.0422 20 12.0009 20C9.95949 20 7.96824 19.3677 6.30086 18.19C6.20086 18.115 6.10086 18.04 6.00586 17.965C6.05586 16.885 8.65586 16.5 12.0009 16.5C15.0059 16.5 17.9359 16.885 17.9959 17.965Z"
          fill="#AE2D4C"
        />
        <path
          d="M20.5 13C20.5014 13.5058 20.4392 14.0097 20.315 14.5H3.68502C3.56082 14.0097 3.49867 13.5058 3.50002 13C3.50002 12.445 7.30002 12 12 12C16.7 12 20.5 12.445 20.5 13Z"
          fill="#F6FAFD"
        />
        <path
          d="M18.7765 9.95C17.882 9.5 16.8585 9.5 15 9.5C14.8674 9.5 14.7402 9.44732 14.6464 9.35355C14.5527 9.25979 14.5 9.13261 14.5 9C14.5 7.8675 16.284 6.5 18.5 6.5V7.5C17.5022 7.47467 16.5326 7.83252 15.7905 8.5C17.3195 8.509 18.272 8.5755 19.2235 9.05L18.7765 9.95Z"
          fill="#273941"
        />
        <path
          d="M5.22434 9.95L4.77734 9.055C5.72734 8.5795 6.68134 8.513 8.21034 8.505C7.46901 7.8357 6.49932 7.47603 5.50084 7.5V6.5C7.71684 6.5 9.50084 7.8675 9.50084 9C9.50084 9.13261 9.44817 9.25979 9.3544 9.35355C9.26063 9.44732 9.13345 9.5 9.00084 9.5C7.14234 9.5 6.11884 9.5 5.22434 9.95Z"
          fill="#273941"
        />
        <path
          d="M11.9992 1.99911C18.0742 1.99911 22.9992 6.25261 22.9992 11.4991H23.4867C23.3489 8.54514 22.0784 5.7578 19.9392 3.71607C17.7999 1.67434 14.9564 0.535156 11.9992 0.535156C9.04203 0.535156 6.1985 1.67434 4.05927 3.71607C1.92005 5.7578 0.649561 8.54514 0.511719 11.4991H0.999219C0.999219 6.25261 5.92422 1.99911 11.9992 1.99911Z"
          fill="#FFE369"
        />
        <path
          d="M23 11.5C23 16.7465 18.075 21 12 21C5.925 21 1 16.7465 1 11.5H0.5125C0.5055 11.666 0.5 11.8325 0.5 12C0.5 15.05 1.7116 17.9751 3.86827 20.1317C6.02494 22.2884 8.95001 23.5 12 23.5C15.05 23.5 17.9751 22.2884 20.1317 20.1317C22.2884 17.9751 23.5 15.05 23.5 12C23.5 11.8325 23.4945 11.666 23.4875 11.5H23Z"
          fill="#FFB32B"
        />
        <path
          d="M18.9064 4.90641C19.0674 4.74526 19.0162 4.43297 18.7919 4.20889C18.5677 3.98481 18.2553 3.93379 18.0943 4.09494C17.9333 4.25608 17.9845 4.56837 18.2088 4.79246C18.433 5.01654 18.7453 5.06756 18.9064 4.90641Z"
          fill="#F6FAFD"
        />
        <path
          d="M15.5754 2.45498C15.656 2.37431 15.6305 2.21809 15.5184 2.10604C15.4063 1.994 15.25 1.96857 15.1694 2.04924C15.0888 2.12992 15.1143 2.28614 15.2264 2.39818C15.3386 2.51022 15.4948 2.53565 15.5754 2.45498Z"
          fill="#F6FAFD"
        />
        <path
          d="M17.8134 4.31353C18.1355 3.99123 18.0329 3.36649 17.5842 2.91813C17.1355 2.46977 16.5107 2.36758 16.1886 2.68987C15.8666 3.01217 15.9692 3.63691 16.4179 4.08527C16.8665 4.53363 17.4913 4.63582 17.8134 4.31353Z"
          fill="#F6FAFD"
        />
        <path
          d="M6.46705 17.3C6.41255 17.2625 6.35405 17.2295 6.30055 17.1905C6.20055 17.1155 6.10055 17.0405 6.00555 16.9655C5.16193 16.3123 4.48653 15.467 4.03555 14.5H3.68555C4.05253 15.8806 4.8688 17.0998 6.00555 17.965C6.02198 17.8248 6.07268 17.6908 6.15317 17.5748C6.23365 17.4588 6.34145 17.3645 6.46705 17.3Z"
          fill="#141E21"
        />
        <path
          d="M17.9943 16.965C17.8993 17.04 17.7993 17.115 17.6993 17.19C17.6378 17.2345 17.5708 17.273 17.5078 17.316C17.6362 17.3758 17.7478 17.4665 17.8327 17.5799C17.9176 17.6933 17.9731 17.8259 17.9943 17.966C19.1312 17.1005 19.9475 15.881 20.3143 14.5H19.9643C19.5133 15.4668 18.8379 16.3119 17.9943 16.965Z"
          fill="#141E21"
        />
        <path
          d="M17.5074 17.3168C15.8747 18.4031 13.96 18.9884 11.9989 19.0008C10.0276 18.9889 8.10332 18.3978 6.46541 17.3008C6.33975 17.3653 6.23191 17.4597 6.15142 17.5758C6.07093 17.6919 6.02026 17.826 6.00391 17.9663C6.09891 18.0413 6.19891 18.1163 6.29891 18.1913C7.96629 19.369 9.95754 20.0013 11.9989 20.0013C14.0403 20.0013 16.0315 19.369 17.6989 18.1913C17.7989 18.1163 17.8989 18.0413 17.9939 17.9663C17.9726 17.8263 17.9171 17.6938 17.8322 17.5805C17.7473 17.4671 17.6357 17.3766 17.5074 17.3168Z"
          fill="#8A293D"
        />
        <path
          d="M20.4325 13.879C20.4759 13.588 20.4984 13.2942 20.5 13C20.5 12.445 16.7 12 12 12C7.3 12 3.5 12.445 3.5 13C3.50159 13.2942 3.52415 13.588 3.5675 13.879C4.074 13.382 7.65 13 12 13C16.35 13 19.926 13.382 20.4325 13.879Z"
          fill="#EDEDED"
        />
      </g>
      <defs>
        <clipPath id="clip0_740_10503">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
