import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { checkDevServer } from "../../utils/utils";

export const errorToast = (msg, dop = {}) => toast(msg, { type: "error", ...dop });

export const errorDevToast = (msg, dop = {}) => {
  if (checkDevServer()) toast(msg, { type: "error", ...dop });
};

export const successToast = (msg, dop = {}) => toast(msg, { type: "success", ...dop });

export const infoToast = (msg, dop = {}) => toast(msg, { type: "info", ...dop });

export default function Toaster() {
  return <ToastContainer position="bottom-right" theme="dark" />;
}
