import React from "react";

export default function Simple22() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_740_10776)">
        <path
          d="M12 23.5C18.3513 23.5 23.5 18.3513 23.5 12C23.5 5.64873 18.3513 0.5 12 0.5C5.64873 0.5 0.5 5.64873 0.5 12C0.5 18.3513 5.64873 23.5 12 23.5Z"
          fill="#FFCE52"
        />
        <path
          d="M10.5 18.007L9.5 18C9.5 16.421 12.9575 16 15 16V17C12.0705 17 10.5735 17.7295 10.5 18.007Z"
          fill="#273941"
        />
        <path
          d="M19.57 7.96C19.549 7.923 19.523 7.889 19.5 7.853V8.5C19.5 8.76522 19.3947 9.01957 19.2071 9.20711C19.0196 9.39464 18.7652 9.5 18.5 9.5C18.2348 9.5 17.9804 9.39464 17.7929 9.20711C17.6054 9.01957 17.5 8.76522 17.5 8.5V7.5H14.77C14.273 8.04737 13.9984 8.76066 14 9.5C14 10.2956 14.3161 11.0587 14.8787 11.6213C15.4413 12.1839 16.2044 12.5 17 12.5C17.7957 12.5 18.5587 12.1839 19.1213 11.6213C19.6839 11.0587 20 10.2956 20 9.5C20.0019 8.95677 19.8531 8.42366 19.57 7.96Z"
          fill="#F6FAFD"
        />
        <path
          d="M18.5 9.5C18.7652 9.5 19.0196 9.39464 19.2071 9.20711C19.3946 9.01957 19.5 8.76522 19.5 8.5V7.853C19.4204 7.72775 19.33 7.60964 19.23 7.5H17.5V8.5C17.5 8.76522 17.6054 9.01957 17.7929 9.20711C17.9804 9.39464 18.2348 9.5 18.5 9.5Z"
          fill="#141E21"
        />
        <path
          d="M9.57001 7.96C9.54901 7.923 9.52301 7.889 9.50001 7.853V8.5C9.50001 8.76522 9.39465 9.01957 9.20711 9.20711C9.01958 9.39464 8.76522 9.5 8.50001 9.5C8.23479 9.5 7.98044 9.39464 7.7929 9.20711C7.60536 9.01957 7.50001 8.76522 7.50001 8.5V7.5H4.77001C4.273 8.04737 3.99838 8.76066 4.00001 9.5C4.00001 10.2956 4.31608 11.0587 4.87869 11.6213C5.4413 12.1839 6.20436 12.5 7.00001 12.5C7.79566 12.5 8.55872 12.1839 9.12133 11.6213C9.68394 11.0587 10 10.2956 10 9.5C10.0019 8.95677 9.85307 8.42366 9.57001 7.96Z"
          fill="#F6FAFD"
        />
        <path
          d="M8.5 9.5C8.76522 9.5 9.01957 9.39464 9.20711 9.20711C9.39464 9.01957 9.5 8.76522 9.5 8.5V7.853C9.42038 7.72775 9.33004 7.60964 9.23 7.5H7.5V8.5C7.5 8.76522 7.60536 9.01957 7.79289 9.20711C7.98043 9.39464 8.23478 9.5 8.5 9.5Z"
          fill="#141E21"
        />
        <path
          d="M11.9992 1.99911C18.0742 1.99911 22.9992 6.25261 22.9992 11.4991H23.4867C23.3489 8.54514 22.0784 5.7578 19.9392 3.71607C17.7999 1.67434 14.9564 0.535156 11.9992 0.535156C9.04203 0.535156 6.1985 1.67434 4.05927 3.71607C1.92005 5.7578 0.649561 8.54514 0.511719 11.4991H0.999219C0.999219 6.25261 5.92422 1.99911 11.9992 1.99911Z"
          fill="#FFE369"
        />
        <path
          d="M23 11.5C23 16.7465 18.075 21 12 21C5.925 21 1 16.7465 1 11.5H0.5125C0.5055 11.666 0.5 11.8325 0.5 12C0.5 15.05 1.7116 17.9751 3.86827 20.1317C6.02494 22.2884 8.95001 23.5 12 23.5C15.05 23.5 17.9751 22.2884 20.1317 20.1317C22.2884 17.9751 23.5 15.05 23.5 12C23.5 11.8325 23.4945 11.666 23.4875 11.5H23Z"
          fill="#FFB32B"
        />
        <path
          d="M18.6564 4.65641C18.8174 4.49526 18.7662 4.18297 18.5419 3.95889C18.3177 3.73481 18.0053 3.68379 17.8443 3.84494C17.6833 4.00608 17.7345 4.31837 17.9588 4.54246C18.183 4.76654 18.4953 4.81756 18.6564 4.65641Z"
          fill="#F6FAFD"
        />
        <path
          d="M15.3254 2.20108C15.406 2.1204 15.3805 1.96418 15.2684 1.85214C15.1563 1.7401 15 1.71467 14.9194 1.79534C14.8388 1.87601 14.8643 2.03223 14.9764 2.14427C15.0886 2.25632 15.2448 2.28175 15.3254 2.20108Z"
          fill="#F6FAFD"
        />
        <path
          d="M17.5634 4.06353C17.8855 3.74123 17.7829 3.11649 17.3342 2.66813C16.8855 2.21977 16.2607 2.11758 15.9386 2.43987C15.6166 2.76217 15.7192 3.38691 16.1679 3.83527C16.6165 4.28363 17.2413 4.38582 17.5634 4.06353Z"
          fill="#F6FAFD"
        />
        <path
          d="M16.9994 12.5009C16.2922 12.5003 15.608 12.2498 15.0675 11.7938C14.527 11.3377 14.165 10.7054 14.0454 10.0084C13.9695 10.4399 13.989 10.8829 14.1026 11.306C14.2162 11.7292 14.4212 12.1223 14.703 12.4578C14.9849 12.7933 15.3368 13.063 15.734 13.2479C16.1312 13.4328 16.5641 13.5284 17.0023 13.5281C17.4404 13.5277 17.8732 13.4314 18.2702 13.2459C18.6671 13.0604 19.0186 12.7902 19.2999 12.4543C19.5812 12.1184 19.7856 11.7249 19.8986 11.3016C20.0115 10.8783 20.0304 10.4353 19.9539 10.0039C19.8352 10.7018 19.4735 11.3352 18.9328 11.7922C18.3921 12.2492 17.7073 12.5002 16.9994 12.5009Z"
          fill="#FBB40A"
        />
        <path
          d="M6.99935 12.5009C6.29217 12.5003 5.60796 12.2498 5.06747 11.7938C4.52699 11.3377 4.16498 10.7054 4.04535 10.0084C3.96945 10.4399 3.989 10.8829 4.10262 11.306C4.21624 11.7292 4.42118 12.1223 4.70302 12.4578C4.98486 12.7933 5.33676 13.063 5.73399 13.2479C6.13122 13.4328 6.56413 13.5284 7.00229 13.5281C7.44044 13.5277 7.8732 13.4314 8.27015 13.2459C8.6671 13.0604 9.01859 12.7902 9.29992 12.4543C9.58125 12.1184 9.78558 11.7249 9.89856 11.3016C10.0115 10.8783 10.0304 10.4353 9.95385 10.0039C9.83516 10.7018 9.47349 11.3352 8.93281 11.7922C8.39213 12.2492 7.70727 12.5002 6.99935 12.5009Z"
          fill="#FBB40A"
        />
        <path
          d="M17.5 7.5H19.23C19.33 7.60964 19.4204 7.72775 19.5 7.853C19.523 7.889 19.55 7.923 19.57 7.96C19.8132 8.36266 19.9558 8.81803 19.9855 9.2875C19.9945 9.193 20 9.097 20 9C20.0019 8.45677 19.8531 7.92366 19.57 7.46C19.549 7.423 19.523 7.389 19.5 7.353C19.4204 7.22775 19.33 7.10964 19.23 7H14.77C14.273 7.54737 13.9984 8.26066 14 9C14.0001 9.10589 14.0058 9.21171 14.017 9.317C14.0538 8.64327 14.3195 8.00228 14.77 7.5H17.5Z"
          fill="#FBB40A"
        />
        <path
          d="M7.50001 7.5H9.23001C9.33004 7.60964 9.42038 7.72775 9.50001 7.853C9.52301 7.889 9.55001 7.923 9.57001 7.96C9.81322 8.36266 9.95575 8.81803 9.98551 9.2875C9.99451 9.193 10 9.097 10 9C10.0019 8.45677 9.85307 7.92366 9.57001 7.46C9.54901 7.423 9.52301 7.389 9.50001 7.353C9.42038 7.22775 9.33004 7.10964 9.23001 7H4.77001C4.273 7.54737 3.99838 8.26066 4.00001 9C4.0001 9.10589 4.00578 9.21171 4.01701 9.317C4.05384 8.64327 4.31948 8.00228 4.77001 7.5H7.50001Z"
          fill="#FBB40A"
        />
      </g>
      <defs>
        <clipPath id="clip0_740_10776">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
