import React from "react";

export default function IconStartChat() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <g clipPath="url(#clip0_26_14333)">
        <g filter="url(#filter0_di_26_14333)">
          <path
            d="M12.5338 0.871948H1.46738C0.657104 0.871948 0 1.52905 0 2.33962V9.59085C0 10.4008 0.657104 11.0582 1.46738 11.0582H9.87439L11.4894 12.6928C11.7703 12.9775 12.1488 13.1278 12.5329 13.1278C12.7209 13.1278 12.9097 13.0918 13.0901 13.0179C13.6405 12.7922 13.9994 12.2561 13.9994 11.6598V2.33962C14.0003 1.52905 13.3435 0.871948 12.5338 0.871948ZM9.21641 8.21234C9.26289 8.48448 9.15094 8.75807 8.92907 8.91972C8.8028 9.01121 8.65431 9.05857 8.50435 9.05857C8.3886 9.05857 8.27372 9.03109 8.1682 8.97526L7.00044 8.36171L5.83297 8.97526C5.58918 9.10329 5.29425 9.08166 5.0718 8.9203C4.84936 8.75924 4.7377 8.48506 4.78417 8.21351L4.79937 8.12319L5.0072 6.91362L4.06276 5.99286C3.86575 5.80081 3.79472 5.51348 3.87978 5.25186C3.96484 4.98996 4.19108 4.79937 4.46351 4.75991L5.76837 4.5702L6.14515 3.80699L6.3521 3.38783C6.474 3.14083 6.72509 2.98474 7.00044 2.98474C7.27521 2.98474 7.52688 3.14083 7.64877 3.38783L7.85573 3.80699L8.23251 4.5702L9.53795 4.75991C9.81038 4.79937 10.036 4.98996 10.1211 5.25186C10.2062 5.51377 10.1357 5.80081 9.93812 5.99286L8.99397 6.91362L9.2018 8.12319L9.21641 8.21234Z"
            fill="url(#paint0_linear_26_14333)"
            shapeRendering="crispEdges"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_di_26_14333"
          x="-58"
          y="-28.1281"
          width="130"
          height="128.256"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="29" />
          <feGaussianBlur stdDeviation="29" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_14333" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_26_14333"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="6.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.169764 0 0 0 0 0.624988 0 0 0 0 0.768743 0 0 0 0.03 0"
          />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_26_14333" />
        </filter>
        <linearGradient
          id="paint0_linear_26_14333"
          x1="6.99971"
          y1="0.871948"
          x2="6.99971"
          y2="14.1491"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_26_14333">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
