import React from "react";
import Smile1 from "../images/jsIcons/smile/Smile1";
import Smile2 from "../images/jsIcons/smile/Smile2";
import Smile3 from "../images/jsIcons/smile/Smile3";
import Smile4 from "../images/jsIcons/smile/Smile4";
import Smile5 from "../images/jsIcons/smile/Smile5";
import Smile6 from "../images/jsIcons/smile/Smile6";
import Smile7 from "../images/jsIcons/smile/Smile7";
import Smile8 from "../images/jsIcons/smile/Smile8";
import Smile9 from "../images/jsIcons/smile/Smile9";
import Smile10 from "../images/jsIcons/smile/Smile10";
import Smile11 from "../images/jsIcons/smile/Smile11";
import Smile12 from "../images/jsIcons/smile/Smile12";
import Smile13 from "../images/jsIcons/smile/Smile13";
import Smile14 from "../images/jsIcons/smile/Smile14";
import Smile15 from "../images/jsIcons/smile/Smile15";
import Smile16 from "../images/jsIcons/smile/Smile16";
import Smile17 from "../images/jsIcons/smile/Smile17";
import Smile18 from "../images/jsIcons/smile/Smile18";
import Smile19 from "../images/jsIcons/smile/Smile19";
import Smile20 from "../images/jsIcons/smile/Smile20";
import Smile21 from "../images/jsIcons/smile/Smile21";
import Smile22 from "../images/jsIcons/smile/Smile22";
import Smile23 from "../images/jsIcons/smile/Smile23";
import Smile24 from "../images/jsIcons/smile/Smile24";
import Smile25 from "../images/jsIcons/smile/Smile25";
import Smile26 from "../images/jsIcons/smile/Smile26";
import Smile27 from "../images/jsIcons/smile/Smile27";
import Smile28 from "../images/jsIcons/smile/Smile28";
import Smile29 from "../images/jsIcons/smile/Smile29";
import Smile30 from "../images/jsIcons/smile/Smile30";

export default function useSmile() {
  const SMILE_TO_IMGSMILE = {
    "😗": <Smile1 key={1} />,
    "😘": <Smile2 key={2} />,
    "😂": <Smile3 key={3} />,
    "😄": <Smile4 key={4} />,
    "😷": <Smile5 key={5} />,
    "🤑": <Smile6 key={6} />,
    "😰": <Smile7 key={7} />,
    "😙": <Smile8 key={8} />,
    "😆": <Smile9 key={9} />,
    "😍": <Smile10 key={10} />,
    "😊": <Smile11 key={11} />,
    "🤤": <Smile12 key={12} />,
    "😡": <Smile13 key={13} />,
    "😭": <Smile14 key={14} />,
    "😋": <Smile15 key={15} />,
    "😈": <Smile16 key={16} />,
    "😅": <Smile17 key={17} />,
    "🤔": <Smile18 key={18} />,
    "😑": <Smile19 key={19} />,
    "🤮": <Smile20 key={20} />,
    "😢": <Smile21 key={21} />,
    "😕": <Smile22 key={22} />,
    "🙂": <Smile23 key={23} />,
    "😴": <Smile24 key={24} />,
    "😏": <Smile25 key={25} />,
    "😀": <Smile26 key={26} />,
    "🤪": <Smile27 key={27} />,
    "😝": <Smile28 key={28} />,
    "😉": <Smile29 key={29} />,
    "😮": <Smile30 key={30} />,
  };

  // TODO в будущем возможно будут свои смайлы
  const replaceEmojiWithSvg = (text) => {
    return text;
    // const resText = text
    //   .split(/([😗😘😂😄😷🤑😰😙😆😍😊🤤😡😭😋😈😅🤔😑🤮😢😕🙂😴😏😀🤪😝😉😮])/u)
    //   .map((part, index) =>
    //     SMILE_TO_IMGSMILE[part] ? React.cloneElement(SMILE_TO_IMGSMILE[part], { key: index }) : part
    //   );
    //  return resText;
  };

  return { SMILE_TO_IMGSMILE, replaceEmojiWithSvg };
}
