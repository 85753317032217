import React from "react";

export default function IconLoveCircle() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_26_14362)">
        <path
          d="M7 0C3.134 0 0 3.13403 0 7C0 10.866 3.134 14 7 14C10.866 14 14 10.866 14 7C14 3.13403 10.866 0 7 0ZM7 10.396C7 10.396 3.34767 8.12801 3.34767 6.10408C3.34767 5.00555 4.2382 4.11502 5.33673 4.11502C6.03252 4.11502 6.64453 4.47256 7 5.01367C7.35547 4.47254 7.96745 4.11502 8.66327 4.11502C9.76177 4.11502 10.6523 5.00555 10.6523 6.10408C10.6523 8.12801 7 10.396 7 10.396Z"
          fill="url(#paint0_linear_26_14362)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_26_14362"
          x1="7"
          y1="0"
          x2="7"
          y2="15.1667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181" />
          <stop offset="1" stopColor="#818181" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_26_14362">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
