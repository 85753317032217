import React from "react";

export default function IconGirls({ className }) {
  return (
    <svg
      className={className}
      width="14"
      height="22"
      viewBox="0 0 14 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2616 7.31643C13.1181 3.82845 10.0879 1.11064 6.50683 1.25801C2.92579 1.40537 0.129105 4.36297 0.272641 7.85095C0.399296 10.9287 2.77373 13.4063 5.79078 13.8454L5.85851 15.4912L3.8874 15.5724C3.20703 15.6004 2.67755 16.1603 2.70482 16.823C2.73209 17.4857 3.30579 18.0002 3.98616 17.9722L5.95727 17.8911L6.04783 20.0919C6.0751 20.7546 6.6488 21.2691 7.32917 21.2411C8.00953 21.2131 8.53905 20.6531 8.51178 19.9905L8.42121 17.7897L10.3923 17.7086C11.0727 17.6806 11.6022 17.1207 11.5749 16.458C11.5476 15.7953 10.9739 15.2807 10.2936 15.3087L8.32246 15.3898L8.25473 13.744C11.2255 13.0585 13.3883 10.3942 13.2616 7.31643ZM2.73656 7.74955C2.64748 5.58487 4.19549 3.84097 6.41798 3.74951C8.64047 3.65805 10.7086 5.25308 10.7977 7.41782C10.8867 9.5825 9.15116 11.418 6.92867 11.5095C4.70624 11.6009 2.82564 9.91426 2.73656 7.74955Z"
        fill="#4B4B4B"
      />
    </svg>
  );
}
