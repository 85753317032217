import React from "react";

export default function Smile5() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_740_10412)">
        <path
          d="M12 23.5C18.3513 23.5 23.5 18.3513 23.5 12C23.5 5.64873 18.3513 0.5 12 0.5C5.64873 0.5 0.5 5.64873 0.5 12C0.5 18.3513 5.64873 23.5 12 23.5Z"
          fill="#FFCE52"
        />
        <path
          d="M11.9992 1.99911C18.0742 1.99911 22.9992 6.25261 22.9992 11.4991H23.4867C23.3489 8.54514 22.0784 5.7578 19.9392 3.71607C17.7999 1.67434 14.9564 0.535156 11.9992 0.535156C9.04203 0.535156 6.1985 1.67434 4.05927 3.71607C1.92005 5.7578 0.649561 8.54514 0.511719 11.4991H0.999219C0.999219 6.25261 5.92422 1.99911 11.9992 1.99911Z"
          fill="#FFE369"
        />
        <path
          d="M23 11.5C23 16.7465 18.075 21 12 21C5.925 21 1 16.7465 1 11.5H0.5125C0.5055 11.666 0.5 11.8325 0.5 12C0.5 15.05 1.7116 17.9751 3.86827 20.1317C6.02494 22.2884 8.95001 23.5 12 23.5C15.05 23.5 17.9751 22.2884 20.1317 20.1317C22.2884 17.9751 23.5 15.05 23.5 12C23.5 11.8325 23.4945 11.666 23.4875 11.5H23Z"
          fill="#FFB32B"
        />
        <path
          d="M6.5 16.3V14.2C6.5 13.5375 7.2035 13 8.0715 13H15.9285C16.7965 13 17.5 13.5375 17.5 14.2V16.3C17.5 17.129 15.04 19 12 19C8.96 19 6.5 17.129 6.5 16.3Z"
          fill="#88D6F9"
        />
        <path
          d="M4.5 16.5011V13.0011C4.51969 12.452 4.75634 11.9331 5.15807 11.5581C5.55979 11.1832 6.0938 10.9829 6.643 11.0011H17.357C17.9062 10.9829 18.4402 11.1832 18.8419 11.5581C19.2437 11.9331 19.4803 12.452 19.5 13.0011V16.5011C19.5 17.8831 16.1455 21.0011 12 21.0011C7.8545 21.0011 4.5 17.8831 4.5 16.5011Z"
          fill="#E5EBF7"
        />
        <path
          d="M6 16.35V13.9C6 13.127 6.7675 12.5 7.7145 12.5H16.2855C17.2325 12.5 18 13.127 18 13.9V16.35C18 17.3175 15.3165 19.5 12 19.5C8.6835 19.5 6 17.3175 6 16.35Z"
          fill="#CCEDFC"
        />
        <path
          d="M17.357 11.0011H6.643C6.0938 10.9829 5.55979 11.1832 5.15807 11.5581C4.75634 11.9331 4.51969 12.452 4.5 13.0011V14.0011C4.51969 13.452 4.75634 12.9331 5.15807 12.5581C5.55979 12.1832 6.0938 11.9829 6.643 12.0011H17.357C17.9062 11.9829 18.4402 12.1832 18.8419 12.5581C19.2437 12.9331 19.4803 13.452 19.5 14.0011V13.0011C19.4803 12.452 19.2437 11.9331 18.8419 11.5581C18.4402 11.1832 17.9062 10.9829 17.357 11.0011Z"
          fill="#EDEDED"
        />
        <path
          d="M14.5 7.5V8.5C16.9415 8.5 18.5 9.388 18.5 10H19.5C19.5 8.5745 17.35 7.5 14.5 7.5Z"
          fill="#273941"
        />
        <path
          d="M4.5 10H5.5C5.5 9.388 7.0585 8.5 9.5 8.5V7.5C6.65 7.5 4.5 8.5745 4.5 10Z"
          fill="#273941"
        />
        <path
          d="M19.5002 12.9991V13.1241L23.3637 10.2241C23.3074 9.85746 23.2335 9.49629 23.1422 9.14062L19.2422 12.0651C19.4085 12.3485 19.4975 12.6706 19.5002 12.9991Z"
          fill="#E5EBF7"
        />
        <path
          d="M19.5769 16V16.5C19.5601 16.6818 19.4921 16.8552 19.3809 17H22.3589C22.5162 16.675 22.658 16.3417 22.7844 16H19.5769Z"
          fill="#E5EBF7"
        />
        <path
          d="M4.50072 12.9971C4.50364 12.6693 4.59242 12.348 4.75822 12.0651L0.858219 9.14062C0.767552 9.49562 0.693719 9.85679 0.636719 10.2241L4.50072 13.1221V12.9971Z"
          fill="#E5EBF7"
        />
        <path
          d="M4.5008 16.5V16H1.2168C1.34346 16.341 1.4853 16.6743 1.6423 17H4.6203C4.54968 16.8422 4.50915 16.6727 4.5008 16.5Z"
          fill="#E5EBF7"
        />
        <path
          d="M15.9293 12.9975H8.07234C7.74045 12.9696 7.40871 13.0527 7.12911 13.2337C6.84951 13.4146 6.63786 13.6833 6.52734 13.9975H17.4743C17.3638 13.6833 17.1522 13.4146 16.8726 13.2337C16.593 13.0527 16.2612 12.9696 15.9293 12.9975Z"
          fill="#88D6F9"
        />
        <path
          d="M12.0006 19C13.3367 18.9927 14.6495 18.6488 15.8176 18H8.18359C9.35167 18.6488 10.6644 18.9927 12.0006 19Z"
          fill="#3BC5F6"
        />
        <path
          d="M17.5 14.2C17.4985 14.1326 17.4896 14.0655 17.4735 14H6.5265C6.51037 14.0655 6.50149 14.1326 6.5 14.2V16H17.5V14.2Z"
          fill="#3BC5F6"
        />
        <path
          d="M17.5 16.3V16H6.5V16.3C6.5 16.724 7.147 17.4205 8.183 18H15.817C16.853 17.4205 17.5 16.724 17.5 16.3Z"
          fill="#88D6F9"
        />
        <path
          d="M18.9064 4.90641C19.0674 4.74526 19.0162 4.43297 18.7919 4.20889C18.5677 3.98481 18.2553 3.93379 18.0943 4.09494C17.9333 4.25608 17.9845 4.56837 18.2088 4.79246C18.433 5.01654 18.7453 5.06756 18.9064 4.90641Z"
          fill="#F6FAFD"
        />
        <path
          d="M15.5754 2.45498C15.656 2.37431 15.6305 2.21809 15.5184 2.10604C15.4063 1.994 15.25 1.96857 15.1694 2.04924C15.0888 2.12992 15.1143 2.28614 15.2264 2.39818C15.3386 2.51022 15.4948 2.53565 15.5754 2.45498Z"
          fill="#F6FAFD"
        />
        <path
          d="M17.8134 4.31353C18.1355 3.99123 18.0329 3.36649 17.5842 2.91813C17.1355 2.46977 16.5107 2.36758 16.1886 2.68987C15.8666 3.01217 15.9692 3.63691 16.4179 4.08527C16.8665 4.53363 17.4913 4.63582 17.8134 4.31353Z"
          fill="#F6FAFD"
        />
      </g>
      <defs>
        <clipPath id="clip0_740_10412">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
