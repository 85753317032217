import React from "react";
import ShowContainer from "../../mui/ShowContainer";
import { useSelector } from "react-redux";
import HomeFilters from "./HomeFilters";

export default function FiltersDesktopContainer() {
  const isMobileHeader = useSelector((state) => state.deviceSize.isMobileHeader);

  return (
    <ShowContainer condition={!isMobileHeader}>
      <HomeFilters />
    </ShowContainer>
  );
}
