import React from "react";

export default function IconPrivate() {
  return (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_16_1341)">
        <path
          d="M1.52009 10.676H16.4801C16.8881 10.676 17.1601 10.404 17.1601 9.996C17.1601 9.588 16.8881 9.316 16.4801 9.316H15.3241C15.2561 7.684 14.8481 6.12 14.2361 4.76C13.7601 3.808 13.2161 2.992 12.4681 2.312C11.8561 1.768 10.9721 1.768 10.3601 2.244C9.61209 2.924 8.38809 2.924 7.64009 2.244C7.02809 1.7 6.07609 1.768 5.53209 2.312C3.90009 3.876 2.81209 6.46 2.67609 9.316H1.52009C1.11209 9.316 0.840088 9.588 0.840088 9.996C0.840088 10.404 1.11209 10.676 1.52009 10.676Z"
          fill="white"
          fillOpacity="0.6"
        />
        <path
          d="M15.1881 12.376V12.036H9.61206V12.376C9.61206 13.94 10.8361 15.164 12.4001 15.164C13.9641 15.164 15.1881 13.872 15.1881 12.376Z"
          fill="white"
          fillOpacity="0.6"
        />
        <path
          d="M5.60001 15.164C7.16401 15.164 8.38801 13.94 8.38801 12.376V12.036H2.81201V12.376C2.81201 13.872 4.03601 15.164 5.60001 15.164Z"
          fill="white"
          fillOpacity="0.6"
        />
      </g>
      <defs>
        <clipPath id="clip0_16_1341">
          <rect width="17" height="17" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
