import React from "react";

export default function Simple20() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_740_10705)">
        <path
          d="M12 23.5C18.3513 23.5 23.5 18.3513 23.5 12C23.5 5.64873 18.3513 0.5 12 0.5C5.64873 0.5 0.5 5.64873 0.5 12C0.5 18.3513 5.64873 23.5 12 23.5Z"
          fill="#FFCE52"
        />
        <path
          d="M11.9992 1.99911C18.0742 1.99911 22.9992 6.25261 22.9992 11.4991H23.4867C23.3489 8.54514 22.0784 5.7578 19.9392 3.71607C17.7999 1.67434 14.9564 0.535156 11.9992 0.535156C9.04203 0.535156 6.1985 1.67434 4.05927 3.71607C1.92005 5.7578 0.649561 8.54514 0.511719 11.4991H0.999219C0.999219 6.25261 5.92422 1.99911 11.9992 1.99911Z"
          fill="#FFE369"
        />
        <path
          d="M23 11.5C23 16.7465 18.075 21 12 21C5.925 21 1 16.7465 1 11.5H0.5125C0.5055 11.666 0.5 11.8325 0.5 12C0.5 15.05 1.7116 17.9751 3.86827 20.1317C6.02494 22.2884 8.95001 23.5 12 23.5C15.05 23.5 17.9751 22.2884 20.1317 20.1317C22.2884 17.9751 23.5 15.05 23.5 12C23.5 11.8325 23.4945 11.666 23.4875 11.5H23Z"
          fill="#FFB32B"
        />
        <path
          d="M18.9064 4.90641C19.0674 4.74526 19.0162 4.43297 18.7919 4.20889C18.5677 3.98481 18.2553 3.93379 18.0943 4.09494C17.9333 4.25608 17.9845 4.56837 18.2088 4.79246C18.433 5.01654 18.7453 5.06756 18.9064 4.90641Z"
          fill="#F6FAFD"
        />
        <path
          d="M15.5754 2.45108C15.656 2.3704 15.6305 2.21418 15.5184 2.10214C15.4063 1.9901 15.25 1.96467 15.1694 2.04534C15.0888 2.12601 15.1143 2.28223 15.2264 2.39427C15.3386 2.50632 15.4948 2.53175 15.5754 2.45108Z"
          fill="#F6FAFD"
        />
        <path
          d="M17.8134 4.31353C18.1355 3.99123 18.0329 3.36649 17.5842 2.91813C17.1355 2.46977 16.5107 2.36758 16.1886 2.68987C15.8666 3.01217 15.9692 3.63691 16.4179 4.08527C16.8665 4.53363 17.4913 4.63582 17.8134 4.31353Z"
          fill="#F6FAFD"
        />
        <path
          d="M12 14C16.4183 14 20 12.433 20 10.5C20 8.567 16.4183 7 12 7C7.58172 7 4 8.567 4 10.5C4 12.433 7.58172 14 12 14Z"
          fill="url(#paint0_radial_740_10705)"
        />
        <path
          d="M12 18.5C15.5899 18.5 18.5 16.933 18.5 15C18.5 13.067 15.5899 11.5 12 11.5C8.41015 11.5 5.5 13.067 5.5 15C5.5 16.933 8.41015 18.5 12 18.5Z"
          fill="#273941"
        />
        <path
          d="M4.8 20.9652C4.95906 20.8118 5.14712 20.6917 5.35317 20.6118C5.55921 20.532 5.77911 20.494 6 20.5002C6.30327 20.4776 6.60614 20.549 6.86739 20.7046C7.12863 20.8603 7.33556 21.0927 7.46 21.3702C7.6287 21.2901 7.81327 21.2491 8 21.2502C8.24631 21.2463 8.48764 21.3197 8.69 21.4602C8.84924 21.2692 9.05009 21.1172 9.27715 21.0158C9.50422 20.9144 9.75148 20.8663 10 20.8752C10.2485 20.8663 10.4958 20.9144 10.7228 21.0158C10.9499 21.1172 11.1508 21.2692 11.31 21.4602C11.5141 21.3233 11.7543 21.2502 12 21.2502C12.2457 21.2502 12.4859 21.3233 12.69 21.4602C12.855 21.2762 13.057 21.1289 13.2827 21.0282C13.5084 20.9274 13.7528 20.8753 14 20.8753C14.2472 20.8753 14.4916 20.9274 14.7173 21.0282C14.943 21.1289 15.145 21.2762 15.31 21.4602C15.5124 21.3197 15.7537 21.2463 16 21.2502C16.03 21.2502 16.06 21.2552 16.09 21.2552C16.2319 21.011 16.439 20.8111 16.688 20.6778C16.9369 20.5445 17.2181 20.483 17.5 20.5002C17.664 20.4983 17.8273 20.5202 17.985 20.5652C18.2869 20.6327 18.5598 20.7937 18.765 21.0252C18.9337 20.9523 19.1123 20.9051 19.295 20.8852H19.3C19.365 20.8802 19.43 20.8752 19.5 20.8752C19.732 20.8681 19.9629 20.9093 20.1781 20.9963C20.3933 21.0832 20.5881 21.2139 20.75 21.3802C20.9119 21.2139 21.1067 21.0832 21.3219 20.9963C21.5371 20.9093 21.768 20.8681 22 20.8752C22.83 20.8752 23.5 21.3802 23.5 22.0002V23.5002H0.5V22.0002C0.5 21.5852 0.95 21.2502 1.5 21.2502C1.74631 21.2463 1.98764 21.3197 2.19 21.4602C2.34924 21.2692 2.55009 21.1172 2.77715 21.0158C3.00422 20.9144 3.25148 20.8663 3.5 20.8752C3.89618 20.8669 4.28306 20.9958 4.595 21.2402C4.64633 21.137 4.71576 21.0439 4.8 20.9652Z"
          fill="#78B300"
        />
        <path
          d="M17.205 17.095C17.395 18.445 17.7 20.2 18 20.5L17.985 20.565C17.8273 20.52 17.664 20.4981 17.5 20.5C17.2181 20.4828 16.9369 20.5443 16.688 20.6776C16.439 20.8109 16.2319 21.0108 16.09 21.255C16.06 21.255 16.03 21.25 16 21.25C15.7537 21.2461 15.5124 21.3195 15.31 21.46C15.145 21.276 14.943 21.1288 14.7173 21.028C14.4916 20.9272 14.2472 20.8751 14 20.8751C13.7528 20.8751 13.5084 20.9272 13.2827 21.028C13.057 21.1288 12.855 21.276 12.69 21.46C12.4859 21.3231 12.2457 21.25 12 21.25C11.7543 21.25 11.5141 21.3231 11.31 21.46C11.1508 21.269 10.9499 21.117 10.7228 21.0156C10.4958 20.9142 10.2485 20.8661 10 20.875C9.75148 20.8661 9.50422 20.9142 9.27715 21.0156C9.05009 21.117 8.84924 21.269 8.69 21.46C8.48764 21.3195 8.24631 21.2461 8 21.25C7.81327 21.2489 7.6287 21.2899 7.46 21.37C7.33556 21.0925 7.12863 20.8601 6.86739 20.7044C6.60614 20.5488 6.30327 20.4774 6 20.5C6.3 20.2 6.605 18.445 6.795 17.095C6.92 16.21 7 15.5 7 15.5V15C7 14.6022 7.15804 14.2206 7.43934 13.9393C7.72064 13.658 8.10218 13.5 8.5 13.5H15.5C15.8978 13.5 16.2794 13.658 16.5607 13.9393C16.842 14.2206 17 14.6022 17 15V15.5C17 15.5 17.08 16.21 17.205 17.095Z"
          fill="#4F813B"
        />
        <path
          d="M18.7765 10.45C17.882 10 16.8585 10 15 10C14.8674 10 14.7402 9.94732 14.6464 9.85355C14.5527 9.75979 14.5 9.63261 14.5 9.5C14.5 8.3675 16.284 7 18.5 7V8C17.5023 7.97444 16.5328 8.33233 15.791 9C17.3195 9.009 18.272 9.0755 19.2235 9.55L18.7765 10.45Z"
          fill="#273941"
        />
        <path
          d="M5.22434 10.45L4.77734 9.555C5.72734 9.0795 6.68134 9.013 8.20984 9.005C7.46882 8.33551 6.49921 7.9758 5.50084 8V7C7.71684 7 9.50084 8.3675 9.50084 9.5C9.50084 9.63261 9.44817 9.75979 9.3544 9.85355C9.26063 9.94732 9.13345 10 9.00084 10C7.14234 10 6.11884 10 5.22434 10.45Z"
          fill="#273941"
        />
        <path
          d="M18 6.5C15.4345 6.5 13.5 5.4255 13.5 4H14.5C14.5 4.708 15.997 5.5 18 5.5V6.5Z"
          fill="#273941"
        />
        <path
          d="M6 6.5V5.5C8.003 5.5 9.5 4.708 9.5 4H10.5C10.5 5.4255 8.5655 6.5 6 6.5Z"
          fill="#273941"
        />
        <path
          d="M12 12.5C15.2735 12.5 17.9745 13.8045 18.426 15.5C18.4726 15.3373 18.4974 15.1692 18.5 15C18.5 13.067 15.59 11.5 12 11.5C8.41 11.5 5.5 13.067 5.5 15C5.50256 15.1692 5.52744 15.3373 5.574 15.5C6.0255 13.8045 8.7265 12.5 12 12.5Z"
          fill="#141E21"
        />
        <path
          d="M7 16.5V16C7 15.6022 7.15804 15.2206 7.43934 14.9393C7.72064 14.658 8.10218 14.5 8.5 14.5H15.5C15.8978 14.5 16.2794 14.658 16.5607 14.9393C16.842 15.2206 17 15.6022 17 16V16.5C17 16.5 17.08 17.21 17.205 18.095C17.3165 18.8855 17.467 19.8135 17.633 20.5085C17.7514 20.5143 17.8688 20.5332 17.983 20.565L18 20.5C17.7 20.2 17.395 18.445 17.205 17.095C17.08 16.21 17 15.5 17 15.5V15C17 14.6022 16.842 14.2206 16.5607 13.9393C16.2794 13.658 15.8978 13.5 15.5 13.5H8.5C8.10218 13.5 7.72064 13.658 7.43934 13.9393C7.15804 14.2206 7 14.6022 7 15V15.5C7 15.5 6.92 16.21 6.795 17.095C6.605 18.445 6.3 20.2 6 20.5C6.12088 20.5012 6.24139 20.5136 6.36 20.537C6.5285 19.837 6.682 18.8965 6.795 18.095C6.92 17.21 7 16.5 7 16.5Z"
          fill="#78B300"
        />
        <path
          d="M17.5 19.4989C17.2181 19.4817 16.9369 19.5432 16.688 19.6765C16.439 19.8098 16.2319 20.0098 16.09 20.2539C16.06 20.2539 16.03 20.2489 16 20.2489C15.7537 20.245 15.5124 20.3184 15.31 20.4589C15.145 20.2749 14.943 20.1277 14.7173 20.0269C14.4916 19.9261 14.2472 19.8741 14 19.8741C13.7528 19.8741 13.5084 19.9261 13.2827 20.0269C13.057 20.1277 12.855 20.2749 12.69 20.4589C12.4859 20.322 12.2457 20.2489 12 20.2489C11.7543 20.2489 11.5141 20.322 11.31 20.4589C11.1508 20.2679 10.9499 20.1159 10.7228 20.0145C10.4958 19.9131 10.2485 19.865 10 19.8739C9.75148 19.865 9.50422 19.9131 9.27715 20.0145C9.05009 20.1159 8.84924 20.2679 8.69 20.4589C8.48764 20.3184 8.24631 20.245 8 20.2489C7.81327 20.2479 7.6287 20.2889 7.46 20.3689C7.36687 20.1451 7.21594 19.95 7.02267 19.8036C6.82939 19.6573 6.6007 19.5649 6.36 19.5359C6.30613 19.8779 6.18367 20.2055 6 20.4989C6.30327 20.4763 6.60614 20.5477 6.86739 20.7034C7.12863 20.859 7.33556 21.0914 7.46 21.3689C7.6287 21.2889 7.81327 21.2479 8 21.2489C8.24631 21.245 8.48764 21.3184 8.69 21.4589C8.84924 21.2679 9.05009 21.1159 9.27715 21.0145C9.50422 20.9131 9.75148 20.865 10 20.8739C10.2485 20.865 10.4958 20.9131 10.7228 21.0145C10.9499 21.1159 11.1508 21.2679 11.31 21.4589C11.5141 21.322 11.7543 21.2489 12 21.2489C12.2457 21.2489 12.4859 21.322 12.69 21.4589C12.855 21.2749 13.057 21.1277 13.2827 21.0269C13.5084 20.9261 13.7528 20.8741 14 20.8741C14.2472 20.8741 14.4916 20.9261 14.7173 21.0269C14.943 21.1277 15.145 21.2749 15.31 21.4589C15.5124 21.3184 15.7537 21.245 16 21.2489C16.03 21.2489 16.06 21.2539 16.09 21.2539C16.2319 21.0098 16.439 20.8098 16.688 20.6765C16.9369 20.5432 17.2181 20.4817 17.5 20.4989C17.664 20.497 17.8273 20.5189 17.985 20.5639L18 20.4989C17.8122 20.1965 17.6874 19.8593 17.633 19.5074C17.589 19.5044 17.5455 19.4989 17.5 19.4989Z"
          fill="#426704"
        />
        <path
          d="M9.5 17C10.0523 17 10.5 16.5523 10.5 16C10.5 15.4477 10.0523 15 9.5 15C8.94772 15 8.5 15.4477 8.5 16C8.5 16.5523 8.94772 17 9.5 17Z"
          fill="#78B300"
        />
        <path
          d="M11 18.5C11.2761 18.5 11.5 18.2761 11.5 18C11.5 17.7239 11.2761 17.5 11 17.5C10.7239 17.5 10.5 17.7239 10.5 18C10.5 18.2761 10.7239 18.5 11 18.5Z"
          fill="#78B300"
        />
        <path
          d="M12.5 16.5C12.7761 16.5 13 16.2761 13 16C13 15.7239 12.7761 15.5 12.5 15.5C12.2239 15.5 12 15.7239 12 16C12 16.2761 12.2239 16.5 12.5 16.5Z"
          fill="#78B300"
        />
        <path
          d="M8 23C8.27614 23 8.5 22.7761 8.5 22.5C8.5 22.2239 8.27614 22 8 22C7.72386 22 7.5 22.2239 7.5 22.5C7.5 22.7761 7.72386 23 8 23Z"
          fill="#4F813B"
        />
        <path
          d="M12 22.5C12.2761 22.5 12.5 22.2761 12.5 22C12.5 21.7239 12.2761 21.5 12 21.5C11.7239 21.5 11.5 21.7239 11.5 22C11.5 22.2761 11.7239 22.5 12 22.5Z"
          fill="#4F813B"
        />
        <path
          d="M16 23C16.2761 23 16.5 22.7761 16.5 22.5C16.5 22.2239 16.2761 22 16 22C15.7239 22 15.5 22.2239 15.5 22.5C15.5 22.7761 15.7239 23 16 23Z"
          fill="#4F813B"
        />
        <path
          d="M22 22C22.2761 22 22.5 21.7761 22.5 21.5C22.5 21.2239 22.2761 21 22 21C21.7239 21 21.5 21.2239 21.5 21.5C21.5 21.7761 21.7239 22 22 22Z"
          fill="#4F813B"
        />
        <path
          d="M4 22.5C4.27614 22.5 4.5 22.2761 4.5 22C4.5 21.7239 4.27614 21.5 4 21.5C3.72386 21.5 3.5 21.7239 3.5 22C3.5 22.2761 3.72386 22.5 4 22.5Z"
          fill="#4F813B"
        />
        <path
          d="M8.25 19C8.38807 19 8.5 18.8881 8.5 18.75C8.5 18.6119 8.38807 18.5 8.25 18.5C8.11193 18.5 8 18.6119 8 18.75C8 18.8881 8.11193 19 8.25 19Z"
          fill="#A9CC11"
        />
        <path
          d="M14.75 16C14.8881 16 15 15.8881 15 15.75C15 15.6119 14.8881 15.5 14.75 15.5C14.6119 15.5 14.5 15.6119 14.5 15.75C14.5 15.8881 14.6119 16 14.75 16Z"
          fill="#A9CC11"
        />
        <path
          d="M16.25 18.5C16.3881 18.5 16.5 18.3881 16.5 18.25C16.5 18.1119 16.3881 18 16.25 18C16.1119 18 16 18.1119 16 18.25C16 18.3881 16.1119 18.5 16.25 18.5Z"
          fill="#A9CC11"
        />
        <path
          d="M17.25 22.5C17.3881 22.5 17.5 22.3881 17.5 22.25C17.5 22.1119 17.3881 22 17.25 22C17.1119 22 17 22.1119 17 22.25C17 22.3881 17.1119 22.5 17.25 22.5Z"
          fill="#A9CC11"
        />
        <path
          d="M1.75 22C1.88807 22 2 21.8881 2 21.75C2 21.6119 1.88807 21.5 1.75 21.5C1.61193 21.5 1.5 21.6119 1.5 21.75C1.5 21.8881 1.61193 22 1.75 22Z"
          fill="#A9CC11"
        />
        <path
          d="M21.25 22C21.3881 22 21.5 21.8881 21.5 21.75C21.5 21.6119 21.3881 21.5 21.25 21.5C21.1119 21.5 21 21.6119 21 21.75C21 21.8881 21.1119 22 21.25 22Z"
          fill="#A9CC11"
        />
        <path
          d="M22.25 23C22.3881 23 22.5 22.8881 22.5 22.75C22.5 22.6119 22.3881 22.5 22.25 22.5C22.1119 22.5 22 22.6119 22 22.75C22 22.8881 22.1119 23 22.25 23Z"
          fill="#A9CC11"
        />
        <path
          d="M17.25 21.5C17.3881 21.5 17.5 21.3881 17.5 21.25C17.5 21.1119 17.3881 21 17.25 21C17.1119 21 17 21.1119 17 21.25C17 21.3881 17.1119 21.5 17.25 21.5Z"
          fill="#A9CC11"
        />
        <path
          d="M9.25 22C9.38807 22 9.5 21.8881 9.5 21.75C9.5 21.6119 9.38807 21.5 9.25 21.5C9.11193 21.5 9 21.6119 9 21.75C9 21.8881 9.11193 22 9.25 22Z"
          fill="#A9CC11"
        />
        <path
          d="M13.75 23C13.8881 23 14 22.8881 14 22.75C14 22.6119 13.8881 22.5 13.75 22.5C13.6119 22.5 13.5 22.6119 13.5 22.75C13.5 22.8881 13.6119 23 13.75 23Z"
          fill="#A9CC11"
        />
        <path
          d="M12.25 19.5C12.3881 19.5 12.5 19.3881 12.5 19.25C12.5 19.1119 12.3881 19 12.25 19C12.1119 19 12 19.1119 12 19.25C12 19.3881 12.1119 19.5 12.25 19.5Z"
          fill="#A9CC11"
        />
        <path
          d="M14 19C14.5523 19 15 18.5523 15 18C15 17.4477 14.5523 17 14 17C13.4477 17 13 17.4477 13 18C13 18.5523 13.4477 19 14 19Z"
          fill="#78B300"
        />
        <path
          d="M19.5 23C20.0523 23 20.5 22.5523 20.5 22C20.5 21.4477 20.0523 21 19.5 21C18.9477 21 18.5 21.4477 18.5 22C18.5 22.5523 18.9477 23 19.5 23Z"
          fill="#A9CC11"
        />
        <path
          d="M6 23C6.55228 23 7 22.5523 7 22C7 21.4477 6.55228 21 6 21C5.44772 21 5 21.4477 5 22C5 22.5523 5.44772 23 6 23Z"
          fill="#A9CC11"
        />
        <path
          d="M20.9064 22.4064C21.0674 22.2453 21.0162 21.933 20.7919 21.7089C20.5677 21.4848 20.2553 21.4338 20.0943 21.5949C19.9333 21.7561 19.9845 22.0684 20.2088 22.2925C20.433 22.5165 20.7453 22.5676 20.9064 22.4064Z"
          fill="#F6FAFD"
        />
        <path
          d="M16.4064 15.9064C16.5674 15.7453 16.5162 15.433 16.2919 15.2089C16.0677 14.9848 15.7553 14.9338 15.5943 15.0949C15.4333 15.2561 15.4845 15.5684 15.7088 15.7925C15.933 16.0165 16.2453 16.0676 16.4064 15.9064Z"
          fill="#F6FAFD"
        />
        <path
          d="M19.5754 21.9511C19.656 21.8704 19.6305 21.7142 19.5184 21.6021C19.4063 21.4901 19.25 21.4647 19.1694 21.5453C19.0888 21.626 19.1143 21.7822 19.2264 21.8943C19.3386 22.0063 19.4948 22.0317 19.5754 21.9511Z"
          fill="#F6FAFD"
        />
        <path
          d="M16.5754 16.9511C16.656 16.8704 16.6305 16.7142 16.5184 16.6021C16.4063 16.4901 16.25 16.4647 16.1694 16.5453C16.0888 16.626 16.1143 16.7822 16.2264 16.8943C16.3386 17.0063 16.4948 17.0317 16.5754 16.9511Z"
          fill="#F6FAFD"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_740_10705"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12.0021 10.5001) scale(8.001 3.501)"
        >
          <stop stopColor="#63B300" />
          <stop offset="0.13" stopColor="#7AB80F" />
          <stop offset="0.547" stopColor="#C1C63D" />
          <stop offset="0.848" stopColor="#EED059" />
          <stop offset="1" stopColor="#FFD364" />
        </radialGradient>
        <clipPath id="clip0_740_10705">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
