import React from "react";

export default function Simple21() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_740_10750)">
        <path
          d="M12 23.5C18.3513 23.5 23.5 18.3513 23.5 12C23.5 5.64873 18.3513 0.5 12 0.5C5.64873 0.5 0.5 5.64873 0.5 12C0.5 18.3513 5.64873 23.5 12 23.5Z"
          fill="#FFCE52"
        />
        <path
          d="M18.5 17.8335C18.521 18.2531 18.375 18.664 18.0939 18.9763C17.8128 19.2886 17.4196 19.4769 17 19.5C16.5805 19.4769 16.1872 19.2886 15.9061 18.9763C15.6251 18.664 15.479 18.2531 15.5 17.8335C15.5 16.913 16.625 14.5 17 14.5C17.375 14.5 18.5 16.913 18.5 17.8335Z"
          fill="#3BC5F6"
        />
        <path d="M5 8.5V7.5C6.861 7.5 8 6.8525 8 6.5H9C9 7.813 6.988 8.5 5 8.5Z" fill="#273941" />
        <path
          d="M19 8.5C17.012 8.5 15 7.813 15 6.5H16C16 6.8525 17.139 7.5 19 7.5V8.5Z"
          fill="#273941"
        />
        <path
          d="M12 17.5C11.3065 17.5308 10.6264 17.7008 10 18C10 17.4696 10.2107 16.9609 10.5858 16.5858C10.9609 16.2107 11.4696 16 12 16C12.5304 16 13.0391 16.2107 13.4142 16.5858C13.7893 16.9609 14 17.4696 14 18C13.3736 17.7008 12.6935 17.5308 12 17.5Z"
          fill="#273941"
        />
        <path
          d="M17 13.5C18.3807 13.5 19.5 12.3807 19.5 11C19.5 9.61929 18.3807 8.5 17 8.5C15.6193 8.5 14.5 9.61929 14.5 11C14.5 12.3807 15.6193 13.5 17 13.5Z"
          fill="#273941"
        />
        <path
          d="M7 13.5C8.38071 13.5 9.5 12.3807 9.5 11C9.5 9.61929 8.38071 8.5 7 8.5C5.61929 8.5 4.5 9.61929 4.5 11C4.5 12.3807 5.61929 13.5 7 13.5Z"
          fill="#273941"
        />
        <path
          d="M17 13C18.1046 13 19 12.1046 19 11C19 9.89543 18.1046 9 17 9C15.8954 9 15 9.89543 15 11C15 12.1046 15.8954 13 17 13Z"
          fill="#141E21"
        />
        <path
          d="M7 13C8.10457 13 9 12.1046 9 11C9 9.89543 8.10457 9 7 9C5.89543 9 5 9.89543 5 11C5 12.1046 5.89543 13 7 13Z"
          fill="#141E21"
        />
        <path
          d="M17.75 11C18.1642 11 18.5 10.6642 18.5 10.25C18.5 9.83579 18.1642 9.5 17.75 9.5C17.3358 9.5 17 9.83579 17 10.25C17 10.6642 17.3358 11 17.75 11Z"
          fill="#F6FAFD"
        />
        <path
          d="M16.25 12.5C16.6642 12.5 17 12.1642 17 11.75C17 11.3358 16.6642 11 16.25 11C15.8358 11 15.5 11.3358 15.5 11.75C15.5 12.1642 15.8358 12.5 16.25 12.5Z"
          fill="#F6FAFD"
        />
        <path
          d="M17.75 12C17.8881 12 18 11.8881 18 11.75C18 11.6119 17.8881 11.5 17.75 11.5C17.6119 11.5 17.5 11.6119 17.5 11.75C17.5 11.8881 17.6119 12 17.75 12Z"
          fill="#F6FAFD"
        />
        <path
          d="M6.25 11C6.66421 11 7 10.6642 7 10.25C7 9.83579 6.66421 9.5 6.25 9.5C5.83579 9.5 5.5 9.83579 5.5 10.25C5.5 10.6642 5.83579 11 6.25 11Z"
          fill="#F6FAFD"
        />
        <path
          d="M7.75 12.5C8.16421 12.5 8.5 12.1642 8.5 11.75C8.5 11.3358 8.16421 11 7.75 11C7.33579 11 7 11.3358 7 11.75C7 12.1642 7.33579 12.5 7.75 12.5Z"
          fill="#F6FAFD"
        />
        <path
          d="M6.25 12C6.38807 12 6.5 11.8881 6.5 11.75C6.5 11.6119 6.38807 11.5 6.25 11.5C6.11193 11.5 6 11.6119 6 11.75C6 11.8881 6.11193 12 6.25 12Z"
          fill="#F6FAFD"
        />
        <path
          d="M11.9992 1.99911C18.0742 1.99911 22.9992 6.25261 22.9992 11.4991H23.4867C23.3489 8.54514 22.0784 5.7578 19.9392 3.71607C17.7999 1.67434 14.9564 0.535156 11.9992 0.535156C9.04203 0.535156 6.1985 1.67434 4.05927 3.71607C1.92005 5.7578 0.649561 8.54514 0.511719 11.4991H0.999219C0.999219 6.25261 5.92422 1.99911 11.9992 1.99911Z"
          fill="#FFE369"
        />
        <path
          d="M23 11.5C23 16.7465 18.075 21 12 21C5.925 21 1 16.7465 1 11.5H0.5125C0.5055 11.666 0.5 11.8325 0.5 12C0.5 15.05 1.7116 17.9751 3.86827 20.1317C6.02494 22.2884 8.95001 23.5 12 23.5C15.05 23.5 17.9751 22.2884 20.1317 20.1317C22.2884 17.9751 23.5 15.05 23.5 12C23.5 11.8325 23.4945 11.666 23.4875 11.5H23Z"
          fill="#FFB32B"
        />
        <path
          d="M18.9064 4.90641C19.0674 4.74526 19.0162 4.43297 18.7919 4.20889C18.5677 3.98481 18.2553 3.93379 18.0943 4.09494C17.9333 4.25608 17.9845 4.56837 18.2088 4.79246C18.433 5.01654 18.7453 5.06756 18.9064 4.90641Z"
          fill="#F6FAFD"
        />
        <path
          d="M15.5754 2.45108C15.656 2.3704 15.6305 2.21418 15.5184 2.10214C15.4063 1.9901 15.25 1.96467 15.1694 2.04534C15.0888 2.12601 15.1143 2.28223 15.2264 2.39427C15.3386 2.50632 15.4948 2.53175 15.5754 2.45108Z"
          fill="#F6FAFD"
        />
        <path
          d="M17.8134 4.31353C18.1355 3.99123 18.0329 3.36649 17.5842 2.91813C17.1355 2.46977 16.5107 2.36758 16.1886 2.68987C15.8666 3.01217 15.9692 3.63691 16.4179 4.08527C16.8665 4.53363 17.4913 4.63582 17.8134 4.31353Z"
          fill="#F6FAFD"
        />
        <path
          d="M17.0675 14.5234C17.429 14.7429 18.25 16.4774 18.25 17.1664C18.2608 17.3372 18.2365 17.5084 18.1786 17.6695C18.1207 17.8305 18.0304 17.978 17.9132 18.1028C17.7961 18.2275 17.6546 18.327 17.4976 18.395C17.3405 18.463 17.1712 18.498 17 18.498C16.8289 18.498 16.6596 18.463 16.5025 18.395C16.3454 18.327 16.204 18.2275 16.0868 18.1028C15.9697 17.978 15.8794 17.8305 15.8215 17.6695C15.7635 17.5084 15.7392 17.3372 15.75 17.1664C15.75 16.4774 16.571 14.7429 16.9325 14.5234C16.5065 14.7579 15.5 16.9619 15.5 17.8334C15.479 18.2531 15.6251 18.6639 15.9061 18.9762C16.1872 19.2885 16.5805 19.4768 17 19.4999C17.4196 19.4768 17.8128 19.2885 18.0939 18.9762C18.375 18.6639 18.521 18.2531 18.5 17.8334C18.5 16.9619 17.4935 14.7579 17.0675 14.5234Z"
          fill="#00A3E1"
        />
        <path
          d="M17.9064 17.9064C18.0674 17.7453 18.0162 17.433 17.7919 17.2089C17.5677 16.9848 17.2553 16.9338 17.0943 17.0949C16.9333 17.2561 16.9845 17.5684 17.2088 17.7925C17.433 18.0165 17.7453 18.0676 17.9064 17.9064Z"
          fill="#F6FAFD"
        />
        <path
          d="M18.0754 16.9511C18.156 16.8704 18.1305 16.7142 18.0184 16.6021C17.9063 16.4901 17.75 16.4647 17.6694 16.5453C17.5888 16.626 17.6143 16.7822 17.7264 16.8943C17.8386 17.0063 17.9948 17.0317 18.0754 16.9511Z"
          fill="#F6FAFD"
        />
        <path
          d="M17 19.5012C16.6661 19.4886 16.3456 19.3665 16.0878 19.1538C15.8301 18.9412 15.6494 18.6497 15.5735 18.3242C15.5308 18.4913 15.5062 18.6624 15.5 18.8347C15.479 19.2543 15.6251 19.6652 15.9061 19.9775C16.1872 20.2898 16.5805 20.4781 17 20.5012C17.4196 20.4781 17.8128 20.2898 18.0939 19.9775C18.375 19.6652 18.521 19.2543 18.5 18.8347C18.4939 18.6624 18.4693 18.4913 18.4265 18.3242C18.3507 18.6497 18.17 18.9412 17.9122 19.1538C17.6545 19.3665 17.334 19.4886 17 19.5012Z"
          fill="#FFB32B"
        />
      </g>
      <defs>
        <clipPath id="clip0_740_10750">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
