import React from "react";

export default function Simple25() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_740_10824)">
        <path
          d="M12 23.5C18.3513 23.5 23.5 18.3513 23.5 12C23.5 5.64873 18.3513 0.5 12 0.5C5.64873 0.5 0.5 5.64873 0.5 12C0.5 18.3513 5.64873 23.5 12 23.5Z"
          fill="#FFCE52"
        />
        <path
          d="M16.5 11C17.3284 11 18 10.1046 18 9C18 7.89543 17.3284 7 16.5 7C15.6716 7 15 7.89543 15 9C15 10.1046 15.6716 11 16.5 11Z"
          fill="#273941"
        />
        <path
          d="M7.5 11C8.32843 11 9 10.1046 9 9C9 7.89543 8.32843 7 7.5 7C6.67157 7 6 7.89543 6 9C6 10.1046 6.67157 11 7.5 11Z"
          fill="#273941"
        />
        <path
          d="M16.5 10.5C17.0523 10.5 17.5 9.82843 17.5 9C17.5 8.17157 17.0523 7.5 16.5 7.5C15.9477 7.5 15.5 8.17157 15.5 9C15.5 9.82843 15.9477 10.5 16.5 10.5Z"
          fill="#141E21"
        />
        <path
          d="M7.5 10.5C8.05228 10.5 8.5 9.82843 8.5 9C8.5 8.17157 8.05228 7.5 7.5 7.5C6.94772 7.5 6.5 8.17157 6.5 9C6.5 9.82843 6.94772 10.5 7.5 10.5Z"
          fill="#141E21"
        />
        <path
          d="M17 9C17.2761 9 17.5 8.77614 17.5 8.5C17.5 8.22386 17.2761 8 17 8C16.7239 8 16.5 8.22386 16.5 8.5C16.5 8.77614 16.7239 9 17 9Z"
          fill="#F6FAFD"
        />
        <path
          d="M8 9C8.27614 9 8.5 8.77614 8.5 8.5C8.5 8.22386 8.27614 8 8 8C7.72386 8 7.5 8.22386 7.5 8.5C7.5 8.77614 7.72386 9 8 9Z"
          fill="#F6FAFD"
        />
        <path
          d="M12 19.5C8.14 19.5 5 16.584 5 13H6C6 16.0325 8.6915 18.5 12 18.5C15.3085 18.5 18 16.0325 18 13H19C19 16.584 15.86 19.5 12 19.5Z"
          fill="#273941"
        />
        <path
          d="M11.9992 1.99911C18.0742 1.99911 22.9992 6.25261 22.9992 11.4991H23.4867C23.3489 8.54514 22.0784 5.7578 19.9392 3.71607C17.7999 1.67434 14.9564 0.535156 11.9992 0.535156C9.04203 0.535156 6.1985 1.67434 4.05927 3.71607C1.92005 5.7578 0.649561 8.54514 0.511719 11.4991H0.999219C0.999219 6.25261 5.92422 1.99911 11.9992 1.99911Z"
          fill="#FFE369"
        />
        <path
          d="M23 11.5C23 16.7465 18.075 21 12 21C5.925 21 1 16.7465 1 11.5H0.5125C0.5055 11.666 0.5 11.8325 0.5 12C0.5 15.05 1.7116 17.9751 3.86827 20.1317C6.02494 22.2884 8.95001 23.5 12 23.5C15.05 23.5 17.9751 22.2884 20.1317 20.1317C22.2884 17.9751 23.5 15.05 23.5 12C23.5 11.8325 23.4945 11.666 23.4875 11.5H23Z"
          fill="#FFB32B"
        />
        <path
          d="M18.9064 4.90641C19.0674 4.74526 19.0162 4.43297 18.7919 4.20889C18.5677 3.98481 18.2553 3.93379 18.0943 4.09494C17.9333 4.25608 17.9845 4.56837 18.2088 4.79246C18.433 5.01654 18.7453 5.06756 18.9064 4.90641Z"
          fill="#F6FAFD"
        />
        <path
          d="M15.5754 2.45108C15.656 2.3704 15.6305 2.21418 15.5184 2.10214C15.4063 1.9901 15.25 1.96467 15.1694 2.04534C15.0888 2.12601 15.1143 2.28223 15.2264 2.39427C15.3386 2.50632 15.4948 2.53175 15.5754 2.45108Z"
          fill="#F6FAFD"
        />
        <path
          d="M17.8134 4.31353C18.1355 3.99123 18.0329 3.36649 17.5842 2.91813C17.1355 2.46977 16.5107 2.36758 16.1886 2.68987C15.8666 3.01217 15.9692 3.63691 16.4179 4.08527C16.8665 4.53363 17.4913 4.63582 17.8134 4.31353Z"
          fill="#F6FAFD"
        />
      </g>
      <defs>
        <clipPath id="clip0_740_10824">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
