import React, { lazy, Suspense, useState } from "react";
import Header from "./Header";
import { useSelector } from "react-redux";

const SignUpModal = lazy(() => import("../../common/SignUpModal"));
const HeaderMobileMenu = lazy(() => import("../HeaderMobileMenu"));
const MobileHeader = lazy(() => import("./MobileHeader"));

export default function HeaderContainer() {
  const mobileHeader = useSelector((state) => state.deviceSize.isMobileHeader);

  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  return (
    <>
      {mobileHeader ? (
        <Suspense>
          <MobileHeader
            setOpenLoginModal={setOpenLoginModal}
            setOpenMobileMenu={setOpenMobileMenu}
            openMobileMenu={openMobileMenu}
          />
        </Suspense>
      ) : (
        <Header setOpenLoginModal={setOpenLoginModal} />
      )}

      <Suspense>
        <SignUpModal isOpen={!!openLoginModal} type={openLoginModal} setOpen={setOpenLoginModal} />
        <HeaderMobileMenu isOpen={openMobileMenu} closeModal={() => setOpenMobileMenu(false)} />
      </Suspense>
    </>
  );
}
