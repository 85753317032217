import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./style/vars.scss";
import "./style/base.scss";
import "./style/materials/materials.scss";
import "zoomist/css";
import CusSuspense from "./components/mui/CusSuspense";
import { OPEN_PATH_NAMES } from "./components/common/consts";

const Common = lazy(() => import("./components/common/Common"));
const Toaster = lazy(() => import("./components/mui/Toaster"));

import Home from "./pages/Home";
import ErrorBoundary from "./components/mui/error_boundary/ErrorBoundary";
import ErrorModal from "./components/common/error_modal/ErrorModal";
// const Home = lazy(() => import("./pages/Home"));
const EmailConfirm = lazy(() => import("./pages/EmailConfirm"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const CommonModals = lazy(() => import("./components/common/CommonModals"));
const CurrentModel = lazy(() => import("./pages/CurrentModel"));
const P18usc2257 = lazy(() => import("./pages/P18usc2257"));
const DMCA = lazy(() => import("./pages/DMCA"));
const Cookie = lazy(() => import("./pages/Cookie"));
const Policy = lazy(() => import("./pages/Policy"));
const Support = lazy(() => import("./pages/Support"));
const About = lazy(() => import("./pages/About"));
const Terms = lazy(() => import("./pages/Terms"));
const CheckOrder = lazy(() => import("./pages/CheckOrder"));

function App() {
  return (
    <>
      <Router basename="/">
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Routes>
          <Route
            path={OPEN_PATH_NAMES.confirmEmail}
            exact
            element={
              <CusSuspense>
                <EmailConfirm />
              </CusSuspense>
            }
          ></Route>
          <Route
            path={OPEN_PATH_NAMES.emailChange}
            exact
            element={
              <CusSuspense>
                <EmailConfirm />
              </CusSuspense>
            }
          ></Route>
          <Route
            path={OPEN_PATH_NAMES.passwordReset}
            exact
            element={
              <CusSuspense>
                <ResetPassword />
              </CusSuspense>
            }
          ></Route>
          <Route
            path="/chat/:id"
            exact
            element={
              <CusSuspense>
                <CurrentModel />
              </CusSuspense>
            }
          />

          <Route
            path={OPEN_PATH_NAMES.P2257}
            exact
            element={
              <CusSuspense>
                <P18usc2257 />
              </CusSuspense>
            }
          ></Route>
          <Route
            path={OPEN_PATH_NAMES.dmca}
            exact
            element={
              <CusSuspense>
                <DMCA />
              </CusSuspense>
            }
          ></Route>
          <Route
            path={OPEN_PATH_NAMES.contact}
            exact
            element={
              <CusSuspense>
                <Support />
              </CusSuspense>
            }
          ></Route>
          <Route
            path={OPEN_PATH_NAMES.cookie}
            exact
            element={
              <CusSuspense>
                <Cookie />
              </CusSuspense>
            }
          ></Route>
          <Route
            path={OPEN_PATH_NAMES.policy}
            exact
            element={
              <CusSuspense>
                <Policy />
              </CusSuspense>
            }
          ></Route>
          <Route
            path={OPEN_PATH_NAMES.about}
            exact
            element={
              <CusSuspense>
                <About />
              </CusSuspense>
            }
          ></Route>
          <Route
            path={OPEN_PATH_NAMES.terms}
            exact
            element={
              <CusSuspense>
                <Terms />
              </CusSuspense>
            }
          ></Route>
          <Route
            path={OPEN_PATH_NAMES.checkOrder}
            exact
            element={
              <CusSuspense>
                <CheckOrder />
              </CusSuspense>
            }
          ></Route>
          <Route
            path="/*"
            element={
              <CusSuspense>
                <Home />
              </CusSuspense>
            }
          ></Route>
        </Routes>
        {/* default main components */}
        <ErrorBoundary fallbackComponent={<ErrorModal reload={() => window.location.reload()} />}>
          <Suspense>
            <Common />
            <CommonModals />
          </Suspense>
        </ErrorBoundary>
      </Router>
      <Toaster />
    </>
  );
}

export default App;
