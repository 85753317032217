import axios from "axios";
import React, { useEffect, useState } from "react";
import { useInterval } from "usehooks-ts";
import noImg from "../../images/template/no-img.svg";
import ShowContainer from "./ShowContainer";

export default function ImageUpdate({
  isUpdate,
  path,
  imgSource,
  time = 45 * 1000,
  width,
  height,
  isBlur,
}) {
  const defaultImg = imgSource;
  const [errorImg, setErrorImg] = useState(false);
  const [source, setSource] = useState(defaultImg);
  const [sourceBlur, setSourceBlur] = useState(imgSource);

  const getPhotoByPath = () => {
    const data = { method: "GET", responseType: "blob", url: path };
    axios(data)
      .then((e) => {
        if (e.status != 200) return setSource(defaultImg);
        const imageUrl = URL.createObjectURL(e.data);
        setSource(imageUrl);
        setSourceBlur(imageUrl);
      })
      .catch(() => setSource(defaultImg))
      .finally(() => setErrorImg(false));
  };

  useInterval(() => {
    if (!isUpdate) return;
    if (!path) return;

    getPhotoByPath();
  }, time);

  useEffect(() => {
    if (!isUpdate || !path) {
      setSourceBlur(path || imgSource);
      setSource(path || defaultImg);
      setErrorImg(false);

      return;
    }
    getPhotoByPath();
  }, [isUpdate, path, imgSource]);

  return (
    <>
      <img
        width={width}
        height={height}
        src={errorImg || source || noImg}
        alt={"изображение"}
        onError={() => setErrorImg(noImg)}
      />

      <ShowContainer condition={isBlur && sourceBlur}>
        <img className="blur-img" width={150} height={150} src={sourceBlur} alt="картинка" />
      </ShowContainer>
    </>
  );
}
