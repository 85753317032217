import React from "react";

export default function IconCouples() {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_16_1327)">
        <path
          d="M7.11002 3.24689C8.00662 3.24689 8.73346 2.52005 8.73346 1.62345C8.73346 0.726841 8.00662 0 7.11002 0C6.21341 0 5.48657 0.726841 5.48657 1.62345C5.48657 2.52005 6.21341 3.24689 7.11002 3.24689Z"
          fill="white"
          fillOpacity="0.6"
        />
        <path
          d="M4.08192 4.60924C3.59092 6.06486 3.48543 7.0123 3.67202 8.61518C3.82419 9.39826 4.2356 9.70737 4.6901 9.82332C5.01558 10.9028 5.48631 13.2351 4.54355 15.7806C4.37053 16.2478 4.60892 16.7667 5.07604 16.9396C5.17937 16.9779 5.28507 16.9961 5.38919 16.9961C5.75573 16.9961 6.10033 16.7709 6.23504 16.4071C7.52997 12.9109 6.62863 9.82065 6.25135 8.79809C6.1784 8.25038 6.20611 7.66233 6.27674 7.08936C5.31061 5.97972 4.90504 5.04252 4.87741 4.97701C4.71975 4.60304 4.89573 4.17062 5.26963 4.0129C5.36083 3.97444 5.45694 3.95496 5.55521 3.95496C5.85133 3.95496 6.11736 4.13101 6.23302 4.40353C6.23634 4.41111 6.3603 4.69034 6.63195 5.112C6.63253 5.10905 6.63318 5.10587 6.63376 5.10291C6.84076 4.38477 6.5699 3.52529 5.95407 3.37182C5.3381 3.2182 4.44521 3.55278 4.08192 4.60924Z"
          fill="white"
          fillOpacity="0.6"
        />
        <path
          d="M10.2805 8.972C10.3523 8.84494 10.4313 8.71354 10.518 8.57855C9.95856 7.10439 10.3009 5.6435 10.3224 5.55591C10.3953 5.25799 10.6602 5.0499 10.9666 5.0499C11.0197 5.0499 11.073 5.05632 11.125 5.06909C11.4797 5.15596 11.6982 5.51536 11.6122 5.87028C11.5979 5.92981 11.3838 6.86924 11.6752 7.8573C11.8959 7.7819 12.0958 7.57677 12.2226 7.2598C12.4169 6.77306 12.3824 6.16474 12.1668 5.72446C12.1141 5.52531 12.0166 5.32841 11.8739 5.14276C11.4374 4.56994 10.6169 4.42375 9.95502 5.18583C9.44959 5.7676 9.88114 6.57038 9.0293 7.59885C9.30269 7.7915 9.59815 7.9778 9.91815 8.15205C10.217 8.31483 10.3572 8.65597 10.2805 8.972Z"
          fill="white"
          fillOpacity="0.6"
        />
        <path
          d="M9.56574 9.53454C9.44358 9.53454 9.322 9.50344 9.21421 9.44478C8.71779 9.17435 8.27153 8.8809 7.87137 8.578C6.52189 9.49947 7.24255 10.5973 8.12902 11.0061C8.28148 12.1191 8.37824 14.0161 7.76819 16.0733C7.65491 16.4553 7.87274 16.8569 8.25479 16.9702C8.32326 16.9905 8.39231 17.0001 8.46021 17.0001C8.7714 17.0001 9.05864 16.7972 9.15165 16.4836C9.86012 14.0946 9.72967 11.9321 9.54467 10.6873C9.65268 10.3922 9.79995 9.90468 10.1082 9.29499C9.97059 9.44514 9.7752 9.53454 9.56574 9.53454Z"
          fill="white"
          fillOpacity="0.6"
        />
        <path
          d="M11.8631 4.57744C12.7198 4.57744 13.4144 3.8829 13.4144 3.02615C13.4144 2.16939 12.7198 1.47485 11.8631 1.47485C11.0063 1.47485 10.3118 2.16939 10.3118 3.02615C10.3118 3.8829 11.0063 4.57744 11.8631 4.57744Z"
          fill="white"
          fillOpacity="0.6"
        />
        <path
          d="M11.4581 5.8328C11.5238 5.56222 11.358 5.28948 11.0875 5.22325C10.8164 5.15687 10.5431 5.32267 10.4768 5.59361C10.4467 5.71605 9.76813 8.61084 12.0028 10.307C12.0941 10.3763 12.2014 10.4098 12.3078 10.4098C12.4603 10.4098 12.6111 10.3409 12.7105 10.21C12.8791 9.98788 12.8357 9.67105 12.6135 9.50236C10.9136 8.21219 11.4344 5.93193 11.4581 5.8328Z"
          fill="white"
          fillOpacity="0.6"
        />
        <path
          d="M5.3314 4.1591C5.03767 4.28298 4.89993 4.62152 5.02374 4.91519C5.06992 5.02472 6.19024 7.6166 9.29016 9.3052C9.37782 9.35297 9.47241 9.37562 9.56571 9.37562C9.77026 9.37562 9.96847 9.26667 10.0732 9.07438C10.2256 8.79443 10.1223 8.44384 9.84234 8.29138C7.11878 6.80784 6.10149 4.49894 6.08699 4.46553C5.96274 4.17266 5.62463 4.0355 5.3314 4.1591Z"
          fill="white"
          fillOpacity="0.6"
        />
      </g>
      <defs>
        <clipPath id="clip0_16_1327">
          <rect width="17" height="17" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
