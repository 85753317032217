import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React, { useRef } from "react";
import IconArrow from "../../../images/jsIcons/IconArrow";
import ShowContainer from "../../mui/ShowContainer";
import CusCheckbox from "../../mui/CusCheckbox";
import { useSelector } from "react-redux";
import { useEventListener } from "usehooks-ts";

export default function HomeFilterMenu({
  buttonRef,
  onChangeItem,
  filtersCategory,
  filterOptions,
}) {
  const filterMenu = useSelector((state) => state.siteInfo.commonSiteInfo.options?.filter);
  const isMobileHeader = useSelector((state) => state.deviceSize.isMobileHeader);

  const filterMenuRef = useRef();

  const defaultOpen = (arr1) => {
    function checkChildrenValues(arr, valuesToCheck) {
      for (let obj of arr) {
        if (obj.children) {
          for (let childObj of obj.children) {
            if (valuesToCheck.includes(childObj.value)) {
              return true;
            }
          }
        }
      }
      return false;
    }

    try {
      let res = arr1.map((el) => el.value).some((el2) => filtersCategory.includes(el2));

      if (res) return true;
      if (arr1[0].children) return checkChildrenValues(arr1, filtersCategory);

      return false;
    } catch (e) {
      return false;
    }
  };

  const changeSizeFilterMenu = () => {
    const filterMenu = filterMenuRef.current;
    if (!filterMenu) return;
    const buttons = buttonRef.current;
    const bottomBorder = filterMenu.offsetTop + filterMenu.offsetHeight;

    if (
      // если это вариант для мобильного, то не стоит добавлять класс
      !isMobileHeader &&
      bottomBorder > window.innerHeight - buttons.offsetHeight * 2 + window.scrollY
    ) {
      buttons.classList.add("fixed-button");
    } else {
      buttons.classList.remove("fixed-button");
    }
  };

  useEventListener("scroll", () => {
    changeSizeFilterMenu();
  });

  return (
    <ul
      ref={filterMenuRef}
      className="home__filter__menu"
      id="home-filter-menu"
      onClick={() => setTimeout(changeSizeFilterMenu, 450)}
    >
      {filterMenu.map((el, indx) => (
        <li className="home__filter__menu__li" key={indx}>
          <Accordion
            className="home__filter__menu__accordion"
            defaultExpanded={defaultOpen(el.children)}
          >
            <AccordionSummary className="home__filter__menu__accordion__summary">
              <div className="container__icon">{el.icon}</div>
              <span>{el.title}</span>
              <IconArrow className={"arrow"} />
            </AccordionSummary>

            {el.children.map((elCHild, indx) => (
              <AccordionDetails className="home__filter__menu__accordion__details" key={indx}>
                <ShowContainer condition={elCHild.type === "checkbox"}>
                  <CusCheckbox
                    checked={filterOptions.includes(elCHild.value)}
                    title={elCHild.title}
                    id={elCHild.title}
                    count={elCHild.count}
                    onChange={() => onChangeItem(elCHild.value)}
                  />
                </ShowContainer>

                <ShowContainer condition={elCHild.type === "container" && elCHild.children?.length}>
                  <div className="home__filter__type__container">
                    <span>{elCHild.title}</span>
                    {elCHild.children?.map((child2, indx2) => (
                      <div key={indx2} className="home__filter__type__container__child">
                        <ShowContainer condition={child2.type === "checkbox"}>
                          <CusCheckbox
                            checked={filterOptions.includes(child2.value)}
                            title={child2.title}
                            id={child2.title}
                            count={child2.count}
                            onChange={() => onChangeItem(child2.value)}
                          />
                        </ShowContainer>
                      </div>
                    ))}
                  </div>
                </ShowContainer>
              </AccordionDetails>
            ))}
          </Accordion>
        </li>
      ))}
    </ul>
  );
}
