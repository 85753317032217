import React from "react";

export default function IconVibrator() {
  return (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.99902 5.33459C4.72397 5.33459 4.50098 5.55759 4.50098 5.83264V11.1673C4.52548 11.8274 5.4728 11.8269 5.49707 11.1673V5.83264C5.49707 5.55755 5.27408 5.33459 4.99902 5.33459Z"
        fill="white"
        fillOpacity="0.6"
      />
      <path
        d="M13.001 5.33459C12.7259 5.33459 12.5029 5.55759 12.5029 5.83264V11.1673C12.5274 11.8274 13.4748 11.8269 13.499 11.1673V5.83264C13.499 5.55755 13.276 5.33459 13.001 5.33459Z"
        fill="white"
        fillOpacity="0.6"
      />
      <path
        d="M2.86523 6.93506C2.59018 6.93506 2.36719 7.15805 2.36719 7.43311V9.56697C2.39169 10.2271 3.33901 10.2266 3.36328 9.56697V7.43311C3.36328 7.15805 3.14032 6.93506 2.86523 6.93506Z"
        fill="white"
        fillOpacity="0.6"
      />
      <path
        d="M15.135 6.93506C14.86 6.93506 14.637 7.15805 14.637 7.43311V9.56697C14.6615 10.2271 15.6088 10.2266 15.6331 9.56697V7.43311C15.6331 7.15805 15.4101 6.93506 15.135 6.93506Z"
        fill="white"
        fillOpacity="0.6"
      />
      <path
        d="M11.3652 3.16482C11.3652 1.41372 9.94833 0 8.19985 0C6.79141 0 5.75902 1.32245 6.10032 2.68899L6.63489 4.82704V13.87H11.3652V3.16482H11.3652ZM9.4981 12.2343C9.47359 12.8944 8.52627 12.8939 8.502 12.2343V11.1673C8.52651 10.5072 9.47382 10.5077 9.4981 11.1673V12.2343Z"
        fill="white"
        fillOpacity="0.6"
      />
      <path
        d="M9.00004 17C10.2304 17 11.2378 16.0638 11.3538 14.8661H6.64624C6.76235 16.0656 7.77153 17 9.00004 17Z"
        fill="white"
        fillOpacity="0.6"
      />
    </svg>
  );
}
