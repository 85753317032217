import React, { useEffect } from "react";

export default function ShowContainer({ condition, children, successCondition, errorCondition }) {
  useEffect(() => {
    if (condition) successCondition?.();
    if (!condition) errorCondition?.();
  }, [condition]);

  return !!condition && <>{children}</>;
}
