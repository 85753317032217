import { Segmented } from "antd";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTypeModel } from "../../../store/commonSlice";
import { useNavigate } from "react-router-dom";

export default function HeaderSegment({ isTopSegment, classNameSegment = "" }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mobileHeader = useSelector((state) => state.deviceSize.isMobileHeader);
  const width = useSelector((state) => state.deviceSize.width);

  const optionsSex = useSelector((state) => state.siteInfo.commonSiteInfo.options?.sex);
  const modelTypes = useSelector((state) => state.common.typeModel);

  const onSegmentedChange = (val) => dispatch(setTypeModel(val));

  const topSegmentClass = isTopSegment && mobileHeader ? "top-segment" : "";

  const createTemplateSegment = (icon, title = "") => (
    <div className="header__segment__label">
      {icon}
      <span>{title}</span>
    </div>
  );

  const segmentType = useMemo(
    () =>
      optionsSex.map((el) => ({
        label: createTemplateSegment(
          el.icon,
          width < 820 && width > 640 ? el.titleShort : el.title
        ),
        value: "" + el.value,
      })),
    [optionsSex, width]
  );

  return (
    <div className={`header__segment ${topSegmentClass} ${classNameSegment}`}>
      <Segmented
        onChange={onSegmentedChange}
        options={segmentType}
        value={"" + modelTypes}
        rootClassName="header__segment__root"
        classID="header__segment__id"
        onClick={() => setTimeout(() => navigate("/"), 500)}
        className="header__segment__some"
      />
    </div>
  );
}
